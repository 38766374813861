




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, THIS_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdOutlineRule, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import TabPanel from "../ReusableComponents/TabPanel";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import Redacoes from '../Redacoes/Redacoes';
import Provas from '../Provas/Provas';
import CandidatosVinculados from '../CandidatosVinculados/CandidatosVinculados';
import FiscaisVinculados from '../FiscaisVinculados/FiscaisVinculados';
import CorretoresVinculados from '../CorretoresVinculados/CorretoresVinculados';
import SalasProcesso from '../SalasProcesso/SalasProcesso';
import QuestoesProva from './QuestoesProva';
import ResultadosProva from './ResultadosProva';
import ProvasModelos from './ProvasModelos';


class ShowProvasProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            processo: {},
            prova: {questoes:[]},
            show_more: false,

            activeTab: 0
        };

    }


    get_prova_processo(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_prova_processo/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_prova_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, prova: resp.prova, processo: resp.prova.processo
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    componentDidMount() {
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.get_prova_processo(this.props.match.params.id);
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div style={{ position: "relative" }}>
                <div className="card">
                    <div className="card-body">
                        <div style={{ float: 'right' }}>
                            <DefaultButton
                                bg="secondary"
                                text="Voltar"
                                icon={<RiArrowGoBackLine />}
                                search={'?tab=1'}
                                to={`/processos/show/${this.state.processo.id}`}
                            />
                        </div>
                        <div className="flex jcsb">
                            <h4 className="header">{this.state.prova.name}</h4>
                        </div>
                        <div>
                            <p className="text-muted" style={{marginBottom:0}}><a target="_blank" href={THIS_URL+'prova/'+this.state.prova.uuid}>{'Visualizar a prova externamente'}</a></p>
                        </div>


                        {/* <p className="ver_regras" onClick={() => {
                            this.setState({ show_more: true })
                        }} style={{ float: "left", marginRight: "2px" }}> <b style={{ float: "left", marginRight: "2px", color: 'black', marginRight: 2 }}>Enunciado: </b> <MdOutlineRule /> {'Ver Enunciado'} </p>
                        <ModalRedacao showModal={this.state.show_more} setShowModal={(e) => this.setState({ show_more: e })} prova_name={this.state.prova.name} regras={this.state.prova.enunciado} /> */}

                        {/* <div style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre',height:this.state.show_more==false?100:'fit-content',overflowY:this.state.show_more==false? 'hidden':'visible' }}>
                            <b style={{ float: "left", marginRight: "2px" }}>{'Regras:'} </b>
                            <br/>
                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.state.processo.regras }}>
                            </div>
                        </div> */}
                        {/* <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div className="course-banner" style={{
                                backgroundImage: `url(${STORAGE_URL+ this.state.faculdade.logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat:'no-repeat',
                                backgroundPositionX:'center',
                                width: "100%",
                                height: "200px",
                                borderRadius: "7px"
                            }} />
                        </div> */}
                        <br />
                        <br />

                        <Tabs
                            value={this.state.activeTab}
                            onChange={(_, value) => {
                                this.setState({ activeTab: value });
                                this.props.history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label="Questões" />
                            {this.state.prova?.processo?.online==true && <Tab label="Resultados" />}


                        </Tabs>
                        <TabPanel value={this.state.activeTab} index={0}>
                            {this.state.prova.random_layouts_max== false && <QuestoesProva prova_name={this.state.prova.name} questoes={this.state.prova.questoes} processo_name={this.state.processo.name}  />}
                            {this.state.prova.random_layouts_max== true && <ProvasModelos provas={this.state.prova.provas} prova_name={this.state.prova.name} processo_name={this.state.processo.name}  questoes={this.state.prova.questoes} />}
                        </TabPanel>
                        {this.state.prova?.processo?.online==true && <TabPanel value={this.state.activeTab} index={1}>
                            {/* <Corretores faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} /> */}
                            <ResultadosProva prova={this.state.prova} models={this.state.prova.provas.map((item,id2)=>{return{id:item.id,name:'Modelo '+item.code_exam}})} processo_name={this.state.processo.name} processo_id={this.state.processo.id} prova_name={this.state.prova.name} prova_id={this.state.prova.id} prova_type={this.state.prova.type} />

                        </TabPanel>}

                    </div>
                </div>

            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowProvasProcesso);


