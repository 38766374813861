// Libraries imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Components imports
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";

// Utils imports
import { URL } from "../../variables";
import { logout } from "../../actions/AppActions";
import { get } from "../../Services/api";
import { RiArrowGoBackLine } from "react-icons/ri";
import { handleFileChange } from "../../Auxiliar/fileUtils";

const EditarConteudo = ({ match, token }) => {
  const [redirect, setRedirect] = useState(false);
  const [path, setPath] = useState("/conteudos");
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [has_questions, setHasQuestions] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [msgErro, setMsgErro] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [newThumbnail, setNewThumbnail] = useState(null);

  useEffect(() => {
    const { id } = match.params;
    getContent(id);
  }, [match.params, token]);

  const getContent = async (id) => {
    try {
      const resp = await get(`api/conteudo/get_content/${id}`, token);
      const { name, type, file, thumbnail, has_questions } = resp.data;
      const videoFileName = file.replace("conteudos/", "");
      setLoading(false);
      setName(name);
      setType(type);
      setFile(file);
      setHasQuestions(has_questions);
      setVideoFileName(videoFileName);
      setThumbnail(thumbnail);
    } catch (error) {
      console.error("Erro:", error);
      setRedirect(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "type":
        setType(value);
        setFilePreview(null);
        setVideoPreview(null);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    salvar();
  };

  const handleChangeTypeSelect = (e) => {
    setType(e.target.value);
  };

  const salvar = () => {
    const { id } = match.params;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    formData.append("has_questions", has_questions ? "true" : "false");
    if (newFile !== null) {
      formData.append("newFile", newFile);
    }
    if (newThumbnail !== null) {
      formData.append("newThumbnail", newThumbnail);
    }
    setLoadingSave(true);
    setMsgErro("");
    fetch(`${URL}api/conteudo/edit_content/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then(async (responseLog) => {
        const resp = await responseLog.json();
        if (!responseLog.ok || resp.errors || resp.error) {
          const errors = resp.errors
            ? Object.values(resp.errors)
            : [resp.error];
          const erro = errors.join("\n");
          console.log(erro);
          setLoadingSave(false);
          setMsgErro(erro);
          console.log("dados: ", file);
        } else {
          setLoadingSave(false);
          setRedirect(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingSave(false);
        setMsgErro(
          "Erro ao pegar resposta do servidor. Você está conectado à internet?"
        );
      });
  };

  const handlePdfFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      if (newFile !== file) {
        handleFileChange(
          newFile,
          setFilePreview,
          setNewFile,
          setThumbnail,
          setThumbnailPreview,
          setFile,
          null
        );
      }
    }
  };

  const handleVideoFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      if (newFile !== file) {
        handleFileChange(
          newFile,
          setVideoPreview,
          setNewFile,
          setThumbnail,
          setThumbnailPreview,
          setFile,
          null
        );
      }
    }
  };

  const handleCapaImageFileChange = (event) => {
    const newThumbnailFile = event.target.files[0];
    if (newThumbnailFile) {
      if (newThumbnailFile !== thumbnail) {
        handleFileChange(
          newThumbnailFile,
          setThumbnailPreview,
          setNewThumbnail,
          setFile,
          setFilePreview,
          setThumbnail,
          null
        );
      }
    } else {
      setThumbnail(null);
      setThumbnailPreview(null);
    }
  };

  const removeFilePDF = (e) => {
    e.preventDefault();
    setFilePreview(null);
    setFile(null);
  };

  const removeFileAllVideo = (e) => {
    e.preventDefault();
    setVideoPreview(null);
    setFile(null);
    setThumbnailPreview(null);
    setThumbnail(null);
  };

  const removeFileVideo = (e) => {
    e.preventDefault();
    setVideoPreview(null);
    setFile(null);
  };

  const removeFileImage = (e) => {
    e.preventDefault();
    setThumbnailPreview(null);
    setThumbnail(null);
  };

  if (redirect) {
    return <Redirect to={path} />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card">
            <h4 className="mt-0 header-title">Editar Conteúdo</h4>
            <p>Cadastre os conteúdos do seu sistema</p>
            {!loading ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name">
                    Nome: <Attention />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="tipo">
                    Tipo <Attention />
                  </label>
                  <select
                    className="form-control"
                    id="tipo"
                    value={type}
                    onChange={handleChangeTypeSelect}
                    required
                  >
                    <option value="pdf">PDF</option>
                    <option value="video">Vídeo</option>
                  </select>
                </div>
                {type === "pdf" && (
                  <div className="form-group mb-3">
                    <label
                      htmlFor="pdfUpload"
                      className="label-custom d-block mb-2"
                    >
                      Upload do PDF (Caso seja o mesmo, não escolha nenhum
                      arquivo)
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control input-custom"
                        id="pdfUpload"
                        accept=".pdf"
                        onChange={handlePdfFileChange}
                      />
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={removeFilePDF}
                      >
                        Limpar arquivo
                      </button>
                    </div>
                    {file && !filePreview && (
                      <div className="mt-3">
                        <h5>PDF atual:</h5>
                        <iframe
                          src={`${URL}${file}`}
                          title="PDF Viewer"
                          width="100%"
                          height="500px"
                          style={{ border: "none" }}
                        />
                      </div>
                    )}
                    {filePreview && file && (
                      <div className="mt-3">
                        <label className="label-custom">
                          Pré-visualização do PDF
                        </label>
                        <iframe
                          src={filePreview}
                          style={{ width: "100%", height: "500px" }}
                          frameBorder="0"
                        />
                      </div>
                    )}
                  </div>
                )}
                {type === "video" && (
                  <div className="form-group mb-3">
                    <label
                      htmlFor="videoUpload"
                      className="label-custom d-block mb-2"
                    >
                      Upload do Vídeo (Caso seja o mesmo, não escolha nenhum
                      arquivo){" "}
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control input-custom"
                        id="videoUpload"
                        accept=".mp4,.avi,.mov,.wmv,.mkv,.flv,.webm,.mpeg,.mpg,.3gp,.m4v"
                        onChange={handleVideoFileChange}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={removeFileVideo}
                      >
                        Limpar
                      </button>
                    </div>
                    <div className="mt-3">
                      <label htmlFor="capaImage" className="label-custom">
                        Upload da Capa do Vídeo (Caso seja o mesmo, não escolha
                        nenhum arquivo){" "}
                      </label>
                      <div className="input-group">
                        <input
                          type="file"
                          className="form-control input-custom"
                          id="capaImage"
                          accept=".jpg,.jpeg,.png,.bmp,.tiff,.tif,.svg,.webp"
                          onChange={handleCapaImageFileChange}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={removeFileImage}
                        >
                          Limpar
                        </button>
                      </div>
                    </div>
                    {file && !videoPreview && (
                      <div className="mt-3">
                        <h5>Vídeo atual</h5>
                        <video
                          controls
                          preload="metadata"
                          poster={`${URL}${thumbnail}`}
                          className="img-thumbnail"
                          style={{ maxWidth: "100%" }}
                        >
                          <source src={`${URL}${file}`} type="video/mp4" />
                          Seu navegador não suporta o elemento de vídeo.
                        </video>
                      </div>
                    )}
                    {videoPreview && (
                      <div className="mt-3">
                        <h5>Vídeo atual</h5>
                        <video
                          controls
                          src={videoPreview}
                          className="img-thumbnail"
                          style={{ maxWidth: "100%" }}
                        />
                      </div>
                    )}
                    {thumbnailPreview && (
                      <div className="mt-2">
                        <h5>Capa do vídeo atual</h5>
                        <img
                          src={thumbnailPreview}
                          alt="Pré-visualização"
                          className="img-thumbnail"
                          style={{ maxWidth: "100%" }}
                        />
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn btn-danger mt-3"
                      onClick={removeFileAllVideo}
                    >
                      Limpar todos os arquivos
                    </button>
                  </div>
                )}
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="has_questions"
                    checked={has_questions}
                    onChange={() => setHasQuestions(!has_questions)}
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="has_questions"
                    style={{ cursor: "pointer" }}
                  >
                    Há questões neste conteúdo?
                  </label>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/conteudos"
                  />
                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar conteúdo"
                    loading={loadingSave}
                    disabled={loadingSave}
                  />
                </div>
                {msgErro && (
                  <div className="alert alert-danger" role="alert">
                    {msgErro}
                  </div>
                )}
              </form>
            ) : (
              <div className="d-flex justify-content-center mt-3">
                <div className="spinner-border text-primary" role="status" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(EditarConteudo);
