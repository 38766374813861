import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";

import { Button } from "react-bootstrap";
import Request from "../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFilePdf, BsFilePdfFill, BsFillEyeFill } from "react-icons/bs";
import { logout } from "../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import { URL } from "../variables";



const ProvaUuid = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(false);
    const [loading_questoes, setLoadingquestoes] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [questoes, setquestoes] = useState([]);

    const [prova, setProva] = useState({ questoes: [] });

    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, prova_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [questoes_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();
    const { uuid } = useParams()

    const Getquestoes = async (uuid) => {
        setLoadingScreen(true);
        let response = await Request.Get(`faculdade/get_prova_from_uuid/${uuid}`);
        console.log(response);
        console.log(uuid)
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }

        setProva(response.prova);

        setLoadingScreen(false);
        setLoadingquestoes(false);


    }






    const DeleteRedacao = async (questoes_id) => {

        let response = await Request.Delete(`faculdade/desvincular_questao/${questoes_id}/${processo_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getquestoes(uuid);



    }

    useEffect(() => {
        Getquestoes(uuid);
        // setquestoes(props.questoes);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    let number_questao = 0;

    return (
        <div style={{ position: "relative", padding: '1.25rem', backgroundColor: 'white' }}>
            <div className="card">
                <div className="card-body">
                    <div className="row" style={{margin:0}}>

                        <div className="col-12">


                            {/* <hr /> */}
                            {loading_screen == false && <div>
                                <h4 className="header">{prova.name}</h4>

                                {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                                {/* <br /> */}

                                {prova.questoes.map((item, id) => {
                                    if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                                        number_questao = number_questao + 1;
                                        console.log(item.id+' - '+ number_questao)
                                    }
                                    return (
                                        <div key={id}>
                                            <hr />

                                            <h6 className="mt-0 header-title" style={{ textAlign: 'right', marginBottom: 10, fontSize: 15 }}>
                                                {item.categoria.name}</h6>

                                            {item.type_questao == 'dissertativa' && <div className="row" style={{ fontWeight: 'normal' }}>
                                                <div className="col-2 col-sm-1">
                                                    <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                        <b>{number_questao})</b></h5>
                                                </div>
                                                <div className="col-10 col-sm-11">


                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                                    </div>
                                                </div>
                                            </div>}
                                            {/* {!!Form::open(['action' =>
['ProcessoUserController@responder_questao'],'data-toggle'=>'validator','files' =>
false,'method'=>'post','id'=>$data['prova']->id.'_'.$questao->id]) !!} */}
                                            {item.type_questao == 'simples' && <div className="row" style={{ fontWeight: 'normal' }}>
                                                <div className="col-2 col-sm-1">
                                                    <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                        <b>{number_questao})</b></h5>
                                                </div>
                                                <div className="col-10 col-sm-11">


                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                                    </div>
                                                    <p><b>Alternativas:</b></p>
                                                    <div style={{ border: item.correta == 'a' ? '1px solid green' : '0px solid black', display: 'flex', marginTop: 10 }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ fontWeight: item.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[0].name }}>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    {item.alternativas[1] != undefined && <div style={{ border: item.correta == 'b' ? '1px solid green' : '0px solid black', display: 'flex', marginTop: 10 }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ fontWeight: item.correta == 'b' ? 'bold' : 'normal' }}>b)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[1].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                    {item.alternativas[2] != undefined && <div style={{ display: 'flex', border: item.correta == 'c' ? '1px solid green' : '0px solid black', marginTop: 10 }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ fontWeight: item.correta == 'c' ? 'bold' : 'normal' }}>c)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[2].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                    {item.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10, border: item.correta == 'd' ? '1px solid green' : '0px solid black', }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ fontWeight: item.correta == 'd' ? 'bold' : 'normal' }}>d)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[3].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}

                                                    {item.alternativas[4] != undefined && <div style={{ border: item.correta == 'e' ? '1px solid green' : '0px solid black', display: 'flex', marginTop: 10 }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ fontWeight: item.correta == 'e' ? 'bold' : 'normal' }}>e)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[4].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                </div>
                                            </div>}

                                            {item.type_questao == 'conjunto' && <div className="row" style={{ fontWeight: 'normal',margin:0 }}>

                                                <div className="col-12 col-sm-12">


                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                                    </div>
                                                    {item.questoes.map((item2, id2) => {
                                                        number_questao = number_questao + 1;

                                                        return (
                                                            <div key={id2}>
                                                                <hr />

                                                                <div className="row" style={{ fontWeight: 'normal' }}>
                                                                    <div className="col-2 col-sm-1">
                                                                        <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                            <b>{number_questao})</b></h5>
                                                                    </div>
                                                                    <div className="col-10 col-sm-11">


                                                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.name }}>

                                                                        </div>
                                                                        <p><b>Alternativas:</b></p>
                                                                        <div style={{ border: item2.correta == 'a' ? '1px solid green' : '0px solid black', display: 'flex', marginTop: 10 }}>
                                                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                <p style={{ fontWeight: item2.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                                            </div>
                                                                            <div style={{ width: '90%' }}>

                                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[0].name }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {item2.alternativas[1] != undefined && <div style={{ border: item2.correta == 'b' ? '1px solid green' : '0px solid black', display: 'flex', marginTop: 10 }}>
                                                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                <p style={{ fontWeight: item2.correta == 'b' ? 'bold' : 'normal' }}>b)</p>
                                                                            </div>
                                                                            <div style={{ width: '90%' }}>

                                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[1].name }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}
                                                                        {item2.alternativas[2] != undefined && <div style={{ display: 'flex', border: item2.correta == 'c' ? '1px solid green' : '0px solid black', marginTop: 10 }}>
                                                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                <p style={{ fontWeight: item2.correta == 'c' ? 'bold' : 'normal' }}>c)</p>
                                                                            </div>
                                                                            <div style={{ width: '90%' }}>

                                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[2].name }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}
                                                                        {item2.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10, border: item2.correta == 'd' ? '1px solid green' : '0px solid black', }}>
                                                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                <p style={{ fontWeight: item2.correta == 'd' ? 'bold' : 'normal' }}>d)</p>
                                                                            </div>
                                                                            <div style={{ width: '90%' }}>

                                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[3].name }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}

                                                                        {item2.alternativas[4] != undefined && <div style={{ border: item2.correta == 'e' ? '1px solid green' : '0px solid black', display: 'flex', marginTop: 10 }}>
                                                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                <p style={{ fontWeight: item2.correta == 'e' ? 'bold' : 'normal' }}>e)</p>
                                                                            </div>
                                                                            <div style={{ width: '90%' }}>

                                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[4].name }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>


                                            </div>}

                                        </div>)
                                })}



                                {loading_questoes == false && prova.questoes.length == 0 && <div className="row w-100">
                                    <div className="col-12">
                                        <p style={{ textAlign: 'center' }}>Nenhuma questão encontrada</p>

                                    </div>
                                </div>}



                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_questoes={this.get_questoes.bind(this)} {...this.props} />} */}

                            </div>}

                            {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                                <CircularProgress />
                            </div></div>}

                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => {
                                    setShowWarning(false);
                                    setLoadingquestoes(true);
                                    DeleteRedacao(questoes_id);
                                    // this.delete_banner(this.state.banner_to_delete);
                                }}
                                onCancel={() => {
                                    setShowWarning(false);
                                }}
                                show={show_warning}
                                confirmBtnText='Sim, desejo desvincular'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Ao desvincular este questao, ele não terá mais acesso as questoes deste processo
                            </SweetAlert>


                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ProvaUuid;
