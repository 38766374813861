// Libraries imports
import React, { useState, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Redirect } from "react-router-dom";

// Components imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiArrowGoBackLine, RiCloseCircleLine } from "react-icons/ri";
import Skeleton from "@material-ui/lab/Skeleton";
import Attention from "../ReusableComponents/Attention";

// Utils imports
import { get, post } from "../../Services/api";
import { logout } from "../../actions/AppActions";

const CriarTreinamento = ({ token }) => {
  const [formData, setFormData] = useState({
    nome: "",
    descricao: "",
    loadingSave: false,
    msgErro: "",
    modulos: [],
    loading: true,
    modulo_ids: [],
    selectedModulos: [],
    redirect: false,
  });

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getModulos = async () => {
      try {
        const data = await get("api/modulo/get_modules", token);

        if (data.errors) {
          let errors = Object.values(data.errors);
          let errorMsg = errors.join("\n");
          console.log(errorMsg);
        } else {
          setFormData((prevState) => ({
            ...prevState,
            modulos: data.data,
            loading: false,
          }));
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.log(error.message || "Erro ao buscar módulos");
          setFormData((prevState) => ({
            ...prevState,
            msgErro: error.message || "Erro ao buscar módulos",
            loading: false,
          }));
        }
      }
    };

    getModulos();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (id, e) => {
    setFormData((prevState) => {
      const modulo_ids = prevState.modulo_ids.includes(id)
        ? prevState.modulo_ids.filter((moduloId) => moduloId !== id)
        : [...prevState.modulo_ids, id];

      const selectedModulos = prevState.modulos.filter((modulo) =>
        modulo_ids.includes(modulo.id)
      );

      return {
        ...prevState,
        modulo_ids,
        selectedModulos,
      };
    });
  };

  const salvar = async (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      loadingSave: true,
    }));

    try {
      const requestBody = {
        nome: formData.nome,
        descricao: formData.descricao,
        modulo_ids: formData.selectedModulos.map((modulo) => modulo.id),
      };

      const responseData = await post(
        "api/treinamento/store_training",
        requestBody,
        token
      );

      if (responseData.errors) {
        throw new Error(responseData.errors);
      }

      setFormData((prevState) => ({
        ...prevState,
        msgErro: "Treinamento cadastrado com sucesso",
        redirect: true,
      }));
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao cadastrar treinamento",
      }));
    } finally {
      setFormData((prevState) => ({
        ...prevState,
        loadingSave: false,
      }));
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const selectedModulos = [...formData.selectedModulos];
    const [reorderedItem] = selectedModulos.splice(result.source.index, 1);
    selectedModulos.splice(result.destination.index, 0, reorderedItem);

    setFormData((prevState) => ({
      ...prevState,
      selectedModulos,
    }));
  };

  const handleRemoveModulo = (id) => {
    setFormData((prevState) => {
      const modulo_ids = prevState.modulo_ids.filter(
        (moduloId) => moduloId !== id
      );
      const selectedModulos = prevState.selectedModulos.filter(
        (modulo) => modulo.id !== id
      );

      return {
        ...prevState,
        modulo_ids,
        selectedModulos,
      };
    });
  };

  if (formData.redirect) {
    return <Redirect to="/treinamentos" />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div
            className="card-body card-body-custom"
            id="card"
            style={{ overflow: "visible" }}
          >
            <h4
              className="mt-0 header-title header-title-custom"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Cadastrar Treinamento
            </h4>
            <p
              className="paragraph-custom"
              style={{ fontSize: "1rem", color: "black" }}
            >
              Cadastre os treinamentos do seu sistema
            </p>
            <br />
            {formData.msgErro && (
              <div style={{ display: "flex", marginBottom: 15, width: "100%" }}>
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        msgErro: "",
                      }))
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{formData.msgErro}</p>
                </div>
              </div>
            )}
            {!formData.loading ? (
              <form onSubmit={salvar} className="position-relative">
                <div className="form-group flex fdcolumn flex1 margin-bottom">
                  <label htmlFor="nome">
                    Nome <Attention />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nome"
                    name="nome"
                    placeholder="Nome do treinamento"
                    value={formData.nome}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group flex fdcolumn flex1 margin-bottom">
                  <label htmlFor="descricao">Descrição</label>
                  <textarea
                    className="form-control"
                    id="descricao"
                    name="descricao"
                    placeholder="Descrição do treinamento"
                    value={formData.descricao}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group flex fdcolumn flex1 margin-bottom">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-modulo">
                      Selecione os Módulos
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      {formData.modulos.map((modulo, index) => (
                        <Dropdown.Item
                          key={index}
                          as="div"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            label={modulo.name}
                            checked={formData.modulo_ids.includes(modulo.id)}
                            onChange={() => {
                              handleSelectChange(modulo.id);
                            }}
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="modulos_vinculados">Módulos vinculados</label>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="modulos">
                      {(provided) => (
                        <ul
                          className="list-group"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {formData.selectedModulos.length === 0 ? (
                            <li className="list-group-item">
                              Nenhum conteúdo selecionado
                            </li>
                          ) : (
                            formData.selectedModulos.map((modulo, index) => (
                              <Draggable
                                key={modulo.id.toString()}
                                draggableId={modulo.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {modulo.name}
                                    <button
                                      type="button"
                                      className="btn btn-link p-0"
                                      onClick={() =>
                                        handleRemoveModulo(modulo.id)
                                      }
                                    >
                                      <RiCloseCircleLine
                                        size={20}
                                        color="red"
                                      />
                                    </button>
                                  </li>
                                )}
                              </Draggable>
                            ))
                          )}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="w-100 d-flex justify-content-between mt-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/treinamentos"
                  />

                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar treinamento"
                    disabled={formData.loadingSave}
                    loadingtext="Salvando treinamento"
                    loading={formData.loadingSave}
                  />
                </div>
              </form>
            ) : (
              <div>
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(CriarTreinamento);
