import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';

const ModalRedacao = props => {
    const { redacao_name, showModal, setShowModal,regras } = props;
    return (
        regras != null && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Enunciado da redação {redacao_name}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <div className="custom_scrollbar">

                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: regras }}>

                    </div>
                </div>

            </Modal.Body>
        </Modal>

    );
}

export default ModalRedacao;