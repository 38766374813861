
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdArrowDropDown, MdArrowDropUp, MdDelete, MdFileDownload, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner, FormCheck } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';



import { FormControlLabel, Radio, TextField } from '@material-ui/core';
import './label.css';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import { BsFile, BsFillEyeFill } from 'react-icons/bs';

import DragDropTable from './DragDropTable';
import ModalPalavraChave from './ModalPalavraChave';
import { RadioGroup } from '@mui/material';

class CriarProva extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            enunciado: '',
            msgErroTextoApoio: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            inicio: moment(),
            processo: { name: '' },
            arquivos: [],
            texto: '',
            titulo: '',
            texto_value: '',
            questao_enunciado: '',
            show_autocomplete_questao: false,
            loading_search_questao: false,
            questoes: [],
            questoes_selecionadas: {},
            categorias: [],
            categoria: { id: '' },

            showModal: false,
            questaoSelect: null,
            loading_get_all: false,
            questoes_array: [],
            tipos_prova: [
                {
                    id: 'multipla_escolha',
                    name: 'Prova objetiva'
                },
                {
                    id: 'dissertativa',
                    name: 'Prova dissertativa'
                }
            ],
            type: 'multipla_escolha',
            showModalPalavraChave: false,
            random_layouts_max:false,
            time:0,
            time_per_question:3,
            number_exams:1,
            aleatorizar_questoes: false,
            aleatorizar_alternativas: false,
            one_question_per_page: false,
            select_from_group: false,
            number_select_questions: 0,
            random_per_category: false,
            categorias_selecionadas: [],
            exibicao: 'folha_respostas'

        };


    }

    get_processo(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_dados_prova/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, processo: resp.processo,
                        categorias: resp.categorias,
                        one_question_per_page: resp.processo.exam_per_time==true?true:false
                    }, () => {
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let data = {};
        data.name = this.state.name;
        if (Object.values(this.state.questoes_selecionadas).length == 0) {
            this.setState({ loading_save: false, msgErro: 'A prova deve ter pelo menos uma questão' });
            return;
        }
        let questoes = this.state.questoes_array.map((item, id) => item.id);
        data.questoes = questoes;
        data.processo_id = this.state.processo.id;
        data.type = this.state.type;
        data.aleatorizar_questoes = this.state.aleatorizar_questoes;
        data.aleatorizar_alternativas = this.state.aleatorizar_alternativas;
        data.one_question_per_page = this.state.processo.exam_per_time==true?true: this.state.one_question_per_page;
        data.select_from_group = this.state.select_from_group;
        data.number_select_questions = this.state.number_select_questions;
        data.random_per_category = this.state.random_per_category;

        data.random_layouts_max = this.state.random_layouts_max;
        data.time = this.state.time_per_question*Object.values(this.state.questoes_selecionadas).map((item, id) => {
            if (item.type_questao == 'conjunto') {
                return item.questoes.length;
            }
            else {
                return 1;
            }
        }).reduce((a, b) => a + b, 0) ?? 0;

        data.time_per_question=this.state.time_per_question;

        data.number_exams = this.state.random_layouts_max==true? this.state.number_exams:null;

        data.categorias_selecionadas = this.state.categorias_selecionadas.sort((a, b) => a.order - b.order).map((item, id) => item.id);
        data.categorias_number = this.state.categorias_selecionadas.sort((a, b) => a.order - b.order).map((item, id) => item.number);
        data.exibicao = this.state.exibicao;

        fetch(`${URL}api/faculdade/store_prova`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }




    componentDidMount() {
        this.get_processo(this.props.match.params.id);
    }
    componentDidUpdate(prevProps, prevState) {
        if (Object.values(prevState.questoes_selecionadas).length != Object.values(this.state.questoes_selecionadas).length) {
            this.parseQuestoesDragDrop();
        }
    }

    parseQuestoesDragDrop() {
        let questoes = [...this.state.questoes_array];
        let ids = questoes.map((item, id) => item.id);
        let ids_exists = []

        let categorias_selecionadas = [...this.state.categorias_selecionadas];
        let ids_categoria = categorias_selecionadas.map((item, id) => item.id);
        let ids_exists_categoria = []

        for (let i = 0; i < Object.values(this.state.questoes_selecionadas).length; i++) {

            let item = Object.values(this.state.questoes_selecionadas)[i];
            ids_exists.push(item.id);
            ids_exists_categoria.push(item.categoria.id);

            if (!ids.includes(item.id)) {
                questoes.push({
                    numero: i + 1,
                    id: item.id,
                    enunciado: <p className='text_overflow'>{item.text_questao}</p>,
                    categoria: item.categoria.name,
                    tipo_questao: <p style={{ whiteSpace: 'pre' }}>{item.type_questao == 'conjunto' ? ('Conjunto de questões\n' + item.questoes.length + (item.questoes.length == 1 ? ' questão' : ' questões')) :
                        (item.type_questao == 'simples' ? 'Questão única' : 'Questão dissertativa')}</p>,
                    acoes: i
                });
            }

            if (!ids_categoria.includes(item.categoria.id)) {
                categorias_selecionadas.push({ ...item.categoria, order: categorias_selecionadas.length, number: 1 });
                ids_categoria.push(item.categoria.id);
            }
        }
        questoes = questoes.filter((item, id) => {
            if (ids_exists.includes(item.id)) {
                return true;
            }
            return false;
        })

        categorias_selecionadas = categorias_selecionadas.filter((item, id) => {
            if (ids_exists_categoria.includes(item.id)) {
                return true;
            }
            return false;
        })

        this.setState({ questoes_array: questoes, categorias_selecionadas });
    }



    get_questoes_categoria(categoria_id) {
        if (this.state.loading_get_all == false) {
            this.setState({ loading_get_all: true });
        }
        fetch(`${URL}api/faculdade/get_questoes_categoria/${categoria_id}?type=${this.state.type}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_get_all: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let questoes = { ...this.state.questoes_selecionadas };
                    for (let i = 0; i < resp.questoes.length; i++) {
                        if (questoes[resp.questoes[i].id] == undefined) {
                            questoes[resp.questoes[i].id] = resp.questoes[i];
                        }
                    }

                    this.setState({ loading_get_all: false, questao_enunciado: '', show_autocomplete_questao: false, questoes_selecionadas: questoes });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_get_all: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_get_all: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_questao_search(search, categoria_id) {
        if (this.state.loading_search_questao == false) {
            this.setState({ loading_search_questao: true });
        }
        fetch(`${URL}api/faculdade/get_questoes_search?page=1&search=${search}&categoria_id=${categoria_id}&type=${this.state.type}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_questao: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_questao: false, questoes: resp.questoes });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_questao: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_questao: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    select_questao(item) {
        let questoes = { ...this.state.questoes_selecionadas };
        if (questoes[item.id] == undefined) {
            questoes[item.id] = item;
        }
        this.setState({ questao_enunciado: '', show_autocomplete_questao: false, questoes_selecionadas: questoes })
    }


    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }




    render() {


        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/processos/show/${this.props.match.params.id}?tab=1`} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>

                            <div hidden id="text_enunciado">

                            </div>
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar prova</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre as provas {(this.props.user.empresa == null || this.props.user.empresa.change_text == false) ? 'do processo' : 'da vaga/cargo'} {this.state.processo.name}</p>
                            <br />
                            <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} />
                            <ModalPalavraChave saveQuestoes={(questoes_selecionadas) => {
                                let questoes = { ...this.state.questoes_selecionadas };
                                for (let i = 0; i < Object.keys(questoes_selecionadas).length; i++) {
                                    if (questoes[Object.values(questoes_selecionadas)[i].id] == undefined) {
                                        questoes[Object.values(questoes_selecionadas)[i].id] = Object.values(questoes_selecionadas)[i];
                                    }
                                }
                                this.setState({ questoes_selecionadas: questoes, showModalPalavraChave: false });
                            }} type={this.state.type} showModal={this.state.showModalPalavraChave} setShowModal={(e) => this.setState({ showModalPalavraChave: e })} categoria={this.state.categoria} />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0 }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Tipo de prova <Attention /></Form.Label>

                                            <select disabled={this.state.loading_get_all} className="form-select " onChange={(e) => {
                                                this.setState({ categoria: { id: '' }, exibicao: e.target.value == 'dissertativa' ? 'none' : this.state.exibicao, one_question_per_page: e.target.value == 'dissertativa' ? true : this.state.one_question_per_page, type: e.target.value, questao_enunciado: '', questoes: [], questoes_selecionadas: {}, questoes_array: [] });
                                            }} value={this.state.type}>
                                                {(this.state.tipos_prova && this.state.tipos_prova.length != 0) && this.state.tipos_prova.map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value["id"]}
                                                    >{value["name"]}</option>
                                                ))}
                                            </select>

                                        </div>

                                    </InputGroup>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder="Nome da prova"
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>
                                    <hr />
                                    <h5 style={{ fontWeight: 'bold' }}>Configurações adicionais</h5>
                                    <br />

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Ordem das questões <Attention /></Form.Label>

                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={this.state.aleatorizar_questoes}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ aleatorizar_questoes: e.target.value });
                                                }}>

                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ aleatorizar_questoes: false,random_layouts_max:this.state.aleatorizar_alternativas==true?this.state.random_layouts_max:false });
                                                }} control={<Radio checked={this.state.aleatorizar_questoes == false} value={false} />} style={{ color: 'black' }} label={'Questões na ordem de cadastro'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ aleatorizar_questoes: true });

                                                }} control={<Radio checked={this.state.aleatorizar_questoes == true} value={true} />} style={{ color: 'black' }} label={'Questões em ordem aleatória'} />
                                            </RadioGroup>

                                        </div>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                        </div>

                                    </InputGroup>
                                    {this.state.aleatorizar_questoes == true && <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Seleção das questões <Attention /></Form.Label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group1"
                                                value={this.state.select_from_group}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ select_from_group: e.target.value });
                                                }}>


                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ select_from_group: false });

                                                }} control={<Radio checked={this.state.select_from_group == false} value={false} />} style={{ color: 'black' }} label={'Usar todas questões selecionadas'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ select_from_group: true });
                                                }} control={<Radio checked={this.state.select_from_group == true} value={true} />} style={{ color: 'black' }} label={'Selecionar questões aleatórias para a prova de cada candidato'} />
                                                {/* <Form.Label>Número de questões por prova <Attention /></Form.Label> */}

                                            </RadioGroup>
                                        </div>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Forma de aleatorização <Attention /></Form.Label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group0"
                                                value={this.state.random_per_category}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ random_per_category: e.target.value });
                                                }}>


                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ random_per_category: false });

                                                }} control={<Radio checked={this.state.random_per_category == false} value={false} />} style={{ color: 'black' }} label={'Seguindo a ordem das disciplinas'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ random_per_category: true });
                                                }} control={<Radio checked={this.state.random_per_category == true} value={true} />} style={{ color: 'black' }} label={'Questões de forma aleatória independente da disciplina'} />
                                            </RadioGroup>
                                            {this.state.random_per_category == false && <>
                                                <Form.Label className='mt-1'>Ordem das disciplinas: </Form.Label>
                                                {this.state.categorias_selecionadas.sort((a, b) => a.order - b.order).map((item, id) => (
                                                    <div key={id} className="d-flex justify-content-between mt-2">
                                                        <p>{item.name}</p>
                                                        <div style={{ marginRight: 15, display: 'flex' }}>
                                                            {this.state.select_from_group == true && <Input
                                                                onWheel={(e) => e.target.blur()}

                                                                placeholder="Número de questões que devem ser selecionadas"
                                                                value={item.number}
                                                                type="number"
                                                                min={1}
                                                                onChange={(e) => {
                                                                    let categorias_selecionadas = this.state.categorias_selecionadas.map((item2, id2) => {
                                                                        if (item2.id == item.id) {
                                                                            item2.number = e.target.value;
                                                                        }
                                                                        return item2;
                                                                    });
                                                                    this.setState({ categorias_selecionadas }, () => {
                                                                        console.log(categorias_selecionadas)
                                                                    })
                                                                }}
                                                                required
                                                                style={{ maxWidth: 80 }}
                                                            // max={Object.values(this.state.questoes_selecionadas).filter((item2,id2)=>{
                                                            //     if(item2.categoria_id==item.id){
                                                            //         return true;
                                                            //     }
                                                            //     return false;
                                                            // }).map((item2, id2) => {
                                                            //     if (item2.type_questao == 'conjunto') {
                                                            //         return item2.questoes.length;
                                                            //     }
                                                            //     else {
                                                            //         return 1;
                                                            //     }
                                                            // }).reduce((a, b) => a + b, 0)}

                                                            // title="Coloque o nome completo do usuário"
                                                            />}
                                                            <MdArrowDropUp style={{ cursor: 'pointer', color: id == 0 ? 'grey' : 'black' }} className="click_input" onClick={() => {
                                                                // if(item.order)
                                                                let first = true;
                                                                let categorias_selecionadas = this.state.categorias_selecionadas.map((item2, id2) => {
                                                                    if (id2 + 1 == id && first == true) {
                                                                        let order = item.order;
                                                                        console.log(order, item2.order)

                                                                        item.order = item2.order;
                                                                        item2.order = order;
                                                                        first = false;
                                                                        console.log('order changed')

                                                                    }
                                                                    return item2;
                                                                })
                                                                console.log(categorias_selecionadas)

                                                                categorias_selecionadas = categorias_selecionadas.map((item2, id2) => {
                                                                    if (item2.id == item.id) {
                                                                        return item;
                                                                    }
                                                                    return item2;
                                                                })
                                                                console.log(categorias_selecionadas)

                                                                this.setState({ categorias_selecionadas });
                                                            }} size={30} />
                                                            <MdArrowDropDown className="click_input" style={{ cursor: 'pointer', color: id == this.state.categorias_selecionadas.length - 1 ? 'grey' : 'black' }} onClick={() => {
                                                                let first = true;
                                                                let categorias_selecionadas = this.state.categorias_selecionadas.map((item2, id2) => {
                                                                    if (id2 - 1 == id && first == true) {
                                                                        let order = item.order;
                                                                        item.order = item2.order;
                                                                        item2.order = order;
                                                                        first = false;
                                                                    }
                                                                    return item2;
                                                                })
                                                                categorias_selecionadas = categorias_selecionadas.map((item2, id2) => {
                                                                    if (item2.id == item.id) {
                                                                        return item;
                                                                    }
                                                                    return item2;
                                                                })
                                                                console.log(categorias_selecionadas)
                                                                this.setState({ categorias_selecionadas });
                                                            }} size={30} />

                                                        </div>
                                                    </div>
                                                ))}
                                                {this.state.categorias_selecionadas.length == 0 &&
                                                    <div>
                                                        <p>Nenhuma questão selecionada</p>
                                                    </div>
                                                }
                                            </>}

                                            {this.state.select_from_group == true && this.state.random_per_category == true && <>

                                                <Form.Label className='mt-1'>Número de questões que devem ser selecionadas: </Form.Label>

                                                <Input
                                                    onWheel={(e) => e.target.blur()}

                                                    placeholder="Número de questões que devem ser selecionadas"
                                                    value={this.state.number_select_questions}
                                                    type="number"
                                                    onChange={(e) => this.setState({ number_select_questions: e.target.value })}
                                                    required
                                                    min={1}
                                                    max={Object.values(this.state.questoes_selecionadas).map((item, id) => {
                                                        if (item.type_questao == 'conjunto') {
                                                            return item.questoes.length;
                                                        }
                                                        else {
                                                            return 1;
                                                        }
                                                    }).reduce((a, b) => a + b, 0)}
                                                // title="Coloque o nome completo do usuário"
                                                /></>}


                                        </div>



                                    </InputGroup>}
                                    <InputGroup>

                                        {this.state.type != 'dissertativa' && <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Ordem das alternativas <Attention /></Form.Label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group2"
                                                value={this.state.aleatorizar_alternativas}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ aleatorizar_alternativas: e.target.value });
                                                }}>

                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ aleatorizar_alternativas: false,random_layouts_max:this.state.aleatorizar_questoes==true?this.state.random_layouts_max:false });
                                                }} control={<Radio checked={this.state.aleatorizar_alternativas == false} value={false} />} style={{ color: 'black' }} label={'Alternativas na ordem de cadastro'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ aleatorizar_alternativas: true });

                                                }} control={<Radio checked={this.state.aleatorizar_alternativas == true} value={true} />} style={{ color: 'black' }} label={'Alternativas em ordem aleatória'} />
                                            </RadioGroup>
                                        </div>}
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Exibição das questões <Attention /></Form.Label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group3"
                                                value={this.state.one_question_per_page}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    // if (this.state.type == 'dissertativa') {
                                                    //     return;
                                                    // }
                                                    // this.setState({ one_question_per_page: e.target.value });
                                                }}>


                                                <FormControlLabel disabled={this.state.type == 'dissertativa' ||   this.state.processo.exam_per_time==true} onClick={(e) => {
                                                    if (this.state.type == 'dissertativa'  ||  this.state.processo.exam_per_time==true) {
                                                        return;
                                                    }
                                                    console.log(e.target.value);
                                                    this.setState({ one_question_per_page: false });

                                                }} control={<Radio checked={this.state.one_question_per_page == false} value={false} />} style={{ color: 'black' }} label={'Todas questões exibidas de uma vez, em lista'} />
                                                <FormControlLabel disabled={this.state.type == 'dissertativa'  ||  this.state.processo.exam_per_time==true} onClick={(e) => {
                                                    if (this.state.type == 'dissertativa'  ||  this.state.processo.exam_per_time==true) {
                                                        return;
                                                    }
                                                    console.log(e.target.value);
                                                    this.setState({ one_question_per_page: true });
                                                }} control={<Radio checked={this.state.one_question_per_page == true} value={true} />} style={{ color: 'black' }} label={'Uma questão exibida por vez'} />
                                            </RadioGroup>
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="margin-bottom">
                                            <Form.Label>Definir um número máximo de provas aleatórias geradas? <Attention /></Form.Label>

                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={this.state.random_layouts_max}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    if (this.state.aleatorizar_questoes==false && this.state.aleatorizar_alternativas==false) {
                                                        return;
                                                    }
                                                    this.setState({ random_layouts_max: e.target.value });
                                                }}>
                                                <FormControlLabel disabled={(this.state.aleatorizar_questoes==false && this.state.aleatorizar_alternativas==false)} onClick={(e) => {
                                                    console.log(e.target.value);
                                                    if (this.state.aleatorizar_questoes==false && this.state.aleatorizar_alternativas==false) {
                                                        return;
                                                    }
                                                    this.setState({ random_layouts_max: true});

                                                }} control={<Radio checked={this.state.random_layouts_max == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                               
                                                <FormControlLabel disabled={(this.state.aleatorizar_questoes==false && this.state.aleatorizar_alternativas==false)} onClick={(e) => {
                                                    console.log(e.target.value);
                                                    if (this.state.aleatorizar_questoes==false && this.state.aleatorizar_alternativas==false) {
                                                        return;
                                                    }
                                                    this.setState({ random_layouts_max: false});
                                                }} control={<Radio checked={this.state.random_layouts_max == false} value={false} />} style={{ color: 'black' }} label={'Não'} />
                                               
                                            </RadioGroup>

                                            {this.state.random_layouts_max == true  && <>

                                                <Form.Label className='mt-1'>Número de provas geradas: </Form.Label>

                                                <Input
                                                    onWheel={(e) => e.target.blur()}
                                                    placeholder="Número de provas que vão ser geradas"
                                                    value={this.state.number_exams}
                                                    type="number"
                                                    onChange={(e) => this.setState({ number_exams: e.target.value })}
                                                    required
                                                    min={1}
                                                // title="Coloque o nome completo do usuário"
                                                /></>}

                                        </div>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                        </div>

                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Ao final da prova exibir? <Attention /></Form.Label>

                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={this.state.exibicao}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    if (this.state.type == 'dissertativa') {
                                                        return;
                                                    }
                                                    this.setState({ exibicao: e.target.value });
                                                }}>

                                                <FormControlLabel disabled={this.state.type == 'dissertativa'} onClick={(e) => {
                                                    console.log(e.target.value);
                                                    if (this.state.type == 'dissertativa') {
                                                        return;
                                                    }
                                                    this.setState({ exibicao: 'folha_respostas' });
                                                }} control={<Radio checked={this.state.exibicao == 'folha_respostas'} value={'folha_respostas'} />} style={{ color: 'black' }} label={'Folha de resposta do candidato para download'} />
                                                <FormControlLabel disabled={this.state.type == 'dissertativa'} onClick={(e) => {
                                                    console.log(e.target.value);
                                                    if (this.state.type == 'dissertativa') {
                                                        return;
                                                    }
                                                    this.setState({ exibicao: 'relacao_acerto_erros' });

                                                }} control={<Radio checked={this.state.exibicao == 'relacao_acerto_erros'} value={'relacao_acerto_erros'} />} style={{ color: 'black' }} label={'Relação de acertos/erros'} />
                                                <FormControlLabel disabled={this.state.type == 'dissertativa'} onClick={(e) => {
                                                    console.log(e.target.value);
                                                    if (this.state.type == 'dissertativa') {
                                                        return;
                                                    }
                                                    this.setState({ exibicao: 'none' });

                                                }} control={<Radio checked={this.state.exibicao == 'none'} value={'none'} />} style={{ color: 'black' }} label={'Nenhuma das opções'} />
                                            </RadioGroup>

                                        </div>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                        </div>

                                    </InputGroup>


                                    {this.state.processo.exam_per_time == true  && <>
                                    <Form.Label className='mt-1'>Tempo por questão em minutos <Attention/> </Form.Label>
                                    <Input
                                        onWheel={(e) => e.target.blur()}
                                        placeholder="Tempo por questão"
                                        value={this.state.time_per_question}
                                        type="number"
                                        onChange={(e) => this.setState({ time_per_question: e.target.value })}
                                        required
                                        min={1}
                                    // title="Coloque o nome completo do usuário"
                                    />
                                    <p style={{marginTop:10}}><b>Tempo da prova em minutos: </b>{this.state.time_per_question*Object.values(this.state.questoes_selecionadas).map((item, id) => {
                                        if (item.type_questao == 'conjunto') {
                                            return item.questoes.length;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }).reduce((a, b) => a + b, 0) ?? '-'} minutos</p>
                                    </>}



                                    <hr />

                                    <h5 style={{ fontWeight: 'bold' }}>Questões</h5>
                                    <br />
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Selecione uma disciplina</Form.Label>
                                            {/* <Input
                                                placeholder="Nome da prova"
                                                value={this.state.categoria}
                                                onChange={(e) => this.setState({ categoria: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            /> */}
                                            <select disabled={this.state.loading_get_all} className="form-select " onChange={(e) => {
                                                if (e.target.value != '') {
                                                    let categoria = this.state.categorias.filter((item, id) => item.id == e.target.value)[0];

                                                    this.setState({ categoria: categoria, questao_enunciado: '', questoes: [] });
                                                }
                                                else {
                                                    this.setState({ categoria: { id: '' }, questao_enunciado: '', questoes: [] });

                                                }
                                            }} value={this.state.categoria.id}>
                                                <option value="">Selecione uma disciplina</option>
                                                {(this.state.categorias && this.state.categorias.length != 0) && this.state.categorias.map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value["id"]}
                                                    >{value["name"]}</option>
                                                ))}
                                            </select>
                                            {this.state.categoria.id != '' && <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <a onClick={() => {
                                                    this.get_questoes_categoria(this.state.categoria.id);

                                                }} style={{ textAlign: 'left', fontSize: 15, marginTop: 10, marginRight: 20, cursor: 'pointer', textDecoration: 'none' }} className="link-success">Selecionar todas as questões desta disciplina</a>

                                                <a onClick={() => {

                                                    this.setState({ showModalPalavraChave: true });
                                                }} style={{ textAlign: 'left', fontSize: 15, marginTop: 10, cursor: 'pointer', textDecoration: 'none' }} className="link-success">Selecionar questões por palavra chave</a>

                                            </div>}

                                        </div>

                                    </InputGroup>


                                    {this.state.categoria.id != '' && <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Busque questões da disciplina {this.state.categoria.name} </Form.Label>
                                            <Input
                                                disabled={this.state.loading_get_all}
                                                placeholder="Pesquise a questão pelo enunciado"
                                                value={this.state.questao_enunciado}
                                                // onChange={(e) => this.setState({ questao_enunciado: e.target.value })}
                                                onBlur={() => {
                                                    this.timeout_ = setTimeout(() => {
                                                        this.setState({ show_autocomplete_questao: false });

                                                    }, 500)
                                                }}
                                                onFocus={() => {
                                                    clearTimeout(this.timeout_);
                                                    // console.log(Object.values(this.state.questoes_selecionadas).sort((a, b) => {
                                                    //     if (a.created_at > b.created_at) {
                                                    //         return 1;
                                                    //     }
                                                    //     if (a.created_at < b.created_at) {
                                                    //         return -1;
                                                    //     }
                                                    //     // a must be equal to b
                                                    //     return 0;
                                                    // }).sort((a, b) => {
                                                    //     if (a.categoria.created_at > b.categoria.created_at) {
                                                    //         return 1;
                                                    //     }
                                                    //     if (a.categoria.created_at < b.categoria.created_at) {
                                                    //         return -1;
                                                    //     }
                                                    //     // a must be equal to b
                                                    //     return 0;
                                                    // }))
                                                    this.setState({ show_autocomplete_questao: true })
                                                }}
                                                onChange={(e) => { this.get_questao_search(e.target.value, this.state.categoria.id); this.setState({ questao_enunciado: e.target.value }) }}
                                            // title="Coloque o nome completo do usuário"
                                            />
                                            {this.state.show_autocomplete_questao == true && this.state.questao_enunciado != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                                                {this.state.loading_search_questao == false && this.state.questoes.map((item, id) => (
                                                    <div key={id} onClick={() => this.select_questao(item)} className="opcao_autocomplete">
                                                        <b>{item.categoria.name} - {item.type_questao == 'conjunto' ? ('Conjunto de questões: ' + item.questoes.length + (item.questoes.length == 1 ? ' questão' : ' questões')) :
                                                            (item.type_questao == 'simples' ? 'Questão única' : 'Questão dissertativa')}</b> - {item.text_questao.length < 200 ? item.text_questao : item.text_questao.substring(0, 200) + '...'}
                                                    </div>
                                                ))}
                                                {this.state.loading_search_questao == false && this.state.questoes.length == 0 &&
                                                    <div className="opcao_autocomplete">
                                                        Nenhuma questão encontrada com este termo
                                                    </div>
                                                }
                                                {this.state.loading_search_questao == true &&
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <div className="spinner-border text-primary" role="status"  >
                                                        </div></div>}
                                            </div>}
                                        </div>
                                    </InputGroup>}




                                    <Form.Label >Questões selecionadas</Form.Label>
                                    <br />
                                    <Form.Label >Total: <span style={{ fontWeight: 'normal' }}>{Object.values(this.state.questoes_selecionadas).map((item, id) => {
                                        if (item.type_questao == 'conjunto') {
                                            return item.questoes.length;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }).reduce((a, b) => a + b, 0)} {Object.values(this.state.questoes_selecionadas).map((item, id) => {
                                        if (item.type_questao == 'conjunto') {
                                            return item.questoes.length;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }).reduce((a, b) => a + b, 0) == 1 ? 'questão' : 'questões'}</span></Form.Label>

                                    <InputGroup>
                                        <DragDropTable draggable={true} data={this.state.questoes_array}
                                            showData={(index) => {
                                                let questao_id = this.state.questoes_array[index].id;
                                                this.setState({ questaoSelect: { ...this.state.questoes_selecionadas[questao_id] }, showModal: true });

                                            }}
                                            deleteData={(index) => {
                                                let questao_id = this.state.questoes_array[index].id;

                                                let questoes_selecionadas = { ...this.state.questoes_selecionadas };
                                                delete questoes_selecionadas[questao_id];
                                                let questoes_array = this.state.questoes_array.filter((item, id) => item.id != questao_id);
                                                this.setState({ questoes_selecionadas, questoes_array });
                                            }}
                                            setData={(update_) => {
                                                this.setState({ questoes_array: update_ });
                                            }} />

                                        {/* <div className="flex fdcolumn flex1 margin-bottom col-12">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="table" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>N.º</th>

                                                                <th>Enunciado</th>
                                                                <th>Categoria</th>
                                                                <th>Tipo de questão</th>

                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.values(this.state.questoes_selecionadas).map((item, id) => (
                                                                <tr key={id}>
                                                                    <td style={{textAlign:'justify'}}>{id+1})</td>
                                                                    <td ><p className='text_overflow'>{item.text_questao}</p></td>
                                                                    <td >{item.categoria.name}</td>
                                                                    <td ><p style={{whiteSpace:'pre'}}>{item.type_questao=='conjunto'?('Conjunto de questões\n' +item.questoes.length+ (item.questoes.length==1?' questão':' questões')):
                                                                    (item.type_questao=='simples'?'Questão única':'Questão dissertativa')}</p></td>

                                                                    <td style={{minWidth:90}}>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ questaoSelect: item, showModal: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px",marginRight:5 }}
                                                                            title="Gerenciar aula"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let questoes_selecionadas = { ...this.state.questoes_selecionadas };
                                                                                delete questoes_selecionadas[item.id];
                                                                                this.setState({ questoes_selecionadas });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Deletar texto"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {Object.values(this.state.questoes_selecionadas).length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhuma questão adicionada</p>

                                                </div>
                                            </div>}
                                        </div> */}

                                    </InputGroup>


                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            search={'?tab=1'}
                                            to={`/processos/show/${this.props.match.params.id}`}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar prova"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando prova"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);

export default connect(mapsStateToProps, { logout })(CriarProva);


