import JoditEditor from "jodit-react";
import React, { Component, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { API_URL, URL } from "../../variables";
import { Form, FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";
import Btn from '@mui/material/Button';
import Input from '@mui/material/Input';
import { Checkbox, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { uuid } from 'uuidv4';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";


class VerQuestionarioRespondido extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            enunciado: '',

            loading_save: false,
            msgErro: '',
            loading_image_enunciado: false,
            loading_image_a: false,
            loading_image_b: false,
            loading_image_c: false,
            loading_image_d: false,
            loading_image_e: false,
            correta: '',
            respostas: {},
            visao_candidato: false,
            alternativas_inputs: [{ type: 'radio', name: '', uuid: uuid() }],
            type_questao: 'multipla_escolha',
            questoes: []
        };



    }



    renderTypeQuestao(e) {
        if (e == 'multipla_escolha') {
            return 'Escolha múltipla';
        }
        if (e == 'paragrafo') {
            return 'Parágrafo';
        }
    }

    

    // componentDidUpdate(props) {
    //     if (this.props.showModal != props.showModal) {
    //         let respostas = {};
    //         for (let i = 0; i < this.props.questionario.questoes.length; i++) {
    //             let questao = this.props.questionario.questoes[i];
    //             respostas[questao.id] = { type: questao.type_questao, 'alternativa_id': '', textos: {}, checkboxs: {} };
    //             for (let j = 0; j < questao.alternativas_inputs.length; j++) {
    //                 let alternativa = questao.alternativas_inputs[j];
    //                 if (alternativa.type == 'texto') {
    //                     respostas[questao.id]['textos'][alternativa.uuid] = { 'texto': '', 'required': alternativa.required };
    //                 }
    //                 else if (alternativa.type == 'checkbox') {
    //                     respostas[questao.id]['checkboxs'][alternativa.uuid] = false;
    //                 }
    //             }
    //         }

    //         this.setState({ respostas,visao_candidato:false });
    //     }
    // }

    componentDidUpdate(props){
        console.log(this.props.questionario)
        if(this.props.resultado?.user?.id!=props.resultado?.user?.id){
            this.setState({respostas:this.props.resultado?.respostas==undefined?{}:this.props.resultado?.respostas});
        }
    }




    render() {
        console.log(this.state.respostas);

        return (
            <Modal
                show={this.props.showModal == true}
                dialogClassName="modal_width"
                onHide={() => this.props.setShowModal(false)}
            >
                <Modal.Header closeButton closeLabel=''><Modal.Title>{this.props.resultado?.user?.name}<br /></Modal.Title></Modal.Header>
                <Modal.Body style={{ backgroundColor: '#f2f2f2', paddingLeft: 5, paddingRight: 5 }}>
                  
                    {this.props.questionario!=undefined && this.props.questionario.questoes != undefined && this.props.questionario.questoes.map((questao, id5) => {
                            return (Object.values(this.state.respostas).length != 0 && <div key={id5} style={{ marginTop: 15, border: "1px solid #dadce0", borderRadius: 8, transition: "box-shadow .25s cubic-bezier(0,0,0.2,1)", backgroundColor: '#fff', padding: 15 }}>

                                <InputGroup>
                                    {/* {<InputGroup>
                                    <div className="flex fdcolumn flex1 margin-bottom">
                                        <Form.Label>Tipo de questão <Attention /></Form.Label>
                                        <p style={{ marginBottom: 0 }}>{this.renderTypeQuestao(questao.type_questao)}</p>

                                       
                                    </div>
                                </InputGroup>} */}

                                    <div className="flex fdcolumn flex1 ">
                                        <Form.Label >Pergunta {id5 + 1}) <Attention/></Form.Label>
                                        <p style={{ marginBottom: 0 }}>{questao.name}</p>

                                    </div>
                                </InputGroup>



                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={this.state.respostas[questao.id]['alternativa_id']}
                                    onChange={(e) => {
                                       
                                    }}
                                >
                                    {questao.alternativas_inputs.map((item, id) => {
                                        if (item.type == 'checkbox') {
                                            console.log(this.state.respostas[questao.id]['checkboxs'][item.uuid])
                                            return (<InputGroup key={id} style={{ marginTop: 10 }}>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                   

                                                }} value={item.uuid} checked={this.state.respostas[questao.id]['checkboxs'][item.uuid]} />} style={{ flexGrow: 1 }} label={item.name} />

                                            </InputGroup>);
                                        }
                                        else if (item.type == 'radio') {
                                            return (
                                                <InputGroup key={id} style={{ marginTop: 10 }}>

                                                    <FormControlLabel control={<Radio value={item.uuid} />} style={{ color: 'black' }} label={item.name} />


                                                </InputGroup>
                                            );
                                        }
                                        else if (item.type == 'texto') {
                                            return (
                                                (questao.type_questao == 'paragrafo'
                                                    || this.state.respostas[questao.id]['textos'][item.uuid]['required'] == 'required'
                                                    || this.state.respostas[questao.id]['textos'][item.uuid]['required'] == 'not_required'
                                                    || (this.state.respostas[questao.id]['alternativa_id'] == this.state.respostas[questao.id]['textos'][item.uuid]['required'])) && <div key={id} style={{ marginTop: 10 }}>
                                                    {questao.type_questao == 'multipla_escolha' && <p style={{ marginBottom: 0, fontWeight: 'bold' }}>{item.name}</p>}
                                                    <InputGroup >
                                                        <Input style={{ flexGrow: 1 }} disabled={questao.type_questao == 'paragrafo' && this.state.respostas[questao.id]['checkboxs'][Object.keys(this.state.respostas[questao.id]['checkboxs'])] == true} placeholder={item.name} value={this.state.respostas[questao.id]['textos'][item.uuid]['texto']} onChange={(e) => {
                                                           
                                                        }} />
                                                        {questao.type_questao=='multipla_escolha' && this.state.respostas[questao.id]['textos'][item.uuid]['required']!= 'not_required'  &&  <Attention />}


                                                    </InputGroup>
                                                </div>
                                            );
                                        }
                                    })}
                                </RadioGroup>
                            </div>);
                    
                    })}

                </Modal.Body>

                <Modal.Footer>
                    <div className="w100 inline-flex justify-content-end margin-top-1">

                    </div>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default VerQuestionarioRespondido;