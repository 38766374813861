import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner, ProgressBar } from "react-bootstrap";
import DefaultButton from '../ReusableComponents/DefaultButton';
import Attention from "../ReusableComponents/Attention";
import { STORAGE_FILES_URL, URL } from "../../variables";
import { useSelector } from "react-redux";
import Request from "../../Request";
import axios from "axios";

const ModalAddZip = props => {
    const { showModal, setShowModal, redacao_id } = props;
    const [file, setFile] = useState('');
    const [fileValue, setFileValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [progress, setProgress] = useState(0);
    const [messageProgress, setMessageProgress] = useState(0);


    const [msgErro, setMsgErro] = useState('');
    const token = useSelector(state => state.AppReducer.token);
    const timerRef = useRef(null);

    useEffect(()=>{
        return ()=>{
            clearTimeout(timerRef.current);
        }
    },[])


    const save_file = useCallback(() => {
        setLoading(true);
        let form = new FormData();
        form.append('redacao_id', redacao_id);
        form.append('file', file);
        let headers = {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }

        axios.post(`${URL}api/faculdade/save_redacoes_file`, form, {
            headers: headers,
            onUploadProgress: (e) => {
                console.log(e);
                setProgress((e.loaded / e.total) * 100);
                setMessageProgress('Enviando '+parseInt((e.loaded / e.total) * 100)+'%')
            }
        })
            .then(res => {
                console.log(res.data);
                return res.data;

            })
            .then(resp => {
                console.log(resp);
                if (resp.job_id != undefined) {
                    setJobId(resp.job_id);
                    setMessageProgress(resp.message);

                    timerRef.current = setTimeout(() => {
                        console.log('timeout')
                        get_status_job(resp.job_id);
                    }, 10000);
                }
            })
            .catch((e) => {
                if (e.response == undefined) {
                    setLoading(false);
                    setMsgErro('Erro ao pegar resposta do servidor. Você está conectado a internet?');
                    return
                }
                let resp = e.response.data;
                console.log(e.response.data);

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setLoading(false);
                    setMsgErro(erro);

                }
                else {
                    setLoading(false);
                    setMsgErro('Erro ao pegar resposta do servidor. Você está conectado a internet?');
                }
                console.log('error')
            })

            
    }, [loading, file, redacao_id]);



    const get_status_job = useCallback((job_id) => {

        fetch(`${URL}api/faculdade/get_status_job/${job_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // setLoading(false);
                    setMsgErro(erro);

                }
                else {
                    if (resp.status == 'SUCCESS') {
                        setMessageProgress(resp.message);
                        timerRef.current = setTimeout(() => {
                            setShowModal(false);
                        }, 5000);

                    }
                    else if(resp.status == 'Pendente' || resp.status=='Executando'){
                        setMessageProgress(resp.message);
                        timerRef.current = setTimeout(() => {
                            get_status_job(job_id);
                            
                        }, 10000);
                    }
                    else if (resp.status == 'Error') {
                        setLoading(false);
                        setMsgErro(resp.message);
                        setProgress(0);
                        setMessageProgress('');

                    }

                   


                }




            } catch (err) {
                console.log(err);
                // setLoading(false);
                // setMsgErro('Erro ao pegar resposta do servidor');
                timerRef.current = setTimeout(() => {
                    get_status_job(job_id);
                }, 10000);

            }

        })
            .catch((err) => {
                console.log(err);
                // setLoading(false);
                // setMsgErro('Erro ao pegar resposta do servidor. Você está conectado a internet?');
                timerRef.current = setTimeout(() => {
                    get_status_job(job_id);
                }, 10000);
            });
    }, [loading, file, redacao_id, jobId]);


    return (
        <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title> Adicionar .zip/.rar de redações existentes<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p style={{ fontSize: '1rem', color: 'black' }}>Todas as redações devem estar no formato .png ou .jpg, sendo o número de inscrição o nome da imagem, e todas compactadas em um arquivo .zip/.rar, que pode ser selecionado abaixo.</p>

                        {msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>


                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Redações (.zip/.rar) <Attention /> </Form.Label>
                                <input type="file" placeholder="Arquivo de redações (.zip/.rar)"
                                    accept='.zip,.rar'
                                    value={fileValue}
                                    className="form form-control"
                                    onChange={(e) => {
                                        setFile(e.target.files[0]);
                                        setFileValue(e.target.value);
                                    }} />
                            </div>
                        </InputGroup>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>

                {loading == false && <div className="w100 inline-flex justify-content-end margin-top-1">
                    <DefaultButton
                        type="button"
                        onClick={() => {
                            save_file()
                        }}
                        bg="confirm"
                        text="Salvar redações"
                        disabled={loading}
                        loadingtext="Salvando redações"
                        loading={loading}
                    />
                </div>}
                {loading == true && <div className="w100 margin-top-1">
                    <ProgressBar style={{height:40,fontSize:20}} animated now={progress} label={messageProgress} />
                </div>}

            </Modal.Footer>


        </Modal>

    );
}

export default ModalAddZip;