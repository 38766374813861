import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaDownload, FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';

import './modal.css';
import { URL } from "../../variables";

const ImagesAnalises = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [select_image, SetSelectImage] = useState('');

    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_images, setLoadingimages] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [images, setimages] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, candidato_name, candidato_id } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [images_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const [loading_zip, setLoadingZip] = useState(false);
    const [message_zip, setMessageZip] = useState('');


    const Getimages = async (page, search) => {

        let response = await Request.Get(`faculdade/get_images_analises_objetos/${processo_id}/${candidato_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setimages(response.images);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingimages(false);


        }
    }

    const GetExcelFile = async () => {

        let response = await Request.Get(`faculdade/generate_pdf_analise_objetos/${processo_id}/${candidato_id}`);
        console.log(response);
        setLoadingZip(false);
        setMessageZip('');

        if (response === undefined) return;
        if (response.message == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'provas_processo_'+processo_name+'.zip');
        // }
        else {
            document.getElementById('download_link').href = response['link'];
            document.getElementById('download_link').click();

            // saveAs(response, 'analises_candidatos_' + processo_name + '.xlsx');


        }


    }



    useEffect(() => {
        Getimages(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">


            {<div id="myModal" className="modal_2">

                <span onClick={() => {
                    var modal = document.getElementById("myModal");
                    modal.style.display = "none";
                    SetSelectImage('');
                }} className="close">&times;</span>

                <img className="modal-content_2" id="img01" />

                <div id="caption"></div>
            </div>}
            <div className="col-12">

                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as análises das imagens do candidato "{candidato_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}
                    <a id="download_link" target="_blank"></a>

                    <div className="flex jcsb flex-wrap mb-4">
                        <div className={'flex fdrow '} style={{ flexBasis: "50%", height: 'max-content' }}>
                        </div>
                        {/* <Search className="mt-2" placeholder="Pesquisar pelo nome do candidato" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setSearchOficial(value);
                                setLoadingimages(true);

                                Getimages(1, value)

                            }, 500);
                        }} /> */}





                        <div className="d-flex mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>
                            {<div onClick={() => {
                                if (loading_zip == false) {
                                    setLoadingZip(true);
                                    setMessageZip('Criando .pdf para download');
                                    GetExcelFile();
                                }
                                else {

                                }
                            }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {loading_zip == false && <Button variant="success" className="flex align-center jcc me-2"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar pdf com análises</p></Button>}
                                {loading_zip == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <CircularProgress size={15} />
                                </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                                    </div>
                                </div>}
                            </div>}

                          
                        </div>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Imagem</th>


                                        <th>Data</th>


                                        {/* <th>Data de nascimento</th> */}


                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_images == false && images.map((item, id) => (

                                        <tr key={id}>
                                            {/* <td>{item.user.email}</td> */}
                                            
                                            <td>
                                                {
                                                    <div >
                                                        <img className="myImg" onClick={() => {
                                                            var modal = document.getElementById("myModal");
                                                            var modalImg = document.getElementById("img01");
                                                            modalImg.src = item.result_path;
                                                            SetSelectImage(item.result_path);
                                                            modal.style.display = "block";
                                                        }} src={item.result_path} style={{ width: 100, height: 80, objectFit: 'contain' }} />
                                                    </div>
                                                }
                                            </td>

                                            <td>{moment(item.image.path.split('/')[item.image.path.split('/').length - 1].split('.')[0].replace('video_', ''), 'YYYY-MM-DD_HH-mm-ss').format('DD/MM/YYYY HH:mm:ss')}</td>

                                            {/* <td>{moment(item.nascimento).format('DD/MM/YYYY')}</td> */}

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}



                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_images == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_images == false && images.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma análise encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingimages(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getimages(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_images={this.get_images.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}




            </div>

        </div>
    )
}

export default ImagesAnalises;
