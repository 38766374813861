import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaArrowLeft, FaArrowRight, FaListAlt, FaVideo } from "react-icons/fa";
import { MdArrowLeft, MdArrowRight, MdArrowRightAlt, MdDelete, MdModeEdit, MdPlayArrow } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";



const Messages = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_messages, setLoadingmessages] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [messages, setmessages] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });


    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(1);

    const [search, setSearch] = useState('');
    const { candidato_id, candidato_name, processo_id } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [message_id, setmessageId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getmessages = async (page, search) => {

        let response = await Request.Get(`faculdade/get_messages_candidato/${processo_id}/${candidato_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }

        setmessages(response.mensagens);
        // if (response.messages.length > 0) {
        //     setVideoSelect(response.messages[0]);
        // }

        // setPagination({...response.pagination});


        setLoadingScreen(false);
        setLoadingmessages(false);


    }

    const [message_select, setVideoSelect] = useState(null);

    const Deletemessage = async (message_id) => {

        let response = await Request.Delete(`faculdade/delete_message/${message_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getmessages(current_page, search_oficial);



    }

    useEffect(() => {
        Getmessages(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as mensagens do candidato "{candidato_name}"</p>
                    <div style={{display:'flex',justifyContent:'center'}}>

                    <div  style={{ height: '500px', padding: 0, margin: 0,border:'3px solid #f2f2f2', borderRadius:5,width:'400px' }} className="overflow-auto" id="body_msg">
                        {messages.map((item, id) => {
                            if (item.type_message == 'message') {
                                if (item.receive_id == null) {
                                    return (
                                        <div key={id} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                            <div style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }} >
                                                <div>
                                                <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.sender_name}</p>

                                                <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem' }}>{item.message}</p>
                                                <p style={{ marginBottom: 0, color: 'gray', marginRight: '1rem',fontSize:12 }}>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    )
                                }
                                else  {
                                    return (
                                        <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                            <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                                <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>Fiscal {item.sender_name}</p>
                                                <p style={{ marginBottom: 0, color: 'black' }}>{item.message}</p>
                                                <p style={{ marginBottom: 0, color: 'gray', marginRight: '1rem',fontSize:12 }}>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</p>

                                            </div>
                                        </div>
                                    );
                                }
                            }
                            else if (item.type_message == 'comunicado') {
                                return (
                                    <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                        <div style={{ padding: '5px', backgroundColor: 'white', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify', border: '1px dashed gray' }}>
                                            <p style={{ marginBottom: 0, color: 'black', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.name_comunicado}</p>
                                            <p style={{ marginBottom: 0, color: 'black' }}>{item.message}</p>
                                            <p style={{ marginBottom: 0, color: 'gray', marginRight: '1rem',fontSize:12 }}>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</p>

                                        </div>
                                    </div>
                                );
                            }

                        })}
                    </div>
                    </div>






                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_message={this.get_message.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingmessages(true);
                        Deletemessage(message_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar este message, ele não estará mais disponível no sistema
                </SweetAlert>


            </div>

        </div>
    )
}

export default Messages;
