// Libraries imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Card, Button } from "react-bootstrap";

// Components imports
import { BsFillEyeFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";

// Utils imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post } from "../../Services/api";

const SuccessModal = ({ show, handleClose, handleTableUpdate }) => (
  <Modal
    show={show}
    onHide={() => {
      handleClose();
      handleTableUpdate();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>Sucesso!</Modal.Title>
    </Modal.Header>
    <Modal.Body>Questão avaliada com sucesso!</Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={() => {
          handleClose();
          handleTableUpdate();
        }}
      >
        Fechar
      </Button>
    </Modal.Footer>
  </Modal>
);

class AvaliarQuestoes extends Component {
  state = {
    demandas: [],
    questoes: [],
    redirect: false,
    categoria: null,
    demanda: null,
    demandaProfessor: null,
    pagination: null,
    isModalOpen: false,
    novoComentario: "",
    status: "",
    comentarios: [],
    isCommentsLoading: false,
    showSuccessModal: false,
    questaoId: null,
    isModalLoading: false,
    loadingScreen: true,
  };

  componentDidMount() {
    this.fetchDemandas();
  }

  fetchDemandas = async () => {
    try {
      const token = this.props.token;

      const resp = await get(`api/faculdade/get_demandas`, token);

      const demandas = resp.demandas;

      this.setState({ demandas });

      const atribuicoesPromises = demandas.map((demanda) =>
        this.fetchAtribuicoes(demanda.id)
      );

      await Promise.all(atribuicoesPromises);

      this.setState({ loadingScreen: false });
    } catch (error) {
      this.setState({ loadingScreen: false, redirect: true });
    }
  };

  fetchAtribuicoes = async (demandaId) => {
    try {
      const token = this.props.token;

      const resp = await get(
        `api/faculdade/get_atribuicoes/${demandaId}`,
        token
      );

      const atribuicoes = resp.atribuicoes;

      const questoesPromises = atribuicoes.map((atribuicao) =>
        this.fetchQuestoes(atribuicao.id)
      );
      await Promise.all(questoesPromises);
    } catch (error) {}
  };

  fetchQuestoes = async (atribuicao_id) => {
    try {
      const token = this.props.token;

      const resp = await get(
        `api/faculdade/get_atribuicao/${atribuicao_id}?&questoes=true`,
        token
      );

      const questoesWithDemanda = resp.questoes.map((questao) => ({
        ...questao,
        demanda: resp.demanda,
      }));

      this.setState((prevState) => ({
        questoes: [...prevState.questoes, ...questoesWithDemanda],
        loadingSearch: false,
        categoria: resp.categoria,
        demanda: resp.demanda,
        demanda_professor: resp.demanda_professor,
        pagination: resp.pagination,
      }));
    } catch (error) {}
  };

  fetchComentarios = async (questaoId) => {
    try {
      const { token } = this.props;
      const res = await get(`api/get_comentario/${questaoId}`, token);

      const comentarios = res.map((item) => ({
        comentario: item.comentario,
        user_name: item.user_name,
      }));

      this.setState({
        comentarios,
        isCommentsLoading: false,
        isModalLoading: false,
      });
    } catch (error) {
      this.setState({ isCommentsLoading: false, isModalLoading: false });
    }
  };

  editStatusQuestao = async (status, questaoId) => {
    try {
      const { token } = this.props;
      await post(
        `api/professor/update_status_questao/${questaoId}`,
        { status },
        token
      );

      this.setState({ showSuccessModal: true });
    } catch (error) {}
  };

  handleAddComentario = async (questaoId) => {
    try {
      const { token, user } = this.props;
      const { novoComentario } = this.state;
      await post(
        "api/store_comentario",
        {
          questao_id: questaoId,
          comentario: novoComentario,
        },
        token
      );

      this.setState((prevState) => ({
        comentarios: [
          ...prevState.comentarios,
          { comentario: novoComentario, user_name: user.name },
        ],
        novoComentario: "",
      }));
    } catch (error) {}
  };

  openModal = (questaoId) => {
    this.setState({ isModalOpen: true, questaoId, isModalLoading: true });
    this.fetchComentarios(questaoId);
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleNovoComentarioChange = (event) => {
    this.setState({ novoComentario: event.target.value });
  };

  handleStatusChange = (event) => {
    this.setState({ status: event.target.value });
  };

  handleSubmitStatus = () => {
    this.editStatusQuestao(this.state.status, this.state.questaoId);
    this.closeModal();
  };

  handleSubmitComentario = () => {
    this.handleAddComentario(this.state.questaoId);
  };

  handleTableUpdate = () => {
    window.location.reload();
  };

  render() {
    const {
      loadingScreen,
      isModalOpen,
      isCommentsLoading,
      comentarios,
      novoComentario,
      status,
      showSuccessModal,
      isModalLoading,
    } = this.state;

    const questoesFiltradas = this.state.questoes.filter(
      (questao) =>
        questao.user.elaborador_opcoes &&
        questao.user.elaborador_opcoes.can_do_audition === false &&
        questao.statusAprovacao === "pendente"
    );

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4 className="mt-0 header-title">Lista de Questões Pendentes</h4>
              <p>
                Aqui são listados todas as questões pendentes do seu sistema
              </p>
              <div className="row">
                <div className="container mt-5">
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {loadingScreen ? (
                        <div className="d-flex justify-content-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        <table
                          id="tech-companies-1"
                          className="table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Nome do elaborador</th>
                              <th>Nome da demanda</th>
                              <th>Data de criação</th>
                              <th>Data de última alteração</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questoesFiltradas.length === 0 ? (
                              <tr>
                                <td colSpan="5">
                                  <div
                                    className="alert alert-warning"
                                    role="alert"
                                  >
                                    Nenhuma questão pendente.
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              questoesFiltradas.map((questao, index) => (
                                <tr key={index}>
                                  <td>{questao.user.name}</td>
                                  <td>{questao.demanda.name}</td>
                                  <td>{formatDate(questao.created_at)}</td>
                                  <td>{formatDate(questao.updated_at)}</td>
                                  <td>
                                    <Link
                                      to={`/avaliar-questoes/show/${questao.id}`}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-warning icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        title="Gerenciar"
                                      >
                                        <BsFillEyeFill
                                          size={17}
                                          color="white"
                                        />
                                      </span>
                                    </Link>
                                    <span
                                      onClick={() => this.openModal(questao.id)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-info icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        data-toggle="tooltip"
                                        title="Avaliar Questão"
                                      >
                                        <FaStar color="white" size={17} />
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={isModalOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Avaliar Questão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isModalLoading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title className="fw-bold fs-4">
                      Comentários
                    </Card.Title>
                    <div className="mb-3">
                      {isCommentsLoading ? (
                        <p className="text-muted">Carregando comentários...</p>
                      ) : (
                        <ul className="list-group">
                          {comentarios.map((comentario, index) => (
                            <li
                              key={index}
                              className="list-group-item d-flex align-items-center"
                            >
                              <strong>{comentario.user_name}:</strong>
                              <span className="ms-2">
                                {comentario.comentario}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div>
                      <h3 className="mt-4">Adicionar Comentário</h3>
                      <textarea
                        className="form-control mb-2"
                        value={novoComentario}
                        onChange={this.handleNovoComentarioChange}
                        placeholder="Escreva seu comentário aqui..."
                      />
                      <Button
                        variant="secondary"
                        className="mt-2"
                        onClick={this.handleSubmitComentario}
                      >
                        Adicionar
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>Avaliar</Card.Title>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="aprovado"
                          checked={status === "aprovado"}
                          onChange={this.handleStatusChange}
                        />
                        Aprovar
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="recusado"
                          checked={status === "recusado"}
                          onChange={this.handleStatusChange}
                        />
                        Desaprovar
                      </label>
                    </div>
                    <Button
                      variant="success"
                      className="mt-2"
                      onClick={this.handleSubmitStatus}
                    >
                      Enviar Avaliação
                    </Button>
                  </Card.Body>
                </Card>
              </>
            )}
          </Modal.Body>
        </Modal>
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => this.setState({ showSuccessModal: false })}
          handleTableUpdate={this.handleTableUpdate}
        />
      </div>
    );
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(AvaliarQuestoes);
