
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdFileDownload, MdReply } from 'react-icons/md';
import { Form, FormCheck, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';



import { TextField } from '@material-ui/core';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import { BsFile, BsFillEyeFill } from 'react-icons/bs';
import './style.css'
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';

import html2pdf from 'html2pdf.js';
import { uuid } from 'uuidv4';
import styled, { createGlobalStyle } from 'styled-components';
// import { Preview, print } from 'react-html2pdf';

// const StyleQuestion = styled.p` 
//   letter-spacing: 0.2px;
//   margin-bottom:0;
// `;

const StyleQuestion = createGlobalStyle`
   p {
  letter-spacing: 0.2px;
  margin-bottom: 0px;
   }
 `

class EditarDiagramacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            enunciado: '',
            msgErroTextoApoio: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            inicio: moment(),
            processo: { name: '' },
            arquivos: [],
            texto: '',
            titulo: '',
            texto_value: '',
            questao_enunciado: '',
            show_autocomplete_questao: false,
            loading_search_questao: false,
            questoes: [],
            questoes_selecionadas: {},
            categorias: [],
            provas: [],
            categoria: { id: '' },
            provaSelect: { id: '' },

            showModal: false,
            questaoSelect: null,
            loading_get_all: false,

            questoes_array: [],
            tipos_prova: [
                {
                    id: 'multipla_escolha',
                    name: 'Prova objetiva'
                },
                // {
                //     id:'dissertativa',
                //     name:'Prova dissertativa'
                // }
            ],
            type: 'multipla_escolha',
            loading_image: false,
            logo: '',
            logo_inferior: '',

            titulo: '',
            subtitulo: '',
            titulo_pagina: "",
            descricao: '',
            pages: [],
            turno: '',
            nome: '',
            subtitulo_pagina: '',
            instrucoes: '',
            offsetHeight: 0,
            fontSizeQuestoes: 13,
            fontSizeAlternativas: 13,
            numero_colunas: 1,
            fontSizeInstrucoes: 14,
            numero_colunas_instrucoes: 1,
            loading_page: true,
            loading_pdf: false,
            pages_new: [],
            generate_pdf_image: false,

            marginInternaSuperior: 14,
            marginInternaDireita: 40,
            marginInternaEsquerda: 40,
            marginInternaInferior: 40,
            show_category: true,
            barra_dupla: true,
            default_diagramacao: false,
            campo_1: '',
            campo_2: '',
            titulo_campo_1: '',
            titulo_campo_2: ''

        };

        this.pages = [];

        this.config = {
            height: 300,
            // askBeforePasteHTML:false,
            // askBeforePasteFromWord:false,
            // defaultActionOnPaste:'insert_only_text',
            // defaultActionOnPasteFromWord:'insert_only_text',
            // pasteHTMLActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            // pasteFromWordActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            readonly: this.state.loading_image,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }


    }

    get_processo(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_diagramacao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, processo: resp.processo,
                        provas: resp.provas,
                        provaSelect: resp.diagramacao.prova,
                        logo_path: resp.diagramacao.logo,
                        logo_path_inferior: resp.diagramacao.logo_inferior ?? resp.diagramacao.logo,

                        titulo_pagina: resp.diagramacao.titulo_pagina,
                        nome: resp.diagramacao.nome_prova,
                        subtitulo_pagina: resp.diagramacao.subtitulo_pagina,
                        campo_1: resp.diagramacao.campo_1 ?? '',
                        campo_2: resp.diagramacao.campo_2 ?? '',
                        titulo_campo_1: resp.diagramacao.titulo_campo_1 ?? '',
                        titulo_campo_2: resp.diagramacao.titulo_campo_2 ?? '',
                        numero_colunas_instrucoes: resp.diagramacao.numero_colunas_instrucoes ?? 1,
                        fontSizeInstrucoes: resp.diagramacao.tamanho_fonte_instrucoes == null ? 14 : resp.diagramacao.tamanho_fonte_instrucoes,

                        marginInternaSuperior: resp.diagramacao.margin_interna_superior,
                        marginInternaDireita: resp.diagramacao.margin_interna_direita,
                        marginInternaEsquerda: resp.diagramacao.margin_interna_esquerda,
                        marginInternaInferior: resp.diagramacao.margin_interna_inferior,
                        show_category: resp.diagramacao.show_category,
                        barra_dupla: resp.diagramacao.barra_dupla,
                        default_diagramacao: resp.diagramacao.default_diagramacao,

                        titulo: resp.diagramacao.titulo_cabecalho,
                        subtitulo: resp.diagramacao.subtitulo_cabecalho,
                        instrucoes: resp.diagramacao.instrucoes,
                        numero_colunas: resp.diagramacao.numero_colunas,
                        fontSizeQuestoes: resp.diagramacao.tamanho_fonte_questoes == 'default' ? 'default' : parseInt(resp.diagramacao.tamanho_fonte_questoes),
                        fontSizeAlternativas: resp.diagramacao.tamanho_fonte_alternativas == 'default' ? 'default' : parseInt(resp.diagramacao.tamanho_fonte_alternativas),
                    }, () => {
                        if (this.state.provaSelect.id != '') {
                            this.readInitialImages();

                        }
                    });
                    console.log(resp.diagramacao.tamanho_fonte_instrucoes)
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    parseHtmlString(yourHtmlString) {
        var element = document.createElement('div');
        element.innerHTML = yourHtmlString;
        let srcs = []
        var imgSrcUrls = element.getElementsByTagName("img");
        for (var i = 0; i < imgSrcUrls.length; i++) {
            var urlValue = imgSrcUrls[i].getAttribute("src");
            srcs.push(urlValue);
        }
        return srcs;
    }


    readInitialImages() {
        let outputFormat = 'image/png';
        this.setState({ loading_page: true });

        let questoes = this.state.provaSelect.questoes;
        let finish = {};
        for (let i = 0; i < questoes.length; i++) {
            let questao = questoes[i];
            if (questao.type_questao == 'simples') {
                let srcs = this.parseHtmlString(questao.name);
                for (let k = 0; k < srcs.length; k++) {
                    let r = k;
                    finish['q_' + r + '_' + questao.id] = false;
                    let src = srcs[k];
                    let img = new Image();
                    img.crossOrigin = 'Anonymous';
                    img.onload = function () {
                        var canvas = document.createElement('CANVAS');
                        var ctx = canvas.getContext('2d');
                        var dataURL;
                        canvas.height = this.naturalHeight;
                        canvas.width = this.naturalWidth;
                        ctx.drawImage(this, 0, 0);
                        dataURL = canvas.toDataURL(outputFormat);
                        questoes[i].name = questoes[i].name.replace(src, dataURL);
                        finish['q_' + r + '_' + questao.id] = true;

                    };
                    img.src = src;
                }

                for (let j = 0; j < questao.alternativas.length; j++) {
                    let alternativa = questao.alternativas[j];
                    let srcs = this.parseHtmlString(alternativa.name);
                    for (let k = 0; k < srcs.length; k++) {
                        let r = k;
                        finish['a_' + r + '_' + alternativa.id] = false;

                        let src = srcs[k];
                        let img = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = function () {
                            var canvas = document.createElement('CANVAS');
                            var ctx = canvas.getContext('2d');
                            var dataURL;
                            canvas.height = this.naturalHeight;
                            canvas.width = this.naturalWidth;
                            ctx.drawImage(this, 0, 0);
                            dataURL = canvas.toDataURL(outputFormat);
                            questoes[i].alternativas[j].name = questoes[i].alternativas[j].name.replace(src, dataURL);
                            finish['a_' + r + '_' + alternativa.id] = true;

                        };
                        img.src = src;
                    }
                }

            }
            else if (questao.type_questao == 'conjunto') {


                for (let y = 0; y < questao.questoes.length; y++) {
                    let questao2 = questao.questoes[y];
                    let srcs = this.parseHtmlString(questao2.name);
                    for (let k = 0; k < srcs.length; k++) {
                        let src = srcs[k];
                        let r = k
                        finish['q_' + r + '_' + questao2.id] = false;

                        let img = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = function () {
                            var canvas = document.createElement('CANVAS');
                            var ctx = canvas.getContext('2d');
                            var dataURL;
                            canvas.height = this.naturalHeight;
                            canvas.width = this.naturalWidth;
                            ctx.drawImage(this, 0, 0);
                            dataURL = canvas.toDataURL(outputFormat);
                            questoes[i].questoes[y].name = questoes[i].questoes[y].name.replace(src, dataURL);
                            finish['q_' + r + '_' + questao2.id] = true;

                        };
                        img.src = src;
                    }

                    for (let j = 0; j < questao2.alternativas.length; j++) {
                        let alternativa = questao2.alternativas[j];
                        let srcs = this.parseHtmlString(alternativa.name);

                        for (let k = 0; k < srcs.length; k++) {
                            let r = k
                            finish['a_' + r + '_' + alternativa.id] = false;

                            let src = srcs[k];
                            let img = new Image();
                            img.crossOrigin = 'Anonymous';
                            img.onload = function () {
                                var canvas = document.createElement('CANVAS');
                                var ctx = canvas.getContext('2d');
                                var dataURL;
                                canvas.height = this.naturalHeight;
                                canvas.width = this.naturalWidth;
                                ctx.drawImage(this, 0, 0);
                                dataURL = canvas.toDataURL(outputFormat);
                                questoes[i].questoes[y].alternativas[j].name = questoes[i].questoes[y].alternativas[j].name.replace(src, dataURL);
                                finish['a_' + r + '_' + alternativa.id] = true;
                            };
                            img.src = src;
                        }
                    }
                }

            }
        }
        let counter=0;
        this.inverval_load = setInterval(() => {
            let interval_actual=this.inverval_load;
            if (!Object.values(finish).includes(false)) {
                clearInterval(this.inverval_load);
                clearInterval(interval_actual);

                let prova = { ...this.state.provaSelect };
                prova.questoes = [...questoes];
                this.setState({ provaSelect: prova }, () => {
                    this.setPages();
                });
            }
            else {
                console.log("executando interval entrou else");
        
                counter++;
                if (counter > 3) {
                let prova = { ...this.state.provaSelect };
                prova.questoes = [...questoes];

                    clearInterval(this.inverval_load);
                    clearInterval(interval_actual);
                  this.setState({ provaSelect: prova }, () => {
                    this.setPages();
                    });
                }
              }
        }, 1000);


    }


    setPages() {
        try {
            // let width_one_column = (668 - (this.state.marginInternaDireita - 40) - (this.state.marginInternaEsquerda - 40));
            // let width_two_column = 326 - ((this.state.marginInternaDireita - 40) + (this.state.marginInternaEsquerda - 40) / 2);
            let width_one_column = (771 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda));
            let width_two_column = ((755 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda)) / 2);

            this.setState({ loading_page: true });
            // let height_page = this.state.numero_colunas == 1 ? (960 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) : (((950 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) * 2));
            // let height_page = this.state.numero_colunas == 1 ? (960 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) : (((950 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) * 2));
            let height_page = this.state.numero_colunas == 1
                ? 1103 - this.state.marginInternaSuperior - this.state.marginInternaInferior - 67 -20
                : (1103 -20 - this.state.marginInternaSuperior - this.state.marginInternaInferior - 67) * 2;
            // let height_page = 1200;
            console.log('Height page: ', height_page)

            let default_margin = 0;
            let pages = [];
            let questoes = this.state.provaSelect.questoes;
            let page = null;
            for (let i = 0; i < questoes.length; i++) {
                let questao = questoes[i];
                if (page == null) {
                    page = {
                        ids: [],
                        elements: [],
                        height: 0,
                        heights: []
                    }
                }
                //checa categoria
                if (document.getElementById('categoria_' + questao.id) != null && page.height + Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight + default_margin) < height_page) {
                    page.height += Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin;
                    page.elements.push(document.getElementById('categoria_' + questao.id).cloneNode(true))
                    page.heights.push(Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin)

                    page.ids.push('categoria_' + questao.id);
                }
                else if (document.getElementById('categoria_' + questao.id) != null) {
                    pages.push(page);
                    page = {
                        ids: [],
                        elements: [],
                        height: 0,
                        heights: []

                    }
                    if (document.getElementById('categoria_' + questao.id) != null && page.height + Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin < height_page) {
                        page.height += Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin;
                        page.elements.push(document.getElementById('categoria_' + questao.id).cloneNode(true))
                        page.ids.push('categoria_' + questao.id);
                        page.heights.push(Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin)
                    }
                }

                if (page.height + Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin < height_page) {
                    page.height += Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin;
                    let element2 = document.getElementById('enunciado_' + questao.id).cloneNode(true);
                    element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                    page.elements.push(element2)
                    page.ids.push('enunciado_' + questao.id);
                    page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin);

                }
                else {
                    pages.push(page);
                    page = {
                        ids: [],
                        elements: [],
                        height: 0,
                        heights: []

                    }
                    if (page.height + Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin < height_page) {
                        page.height += Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin;
                        let element2 = document.getElementById('enunciado_' + questao.id).cloneNode(true);
                        element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                        page.elements.push(element2)
                        page.ids.push('enunciado_' + questao.id);
                        page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin);
                    }
                }

                if (questao.type_questao == 'simples') {
                    for (let j = 0; j < questao.alternativas.length; j++) {
                        let alternativa = questao.alternativas[j];
                        if (page.height + Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;
                            let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                            element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                            page.elements.push(element2)
                            page.ids.push('alternativa_' + alternativa.id);
                            page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);
                        }
                        else {
                            pages.push(page);
                            page = {
                                ids: [],
                                elements: [],
                                height: 0,
                                heights: []
                            }
                            if (page.height + document.getElementById('alternativa_' + alternativa.id).offsetHeight + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;
                                let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                                element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                page.elements.push(element2)
                                page.ids.push('alternativa_' + alternativa.id);
                                page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);
                            }
                        }
                    }

                    if (document.getElementById('dividor_' + questao.id) != null && page.height + document.getElementById('dividor_' + questao.id).offsetHeight + default_margin < height_page) {
                        page.height += Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight) + default_margin;
                        page.elements.push(document.getElementById('dividor_' + questao.id).cloneNode(true))
                        page.ids.push('dividor_' + questao.id);
                        page.heights.push(Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight) + default_margin);

                    }
                    else if (document.getElementById('dividor_' + questao.id) != null) {
                        pages.push(page);
                        page = {
                            ids: [],
                            elements: [],
                            height: 0,
                            heights: []
                        }
                        if (document.getElementById('dividor_' + questao.id) != null && page.height + document.getElementById('dividor_' + questao.id).offsetHeight + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight) + default_margin;
                            page.elements.push(document.getElementById('dividor_' + questao.id).cloneNode(true))
                            page.ids.push('dividor_' + questao.id);
                            page.heights.push(Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight) + default_margin);
                        }
                    }

                }
                else if (questao.type_questao == 'conjunto') {
                    for (let k = 0; k < questao.questoes.length; k++) {
                        let questao_child = questao.questoes[k];
                        if (page.height + document.getElementById('enunciado_' + questao_child.id).offsetHeight + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin;
                            let element2 = document.getElementById('enunciado_' + questao_child.id).cloneNode(true);
                            element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                            page.elements.push(element2)
                            page.ids.push('enunciado_' + questao_child.id);
                            page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin);
                        }
                        else {
                            pages.push(page);
                            page = {
                                ids: [],
                                elements: [],
                                height: 0,
                                heights: []
                            }
                            if (page.height + document.getElementById('enunciado_' + questao_child.id).offsetHeight + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin;
                                let element2 = document.getElementById('enunciado_' + questao_child.id).cloneNode(true);
                                element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                page.elements.push(element2)
                                page.ids.push('enunciado_' + questao_child.id);
                                page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin);
                            }
                        }



                        for (let j = 0; j < questao_child.alternativas.length; j++) {
                            let alternativa = questao_child.alternativas[j];
                            if (page.height + document.getElementById('alternativa_' + alternativa.id).offsetHeight + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;

                                let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                                element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                                page.elements.push(element2)
                                page.ids.push('alternativa_' + alternativa.id);
                                page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);

                            }
                            else {
                                pages.push(page);
                                page = {
                                    ids: [],
                                    elements: [],
                                    height: 0,
                                    heights: []
                                }
                                if (page.height + document.getElementById('alternativa_' + alternativa.id).offsetHeight + default_margin < height_page) {
                                    page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;
                                    let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                                    element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');


                                    page.elements.push(element2)
                                    page.ids.push('alternativa_' + alternativa.id);
                                    page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);

                                }
                            }
                        }

                        if (document.getElementById('dividor_' + questao_child.id) != null && page.height + document.getElementById('dividor_' + questao_child.id).offsetHeight + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight) + default_margin;
                            page.elements.push(document.getElementById('dividor_' + questao_child.id).cloneNode(true))
                            page.ids.push('dividor_' + questao_child.id);
                            page.heights.push(Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight) + default_margin);

                        }
                        else if (document.getElementById('dividor_' + questao_child.id) != null) {
                            pages.push(page);
                            page = {
                                ids: [],
                                elements: [],
                                height: 0,
                                heights: []
                            }
                            if (document.getElementById('dividor_' + questao_child.id) != null && page.height + document.getElementById('dividor_' + questao_child.id).offsetHeight + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight) + default_margin;
                                page.elements.push(document.getElementById('dividor_' + questao_child.id).cloneNode(true))
                                page.ids.push('dividor_' + questao_child.id);
                                page.heights.push(Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight) + default_margin);
                            }
                        }
                    }
                }
            }
            if (page != null && page.height != 0) {
                pages.push(page);
            }

            console.log(pages)
            console.log('iniciou')
            this.pages = [];
            let length_elements = [];
            this.setState({ pages, pages_new: [] }, () => {
                this.timeout = setTimeout(() => {
                    try {

                        console.log(pages);
                        console.log(this.pages);
                        pages = [];
                        default_margin = 0;
                        let line_height = 60;

                        page = null;
                        let heights = [];
                        for (let i = 0; i < this.pages.length; i++) {
                            let element = this.pages[i];
                            if (page == null) {
                                page = {
                                    ids: [],
                                    elements: [],
                                    height: 0,
                                    heights: []

                                }
                            }
                            //checa categoria
                            if (page.height < height_page / 2 && document.getElementById(element).innerHTML.includes('<img ') && page.height + Math.round(document.getElementById(element).offsetHeight) + default_margin > (height_page / 2) && this.state.numero_colunas != 1) {
                                let div_element = document.createElement('div');
                                div_element.style.height = ((height_page / 2) - page.height - 1) + 'px';
                                // div_element.style.background='yellow';
                                div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                                let id = uuid();
                                let new_div = div_element.cloneNode();
                                new_div.id = id;

                                page.height += (height_page / 2) - page.height;
                                heights.push((height_page / 2) - page.height)
                                page.elements.push(new_div)
                                page.ids.push(id);
                                page.heights.push((height_page / 2) - page.height)
                                console.log(id);
                            }
                            if (page.height < height_page / 2 && page.height + line_height + default_margin > (height_page / 2) && this.state.numero_colunas != 1) {
                                let div_element = document.createElement('div');
                                div_element.style.height = ((height_page / 2) - page.height - 1) + 'px';
                                // div_element.style.background='yellow';
                                div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                                let id = uuid();
                                let new_div = div_element.cloneNode();
                                new_div.id = id;

                                page.height += (height_page / 2) - page.height;
                                heights.push((height_page / 2) - page.height)
                                page.elements.push(new_div)
                                page.ids.push(id);
                                page.heights.push((height_page / 2) - page.height)

                                console.log(id);
                            }
                            // if (
                            //     document.getElementById(element).classList.toString().includes('categoria') &&
                            //     this.pages[i + 1] != undefined &&
                            //     (page.height + Math.round(document.getElementById(element).offsetHeight + document.getElementById(this.pages[i + 1]).offsetHeight) + default_margin > (height_page / 2) && page.height < height_page / 2)

                            //     && this.state.numero_colunas != 1) {
                            //     let div_element = document.createElement('div');
                            //     div_element.style.height = ((height_page / 2) - page.height - 1) + 'px';
                            //     // div_element.style.background='yellow';
                            //     div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                            //     let id = uuid();
                            //     let new_div = div_element.cloneNode();
                            //     new_div.id = id;

                            //     page.height += (height_page / 2) - page.height;
                            //     heights.push((height_page / 2) - page.height)
                            //     page.elements.push(new_div)
                            //     page.ids.push(id);
                            //     page.heights.push((height_page / 2) - page.height)

                            //     console.log(id);
                            // }

                            // if (
                            //     document.getElementById(element).classList.toString().includes('categoria') &&
                            //     this.pages[i + 1] != undefined &&
                            //     (page.height + Math.round(document.getElementById(element).offsetHeight + document.getElementById(this.pages[i + 1]).offsetHeight) + default_margin > height_page)

                            //     && this.state.numero_colunas != 1) {

                            //     let div_element = document.createElement('div');
                            //     div_element.style.height = ((height_page) - page.height - 1) + 'px';
                            //     // div_element.style.background='yellow';
                            //     div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                            //     let id = uuid();
                            //     let new_div = div_element.cloneNode();
                            //     new_div.id = id;

                            //     page.height += (height_page) - page.height;
                            //     heights.push((height_page) - page.height)
                            //     page.elements.push(new_div)
                            //     page.ids.push(id);
                            //     page.heights.push((height_page) - page.height)

                            //     console.log(id);
                            //     pages.push(page);
                            //     page = {
                            //         ids: [],
                            //         elements: [],
                            //         height: 0,
                            //         heights: []

                            //     }
                            // }
                            if (page.height + Math.round(document.getElementById(element).offsetHeight) + default_margin < height_page) {
                                page.height += Math.round(document.getElementById(element).offsetHeight) + default_margin;
                                heights.push(Math.round(document.getElementById(element).offsetHeight))
                                let element2 = document.getElementById(element).cloneNode(true);
                                element2.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                page.elements.push(element2)
                                page.ids.push(element);
                                page.heights.push(Math.round(document.getElementById(element).offsetHeight) + default_margin)

                                // if (pages.length == 7) {
                                //     length_elements.push(document.getElementById(element).offsetHeight)
                                // }
                            }
                            else {
                                pages.push(page);
                                page = {
                                    ids: [],
                                    elements: [],
                                    height: 0,
                                    heights: []

                                }
                                if (page.height + Math.round(document.getElementById(element).offsetHeight) + default_margin < height_page) {
                                    heights.push(Math.round(document.getElementById(element).offsetHeight))

                                    page.height += Math.round(document.getElementById(element).offsetHeight) + default_margin;
                                    let element2 = document.getElementById(element).cloneNode(true);
                                    element2.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                    page.elements.push(element2)
                                    page.ids.push(element);
                                    page.heights.push(Math.round(document.getElementById(element).offsetHeight) + default_margin)
                                    // if (pages.length == 7) {
                                    //     length_elements.push(document.getElementById(element).offsetHeight)
                                    // }
                                }
                            }
                        }
                        if (page != null && page.height != 0) {
                            pages.push(page);
                        }

                        this.setState({ pages_new: pages, pages: [], loading_page: false }, () => {
                            console.log('New pages')
                            console.log(pages)
                            console.log(length_elements)
                            // setTimeout(() => {
                            //     this.generatePDF();

                            // }, 1000)
                        });
                    }
                    catch (e) {
                        console.log(e);
                    }
                }, 1000);

            });
        }
        catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    async generatePDF() {
        this.setState({ loading_pdf: true });
        // let images = document.getElementsByTagName('img')
        // for (let image of images) {
        //     image.crossOrigin = "Anonymous";
        // }
        // this.setState({loading_screen:true});
        // this.setState({loading_screen:false});

        // var doc = new jsPDF("p", "pt", 'a4');




        // for (let page = 0; page < this.state.pages.length + 1; page++) {
        //     let canvas = await html2canvas(document.querySelector("#page_"+page),{
        //         dpi: 192,
        //         scale: 1,
        //         letterRendering: true,
        //         useCORS: true,
        //         // width:3176,
        //         // height:4332
        //     });
        //     let height_page=canvas.height;
        //     try {
        //         let image = canvas.toDataURL('image/jpeg', 0.7);
        //         console.log(image)
        //         console.log(canvas.width)
        //         console.log(canvas.height)


        //         const pageWidth = doc.internal.pageSize.getWidth();
        //         const pageHeight = doc.internal.pageSize.getHeight();

        //         const widthRatio = pageWidth / canvas.width;
        //         const heightRatio = pageHeight / height_page;
        //         const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        //         const canvasWidth = canvas.width * ratio;
        //         const canvasHeight = height_page * ratio;

        //         const marginX = (pageWidth+30 - canvasWidth) / 2;
        //         const marginY = (pageHeight - canvasHeight) / 2;
        //         console.log(marginX)
        //         console.log(marginY)
        //         console.log(pageWidth)
        //         console.log(pageHeight)
        //         console.log(canvasWidth)
        //         console.log(canvasHeight)

        //         doc.addImage(image, 'JPEG', 7, 7, canvasWidth, canvasHeight);
        //         if (page + 1 < this.state.pages.length + 1) {
        //             doc.addPage();
        //             doc.setPage(page + 2);
        //         }
        //         console.log('executou2')
        //     }
        //     catch (e) {
        //         console.log(e)
        //     }
        // }

        // this.setState({loading_pdf:false});

        // doc.save();
        // return;
        if (this.state.generate_pdf_image == false) {

            var doc = new jsPDF("p", "pt", [784, 1113]);

            await doc.html(document.getElementById('pdf_diagramado_2').parentElement);
            doc.save('diagramacao_' + this.state.nome + '.pdf');
            this.setState({ loading_pdf: false });
            return;
        }
        else {


            var doc = new jsPDF("p", "pt", 'a4');

            for (let page = 0; page < this.state.pages_new.length + 1; page++) {

                const element = document.getElementById('page_' + page);
                // Choose the element and save the PDF for our user.
                let canvas = await html2pdf().from(element).set({
                    // margin:       [10, 10, 10, 10], 
                    filename: 'samplepdf.pdf',
                    pagebreak: { mode: 'css', after: '.nextpage1' },
                    jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
                    image: { type: 'jpeg', quality: 0.5 },
                    html2canvas: {
                        dpi: 96,
                        scale: 4,
                        letterRendering: true,
                        useCORS: true,
                        backgroundColor: 'rgba(255, 255, 255, 1)', removeContainer: true

                    }
                }).toImg().get('canvas')
                let height_page = canvas.height;
                // var canvas1 = document.createElement('canvas');
                // var ctx1 = canvas1.getContext('2d');
                // canvas1.width = canvas.width;
                // canvas1.height = height_page;
                // let cropX = 0;
                // let cropY = page * height_page;
                // let cropWidth = canvas.width;
                // let cropHeight = height_page;
                // // use the extended from of drawImage to draw the
                // // cropped area to the temp canvas
                // ctx1.drawImage(canvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                // return the .toDataURL of the temp canvas
                console.log('executou')
                console.log(canvas);
                var ctx = canvas.getContext("2d");
                ctx.fillStyle = '#ffffff';
                try {
                    let image = canvas.toDataURL('image/jpeg', 0.5);
                    console.log(image)

                    const pageWidth = doc.internal.pageSize.getWidth();
                    const pageHeight = doc.internal.pageSize.getHeight();

                    const widthRatio = pageWidth / canvas.width;
                    const heightRatio = pageHeight / height_page;
                    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

                    const canvasWidth = canvas.width * ratio;
                    const canvasHeight = height_page * ratio;

                    const marginX = (pageWidth + 30 - canvasWidth) / 2;
                    const marginY = (pageHeight - canvasHeight) / 2;
                    console.log(marginX)
                    console.log(marginY)
                    console.log(pageWidth)
                    console.log(pageHeight)
                    console.log(canvasWidth)
                    console.log(canvasHeight)

                    doc.addImage(image, 'JPEG', marginX, 15, canvasWidth, canvasHeight);
                    if (page + 1 < this.state.pages_new.length + 1) {
                        doc.addPage();
                        doc.setPage(page + 2);
                    }
                    console.log('executou2')
                }
                catch (e) {
                    console.log(e)
                }

            }
            this.setState({ loading_pdf: false });

            doc.save('diagramacao_' + this.state.nome + '.pdf');

            // var doc = new jsPDF("p", "pt",'a4');

            // var width = doc.internal.pageSize.getWidth();
            // var height = doc.internal.pageSize.getHeight();
            // doc.html(document.getElementById('pdf_diagramado_2').innerHTML,{
            //     x:0,y:0,
            //     width:794,
            //     height:1123,
            //     callback: function (doc) {
            //         doc.save();
            //       }
        }

        // })
        // console.log(width,height);
        // document.getElementById('iframe').srcdoc=document.getElementById('pdf_diagramado_2').innerHTML;
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('prova_id', this.state.provaSelect.id);
        form.append('diagramacao_id', this.props.match.params.id);

        form.append('logo', this.state.logo);
        form.append('logo_inferior', this.state.logo_inferior);

        form.append('titulo_pagina', this.state.titulo_pagina);
        form.append('subtitulo_pagina', this.state.subtitulo_pagina);
        form.append('nome_prova', this.state.nome);
        form.append('titulo_campo_1', this.state.titulo_campo_1);
        form.append('titulo_campo_2', this.state.titulo_campo_2);

        form.append('campo_1', this.state.campo_1);
        form.append('campo_2', this.state.campo_2);
        form.append('titulo_cabecalho', this.state.titulo);
        form.append('subtitulo_cabecalho', this.state.subtitulo);
        form.append('instrucoes', this.state.instrucoes);
        form.append('numero_colunas', this.state.numero_colunas);
        form.append('tamanho_fonte_questoes', this.state.fontSizeQuestoes);
        form.append('tamanho_fonte_alternativas', this.state.fontSizeAlternativas);
        form.append('tamanho_fonte_instrucoes', this.state.fontSizeInstrucoes);
        form.append('numero_colunas_instrucoes', this.state.numero_colunas_instrucoes);
        form.append('margin_interna_superior', this.state.marginInternaSuperior);
        form.append('margin_interna_direita', this.state.marginInternaDireita);
        form.append('margin_interna_esquerda', this.state.marginInternaEsquerda);
        form.append('margin_interna_inferior', this.state.marginInternaInferior);
        form.append('show_category', this.state.show_category);
        form.append('barra_dupla', this.state.barra_dupla);
        form.append('default_diagramacao', this.state.default_diagramacao);

        fetch(`${URL}api/faculdade/update_diagramacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_processo(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (Object.values(prevState.questoes_selecionadas).length != Object.values(this.state.questoes_selecionadas).length) {
            this.parseQuestoesDragDrop();
        }
        if (document.getElementById('header_page') != null && this.state.offsetHeight != document.getElementById('header_page').offsetHeight) {
            this.setState({ offsetHeight: document.getElementById('header_page').offsetHeight })
        }
    }

    parseQuestoesDragDrop() {
        let questoes = [...this.state.questoes_array];
        let ids = questoes.map((item, id) => item.id);
        let ids_exists = []

        for (let i = 0; i < Object.values(this.state.questoes_selecionadas).length; i++) {

            let item = Object.values(this.state.questoes_selecionadas)[i];
            ids_exists.push(item.id);

            if (!ids.includes(item.id)) {
                questoes.push({
                    numero: i + 1,
                    id: item.id,
                    enunciado: <p className='text_overflow'>{item.text_questao}</p>,
                    categoria: item.categoria.name,
                    tipo_questao: <p style={{ whiteSpace: 'pre' }}>{item.type_questao == 'conjunto' ? ('Conjunto de questões\n' + item.questoes.length + (item.questoes.length == 1 ? ' questão' : ' questões')) :
                        (item.type_questao == 'simples' ? 'Questão única' : 'Questão dissertativa')}</p>,
                    acoes: i
                });
            }
        }
        questoes = questoes.filter((item, id) => {
            if (ids_exists.includes(item.id)) {
                return true;
            }
            return false;
        })
        this.setState({ questoes_array: questoes });
    }










    changeFontSizeQuestoes(questao) {
        if (this.state.fontSizeQuestoes == 'default') {
            return questao;
        }
        else {
            return questao.replace(/font-size/g, '');
        }
    }

    changeFontSizeAlts(questao) {
        if (this.state.fontSizeAlternativas == 'default') {
            return questao;
        }
        else {
            return questao.replace(/font-size/g, '');
        }
    }
    render() {
        let height_sample = 1103;
        let width_sample = 777;

        let number_questao = 0;
        let width_one_column = 771;
        // let width_two_column = 326;
        let width_two_column = ((755 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda)) / 2);

        this.pages = []

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/processos/show/${this.state.processo.id}?tab=2`} />}
                <StyleQuestion />

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>
                            <div className='hidethis'>
                                <div hidden id="text_enunciado hidethis">

                                </div>
                                <h4 className="mt-0 header-title hidethis" style={{ fontSize: '1.5rem', color: 'black' }}>Editar diagramação</h4>
                                <p className='hidethis' style={{ fontSize: '1rem', color: 'black' }}>Edite uma diagramação de prova {(this.props.user.empresa == null || this.props.user.empresa.change_text == false) ? 'do processo' : 'da vaga/cargo'} {this.state.processo.name}</p>
                                <br className='hidethis' />
                                <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} />
                            </div>

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div >
                                {this.state.msgErro != '' && <div className='hidethis' style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0 }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <InputGroup className='hidethis'>
                                    <div className='hidethis' style={{ width: '100%' }}>

                                        <div className="flex fdcolumn flex1 margin-bottom hidethis">
                                            <Form.Label className='hidethis'>Selecione uma prova</Form.Label>

                                            <select disabled={this.state.loading_page} className="form-select hidethis" onChange={(e) => {
                                                if (e.target.value != '') {
                                                    let provaSelect = this.state.provas.filter((item, id) => item.id == e.target.value)[0];
                                                    let questoes_selecionadas = {};
                                                    for (let i = 0; i < provaSelect.questoes.length; i++) {
                                                        questoes_selecionadas['q_' + provaSelect.questoes[i].id] = provaSelect.questoes[i];
                                                    }
                                                    this.setState({ provaSelect: provaSelect, questoes_selecionadas }, () => {
                                                        this.readInitialImages();

                                                    });
                                                }
                                                else {
                                                    this.setState({ provaSelect: { id: '' }, questao_enunciado: '', questoes: [] });

                                                }
                                            }} value={this.state.provaSelect.id}>
                                                <option className='hidethis' value="">Selecione uma prova</option>
                                                {(this.state.provas && this.state.provas.length != 0) && this.state.provas.map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value["id"]}
                                                        className='hidethis'
                                                    >{value["name"] + ' - ' + value['questoes'].map((item, id) => {
                                                        if (item.type_questao == 'conjunto') {
                                                            return item.questoes.length;
                                                        }
                                                        else {
                                                            return 1;
                                                        }
                                                    }).reduce((a, b) => a + b, 0) + ' ' + (value['questoes'].length == 1 ? 'questão' : 'questões') + ' - ' + value['processo'].name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </InputGroup>
                                <Form hidden={this.state.provaSelect.id == ''} onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <div className='hidethis'>
                                        <Form.Label>Logo Superior <Attention /> </Form.Label>
                                        <ImageLoaderInput disabled={this.state.loading_page} path={this.state.logo_path} width_='100%' height_='200px' ImageChange={image => this.setState({ logo: image })} />

                                        <Form.Label>Logo Inferior <Attention /> </Form.Label>
                                        <ImageLoaderInput disabled={this.state.loading_page} path={this.state.logo_path_inferior} width_='100%' height_='200px' ImageChange={image => this.setState({ logo_inferior: image })} />

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Título (Ex: Instituição) <Attention /></Form.Label>
                                                <Input
                                                    placeholder="Título. Ex: Escola X"
                                                    value={this.state.titulo_pagina}
                                                    onChange={(e) => this.setState({ titulo_pagina: e.target.value })}
                                                    required
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>
                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Subtítulo (Ex: Local) <Attention /></Form.Label>
                                                <Input
                                                    placeholder="Subtítulo. Ex: Estado de São Paulo"
                                                    value={this.state.subtitulo_pagina}
                                                    onChange={(e) => this.setState({ subtitulo_pagina: e.target.value })}
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>
                                        {/* <InputGroup>
                        
                                                                <div className="flex fdcolumn flex1 margin-bottom">
                                                                    <Form.Label>PDF Image <Attention /></Form.Label>
                                                                    <FormCheck
                                                                        type='checkbox'
                                                                        disabled={this.state.loading_page}
                                                                        checked={this.state.generate_pdf_image}
                                                                        onChange={(e) => this.setState({ generate_pdf_image: e.target.checked }, () => {
                                                                            this.setPages();
                                                                        })}
                                                                        
                                                                    // title="Coloque o nome completo do usuário"
                                                                    />
                                                                </div>
                                                            </InputGroup> */}

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Nome da prova <Attention /></Form.Label>
                                                <Input
                                                    placeholder="Nome da prova"
                                                    value={this.state.nome}
                                                    onChange={(e) => this.setState({ nome: e.target.value })}
                                                    required
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Título campo 1 (Ex: Cargo) </Form.Label>
                                                <Input
                                                    placeholder="Título campo 1"
                                                    value={this.state.titulo_campo_1}
                                                    onChange={(e) => this.setState({ titulo_campo_1: e.target.value })}
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Campo 1 (Ex: Cargo de Poílicia) </Form.Label>
                                                <Input
                                                    placeholder="Campo 1"
                                                    value={this.state.campo_1}
                                                    onChange={(e) => this.setState({ campo_1: e.target.value })}
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Título campo 2 (Ex: Turno) </Form.Label>
                                                <Input
                                                    placeholder="Título campo 2"
                                                    value={this.state.titulo_campo_2}
                                                    onChange={(e) => this.setState({ titulo_campo_2: e.target.value })}
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Campo 2 (Ex: Vespertino) </Form.Label>
                                                <Input
                                                    placeholder="Campo 2"
                                                    value={this.state.campo_2}
                                                    onChange={(e) => this.setState({ campo_2: e.target.value })}
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Título Cabeçalho<Attention /></Form.Label>
                                                <Input
                                                    placeholder="Título do Cabeçalho"
                                                    value={this.state.titulo}
                                                    onChange={(e) => this.setState({ titulo: e.target.value })}
                                                    required
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Subtítulo Cabeçalho <Attention /></Form.Label>
                                                <Input
                                                    placeholder="Subtítulo do Cabeçalho"
                                                    value={this.state.subtitulo}
                                                    onChange={(e) => this.setState({ subtitulo: e.target.value })}
                                                    required
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>



                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label >Instruções <Attention /> {this.state.loading_image == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                                </div>}</Form.Label>

                                                {/* <JoditEditor
                                                                        ref={this.editor}
                                                                        value={this.state.instrucoes}
                                                                        config={this.config}
                                                                        height={400}
                                                                        tabIndex={1} // tabIndex of textarea
                                                                        onBlur={newContent => this.setState({ instrucoes: newContent })} // preferred to use only this option to update the content for performance reasons
                                                                    // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                                                    /> */}
                                                <Input
                                                    placeholder="Instruções"
                                                    value={this.state.instrucoes}
                                                    onChange={(e) => this.setState({ instrucoes: e.target.value })}
                                                    required
                                                    as="textarea"
                                                    style={{ minHeight: 200 }}
                                                    disabled={this.state.loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <div className="row">

                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Tamanho da fonte das instruções</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ fontSizeInstrucoes: parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.fontSizeInstrucoes}>
                                                    {new Array(21).fill(0).map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={index + 9}
                                                        >{index + 9}px</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Número de colunas das instruções</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ numero_colunas_instrucoes: parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.numero_colunas_instrucoes}>
                                                    <option value={1}>1 coluna</option>
                                                    <option value={2}>2 colunas</option>
                                                </select>
                                            </div>
                                        </div>



                                        <div className="row">

                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Número de colunas da Avaliação</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ numero_colunas: parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.numero_colunas}>
                                                    <option value={1}>1 coluna</option>
                                                    <option value={2}>2 colunas</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Tamanho da fonte das questões</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ fontSizeQuestoes: e.target.value == 'default' ? 'default' : parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.fontSizeQuestoes}>
                                                    <option value="default">Tamanho escolhido no cadastro da questão</option>
                                                    {new Array(21).fill(0).map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={index + 9}
                                                        >{index + 9}px</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Tamanho da fonte das alternativas</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ fontSizeAlternativas: e.target.value == 'default' ? 'default' : parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.fontSizeAlternativas}>
                                                    <option value="default">Tamanho escolhido no cadastro da alternativa</option>
                                                    {new Array(21).fill(0).map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={index + 9}
                                                        >{index + 9}px</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 margin-bottom">

                                                <Form.Label>Mostrar disciplina?</Form.Label>
                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ show_category: e.target.value == 'true' ? true : false }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.show_category}>
                                                    <option value={true}>Sim</option>
                                                    <option value={false}>Não</option>


                                                </select>
                                            </div>

                                        </div>


                                        <div className="row">

                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Margem Superior</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ marginInternaSuperior: parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.marginInternaSuperior}>
                                                    {new Array(60).fill(0).map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={index}
                                                        >{index}px</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Margem Inferior</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ marginInternaInferior: parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.marginInternaInferior}>
                                                    {new Array(60).fill(0).map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={index}
                                                        >{index}px</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>




                                        <div className="row ">

                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Margem Esquerda</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ marginInternaEsquerda: parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.marginInternaEsquerda}>
                                                    {new Array(60).fill(0).map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={index}
                                                        >{index}px</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Margem Direita</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ marginInternaDireita: parseInt(e.target.value) }, () => {
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.marginInternaDireita}>
                                                    {new Array(60).fill(0).map((value, index) => (
                                                        <option
                                                            key={index}
                                                            value={index}
                                                        >{index}px</option>
                                                    ))}
                                                </select>
                                            </div>


                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Borda visível?</Form.Label>

                                                <select disabled={this.state.loading_page} className="form-select " onChange={(e) => {
                                                    this.setState({ barra_dupla: e.target.value == 'true' ? true : false }, () => {
                                                        console.log(this.state.barra_dupla)
                                                        setTimeout(() => {
                                                            this.setPages();
                                                        }, 300);
                                                    })
                                                }} value={this.state.barra_dupla}>
                                                    <option value={true}>Sim</option>
                                                    <option value={false}>Não</option>

                                                </select>
                                            </div>

                                            <div className="col-12 col-md-6 margin-bottom">
                                                <Form.Label>Salvar diagramação como padrão?</Form.Label>

                                                <select
                                                    className="form-select " onChange={(e) => {
                                                        this.setState({ default_diagramacao: e.target.value == 'true' ? true : false }, () => {

                                                        })
                                                    }} value={this.state.default_diagramacao}>
                                                    <option value={true}>Sim</option>
                                                    <option value={false}>Não</option>

                                                </select>
                                            </div>
                                        </div>
                                        <br />
                                    </div>

                                    <div className='hidethis' style={{ width: '100%', paddingBottom: 20 }}>



                                        {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1 hidethis">
                                            {/* <Link to={`/accounts/list`}>
                                                            <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                                        </Link> */}
                                            <DefaultButton
                                                bg="secondary"
                                                text="Voltar"
                                                icon={<RiArrowGoBackLine />}
                                                search={'?tab=2'}
                                                to={`/processos/show/${this.state.processo.id}`}
                                            />
                                            {/* <Button variant="success" type="submit" disabled={this.loading}>
                                                            {this.state.loading ? <Spinner /> : ""}
                                                            Registrar usuário
                                                        </Button> */}
                                            <div style={{ display: 'flex' }}>
                                                {this.state.loading_page == false && <DefaultButton
                                                    type="button"
                                                    bg="info"
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {
                                                        // this.generatePDF();
                                                        window.print();
                                                    }}
                                                    text="Imprimir"
                                                    disabled={this.state.loading_pdf == true}
                                                    loadingtext="Imprindo..."
                                                    loading={this.state.loading_pdf}
                                                />}
                                                <DefaultButton
                                                    type="submit"
                                                    bg="confirm"
                                                    text="Salvar diagramação"
                                                    disabled={this.state.loading_image == true}
                                                    loadingtext="Salvando diagramação"
                                                    loading={this.state.loading_save}
                                                />
                                            </div>


                                        </div>}

                                        {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div></div>}



                                    </div>

                                    <div style={{ position: 'relative' }}>
                                        {this.state.loading_page && <div style={{ zIndex: 10, background: 'rgba(255,255,255,0.5)', position: 'absolute', top: 0, height: 600, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div>}
                                        <div className='print_preview' style={{ maxHeight: this.state.loading_page ? 600 : 'none', overflow: this.state.loading_page ? 'scroll' : 'visible', width: '100%', display: 'flex', justifyContent: 'center', background: '#f2f2f2', position: 'relative' }}>

                                            <div style={{ background: 'white', padding: 7.5, paddingTop: this.state.generate_pdf_image == false ? 0 : 15, paddingBottom: this.state.generate_pdf_image == false ? 0 : 15, height: '100%' }}>
                                                <div id="pdf_diagramado_2" >
                                                    <div id={"page_0"} style={{ height: 1120 }}>
                                                        <div style={{ paddingTop: this.state.generate_pdf_image == false ? 15 : 0, paddingBottom: this.state.generate_pdf_image == false ? 15 : 0 }} >

                                                            <div style={{ padding: 0, width: width_sample, background: 'white', marginTop: 0, marginBottom: 0, paddingTop: 0 }}>
                                                                <div style={{ height: 960 + 123 }} >
                                                                    <div id='header_page'>

                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 0 }}>
                                                                            <div>

                                                                                <img style={{ height: 100, width: 150, objectFit: 'contain' }} src={this.state.logo == '' ? (STORAGE_URL + this.state.logo_path) : (window.URL.createObjectURL(this.state.logo))} />
                                                                            </div>

                                                                            <div style={{ maxWidth: 350, maxHeight: 100 }}>
                                                                                <p style={{ textAlign: 'right', fontSize: 30, marginBottom: 0, color: 'black', fontWeight: 'bold' }}>{this.state.provaSelect.name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ width: '100%', height: 1, background: 'black', marginTop: 5, marginBottom: 5 }}></div>
                                                                        <div style={{ position: "relative" }}>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: "left",
                                                                                    fontSize: 15,
                                                                                    marginBottom: 5,
                                                                                    marginLeft: 20,
                                                                                    color: "black",
                                                                                    fontWeight: "normal",
                                                                                }}
                                                                            >
                                                                                <b  className='hidethis'>{"Nome do Candidato"}</b>&nbsp;
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: "left",
                                                                                    fontSize: 15,
                                                                                    marginBottom: 5,
                                                                                    marginLeft: 20,
                                                                                    color: "black",
                                                                                    fontWeight: "normal",
                                                                                }}
                                                                            >
                                                                                <b  className='hidethis'>{"Inscrição"}</b>&nbsp;
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: "left",
                                                                                    fontSize: 15,
                                                                                    marginBottom: 5,
                                                                                    marginLeft: 20,
                                                                                    color: "black",
                                                                                    fontWeight: "normal",
                                                                                }}
                                                                            >
                                                                                <b  className='hidethis'>{"CPF"}</b>&nbsp;
                                                                            </p>
                                                                            <img
                                                                                src={
                                                                                    "https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781"
                                                                                }
                                                                                height={60}
                                                                                width={60}
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    right: 25,
                                                                                    top: 5,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{}}>
                                                                            <p style={{ textAlign: 'center', fontSize: 30, marginBottom: 5, color: 'black', fontWeight: 'bold' }}>{this.state.titulo_pagina}</p>
                                                                            <p style={{ textAlign: 'center', fontSize: 20, marginBottom: 5, color: 'black', fontWeight: 'bold' }}>{this.state.subtitulo_pagina}</p>
                                                                            <p style={{ textAlign: 'center', fontSize: 25, marginBottom: 0, color: 'black', fontWeight: 'bold' }}>{this.state.nome}</p>

                                                                        </div>
                                                                        <div style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}>
                                                                            {this.state.campo_1.trim().length != 0 && <div style={{ border: '1px solid black', borderRightWidth: this.state.campo_2.trim().length == 0 ? 2 : 0, padding: 5, width: this.state.campo_2.trim().length == 0 ? '100%' : '50%', display: 'flex', alignItems: 'center' }}>
                                                                                <p style={{ marginBottom: 0, fontSize: 17 }}><b>{this.state.titulo_campo_1}: </b>{this.state.campo_1}</p>
                                                                            </div>}
                                                                            {this.state.campo_2.trim().length != 0 && <div style={{ border: '1px solid black', padding: 5, width: this.state.campo_1.trim().length == 0 ? '100%' : '50%', display: 'flex', alignItems: 'center' }}>
                                                                                <p style={{ marginBottom: 0, fontSize: 17 }}><b>{this.state.titulo_campo_2}: </b>{this.state.campo_2}</p>
                                                                            </div>}
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ height: 960 + 67 - 20 - this.state.offsetHeight, border: '2px solid black' }}>
                                                                        <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black' }}>
                                                                            <p style={{ marginBottom: 0, fontSize: 25, color: 'white', textAlign: 'center' }}><b>INSTRUÇÕES GERAIS</b></p>
                                                                        </div>
                                                                        <div style={{ height: 960 + 67 - 78 - this.state.offsetHeight, padding: 10, columnCount: this.state.numero_colunas_instrucoes, columnGap: 20, columnFill: 'auto', columnRule: 'double' }}>
                                                                            <p style={{ fontSize: this.state.fontSizeInstrucoes, whiteSpace: 'pre-line', textAlign: 'justify', color: 'black' }}>{this.state.instrucoes}</p>
                                                                        </div>

                                                                    </div>
                                                                    <div >
                                                                        <p style={{ marginBottom: 0, fontSize: 14, color: 'black', textAlign: 'center' }}>Realização</p>

                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                                            <img style={{ height: 50, maxWidth: '100%', objectFit: 'contain' }} src={this.state.logo_inferior == '' ? (STORAGE_URL + this.state.logo_path_inferior) : (window.URL.createObjectURL(this.state.logo_inferior))} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    {this.state.pages.map((item, id) => (
                                                        <div key={id} id={"page_" + (id + 1)} >
                                                            <div style={{ paddingTop: this.state.generate_pdf_image == false ? 10 : 0, paddingBottom: this.state.generate_pdf_image == false ? 0 : 0, height: 1113, }}>

                                                                <div style={{ height: 1103, padding: 0, marginLeft: this.state.marginInternaEsquerda - 40, marginRight: this.state.marginInternaDireita - 40, position: 'relative', width: this.state.numero_colunas == 1 ? (width_one_column - (this.state.marginInternaDireita - 40) - (this.state.marginInternaEsquerda - 40)) : (width_two_column), background: 'white', marginTop: 0, marginBottom: 0, border: '0px double black', paddingTop: this.state.marginInternaSuperior }}>
                                                                    <div style={{ height: 67 }}>
                                                                        <p style={{ marginBottom: 5, textAlign: 'center', fontSize: 14 }}>{this.state.titulo}</p>
                                                                        <p style={{ marginBottom: 20, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{this.state.subtitulo}</p>

                                                                    </div>
                                                                    <div ref={(ref) => {
                                                                        if (ref != null) {

                                                                            ref.innerHTML = '';
                                                                            item.elements.map((elem, index) => {
                                                                                if (elem.id.includes('categoria_')) {
                                                                                    elem.className = 'categoria';
                                                                                }
                                                                                else if (elem.id.includes('dividor_')) {
                                                                                    elem.className = 'dividor';
                                                                                }
                                                                                elem.id = 0 + '_' + id + '_' + index;
                                                                                if (ref != null) {
                                                                                    ref.appendChild(elem);
                                                                                }
                                                                                this.pages.push(0 + '_' + id + '_' + index)
                                                                            })

                                                                        }
                                                                    }} style={{ columnCount: 1, columnFill: 'auto',height:
                                                                    height_sample -
                                                                    this.state.marginInternaSuperior -
                                                                    this.state.marginInternaInferior -
                                                                    67-20, }} >

                                                                    </div>
                                                                    <div style={{ position: 'absolute', bottom: 10, right: 0, left: 0 }} >
                                                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 12 }}>Página {id + 1} de {this.state.pages.length}</p>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            {/* <div className='nextpage1'></div> */}
                                                        </div>
                                                    ))}


                                                    {this.state.pages_new.map((item, id) => (
                                                        <div key={id} style={{ height: 1120 }} id={"page_" + (id + 1)}>

                                                            <div style={{ paddingTop: this.state.generate_pdf_image == false ? 10 : 0, paddingBottom: this.state.generate_pdf_image == false ? 0 : 0, height: 1113, }}>

                                                                <div style={{
                                                                    height: 1103,
                                                                    paddingLeft: this.state.marginInternaEsquerda,
                                                                    paddingBottom: this.state.marginInternaInferior,
                                                                    paddingRight: this.state.marginInternaDireita,
                                                                    position: 'relative', width: width_sample,
                                                                    background: 'white',
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                    border: '3px double ' + (this.state.barra_dupla == true ? 'black' : 'white'),
                                                                    paddingTop: this.state.marginInternaSuperior,
                                                                }}>
                                                                    <div style={{ height: 67 }}>
                                                                        <p style={{ marginBottom: 5, textAlign: 'center', fontSize: 14 }}>{this.state.titulo}</p>
                                                                        <p style={{ marginBottom: 20, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{this.state.subtitulo}</p>

                                                                    </div>
                                                                    <div ref={(ref) => {
                                                                        if (ref != null) {
                                                                            ref.innerHTML = '';
                                                                            item.elements.map((elem, index) => {
                                                                                elem.id = 1 + '_' + id + '_' + index;
                                                                                if (ref != null) {
                                                                                    ref.appendChild(elem);
                                                                                }
                                                                                this.pages.push(1 + '_' + id + '_' + index)
                                                                            })

                                                                        }
                                                                    }} style={{ columnCount: this.state.numero_colunas, height: height_sample - (this.state.marginInternaSuperior) - (this.state.marginInternaInferior) - 67 -20, columnFill: 'auto' }} >

                                                                    </div>
                                                                    <div style={{ position: 'absolute', bottom: 10, right: 0, left: 0 }} >
                                                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 12 }}>Página {id + 1} de {this.state.pages_new.length}</p>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            {/* <div className='nextpage1'></div> */}
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* <div style={{position:'absolute',top:-10000000}} id="pdf_diagramado">

                        {this.state.pages.map((item, id) => (
                            <div key={id} style={{width:794,background:'white'}}>
                                <div ref={(ref) => {
                                    item.elements.map((elem, index) => {
                                        elem=elem.cloneNode();
                                        elem.id = 'a'+Math.random();
                                        if (ref != null) {
                                            ref.appendChild(elem.cloneNode());
                                        }
                                    })
                                }} style={{ columnCount: 2, height: 1123, columnFill: 'auto' }} >

                                </div>

                            </div>
                        ))}
                        </div> */}

                                            </div>

                                        </div>
                                    </div>

                                    {/* <iframe id="iframe"></iframe> */}

                                    <div style={{ width: this.state.numero_colunas == 1 ? (width_one_column - (this.state.marginInternaDireita - 40) - (this.state.marginInternaEsquerda - 40)) : (width_two_column), overflow: 'scroll', position: 'absolute', top: -100000 }}>

                                        <div style={{ columnCount: 1 }} >
                                            {this.state.provaSelect.questoes != undefined && this.state.provaSelect.questoes.map((item, id) => {
                                                if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                                                    number_questao = number_questao + 1;
                                                }
                                                return (
                                                    <div key={id} style={{ marginTop: id == 0 ? 0 : 20 }} id={"container_" + item.id}>

                                                        {/* <h6 className="mt-0 header-title" style={{ textAlign: 'right', marginBottom: 10, fontSize: 15 }}>
                                                                                {item.categoria.name}</h6> */}
                                                        {this.state.show_category == true && (id == 0 || item.categoria.id != this.state.provaSelect.questoes[id - 1].categoria.id) && <div id={'categoria_' + item.id} style={{ display: 'flex', justifyContent: 'center' }}><div style={{ padding: 5, width: '100%', borderRadius: 0, border: '1px solid black', marginBottom: 15, marginTop: id != 0 ? 0 : 0, background: '#f2f2f2' }}>
                                                            <p style={{ textTransform: 'uppercase', textAlign: 'center', color: 'black', fontSize: 18, fontWeight: '500', marginBottom: 0 }}>{item.categoria.name}</p>
                                                        </div>
                                                        </div>}

                                                        {item.type_questao == 'dissertativa' && <div style={{ fontWeight: 'normal' }}>
                                                            <div className="">
                                                                <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                    <b>{number_questao})</b></h5>
                                                            </div>
                                                            <div className="" id={'enunciado_' + item.id}>

                                                                <div>

                                                                    <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item.name) }}>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>}



                                                        {item.type_questao == 'simples' && <div id={"questao_" + item.id} style={{ fontWeight: 'normal' }}>
                                                            <div  >
                                                                {/* <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                                        <b>{number_questao})</b></h5> */}
                                                                <div className='col-12 col-sm-12'>
                                                                    <div style={{ marginTop: 0 }} id={'enunciado_' + item.id}>
                                                                        <div>

                                                                            <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item.name.substring(0, item.name.indexOf('>'))) + '><span style="font-size:1.25rem;font-weight:bold">' + number_questao + ")  </span>" + this.changeFontSizeQuestoes(item.name.substring(item.name.indexOf('>') + 1, item.name.length - 4)) + '</p>' }}>

                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                    <div className="">



                                                                        <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[0].id}>
                                                                            {/* <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                            <p style={{ color: item.correta == 'a' ? 'green' : 'black', fontWeight: item.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                                                        </div> */}
                                                                            <div >

                                                                                <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[0].name.substring(0, item.alternativas[0].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "A)  </span>" + this.changeFontSizeAlts(item.alternativas[0].name.substring(item.alternativas[0].name.indexOf('>') + 1, item.alternativas[0].name.length - 4)) + '</p>' }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {item.alternativas[1] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[1].id}>
                                                                            <div >

                                                                                <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[1].name.substring(0, item.alternativas[1].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "B)  </span>" + this.changeFontSizeAlts(item.alternativas[1].name.substring(item.alternativas[1].name.indexOf('>') + 1, item.alternativas[1].name.length - 4)) + '</p>' }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}
                                                                        {item.alternativas[2] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[2].id}>
                                                                            <div >

                                                                                <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[2].name.substring(0, item.alternativas[2].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "C)  </span>" + this.changeFontSizeAlts(item.alternativas[2].name.substring(item.alternativas[2].name.indexOf('>') + 1, item.alternativas[2].name.length - 4)) + '</p>' }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}
                                                                        {item.alternativas[3] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[3].id}>
                                                                            <div >

                                                                                <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[3].name.substring(0, item.alternativas[3].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "D)  </span>" + this.changeFontSizeAlts(item.alternativas[3].name.substring(item.alternativas[3].name.indexOf('>') + 1, item.alternativas[3].name.length - 4)) + '</p>' }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}

                                                                        {item.alternativas[4] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[4].id}>
                                                                            <div >

                                                                                <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[4].name.substring(0, item.alternativas[4].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "E)  </span>" + this.changeFontSizeAlts(item.alternativas[4].name.substring(item.alternativas[4].name.indexOf('>') + 1, item.alternativas[4].name.length - 4)) + '</p>' }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>}
                                                                    </div>

                                                                    {!(id == this.state.provaSelect.questoes.length - 1) &&
                                                                        <div id={"dividor_" + item.id} style={{ paddingTop: 10, paddingBottom: 10 }} >
                                                                            <div style={{ width: '100%', height: 3, background: 'black', marginTop: 0, marginBottom: 0 }}></div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>

                                                        </div>


                                                        }



                                                        {item.type_questao == 'conjunto' && <div id={"questao_" + item.id} style={{ fontWeight: 'normal' }}>

                                                            <div className="col-12 col-sm-12">

                                                                <div style={{ marginTop: 0 }} id={'enunciado_' + item.id}>
                                                                    <div>

                                                                        <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item.name) }} >

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div id={"dividor_" + item.id} style={{ paddingTop: 10, paddingBottom: 10 }} >

                                                                    <div style={{ width: '100%', height: 3, background: 'black', marginTop: 0, marginBottom: 0 }} ></div>
                                                                </div>

                                                                {item.questoes.map((item2, id2) => {
                                                                    number_questao = number_questao + 1;

                                                                    return (
                                                                        <div key={id2} id={"questao_" + item2.id}>

                                                                            <div style={{ fontWeight: 'normal' }}>

                                                                                <div className="">

                                                                                    <div style={{ marginTop: 0 }} id={'enunciado_' + item2.id}>
                                                                                        <div>
                                                                                            <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item2.name.substring(0, item2.name.indexOf('>'))) + '><span style="font-size:1.25rem;font-weight:bold">' + number_questao + ")  </span>" + this.changeFontSizeQuestoes(item2.name.substring(item2.name.indexOf('>') + 1, item2.name.length - 4)) + '</p>' }}>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ marginTop: 0 }} id={'alternativa_' + item2.alternativas[0].id}>
                                                                                        {/* <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                            <p style={{ color: item2.correta == 'a' ? 'green' : 'black', fontWeight: item2.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                                                        </div> */}
                                                                                        <div >

                                                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[0].name.substring(0, item2.alternativas[0].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "A)  </span>" + this.changeFontSizeAlts(item2.alternativas[0].name.substring(item2.alternativas[0].name.indexOf('>') + 1, item2.alternativas[0].name.length - 4)) + '</p>' }}>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    {item2.alternativas[1] != undefined && <div id={'alternativa_' + item2.alternativas[1].id} style={{ marginTop: 0 }}>
                                                                                        <div >

                                                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[1].name.substring(0, item2.alternativas[1].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "B)  </span>" + this.changeFontSizeAlts(item2.alternativas[1].name.substring(item2.alternativas[1].name.indexOf('>') + 1, item2.alternativas[1].name.length - 4)) + '</p>' }}>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>}
                                                                                    {item2.alternativas[2] != undefined && <div id={'alternativa_' + item2.alternativas[2].id} style={{ marginTop: 0 }}>
                                                                                        <div >

                                                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[2].name.substring(0, item2.alternativas[2].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "C)  </span>" + this.changeFontSizeAlts(item2.alternativas[2].name.substring(item2.alternativas[2].name.indexOf('>') + 1, item2.alternativas[2].name.length - 4)) + '</p>' }}>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>}
                                                                                    {item2.alternativas[3] != undefined && <div id={'alternativa_' + item2.alternativas[3].id} style={{ marginTop: 0 }}>
                                                                                        <div >

                                                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[3].name.substring(0, item2.alternativas[3].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "D)  </span>" + this.changeFontSizeAlts(item2.alternativas[3].name.substring(item2.alternativas[3].name.indexOf('>') + 1, item2.alternativas[3].name.length - 4)) + '</p>' }}>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>}

                                                                                    {item2.alternativas[4] != undefined && <div id={'alternativa_' + item2.alternativas[4].id} style={{ marginTop: 0 }}>
                                                                                        <div >

                                                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[4].name.substring(0, item2.alternativas[4].name.indexOf('>'))) + '><span style="font-size:1rem;font-weight:bold">(' + "E)  </span>" + this.changeFontSizeAlts(item2.alternativas[4].name.substring(item2.alternativas[4].name.indexOf('>') + 1, item2.alternativas[4].name.length - 4)) + '</p>' }}>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                            {!(id2 == item.questoes.length - 1 && id == this.state.provaSelect.questoes.length - 1) &&
                                                                                <div id={"dividor_" + item2.id} style={{ paddingTop: 10, paddingBottom: 10 }} >
                                                                                    <div style={{ width: '100%', height: 3, background: 'black', marginTop: 0, marginBottom: 0 }} ></div>
                                                                                </div>
                                                                            }


                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>


                                                        </div>}

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                </Form>
                                {this.state.provaSelect.id == '' && <DefaultButton

                                    bg="secondary"
                                    text="Voltar"
                                    icon={<RiArrowGoBackLine />}
                                    search={'?tab=2'}
                                    to={`/processos/show/${this.state.processo.id}`}
                                />}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);

export default connect(mapsStateToProps, { logout })(EditarDiagramacao);


