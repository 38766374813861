import React from 'react';

const INITIAL_STATE = {
    token: null,
    user: {},
    adminAsUser: false,
    collapsed: false,
    toggled: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'dados':
            return {
                ...state,
                ...action.payload
            };
        
        case 'logout':
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            return {
                ...state,
                token:null,
                user: {},
            };
        case 'user':
            return {
                ...state,
                user: action.payload,
            };
        case 'login':
            return {
                ...state,
                token: action.payload.token,
                user: {...action.payload.user}
            };
        case "AdminAsUser":
            return {
                ...state,
                adminAsUser: action.payload.adminAsUser
            };
        default:
            return { ...state };
    }
};
