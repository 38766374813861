import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { STORAGE_URL, URL } from "../../variables";
import PDF_IMAGE from '../../assets/images/pdf.png';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";

const ModalResposta = props => {
    const { resposta, showModal, setShowModal } = props;

    return (
        resposta != null && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Resposta<br /></Modal.Title></Modal.Header>
            {(resposta.id != null) && <Modal.Body>
                <div className="custom_scrollbar">
                    <p><b>Data da resposta:</b> {moment(resposta.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                    {resposta.files.length > 0 && <Form.Label >Imagem(s)/arquivo(s) da resposta:</Form.Label>}
                    <div className='row' style={{ marginRight: 0, marginLeft: 0 }}>
                        {resposta.files.map((item, id) => (
                            <div key={id} style={{ width: 100, marginRight: 10, marginTop: 10, position: 'relative' }}>
                                {!item.name.includes('.pdf') && <img
                                    onClick={() => {
                                        var modal = document.getElementById("myModalCam");
                                        var modalImg = document.getElementById("imgShow");
                                        modalImg.src = STORAGE_URL + item.path;
                                        modal.style.display = "block";
                                    }}
                                    className="myImg"
                                    src={STORAGE_URL + item.path}
                                    style={{ height: 75, width: 100, objectFit: 'contain' }}
                                />}
                                {item.name.includes('.pdf') && <img
                                    onClick={() => {
                                        document.getElementById('link_pdf').href = STORAGE_URL + item.path;
                                        document.getElementById('link_pdf').click();
                                    }}
                                    className="myImg"
                                    src={PDF_IMAGE}
                                    style={{ height: 75, width: 100, objectFit: 'contain' }}
                                />}
                                <div style={{ width: 100 }}>
                                    <p style={{ marginBottom: 0, textAlign: 'center', marginTop: 5, fontSize: 11,textOverflow:'ellipsis',overflow:'hidden' }}>{item.name}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div>
                        <p style={{ fontWeight: 'bold', color: 'black', marginBottom: 5,marginTop:resposta.files.length > 0?'1rem':0 }}>Resposta:</p>
                        <p style={{ whiteSpace: 'pre-line' }}>{resposta.resposta}</p>
                    </div>
                </div>
            </Modal.Body>}
        </Modal>

    );
}

export default ModalResposta;