import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdDownload, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from "file-saver";



const RelatoriosGerais = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(false);
    const [loading_candidatos, setLoadingcandidatos] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [candidatos, setcandidatos] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { faculdade_id, faculdade_name, slug } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [candidato_id, setcandidatoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getcandidatos = async (type) => {

        let response = await Request.Get(`faculdade/get_relatorio_geral/${faculdade_id}/${type}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        try {
            let csv='Nome;CPF;Número de Inscrição\n';
            response.result.map((item, id) => {
                csv+=`${item.name};${item.cpf};${item.numero_inscricao}\n`
            })
            console.log(csv)
            var myblob = new Blob([csv], {
                type: 'text/plain'
            });
            saveAs(myblob, 'relatorio_' + type + '_' + moment().format('YYYY-MM-DD-HH:mm:ss') + '.csv')
        }
        catch (e) {

        }
        // if (response.result) {

        //     saveAs(response.file, 'relatorio_' + type + '_' + moment().format('Y-m-d-H:i:s') + '.csv')
        // }
        // setLoadingcandidatos(false);
    }


    const user = useSelector(state => state.AppReducer.user);

    useEffect(() => {
        // Getcandidatos(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted" style={{ marginBottom: 0 }}>Aqui ficam os relatórios gerais {(user.empresa==null || user.empresa.change_text==false)?'da instituição':'do processo'} "{faculdade_name}"</p>
                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    {/* <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo nome do candidato" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingcandidatos(true);

                                Getcandidatos(1, value)

                            }, 500);
                        }} />


                        {(user.role == 'region_admin' || (user.empresa == null || user.empresa.can_add)) && <Link to={{
                            pathname: "/candidatos/register/" + faculdade_id,
                            // state: {
                            //     course_id: id
                            // }
                        }} style={{ textDecoration: "none" }}>
                            <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar candidato</p></Button>
                        </Link>}
                    </div> */}

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Relatório</th>
                                        {<th data-priority="3">Ações</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>Quem já fez login</td>
                                        <td>
                                            {<span
                                                onClick={(e) => {
                                                    // setcandidatoId(item.id);
                                                    // setShowWarning(true);
                                                    e.stopPropagation();
                                                    Getcandidatos('login')
                                                }}
                                                className="inline-flex align-center jcc bg-success icon"
                                                style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                title="Deletar candidato"
                                            >
                                                <MdDownload size={17} color="white" />
                                            </span>}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>Quem concedeu as permissões e chegou na tela de avaliação não iniciada</td>
                                        <td>
                                            {<span
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    Getcandidatos('tela_avaliacao_nao_iniciada')
                                                }}
                                                className="inline-flex align-center jcc bg-success icon"
                                                style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                title="Download relatório"
                                            >
                                                <MdDownload size={17} color="white" />
                                            </span>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {loading_candidatos == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {/* {loading_candidatos == false && candidatos.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum relatório encontrado</p>

                        </div>
                    </div>} */}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingcandidatos(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getcandidatos(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_candidato={this.get_candidato.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}




            </div>

        </div>
    )
}

export default RelatoriosGerais;
