
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// unidades
import moment from 'moment';
import { URL } from '../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import { Pagination } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { FaListAlt } from 'react-icons/fa';
import Search from '../ReusableComponents/Search';
import { BsFillEyeFill, BsGraphUp } from "react-icons/bs";
import { Button } from "react-bootstrap";

class Membros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: false,
            membros: [],
            pagination: {
                page: 1,
                last_page: 1,
            },
            msgErro: '',
            show_warning: false,
            show_erro: false,
            unidade_to_delete: '',
            search: '',
            loading_search: true
        };
    }






    componentDidMount() {

        this.get_membros(this.state.pagination.page);
    }

    get_membros(page, search = '', busca = true) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        }
        else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_membros?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({ loading_search: false, membros: resp.membros, loading_screen: false, pagination: resp.pagination });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    delete_membro(id) {

        fetch(`${URL}api/faculdade/delete_membro/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });
                }
                else {
                    this.setState({ show_warning: false });
                    this.get_membros(this.state.pagination.page, this.state.search);
                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
            });
    }







    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de membros da equipe</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os membros da sua equipe no sistema</p>
                            <br />
                            <br />

                            <div className="row">

                                <div className="col-12">


                                    {/* <hr /> */}
                                    {this.state.loading_screen == false && <div>

                                        {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                                        {/* <br /> */}

                                        <div className="flex jcsb flex-wrap mb-4">

                                            <Search placeholder="Pesquisar pelo nome do membro" ValueChange={value => {
                                                clearTimeout(this.pesquisarTimeout);
                                                this.pesquisarTimeout = setTimeout(() => {
                                                    let page = { ...this.state.pagination };
                                                    page.current_page = 1;
                                                    this.setState({ pagination: page, search: value, loading_search: true });
                                                    this.get_membros(1, value);
                                                }, 500);
                                            }} />


                                            <Link to={{
                                                pathname: "/membros/register/",
                                                // state: {
                                                //     course_id: id
                                                // }
                                            }} style={{ textDecoration: "none" }}>
                                                <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar membro</p></Button>
                                            </Link>
                                        </div>

                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Nome</th>
                                                            <th>E-mail</th>
                                                            <th>Criado em</th>

                                                            <th data-priority="3">Ações</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.loading_search == false && this.state.membros.map((item, id) => (

                                                            <tr key={id}>
                                                                <td >{item.name}</td>
                                                                {/* <td>Questão {id + 1}</td> */}

                                                                <td>{item.email}

                                                                </td>
                                                                <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td>

                                                                {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                                                <td>
                                                                 
                                                                    {/* <Link to={`/membros/show/${item.id}`} onClick={(e) => e.stopPropagation()}>

                                                                        <span

                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", }}
                                                                            title="Gerenciar membro"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                    </Link> */}

                                                                    <Link to={`/membros/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                                        <span
                                                                            className="inline-flex align-center jcc bg-info icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", }}
                                                                            data-toggle="tooltip"
                                                                            title="Editar membro"
                                                                        >
                                                                            <MdModeEdit color="white" size={17} />
                                                                        </span>
                                                                    </Link>
                                                                    {item.id!=this.props.user.id && <span
                                                                        onClick={(e) => {
                                                                            this.questao_id = item.id;
                                                                            this.setState({ show_warning: true });
                                                                        }}
                                                                        className="inline-flex align-center jcc bg-danger icon"
                                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                        title="Deletar membro"
                                                                    >
                                                                        <MdDelete size={17} color="white" />
                                                                    </span>}
                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        {this.state.loading_search == true &&

                                            <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                                <CircularProgress />
                                            </div></div>

                                        }
                                        {this.state.loading_search == false && this.state.membros.length == 0 && <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhum membro encontrado</p>

                                            </div>
                                        </div>}

                                        <Pagination
                                            className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                            count={this.state.pagination.last_page}
                                            value={this.state.pagination.current_page}
                                            onChange={(_, value) => {
                                                console.log(_, value);
                                                let page = { ...this.state.pagination };
                                                page.current_page = value;
                                                this.setState({ pagination: page });
                                                this.get_membros(value, this.state.search);
                                            }}
                                        />

                                        {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                                    </div>}

                                    {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        {/* <div className="spinner-border text-primary" role="status"  >
        </div> */}
                                        <CircularProgress />
                                    </div></div>}

                                    <SweetAlert
                                        warning
                                        title={"Atenção"}
                                        onConfirm={() => {
                                            this.setState({ show_warning: false, loading_search: true });
                                            this.delete_membro(this.questao_id);
                                            // this.delete_banner(this.state.banner_to_delete);
                                        }}
                                        onCancel={() => {
                                            this.setState({ show_warning: false });
                                        }}
                                        show={this.state.show_warning}
                                        confirmBtnText='Sim, desejo apagar'
                                        cancelBtnText='Cancelar'
                                        confirmBtnBsStyle="success"
                                        cancelBtnBsStyle="danger"
                                        showCancel={true}
                                    >
                                        Ao deletar este membro, ele não terá mais acesso ao sistema
                                    </SweetAlert>


                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Membros);


