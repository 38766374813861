import { Box, ButtonBase, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useState } from "react";

const ThreeDots = props => {

    const {
        theme,
        links
    } = props;

    const [anchor, SetAnchor] = useState(null);

    const history = useHistory();

    const OpenMenu = e => SetAnchor(e.currentTarget);
    const CloseMenu = () => SetAnchor(null);

    return (
        <>
            <Box
                component={ButtonBase}
                width={"fit-content"}
                padding={.5}
                marginBottom={1.5}
                borderRadius={5}
                bgcolor={theme.palette?.primary?.main}
                style={{
                    backgroundColor: theme.palette?.primary?.main,
                    marginBottom: "12px",
                    borderRadius: "5px",
                    padding: "4px"
                }}
                zIndex={1}
                onClick={OpenMenu}
            >
                <MoreHorizIcon/>
            </Box>
            <Menu
                id="three-dots"
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={CloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {links?.map((link, linkKey) => (
                    <MenuItem
                        key={linkKey}
                        onClick={() => history.push(link?.link)}
                    >
                        {link?.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default ThreeDots;