// Library Imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RiArrowGoBackLine, RiCloseCircleLine } from "react-icons/ri";

// Components Imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import Attention from "../ReusableComponents/Attention";

// Utils Imports
import { logout } from "../../actions/AppActions";
import { get, put } from "../../Services/api";

const EditarModulo = ({ token, match, history }) => {
  const [formData, setFormData] = useState({
    redirect: false,
    path: "/modulos",
    loading: true,
    name: "",
    description: "",
    loadingSave: false,
    msgErro: "",
    conteudoIds: [],
    contents: [],
    availableContents: [],
    dropdownOpen: false,
  });

  const [initialContents, setInitialContents] = useState([]);

  useEffect(() => {
    const { id } = match.params;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const [moduleData, contentsData] = await Promise.all([
          get(`api/modulo/get_module/${id}`, token, signal),
          get("api/conteudo/get_contents", token, signal),
        ]);

        const { module } = moduleData;
        const { name, description } = module;
        const conteudoIds = module.conteudos.map((item) => item.id);

        setFormData((prevState) => ({
          ...prevState,
          name,
          description,
          contents: module.conteudos,
          conteudoIds,
          availableContents: contentsData.data.data,
          loading: false,
        }));

        setInitialContents(module.conteudos);
      } catch (error) {
        console.error("Erro:", error);
        setFormData((prevState) => ({
          ...prevState,
          redirect: true,
          loading: false,
        }));
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [match.params, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (id) => {
    setFormData((prevState) => {
      const conteudoIds = prevState.conteudoIds.includes(id)
        ? prevState.conteudoIds.filter((conteudoId) => conteudoId !== id)
        : [...prevState.conteudoIds, id];

      const updatedContents = prevState.availableContents.filter((content) =>
        conteudoIds.includes(content.id)
      );

      return {
        ...prevState,
        conteudoIds,
        contents: updatedContents,
      };
    });
  };

  const handleRemoveContent = (id) => {
    setFormData((prevState) => {
      const conteudoIds = prevState.conteudoIds.filter(
        (conteudoId) => conteudoId !== id
      );
      const updatedContents = prevState.contents.filter(
        (content) => content.id !== id
      );

      return {
        ...prevState,
        conteudoIds,
        contents: updatedContents,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id } = match.params;
    const { name, description, contents } = formData;

    setFormData((prevState) => ({
      ...prevState,
      loadingSave: true,
      msgErro: "",
    }));

    try {
      await put(
        `api/modulo/edit_module/${id}`,
        {
          name,
          description,
          conteudo_ids: contents.map((content) => content.id),
        },
        token
      );

      setFormData((prevState) => ({
        ...prevState,
        msgErro: "Módulo editado com sucesso",
      }));

      history.push("/modulos");
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao editar módulo",
        loadingSave: false,
      }));
    }
  };

  const toggleDropdown = () => {
    setFormData((prevState) => ({
      ...prevState,
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const conteudos = [...formData.contents];
    const [reorderedItem] = conteudos.splice(result.source.index, 1);
    conteudos.splice(result.destination.index, 0, reorderedItem);

    try {
      await saveOrder(conteudos);
      setFormData((prevState) => ({
        ...prevState,
        contents: conteudos,
      }));
    } catch (error) {
      console.error("Erro ao reordenar:", error);
    }
  };

  const saveOrder = async (conteudos) => {
    const { id } = match.params;
    try {
      await put(
        `api/modulo/edit_module/${id}`,
        {
          name: formData.name,
          description: formData.description,
          conteudo_ids: conteudos.map((conteudo) => conteudo.id),
        },
        token
      );

      setFormData((prevState) => ({
        ...prevState,
        errorMsg: "",
      }));
    } catch (error) {
      console.error(error.message || "Erro ao salvar a nova ordem");
      setFormData((prevState) => ({
        ...prevState,
        errorMsg: error.message || "Erro ao salvar a nova ordem",
      }));
    }
  };

  const handleVoltar = async () => {
    const { id } = match.params;

    setFormData((prevState) => ({
      ...prevState,
      contents: initialContents,
      conteudoIds: initialContents.map((conteudo) => conteudo.id),
    }));

    try {
      await put(
        `api/modulo/edit_module/${id}`,
        {
          name: formData.name,
          description: formData.description,
          conteudo_ids: initialContents.map((conteudo) => conteudo.id),
        },
        token
      );

      history.push("/modulos");
    } catch (error) {
      console.error(error.message || "Erro ao salvar a nova ordem");
      setFormData((prevState) => ({
        ...prevState,
        errorMsg: error.message || "Erro ao salvar a nova ordem",
      }));
    }
  };

  if (formData.redirect) {
    return <Redirect to={formData.path} />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title mb-3"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Editar Módulo
            </h4>
            <p className="mb-3" style={{ fontSize: "1rem", color: "black" }}>
              Edite o módulo do seu sistema
            </p>
            {formData.msgErro && (
              <div className="mb-3" style={{ display: "flex", width: "100%" }}>
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        msgErro: "",
                      }))
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{formData.msgErro}</p>
                </div>
              </div>
            )}
            {!formData.loading ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name">
                    Nome <Attention />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="description">Descrição</label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="form-group flex fdcolumn flex1 margin-bottom mb-3">
                  <label htmlFor="available_contents">
                    Conteúdos disponíveis
                  </label>
                  <Dropdown
                    show={formData.dropdownOpen}
                    onToggle={toggleDropdown}
                  >
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      Selecione Conteúdos
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {formData.availableContents.map((content) => (
                        <Dropdown.Item
                          key={content.id}
                          as="div"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            label={content.name}
                            checked={formData.conteudoIds.includes(content.id)}
                            onChange={() => handleSelectChange(content.id)}
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="conteudos_vinculados">
                    Conteúdos vinculados
                  </label>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="conteudos">
                      {(provided) => (
                        <ul
                          className="list-group"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {formData.contents.length === 0 ? (
                            <li className="list-group-item">
                              Nenhum conteúdo selecionado
                            </li>
                          ) : (
                            formData.contents.map((content, index) => (
                              <Draggable
                                key={content.id}
                                draggableId={content.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    {content.name}
                                    <button
                                      type="button"
                                      className="btn btn-link p-0"
                                      onClick={() =>
                                        handleRemoveContent(content.id)
                                      }
                                    >
                                      <RiCloseCircleLine
                                        size={20}
                                        color="red"
                                      />
                                    </button>
                                  </li>
                                )}
                              </Draggable>
                            ))
                          )}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="w-100 d-flex justify-content-between mt-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    onClick={handleVoltar}
                  />
                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar módulo"
                    disabled={formData.loadingSave}
                    loadingtext="Salvando módulo"
                    loading={formData.loadingSave}
                  />
                </div>
              </form>
            ) : (
              <div className="row">
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="spinner-border text-primary" role="status" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(EditarModulo);
