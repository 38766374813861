import JoditEditor from "jodit-react";
import React, { Component, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { API_URL, STORAGE_URL, URL } from "../../variables";
import { Form, FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";
import Btn from '@mui/material/Button';
import Input from '@mui/material/Input';
import { Checkbox, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { uuid } from 'uuidv4';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";


class ModalQuestaoQuestionario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            enunciado: '',

            loading_save: false,
            msgErro: '',
            loading_image_enunciado: false,
            loading_image_a: false,
            loading_image_b: false,
            loading_image_c: false,
            loading_image_d: false,
            loading_image_e: false,
            correta: '',
          
            alternativas_inputs: [{ type: 'radio', name: '', uuid: uuid() }],
            type_questao: 'multipla_escolha',
            questoes: []
        };

        this.load_configs();



    }



    renderTypeQuestao(e) {
        if (e == 'multipla_escolha') {
            return 'Escolha múltipla';
        }
        if (e == 'paragrafo') {
            return 'Parágrafo';
        }
    }



    load_configs() {
        this.config_enunciado = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_enunciado,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_enunciado: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_enunciado: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_enunciado: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }




    render() {
        let radio_index=0;

        return (
            <Modal
                show={this.props.showModal == true}
                dialogClassName="modal_width"
                onHide={() => this.props.setShowModal(false)}
            >
                <Modal.Header closeButton closeLabel=''><Modal.Title>Visualizar Pergunta do Questionário<br /></Modal.Title></Modal.Header>
                <Modal.Body>
                    {this.props.questao != undefined && <div >
                        {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        <InputGroup>
                            <InputGroup>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Tipo de questão </Form.Label>
                                    <p style={{ marginBottom: 0 }}>{this.renderTypeQuestao(this.props.questao.type_questao)}</p>

                                    {/* <select className="form-select " onChange={(e) => {
                                        
                                    }} value={this.state.type_questao}>
                                        <option
                                            value={'multipla_escolha'}
                                        >{'Escolha múltipla'}</option>
                                        <option
                                            value={'paragrafo'}
                                        >{'Parágrafo'}</option>


                                    </select> */}
                                </div>
                            </InputGroup>

                            <div className="flex fdcolumn flex1 ">
                                <Form.Label >Enunciado  {this.state.loading_image_enunciado == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>
                                <p style={{ marginBottom: 0 }}>{this.props.questao.name}</p>

                            </div>
                        </InputGroup>



                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={''}
                            onChange={(e) => {
                                console.log(e.target.value);
                            }}
                        >
                            {this.props.questao.alternativas_inputs.map((item, id) => {
                                if (item.type == 'checkbox') {
                                    return (<InputGroup key={id} style={{ marginTop: 10 }}>
                                        <FormControlLabel control={<Checkbox onChange={(e)=>{console.log(e.target.value)}} value={item.uuid} />} style={{ flexGrow: 1 }} label={item.name} />

                                    </InputGroup>);
                                }
                                else if (item.type == 'radio') {
                                radio_index=radio_index+1;

                                    return (
                                        <InputGroup key={id} style={{ marginTop: 10 }}>

                                            <FormControlLabel control={<Radio value={item.uuid} />} style={{ color: 'black' }} label={'(' + (radio_index) + ')  '+item.name} />


                                        </InputGroup>
                                    );
                                }
                                else if (item.type == 'texto') {
                                    return (
                                        <div key={id} style={{ marginTop: 10 }}>
                                            {this.props.questao.type_questao=='multipla_escolha' && <p style={{ marginBottom: 0,fontWeight:'bold' }}>{item.name}</p>}
                                            <InputGroup  >
                                                <Input style={{ flexGrow: 1 }} placeholder={item.name} value={''} onChange={(e) => {

                                                }} />

                                                {this.props.questao.type_questao == 'multipla_escolha' && <div style={{ display: 'flex' }}>

                                                    {/* <InputLabel id={"label"+id}>Obrigatório se</InputLabel> */}
                                                    <Select
                                                        // labelId={"label"+id}
                                                        // id={"select"+id}
                                                        disabled
                                                        SelectDisplayProps={{
                                                            height: 'fit-content',
                                                        }}
                                                        variant='standard'
                                                        value={item.required}
                                                        onChange={(e) => {
                                                            let alternativas_inputs = [...this.props.questao.alternativas_inputs];
                                                            alternativas_inputs[id].required = e.target.value;
                                                            this.setState({ alternativas_inputs });
                                                        }}
                                                        // label="Obrigatório se"
                                                        style={{ marginRight: 10, borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0 }}
                                                    >
                                                        <MenuItem value="not_required">
                                                            Não é obrigatório
                                                        </MenuItem>
                                                        <MenuItem value={'required'}>Obrigatório sempre</MenuItem>
                                                        {this.props.questao.alternativas_inputs.filter((item2, id2) => item2.type == 'radio').map((item2, id2) => (
                                                            <MenuItem key={id2} value={item2.uuid}>Obrigatório se assinalado "({id2 + 1})"</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>}
                                            </InputGroup>
                                        </div>
                                    );
                                }
                            })}
                        </RadioGroup>

                    </div>}

                </Modal.Body>

                <Modal.Footer>
                    <div className="w100 inline-flex justify-content-end margin-top-1">

                    </div>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default ModalQuestaoQuestionario;