import { useContext, useEffect, useState } from "react";
import { Box, Card } from "@material-ui/core";
import { DashboardContext } from "..";
import { blue, purple, red, deepPurple, green, deepOrange } from "@material-ui/core/colors";

import LocalLibraryIcon from '@material-ui/icons/People';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SchoolIcon from '@material-ui/icons/School';
import Board from "./Board";
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../../ReusableComponents/TabPanel";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TextReadMore from "../../../ReusableComponents/TextReadMore";
import ProcessDashboard from "./ProcessDashboard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Cards = props => {

    const dashboard = useContext(DashboardContext);
    const [activeTab, setActiveTab] = useState(-1);
    const history = useHistory();
    const location = useLocation();
    useEffect(()=>{
        let processo_id = new URLSearchParams(location.search).get('processo_id');
        if(dashboard?.processos?.length>0 && processo_id !== undefined && processo_id !== null){
            let tab=0;
            dashboard?.processos.map((item,index)=>{
                if(item.id==processo_id){
                    tab=index;
                }
            })
            setActiveTab(tab);
        }
        else if (processo_id === null){
            setActiveTab(0);
        }
        // if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        // else if (tab === null) this.setState({ activeTab: 0 });
    },[dashboard.processos]);

    return (
        <Box
            component={Card}
            width="100%"
            padding={1.5}
            marginBottom={5}
            elevation={1}
        >
            {dashboard.isLoading == true && <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                <CircularProgress />
            </div></div>}
            {dashboard.isLoading == false && <div>

                <div className="flex jcsb">
                    <h4 className="header">{dashboard?.faculdade?.name}</h4>
                </div>
                <div style={{ flexWrap: 'wrap', display: 'flex', width: '100%' }}>

                    <Board
                        icon={<LocalLibraryIcon />}
                        color={purple}
                        content={{
                            title: "Candidatos",
                            quantity: dashboard?.alunos
                        }}
                        links={[
                            { label: "Gerenciar candidatos", link: "/instituicao/show/" + dashboard?.faculdade?.id + "?tab=3" }
                        ]}
                    />
                    <Board
                        icon={<PeopleAltIcon />}
                        color={red}
                        content={{
                            title: "Fiscais",
                            quantity: dashboard?.fiscais
                        }}
                        links={[
                            { label: "Gerenciar fiscais", link: "/instituicao/show/" + dashboard?.faculdade?.id + "?tab=2" },
                        ]}
                    />
                    <Board
                        icon={<SchoolIcon />}
                        color={blue}
                        content={{
                            title: "Corretores",
                            quantity: dashboard?.corretores
                        }}
                        links={[
                            { label: "Gerenciar corretores", link: "/instituicao/show/" + dashboard?.faculdade?.id + "?tab=1" },
                        ]}
                    />

                    <Board
                        icon={<LocalLibraryIcon />}
                        color={deepPurple}
                        content={{
                            title: "Candidatos online",
                            quantity: dashboard?.alunos_online?.length
                        }}
                        links={[
                            { label: "Gerenciar candidatos", link: "/instituicao/show/" + dashboard?.faculdade?.id + "?tab=3" }
                        ]}
                    />
                    <Board
                        icon={<PeopleAltIcon />}
                        color={deepOrange}
                        content={{
                            title: "Fiscais online",
                            quantity: dashboard?.fiscais_online?.length
                        }}
                        links={[
                            { label: "Gerenciar fiscais", link: "/instituicao/show/" + dashboard?.faculdade?.id + "?tab=2" },
                        ]}
                    />
                </div>
                <br />
                {activeTab!=-1 && <>
                <Tabs
                    value={activeTab}
                    onChange={(_, value) => {
                        setActiveTab(value);
                        history.push({ search: `?tab=${value}` });
                    }}
                    style={{ backgroundColor: "#ececf1" }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={"scrollable"}
                    scrollButtons={"on"}

                >
                    {dashboard?.processos?.map((item, id) => {
                        return (
                            <Tab key={id} label={item.name} />
                        );
                    })}


                </Tabs>

                {dashboard?.processos?.map((processo, id) => {
                    return (
                        activeTab==id && <TabPanel key={id} value={activeTab} index={id}>
                           <ProcessDashboard processo_id={processo.id} />
                        </TabPanel>
                    );

                })}
                </>}

            </div>}


        </Box>
    );
}

export default Cards;