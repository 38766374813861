import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaArrowLeft, FaArrowRight, FaListAlt, FaVideo } from "react-icons/fa";
import { MdArrowLeft, MdArrowRight, MdArrowRightAlt, MdDelete, MdModeEdit, MdPlayArrow } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";



const LogsConexao = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_relatorio, setLoadingrelatorio] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [logs, setlogs] = useState([]);
    const [deixou_aplicacao, setDeixouAplicacao] = useState(0);
    const [logs_saidas, setLogsSaidas] = useState([]);
    const [acoes_relatadas, setAcoesRelatadas] = useState([]);



    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });


    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(1);

    const [search, setSearch] = useState('');
    const { candidato_id, candidato_name, processo_id } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [relatorio_id, setrelatorioId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getlogs = async (page, search) => {

        let response = await Request.Get(`faculdade/get_logs_conexao/${processo_id}/${candidato_id}?page=${page}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }

            setlogs(response.logs);
            setCurrentPage(response.pagination.current_page);
            setLastPage(response.pagination.last_page);
            setLoadingScreen(false);
            setLoadingrelatorio(false);


    }

    const [relatorio_select, setVideoSelect] = useState(null);

   

    useEffect(() => {
        Getlogs(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui fica os logs de conexão do candidato "{candidato_name}"</p>
                   
                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className=" mb-4 mt-4">
                    <p className="mt-0 header-title" style={{  color: 'black',marginBottom:0,fontSize: '1.1rem' }}>Logs de conexão:</p>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Data</th>
                                        <th>Tempo em milissegundo (ms)</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_relatorio == false && logs.map((item, id) => (

                                        <tr style={{  }} key={id}>
                                            <td>{moment(item.datetime).format('DD/MM/YYYY HH:mm:ss')}</td>
                                            <td>{item.ms==null?'-':(item.ms+' ms')}</td>
                                            <td style={{color:item.ms==null?'red':('green')}}>{item.ms==null?'Sem internet':('Com internet')}</td>
                                           
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_relatorio == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_relatorio == false && logs.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum registro encontrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={last_page}
                        // value={pagination.current_page}
                        page={current_page}
                        onChange={(_, value) => {
                            setLoadingrelatorio(true);
                            console.log(_, value);
                            console.log(pagination);
                            setCurrentPage(value);
                            // let page = { ...pagination };
                            // page.current_page = value;
                            // setPagination(page);
                            Getlogs(value, search_oficial)
                        }}
                    />
                   

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingrelatorio(true);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar este relatorio, ele não estará mais disponível no sistema
                </SweetAlert>


            </div>

        </div>
    )
}

export default LogsConexao;
