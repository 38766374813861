import { Box, Card, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import LinkIcon from '@material-ui/icons/Link';
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import Actions from "./Actions";

const useStyles = makeStyles({
    container: {
        overflow: "visible"
    }
});

const QuickActions = () => {

    const user = useSelector(store => store.AppReducer.user);
    const classes = useStyles();

    return (
        <Box
            component={Card}
            padding={1.5}
            paddingTop={8}
            elevation={5}
            position={"relative"}
            className={classes.container}
        >
            <TopIcon/>
            <Typography>
                {user?.name}, seja bem vindo(a)
            </Typography>
            <Actions/>
        </Box>
    );
}

const TopIcon = () => {
    return (
        <Box
            position={"absolute"}
            bgcolor={blue["600"]}
            color={"white"}
            padding={1}
            borderRadius={5}
            top={-15}
            left={20}
        >
            <LinkIcon/>
        </Box>
    );
}

export default QuickActions;