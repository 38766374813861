import JoditEditor from "jodit-react";
import React, { Component, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { API_URL, STORAGE_URL, URL } from "../../variables";
import { Form, FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";
import Btn from '@mui/material/Button';
import Input from '@mui/material/Input';
import { Checkbox, FormControlLabel, IconButton, InputLabel, MenuItem, Radio } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { uuid } from 'uuidv4';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";


class ModalCadastroQuestaoQuestionario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            enunciado: '',
            alternativa_a: '',
            alternativa_b: '',
            alternativa_c: '',
            alternativa_d: '',
            alternativa_e: '',
            loading_save: false,
            msgErro: '',
            loading_image_enunciado: false,
            loading_image_a: false,
            loading_image_b: false,
            loading_image_c: false,
            loading_image_d: false,
            loading_image_e: false,
            correta: '',
          
            alternativas_inputs: [{ type: 'radio', name: '',uuid:uuid() }],
            type_questao: 'multipla_escolha',
            questoes: []
        };

        this.load_configs();



    }





    load_configs() {
        this.config_enunciado = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_enunciado,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_enunciado: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_enunciado: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_enunciado: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }




    render() {
        let radio_index=0;
        return (
            <Modal
                show={this.props.showModal == true}
                dialogClassName="modal_width"
                onHide={() => this.props.setShowModal(false)}
            >
                <Modal.Header closeButton closeLabel=''><Modal.Title>Cadastrar Pergunta do Questionário<br /></Modal.Title></Modal.Header>
                <Modal.Body>
                    <div >
                        {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        <InputGroup>
                            <InputGroup>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Tipo de questão <Attention /></Form.Label>
                                    <Select
                                        // labelId={"label"+id}
                                        // id={"select"+id}
                                        SelectDisplayProps={{
                                            height: '50px'
                                        }}
                                        variant='standard'
                                        value={this.state.type_questao}
                                        onChange={(e) => {
                                            let alternativas_inputs = [];
                                        if (e.target.value == 'multipla_escolha') {
                                            alternativas_inputs.push({ type: 'radio', name: '', uuid: uuid() })
                                        }
                                        else if (e.target.value == 'paragrafo') {
                                            alternativas_inputs.push({
                                                type: 'texto', name: '', required: 'required',
                                                uuid: uuid()
                                            })
                                        }
                                        this.setState({ type_questao: e.target.value, alternativas_inputs: alternativas_inputs })
                                        }}
                                        // label="Obrigatório se"
                                        style={{ marginRight: 10, borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0 }}
                                    >
                                        <MenuItem value="multipla_escolha">
                                        Escolha múltipla
                                        </MenuItem>
                                        <MenuItem value={'paragrafo'}>Parágrafo</MenuItem>
                                       
                                    </Select>
                                    {/* <select className="form-select " onChange={(e) => {
                                        
                                    }} value={this.state.type_questao}>
                                        <option
                                            value={'multipla_escolha'}
                                        >{'Escolha múltipla'}</option>
                                        <option
                                            value={'paragrafo'}
                                        >{'Parágrafo'}</option>


                                    </select> */}
                                </div>
                            </InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Enunciado <Attention /> {this.state.loading_image_enunciado == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>

                                <Input placeholder="Escreva a pergunta" value={this.state.enunciado} onChange={(e) => {
                                    this.setState({ enunciado: e.target.value });
                                }} />
                                {/* <JoditEditor
                                    ref={this.editor_enunciado}
                                    value={this.state.enunciado}
                                    config={this.config_enunciado}
                                    height={400}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => this.setState({ enunciado: newContent })} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                /> */}
                            </div>
                        </InputGroup>

                        {this.state.type_questao == 'multipla_escolha' && <Btn variant="text" onClick={() => {
                            let alternativas_inputs = [...this.state.alternativas_inputs];
                            alternativas_inputs.push({
                                type: 'radio', name: '',
                                uuid: uuid()
                            });
                            this.setState({ alternativas_inputs });
                        }}>Adicionar alternativa de escolha múltipla</Btn>}
                        {this.state.type_questao == 'multipla_escolha' && <Btn variant="text" onClick={() => {
                            let alternativas_inputs = [...this.state.alternativas_inputs];
                            alternativas_inputs.push({
                                type: 'texto', name: '', required: 'not_required',
                                uuid: uuid()
                            });
                            this.setState({ alternativas_inputs });
                        }}>Adicionar campo de texto</Btn>}

                        {this.state.type_questao == 'paragrafo' && this.state.alternativas_inputs.filter((item, id) => item.type == 'checkbox').length == 0 && <Btn variant="text" onClick={() => {
                            let alternativas_inputs = [...this.state.alternativas_inputs];
                            alternativas_inputs.push({
                                type: 'checkbox', name: 'Prefiro não informar',
                                uuid: uuid()
                            });
                            this.setState({ alternativas_inputs });
                        }}>Adicionar "Prefiro não informar"</Btn>}

                        {this.state.alternativas_inputs.map((item, id) => {
                            if (item.type == 'checkbox') {
                                return (<InputGroup key={id} style={{ marginTop: 15 }}>
                                    <FormControlLabel disabled control={<Checkbox />} style={{ flexGrow: 1 }} label={item.name} />
                                    <IconButton aria-label="delete"  style={{ borderRadius: 100 }} onClick={() => {
                                        let alternativas_inputs = this.state.alternativas_inputs.filter((item2, id2) => item2.uuid != item.uuid);
                                        this.setState({ alternativas_inputs });

                                    }}>
                                        <DeleteIcon style={{ borderRadius: 100 }} />
                                    </IconButton>
                                </InputGroup>);
                            }
                            else if (item.type == 'radio') {
                                radio_index=radio_index+1;
                                return (
                                    <InputGroup key={id} style={{ marginTop: 15 }}>
                                        <FormControlLabel disabled control={<Radio />} label={'(' + (radio_index) + ')'} />
                                        <Input style={{ flexGrow: 1 }} placeholder="Escreva a alternativa" value={item.name} onChange={(e) => {
                                            let alternativas_inputs = [...this.state.alternativas_inputs];
                                            alternativas_inputs[id].name = e.target.value;
                                            this.setState({ alternativas_inputs });
                                        }} />
                                        <Attention />
                                        <IconButton style={{ borderRadius: 100 }} aria-label="delete"  onClick={() => {
                                            let alternativas_inputs = this.state.alternativas_inputs.filter((item2, id2) => item2.uuid != item.uuid);
                                            alternativas_inputs=alternativas_inputs.map((i,j)=>{
                                                if(i.required==item.uuid){
                                                    i.required='not_required';
                                                    return i;
                                                }
                                                else{
                                                    return i;
                                                }
                                            })
                                            this.setState({ alternativas_inputs });

                                        }}>
                                            <DeleteIcon style={{ borderRadius: 100 }} />
                                        </IconButton>
                                        <div style={{ display: 'grid' }}>
                                            <IconButton disabled={id == 0} size="small" style={{ padding: 0 }} onClick={() => {
                                                let alternativas_inputs = [...this.state.alternativas_inputs];
                                                const element = alternativas_inputs.splice(id, 1)[0];
                                                if (id - 1 < 0) {
                                                    return;
                                                }
                                                alternativas_inputs.splice(id - 1, 0, element);
                                                this.setState({ alternativas_inputs });
                                            }}>
                                                <ArrowDropUp style={{ borderRadius: 100 }} />
                                            </IconButton>
                                            <IconButton disabled={id == this.state.alternativas_inputs.length - 1} size="small" style={{ padding: 0 }} onClick={() => {
                                                // let alternativas_inputs = this.state.alternativas_inputs.filter((item2, id2) => item2.uuid != item.uuid);
                                                // this.setState({ alternativas_inputs });
                                                let alternativas_inputs = [...this.state.alternativas_inputs];
                                                const element = alternativas_inputs.splice(id, 1)[0];
                                                if (id + 1 >= this.state.alternativas_inputs.length) {
                                                    return;
                                                }
                                                alternativas_inputs.splice(id + 1, 0, element);
                                                this.setState({ alternativas_inputs });
                                            }}>
                                                <ArrowDropDown style={{ borderRadius: 100 }} />
                                            </IconButton>
                                        </div>
                                        
                                    </InputGroup>
                                );
                            }
                            else if (item.type == 'texto') {
                                return (
                                    <InputGroup key={id} style={{ marginTop: 15 }}>


                                        <Input style={{ flexGrow: 1 }} placeholder={this.state.type_questao=='multipla_escolha'? "Escreva a pergunta do campo texto ":"Escreva o placeholder do campo texto "} value={item.name} onChange={(e) => {
                                            let alternativas_inputs = [...this.state.alternativas_inputs];
                                            alternativas_inputs[id].name = e.target.value;
                                            this.setState({ alternativas_inputs });
                                        }} />

                                        {this.state.type_questao == 'multipla_escolha' && <div style={{ display: 'flex' }}>

                                            {/* <InputLabel id={"label"+id}>Obrigatório se</InputLabel> */}
                                            <Select
                                                // labelId={"label"+id}
                                                // id={"select"+id}
                                                SelectDisplayProps={{
                                                    height: 'fit-content'
                                                }}
                                                variant='standard'
                                                value={item.required}
                                                onChange={(e) => {
                                                    let alternativas_inputs = [...this.state.alternativas_inputs];
                                                    alternativas_inputs[id].required = e.target.value;
                                                    this.setState({ alternativas_inputs });
                                                }}
                                                // label="Obrigatório se"
                                                style={{ marginRight: 10, borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0 }}
                                            >
                                                <MenuItem value="not_required">
                                                    Não é obrigatório
                                                </MenuItem>
                                                <MenuItem value={'required'}>Obrigatório sempre</MenuItem>
                                                {this.state.alternativas_inputs.filter((item2, id2) => item2.type == 'radio').map((item2, id2) => (
                                                    <MenuItem key={id2} value={item2.uuid}>Obrigatório se assinalado "({id2 + 1})"</MenuItem>
                                                ))}
                                            </Select>
                                        </div>}
                                        <Attention />

                                        {this.state.type_questao == 'multipla_escolha' && <IconButton style={{ borderRadius: 100 }} aria-label="delete"  onClick={() => {
                                            let alternativas_inputs = this.state.alternativas_inputs.filter((item2, id2) => item2.uuid != item.uuid);
                                            this.setState({ alternativas_inputs });

                                        }}>
                                            <DeleteIcon style={{ borderRadius: 100 }} />
                                        </IconButton>}

                                        {this.state.type_questao == 'multipla_escolha' && <div style={{ display: 'grid' }}>
                                            <IconButton disabled={id == 0} size="small" style={{ padding: 0 }} onClick={() => {
                                                let alternativas_inputs = [...this.state.alternativas_inputs];
                                                const element = alternativas_inputs.splice(id, 1)[0];
                                                if (id - 1 < 0) {
                                                    return;
                                                }
                                                alternativas_inputs.splice(id - 1, 0, element);
                                                this.setState({ alternativas_inputs });
                                            }}>
                                                <ArrowDropUp style={{ borderRadius: 100 }} />
                                            </IconButton>
                                            <IconButton disabled={id == this.state.alternativas_inputs.length - 1} size="small" style={{ padding: 0 }} onClick={() => {
                                                // let alternativas_inputs = this.state.alternativas_inputs.filter((item2, id2) => item2.uuid != item.uuid);
                                                // this.setState({ alternativas_inputs });
                                                let alternativas_inputs = [...this.state.alternativas_inputs];
                                                const element = alternativas_inputs.splice(id, 1)[0];
                                                if (id + 1 >= this.state.alternativas_inputs.length) {
                                                    return;
                                                }
                                                alternativas_inputs.splice(id + 1, 0, element);
                                                this.setState({ alternativas_inputs });
                                            }}>
                                                <ArrowDropDown style={{ borderRadius: 100 }} />
                                            </IconButton>
                                        </div>}
                                    </InputGroup>
                                );
                            }
                        })}


                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <div className="w100 inline-flex justify-content-end margin-top-1">
                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}

                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                        <DefaultButton
                            type="button"
                            onClick={() => {
                                document.getElementById('text_enunciado').innerHTML = this.state.enunciado;

                                if (document.getElementById('text_enunciado').textContent.trim().length == 0 && !this.state.enunciado.includes('<img')) {
                                    this.setState({ msgErro: 'O campo Enunciado é obrigatório' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }


                                if (this.state.alternativas_inputs.length == 0) {
                                    this.setState({ msgErro: 'Selecione pelo menos uma opção de resposta' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }

                                if (this.state.type_questao == 'multipla_escolha') {
                                    for (let i = 0; i < this.state.alternativas_inputs.length; i++) {
                                        if (this.state.alternativas_inputs[i].name.trim().length == 0 && this.state.alternativas_inputs[i].type=='radio') {
                                            this.setState({ msgErro: 'As alternativas não podem ser textos vazios' });
                                            return;
                                        }
                                        if (this.state.alternativas_inputs[i].name.trim().length == 0 && this.state.alternativas_inputs[i].type=='texto') {
                                            this.setState({ msgErro: 'Os campos de texto não podem ter um placeholder vazio' });
                                            return;
                                        }
                                    }
                                }

                                if (this.state.type_questao == 'paragrafo') {
                                    for (let i = 0; i < this.state.alternativas_inputs.length; i++) {
                                        if (this.state.alternativas_inputs[i].name.trim().length == 0 && this.state.alternativas_inputs[i].type=='texto') {
                                            this.setState({ msgErro: 'Os campos de texto não podem ter uma pergunta vazia' });
                                            return;
                                        }
                                    }
                                }

                                document.getElementById('text_enunciado').innerHTML = this.state.enunciado;

                                this.props.salvarQuestao({
                                    name: this.state.enunciado,
                                    id:uuid(),
                                    text_questao: document.getElementById('text_enunciado').textContent,
                                    type_questao:this.state.type_questao,
                                    alternativas_inputs: this.state.alternativas_inputs
                                });
                                this.setState({
                                    enunciado: '', alternativas_inputs: [{ type: 'radio', name: '',uuid:uuid() }],
                                    type_questao: 'multipla_escolha', msgErro: ''
                                });


                            }}
                            bg="confirm"
                            text="Salvar questão"
                            disabled={this.state.loading_image_enunciado == true || this.state.loading_image_a || this.state.loading_image_b || this.state.loading_image_c || this.state.loading_image_d || this.state.loading_image_e}
                            loadingtext="Salvando questão"
                            loading={this.state.loading_save}
                        />
                    </div>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default ModalCadastroQuestaoQuestionario;