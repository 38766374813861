import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../actions/AppActions";
import { CircularProgress } from "@material-ui/core";
import Request from "../../../../Request";


const SalasOnline = props => {
    const [loading,setLoading] = useState(true);
    const [processo,setProcesso] = useState({});
    const dispatch = useDispatch();

    const get_processo = useCallback(async(processo_id)=>{
        setLoading(true);
        let response = await Request.Get(`faculdade/dashboard/processo/salas/${processo_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        setProcesso(response.processo)
        setLoading(false);
        return response;
    },[props.processo_id]);

    useEffect(()=>{
        get_processo(props.processo.id);
    },[]);
    return (
        <>
        {loading==false && 
        <>
        <div className="table-rep-plugin">
                <div className="table-responsive mb-0" data-pattern="priority-columns">
                    <table id="tech-companies-1" className="table table-striped">
                        <thead>
                            <tr>

                                <th>Sala</th>
                                <th>Nº candidatos</th>
                                <th>Candidatos Online</th>

                            </tr>
                        </thead>
                        <tbody>
                            {processo.salas.map((item, id) => (

                                <tr key={id}>
                                    <td>Sala {item.numero}</td>
                                    <td>{item.total}</td>
                                    <td>{item.online}</td>



                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>
            </div>
            {processo.salas.length == 0 && <div className="row w-100">
                <div className="col-12">
                    <p style={{ textAlign: 'center' }}>Nenhuma sala encontrada</p>

                </div>
            </div>}
        </>}
        {loading == true && <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                <CircularProgress />
            </div></div>}

        </>
    );


}

export default SalasOnline;