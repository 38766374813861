import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import Attention from "../ReusableComponents/Attention";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiCloseFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import TextReadMore from "../ReusableComponents/TextReadMore";
import moment from "moment";
import { URL } from "../../variables";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/AppActions";
import { CircularProgress } from "@material-ui/core";
import Request from "../../Request";

const ModalFiscaisCsv = props => {
    const token = useSelector(store => store.AppReducer.token);
    const { processo_name, showModal, setShowModal, processo_id, onFinish } = props;
    const [fiscais_csv, setFiscaisCsv] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [loading_jobs, setLoadingJobs] = useState(true);

    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });

    const [csvFile, setCsvFile] = useState(null);
    const [csvValue, setCsvValue] = useState('');

    const [msgErro, setMessageErro] = useState('');
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    useEffect(() => {

        if(showModal==true){
            setLoadingJobs(true);
            setJobs([]);
            GetJobsCsvs();
        }
    }, [showModal]);

    const GetJobsCsvs = async (page = 1, search = '') => {

        let response = await Request.Get(`faculdade/get_csv_jobs_fiscais/${processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }


        if (response.pagination.current_page == page && response.fiscais) {

            setJobs(response.fiscais);

            setPagination(response.pagination);
            setLoadingJobs(false);


        }
    }


    const addUsers = (fiscais_csv,) => {
        setLoading(true);
        setMessageErro('');
        fetch(`${URL}api/faculdade/save_fiscais_csv`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                processo_id: processo_id,
                fiscais: fiscais_csv
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');

                    dispatch(logout());
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setMessageErro(erro);
                    setLoading(false);



                }
                else {
                    setLoading(false);
                    // setShowModal(false);
                    setFiscaisCsv([]);
                    setCsvFile(null);
                    GetJobsCsvs();

                    // if (resp.processo != null) {


                }




            } catch (err) {
                setLoading(false);

                setMessageErro('Erro ao pegar resposta no servidor');

                console.log(err);


            }

        })
            .catch((err) => {
                setLoading(false);
                setMessageErro('Erro ao pegar resposta no servidor. Você está conectado a internet?');

                console.log(err);
            });
    }


    return (
        <Modal
            show={showModal == true}
            dialogClassName="modal-90w modal-width"
            onHide={() => {
                setShowModal(false);
                setFiscaisCsv([]);
                setCsvFile(null);
                onFinish();

            }}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Adicionar fiscais no processo {processo_name}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                {msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMessageErro('')}></button>


                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>

                    </div>
                    <br />

                </div>}

                    <div className="table-rep-plugin w-100">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Status</th>
                                        <th>Erro</th>
                                        <th>Progresso</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_jobs == false && jobs.map((item, id) => (

                                        <tr key={id}>
                                            <td style={{color:(item.status=='Erro na execução'?'red':(item.status=='Finalizado'?'green':'darkgoldenrod'))}}>{item.status}</td>
                                            <td>{item.error_friendly ?? '-'}</td>
                                            <td>{item.progresso}</td>
                                            <td>{item.total_progresso}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_jobs == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_jobs == false && jobs.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma execução encontrada</p>

                        </div>
                    </div>}
                    <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label >Arquivo .csv <Attention /> </Form.Label>
                        <input type="file" placeholder="Arquivo .csv contendo os dados dos fiscais"
                            accept=".csv"
                            value={csvValue}
                            className="form form-control"
                            onChange={(e) => {

                                console.log(e.target.files[0])
                                setCsvFile(e.target.files[0]);
                                setCsvValue('');
                                setMessageErro('');


                                var reader = new FileReader();
                                reader.onload = () => {
                                    // document.getElementById('out').innerHTML = reader.result;
                                    let lines = reader.result.split("\n");
                                    let fiscais = [];
                                    let cpfs = [];
                                    let emails = [];
                                    let numeros_inscricao=[];
                                    for (let i = 0; i < lines.length; i++) {
                                        let line = lines[i];
                                        line = line.split(';');
                                        let fiscal = {};
                                        console.log(line)
                                        //Nome;E-mail;Senha;Salas
                                        if (line.length == 1) {
                                            continue;
                                        }
                                        if (line.length < 4) {
                                            setCsvFile(null);
                                            setCsvValue('');
                                            setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' não possui 4 colunas.')
                                            return;
                                        }
                                        if (line[0] == '' || line[1] == '' || line[2] == '' || line[3] == '' || line[4] == '') {
                                            setCsvFile(null);
                                            setCsvValue('');
                                            setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui colunas em branco.');
                                            return;
                                        }

                                        if (!validateEmail(line[1])) {
                                            setCsvFile(null);
                                            setCsvValue('');
                                            setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um e-mail inválido.');
                                            return;
                                        }

                                        if (emails.includes(line[1])) {
                                            setCsvFile(null);
                                            setCsvValue('');
                                            setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um e-mail que já apareceu nessa planilha. O E-mail deve ser único por fiscal.');
                                            return;
                                        }

                                        if(!line[3].includes('fiscal_coordenador') && !line[3].includes('fiscal_normal')){
                                            setCsvFile(null);
                                            setCsvValue('');
                                            setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um tipo de fiscal inválido. O Tipo de fiscal deve ser fiscal_coordenador ou fiscal_normal.');
                                            return;
                                        }

                                        let salas = line[4].split(',');
                                        let salas_oficial=[];
                                        for(let iS=0;iS<salas.length;iS++){
                                            if(salas[iS]!='' && isNaN(parseInt(salas[iS]))){
                                                setCsvFile(null);
                                                setCsvValue('');
                                                setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um número de sala que não é um número. As salas devem ser específicadas entre \',\' (Ex: 1,2,3) ');
                                                return;
                                            }
                                            else if(salas[iS]!=''){
                                                salas_oficial.push(parseInt(salas[iS]))
                                            }
                                        }

                                        if(salas_oficial.length==0){
                                            setCsvFile(null);
                                            setCsvValue('');
                                            setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um número de sala que não é um número. As salas devem ser específicadas entre \',\' (Ex: 1,2,3) ');
                                            return;
                                        }



                                        emails.push(line[1])

                                        fiscal.name = line[0];


                                        fiscal.email = line[1];

                                        fiscal.password = line[2];

                                        fiscal.tipo = line[3];

                                        fiscal.salas=salas_oficial;


                                        fiscais.push(fiscal);

                                    }

                                    setFiscaisCsv(fiscais);
                                };
                                reader.readAsBinaryString(e.target.files[0]);
                            }} />

                    </div>




                </InputGroup>
                <div>
                    <b style={{ float: "left", marginRight: "2px" }}>Padrão:  </b>
                    <div >
                        O .csv não deve ter cabeçalho (Nome das colunas). As colunas devem ser separadas por “;” no seguinte formato:
                        <br />
                        <p style={{marginBottom:5,fontWeight:800,textAlign:'center'}}>Nome; E-mail; Senha; Tipo (fiscal_coordenador ou fiscal_normal); Salas </p>
                        
                        As salas devem ser específicadas entre ',' , ou seja, se quiser que um fiscal fique nas salas 1, 2 e 3, deve passar: 1,2,3
                        {/* {(readmore ? props.children : props.children.substring(0, props.limit) + (props.children.length > props.limit ? "..." : ""))} */}
                    </div>
                </div>
                <br />
                <br />



                    <div className="flex fdcolumn flex1 margin-bottom">
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Senha</th>
                                            <th>Tipo</th>
                                            <th>Salas</th>
                                            <th data-priority="3">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fiscais_csv.map((item, id) => (
                                            <tr key={id}>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.password}</td>
                                                <td>{item.tipo}</td>
                                                <td>{item.salas.join(',')}</td>

                                                <td>
                                                    <span
                                                        onClick={(e) => {
                                                            let fiscais_csv_ = fiscais_csv.filter((item1, id1) => id1 != id);
                                                            setFiscaisCsv(fiscais_csv_);
                                                        }}
                                                        className="inline-flex align-center jcc bg-danger icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Deletar fiscal"
                                                    >
                                                        <MdDelete size={17} color="white" />
                                                    </span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {fiscais_csv.length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhum fiscal adicionado</p>

                            </div>
                        </div>}
                    </div>



                {loading == false && <div className="w100 inline-flex jcsb margin-top-1">
                    {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                    <DefaultButton
                        bg="secondary"
                        text="Fechar"
                        icon={<RiCloseFill />}
                        onClick={() => {
                            setShowModal(false);
                            setFiscaisCsv([]);
                            setCsvFile(null);
                onFinish();

                        }}
                    />
                    {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                    <DefaultButton
                        type="button"
                        disabled={fiscais_csv.length == 0}

                        onClick={() => {
                            addUsers(fiscais_csv);
                        }}
                        bg={fiscais_csv.length > 0 ? "confirm" : "secondary"}
                        text="Adicionar"
                    />
                </div>}

                {loading == true && <div className="w100 inline-flex jcsb margin-top-1 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>}

            </Modal.Body>
        </Modal>

    );
}

export default ModalFiscaisCsv;