import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";



const Fiscais = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_fiscais, setLoadingfiscais] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [fiscais, setfiscais] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { faculdade_id,faculdade_name,slug } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [fiscal_id, setfiscalId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getfiscais = async (page, search) => {

        let response = await Request.Get(`faculdade/get_fiscais/${faculdade_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {
           
            setfiscais(response.fiscais);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingfiscais(false);

           
        }
    }

    const Deletefiscal = async (fiscal_id) => {

        let response = await Request.Delete(`faculdade/delete_fiscal/${fiscal_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getfiscais(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getfiscais(1, search_oficial);
    }, []);
    const user = useSelector(state => state.AppReducer.user);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                <p className="text-muted" style={{marginBottom:0}}>Aqui ficam os fiscais {(user.empresa==null || user.empresa.change_text==false)?'da instituição':'do processo'} "{faculdade_name}"</p>
                <p className="text-muted">Link para login: <a target="_blank" href={'https://fiscal.ontest.com.br/'+slug+'/login'}>{'https://fiscal.ontest.com.br/'+slug+'/login'}</a></p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo nome do fiscal" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingfiscais(true);

                                Getfiscais(1, value)

                            }, 500);
                        }} />

                        
                                <Link to={{
                                    pathname: "/fiscais/register/" + faculdade_id,
                                    // state: {
                                    //     course_id: id
                                    // }
                                }} style={{ textDecoration: "none" }}>
                                    <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar fiscal</p></Button>
                                </Link>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>Tipo</th>

                                        <th>Criado em</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_fiscais == false && fiscais.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.role.description}</td>

                                            <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>
                                                {/* <span
                                                    onClick={() => {
                                                       
                                                    }}
                                                    className="inline-flex align-center jcc bg-warning icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Gerenciar fiscal"
                                                >
                                                    <BsFillEyeFill size={17} color="white" />
                                                </span> */}
                                                <Link to={`/fiscais/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={(e) => {
                                                        setfiscalId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar fiscal"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_fiscais == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress/>
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_fiscais == false && fiscais.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum fiscal encontrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingfiscais(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getfiscais(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_fiscal={this.get_fiscal.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress/>
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingfiscais(true);
                        Deletefiscal(fiscal_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar este fiscal, ele não estará mais disponível no sistema
                </SweetAlert>


            </div>

        </div>
    )
}

export default Fiscais;
