
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// unidades
import moment from 'moment';
import { URL } from '../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import { Pagination } from '@material-ui/lab';
import { FaListAlt } from 'react-icons/fa';
import Search from '../ReusableComponents/Search';
import { BsFillEyeFill, BsGraphUp } from "react-icons/bs";
import { Button } from "react-bootstrap";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../ReusableComponents/TabPanel";
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';

class Clientes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            clientes: [],
            pagination: {
                page: 1,
                last_page: 1,
            },
            msgErro: '',
            show_warning: false,
            show_erro: false,
            unidade_to_delete: '',
            search: '',
            loading_search: true,
            organizadora: {},
            activeTab:0
        };
    }






    componentDidMount() {

        this.get_clientes(this.state.pagination.page, this.props.match.params.id);
    }

    get_clientes(page, empresa_id, search = '', busca = true) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        }
        else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_clientes/${empresa_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({ loading_search: false, clientes: resp.clientes, organizadora: resp.organizadora, loading_screen: false, pagination: resp.pagination });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    delete_cliente(id) {

        fetch(`${URL}api/faculdade/delete_cliente/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });
                }
                else {
                    this.setState({ show_warning: false });
                    this.get_clientes(this.state.pagination.page, this.props.match.params.id, this.state.search);
                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
            });
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <div style={{ float: 'right' }}>
                                <DefaultButton
                                    bg="secondary"
                                    text="Voltar"
                                    icon={<RiArrowGoBackLine />}
                                    to={`/organizadoras`}
                                />
                            </div>
                            <div className="flex jcsb">
                                <h4 className="header">{this.state.organizadora?.name}</h4>
                            </div>
                            <TextReadMore title="Admin: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                {this.state.organizadora.owner.name}
                            </TextReadMore>
                            <br />
                            <br />

                            <Tabs
                                value={this.state.activeTab}
                                onChange={(_, value) => {
                                    this.setState({ activeTab: value });
                                    this.props.history.push({ search: `?tab=${value}` });
                                }}
                                style={{ backgroundColor: "#ececf1" }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant={"scrollable"}
                                scrollButtons={"on"}

                            >
                                <Tab label="Membros" />

                            </Tabs>
                            <TabPanel value={this.state.activeTab} index={0}>
                                {/* <Processo faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} /> */}

                                <div className="row">

                                    <div className="col-12">


                                        {/* <hr /> */}
                                        {this.state.loading_screen == false && <div>

                                            {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                                            {/* <br /> */}

                                            <div className="flex jcsb flex-wrap mb-4">

                                                <Search placeholder="Pesquisar pelo nome do membro da organizadora" ValueChange={value => {
                                                    clearTimeout(this.pesquisarTimeout);
                                                    this.pesquisarTimeout = setTimeout(() => {
                                                        let page = { ...this.state.pagination };
                                                        page.current_page = 1;
                                                        this.setState({ pagination: page, search: value, loading_search: true });
                                                        this.get_clientes(1, this.props.match.params.id, value);
                                                    }, 500);
                                                }} />


                                                <Link to={{
                                                    pathname: "/organizadoras/membros/register/"+this.state.organizadora.id,
                                                    // state: {
                                                    //     course_id: id
                                                    // }
                                                }} style={{ textDecoration: "none" }}>
                                                    <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar membro</p></Button>
                                                </Link>
                                            </div>

                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>

                                                                <th>Nome</th>
                                                                <th>E-mail</th>
                                                                <th>Criado em</th>

                                                                <th data-priority="3">Ações</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.loading_search == false && this.state.clientes.map((item, id) => (

                                                                <tr key={id}>
                                                                    <td >{item.name}</td>
                                                                    {/* <td>Questão {id + 1}</td> */}

                                                                    <td>{item.email}

                                                                    </td>
                                                                    <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td>

                                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                                                    <td>

                                                                        {/* <Link to={`/organizadoras/show/${item.id}`} onClick={(e) => e.stopPropagation()}>

                                                                            <span

                                                                                className="inline-flex align-center jcc bg-warning icon"
                                                                                style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", }}
                                                                                title="Gerenciar organizadora"
                                                                            >
                                                                                <BsFillEyeFill size={17} color="white" />
                                                                            </span>
                                                                        </Link> */}

                                                                        {<Link to={`/organizadoras/membros/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                                            <span
                                                                                className="inline-flex align-center jcc bg-info icon"
                                                                                style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", }}
                                                                                data-toggle="tooltip"
                                                                                title="Editar membro"
                                                                            >
                                                                                <MdModeEdit color="white" size={17} />
                                                                            </span>
                                                                        </Link>}
                                                                        {this.state.organizadora.owner.id!=item.id && <span
                                                                            onClick={(e) => {
                                                                                this.questao_id = item.id;
                                                                                this.setState({ show_warning: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Deletar membro"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>}
                                                                    </td>

                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            {this.state.loading_search == true &&

                                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                                    <CircularProgress />
                                                </div></div>

                                            }
                                            {this.state.loading_search == false && this.state.clientes.length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhum membro encontrado</p>

                                                </div>
                                            </div>}

                                            <Pagination
                                                className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                                                style={{ display: "flex", justifyContent: "flex-end" }}
                                                count={this.state.pagination.last_page}
                                                value={this.state.pagination.current_page}
                                                onChange={(_, value) => {
                                                    console.log(_, value);
                                                    let page = { ...this.state.pagination };
                                                    page.current_page = value;
                                                    this.setState({ pagination: page });
                                                    this.get_clientes(value, this.props.match.params.id, this.state.search);
                                                }}
                                            />

                                            {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                                        </div>}

                                        {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                            {/* <div className="spinner-border text-primary" role="status"  >
        </div> */}
                                            <CircularProgress />
                                        </div></div>}

                                        <SweetAlert
                                            warning
                                            title={"Atenção"}
                                            onConfirm={() => {
                                                this.setState({ show_warning: false, loading_search: true });
                                                this.delete_cliente(this.questao_id);
                                                // this.delete_banner(this.state.banner_to_delete);
                                            }}
                                            onCancel={() => {
                                                this.setState({ show_warning: false });
                                            }}
                                            show={this.state.show_warning}
                                            confirmBtnText='Sim, desejo apagar'
                                            cancelBtnText='Cancelar'
                                            confirmBtnBsStyle="success"
                                            cancelBtnBsStyle="danger"
                                            showCancel={true}
                                        >
                                            Ao deletar este membro, ele não terá mais acesso ao sistema
                                        </SweetAlert>


                                    </div>

                                </div>
                            </TabPanel>

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}


export default connect(mapsStateToProps, { logout })(Clientes);


