// Library Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RiArrowGoBackLine, RiErrorWarningLine } from "react-icons/ri";
import { Modal, Button, Dropdown } from "react-bootstrap";

// Components Imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import Search from "../ReusableComponents/Search";

// Utils Imports
import { get, post } from "../../Services/api";

class VincularInstituicao extends Component {
  state = {
    allTrainings: [],
    linkedTrainings: [],
    filteredTrainings: [],
    loading: false,
    pagination: {
      current_page: 1,
      last_page: 1,
      total: 0,
      per_page: 30,
    },
    isMounted: false,
    errorMsg: "",
    search: "",
    loading_search: false,
    selectedTrainings: new Set(),
    showModal: false,
    filter: "todos",
  };

  componentDidMount() {
    this.setState({ isMounted: true });
    this.loadTrainings();
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  loadTrainings = async () => {
    await this.getAllTrainings();
    await this.getLinkedTrainings();
    this.applyFilter();
  };

  getAllTrainings = async (
    page = this.state.pagination.current_page,
    search = this.state.search,
    busca = true
  ) => {
    const { token } = this.props;
    const { loading_search, loading } = this.state;

    if (busca && !loading_search) {
      this.setState({ loading_search: true });
    } else if (!busca && !loading) {
      this.setState({ loading: true });
    }

    try {
      const data = await get(
        `api/treinamento/get_trainings?page=${page}&search=${search}`,
        token
      );

      if (this.state.isMounted) {
        this.setState({
          allTrainings: data.data || [],
          loading: false,
          loading_search: false,
          pagination: {
            current_page: data.pagination.current_page,
            last_page: data.pagination.last_page,
            total: data.pagination.total,
            per_page: data.pagination.per_page,
          },
        });
      }
    } catch (error) {
      console.error(error.message || "Erro ao buscar treinamentos");
    } finally {
      if (this.state.isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  getLinkedTrainings = async () => {
    const { token } = this.props;
    const faculdadeId = this.props.match.params.id;

    try {
      const data = await get(
        `api/faculdade/exibir_vinculacoes/${faculdadeId}`,
        token
      );

      if (this.state.isMounted) {
        this.setState({
          linkedTrainings: data.data || [],
        });
      }
    } catch (error) {
      console.error(error.message || "Erro ao buscar treinamentos vinculados");
    }
  };

  handleConfirmarVinculo = async () => {
    const { selectedTrainings } = this.state;
    try {
      for (let trainingId of selectedTrainings) {
        await this.vincularTreinamento(trainingId);
      }
      this.setState({ selectedTrainings: new Set() });
    } catch (error) {
      console.error(error.message || "Erro ao executar as funções");
      this.setState({
        errorMsg: error.message || "Erro ao executar as funções",
        showModal: true,
      });
    }
  };

  vincularTreinamento = async (trainingId) => {
    const faculdadeId = this.props.match.params.id;

    try {
      await post(
        `api/faculdade/vincular_treinamento`,
        {
          faculdade_id: faculdadeId,
          treinamento_id: trainingId,
        },
        this.props.token
      );

      this.setState({ errorMsg: "", showModal: false });
      this.props.history.push(`/instituicao/show/${faculdadeId}?tab=5`);
    } catch (error) {
      console.error(error.message || "Erro ao vincular treinamento");
      this.setState({
        errorMsg: error.message || "Erro ao vincular treinamento",
        showModal: true,
      });
    }
  };

  handlePageChange = (event, value) => {
    this.setState({
      pagination: { ...this.state.pagination, current_page: value },
    });
    this.loadTrainings();
  };

  handleSearchChange = (value) => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({
        search: value,
        pagination: { ...this.state.pagination, current_page: 1 },
        loading_search: true,
      });
      this.loadTrainings();
    }, 500);
  };

  handleTrainingSelection = (trainingId) => {
    this.setState((prevState) => {
      const selectedTrainings = new Set(prevState.selectedTrainings);
      if (selectedTrainings.has(trainingId)) {
        selectedTrainings.delete(trainingId);
      } else {
        selectedTrainings.add(trainingId);
      }
      return { selectedTrainings };
    });
  };

  handleFilterChange = (filter) => {
    this.setState({ filter }, this.applyFilter);
  };

  applyFilter = () => {
    const { allTrainings, linkedTrainings, filter } = this.state;
    let filteredTrainings = allTrainings;

    const linkedIds = new Set(linkedTrainings.map((training) => training.id));

    if (filter === "nao_vinculados") {
      filteredTrainings = allTrainings.filter(
        (training) => !linkedIds.has(training.id)
      );
    } else if (filter === "vinculados") {
      filteredTrainings = allTrainings.filter((training) =>
        linkedIds.has(training.id)
      );
    }

    this.setState({ filteredTrainings });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      loading,
      loading_search,
      filteredTrainings,
      pagination,
      errorMsg,
      showModal,
      selectedTrainings,
    } = this.state;
    const faculdadeId = this.props.match.params.id;

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Vincular Treinamento
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Aqui estão listados todos os treinamentos do seu sistema
              </p>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="flex jcsb flex-wrap mb-4">
                    <Search
                      placeholder="Pesquisar pelo nome do treinamento"
                      ValueChange={this.handleSearchChange}
                    />

                    <Dropdown onSelect={this.handleFilterChange}>
                      <Dropdown.Toggle
                        variant="outline-primary"
                        id="dropdown-basic"
                      >
                        Filtrar
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="todos">Todos</Dropdown.Item>
                        <Dropdown.Item eventKey="nao_vinculados">
                          Não Vinculados
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="vinculados">
                          Vinculados
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <DefaultButton
                      bg="secondary"
                      text="Voltar"
                      icon={<RiArrowGoBackLine />}
                      onClick={() =>
                        this.props.history.push(
                          `/instituicao/show/${faculdadeId}?tab=5`
                        )
                      }
                    />
                  </div>

                  <div className="table-rep-plugin">
                    {loading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="table-responsive mb-0">
                        {errorMsg && (
                          <div className="alert alert-danger" role="alert">
                            {errorMsg}
                          </div>
                        )}
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Selecionar</th>
                              <th>Nome</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading_search &&
                              filteredTrainings.map((training, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={selectedTrainings.has(
                                        training.id
                                      )}
                                      onChange={() =>
                                        this.handleTrainingSelection(
                                          training.id
                                        )
                                      }
                                    />
                                  </td>
                                  <td>{training.nome}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {loading_search && (
                      <div className="row" style={{ margin: 0 }}>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <CircularProgress />
                        </div>
                      </div>
                    )}
                    {!loading_search && filteredTrainings.length === 0 && (
                      <div className="row w-100">
                        <div className="col-12">
                          <p style={{ textAlign: "center" }}>
                            Nenhuma disciplina cadastrada
                          </p>
                        </div>
                      </div>
                    )}

                    <Pagination
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      count={pagination.last_page}
                      page={pagination.current_page}
                      onChange={this.handlePageChange}
                    />
                  </div>
                  <Button
                    variant="success"
                    disabled={selectedTrainings.size === 0}
                    onClick={this.handleConfirmarVinculo}
                    className="mt-3"
                  >
                    Vincular Selecionados
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <RiErrorWarningLine
                style={{
                  marginRight: "8px",
                  marginBottom: "5px",
                  color: "red",
                }}
              />
              Erro
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorMsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
  faculdade_id: state.AppReducer.faculdade_id,
});

export default connect(mapStateToProps)(withRouter(VincularInstituicao));
