
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL as url } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BsFillEyeFill } from 'react-icons/bs';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';

import PDF_IMAGE from '../../assets/images/pdf.png';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

class EditarRecurso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/recursos',
            loading_screen: true,
            roles: [],
            name: '',
            descricao: '',
            fundamentacao: '',
            pedido_realizado:'',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,

            fim: moment().add(1, 'day'),
            hora_inicio: moment(),
            hora_fim: moment().add(1, 'hour'),
            inicio: moment(),
            categorias: [],
            professores: [],
            pagination: {
                page: 1,
                last_page: 1,
            },
            categoria_search: '',
            show_autocomplete_categoria: false,
            loading_search_categoria: false,
            categorias_select: {},
            showModal: false,
            categoriaSelect: null,
            processo_id: '',
            processos: [],
            processo_enunciado: '',
            categorias: [],
            loading_search_categoria: false,
            categoria_id: '',
            loading_search_questao: false,
            show_autocomplete_questao: false,
            questao_enunciado: '',
            questoes: [],
            questaoSelect: null,
            categoria: {},
            files: [],
            files_images: [],
            numero_inscricao:''
        };

        this.inputRef = React.createRef();


    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('name',this.state.name);
        form.append('description',this.state.descricao);
        form.append('fundamentacao',this.state.fundamentacao);
        form.append('pedido_realizado',this.state.pedido_realizado);
        if(this.state.questaoSelect!=null){
            form.append('questao_id',this.state.questaoSelect?.id);
        }
        form.append('categoria_id',this.state.categoria_id);
        form.append('recurso_id',this.props.match.params.id);

        form.append('processo_id',this.state.processo_id);
        form.append('numero_inscricao_candidato',this.state.numero_inscricao);
        form.append('fim',this.state.fim.format('DD/MM/YYYY'));
        form.append('hora_fim',this.state.hora_fim.format('HH:mm'));

        this.state.files.map((file,id)=>{
            if(file.id==undefined){
                form.append('files[]',file);
            }
            else{
                form.append('files_exists[]',file.id);
            }
        })









        // if (Object.values(this.state.categorias_select).length == 0) {
        //     this.setState({ loading_save: false, msgErro: 'A recurso deve ter pelo menos uma categoria selecionada' });
        //     return;
        // }

        fetch(`${url}api/faculdade/update_recurso`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_data(this.props.match.params.id, this.state.processo_enunciado);
    }


    get_data(id,search) {

        if (this.state.loading_q == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${url}api/faculdade/get_recurso/${id}?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false,
                        processos: resp.processos, 
                        name:resp.recurso.name,
                        descricao:resp.recurso.description,
                        fundamentacao:resp.recurso.fundamentacao ?? '',
                        pedido_realizado:resp.recurso.pedido_realizado ?? '',

                        numero_inscricao:resp.recurso.numero_inscricao_candidato,

                        numero_inscricao:resp.recurso.numero_inscricao_candidato,
                        processo_id:resp.recurso.processo_id,
                        categoria_id:resp.recurso.categoria_id,
                        fim:moment(resp.recurso.data_limite),
                        hora_fim:moment(resp.recurso.data_limite),
                        questaoSelect:resp.recurso.questao,
                        files:resp.recurso.files,
                        files_images:resp.recurso.files.map((item,id)=>STORAGE_URL+ item.path),
                    },()=>{
                        this.get_categorias(resp.recurso.processo_id)
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    get_categorias(processo_id) {

        if (this.state.loading_search_categoria == false) {
            this.setState({ loading_search_categoria: true });
        }
        fetch(`${url}api/faculdade/get_categorias_processo/${processo_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ loading_search_categoria: true,categorias:[] });
                    this.get_categorias();
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_search_categoria: false,
                        categorias: resp.categorias
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.get_categorias();
                // this.setState({ loading_search_categoria: false,categorias:[] });
                console.log(err);


            }

        })
            .catch((err) => {
                // this.setState({ loading_search_categoria: false,categorias:[] });
                this.get_categorias();

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    get_questoes(search) {

        if (this.state.loading_search_questao == false) {
            this.setState({ loading_search_questao: true });
        }
        fetch(`${url}api/faculdade/get_questoes_processo/${this.state.categoria_id}/${this.state.processo_id}?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ loading_search_categoria: true,categorias:[] });
                    this.get_questoes();
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_search_questao: false,
                        questoes: resp.questoes
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.get_questoes();
                // this.setState({ loading_search_categoria: false,categorias:[] });
                console.log(err);


            }

        })
            .catch((err) => {
                // this.setState({ loading_search_categoria: false,categorias:[] });
                this.get_questoes();

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    select_categoria(item) {
        let categorias = { ...this.state.categorias_select };
        categorias[item.id] = item;
        this.setState({ categoria_search: '', categorias_select: categorias })
    }

    select_questao(item) {
        this.setState({ questao_enunciado: '', show_autocomplete_questao: false, questaoSelect: item });
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Recurso de Questão</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um recurso de uma questão do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}

                                <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} />


                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder="Nome do recurso"
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom" style={{ position: 'relative' }}>
                                            <Form.Label>Processo Seletivo do Recurso <Attention /></Form.Label>
                                            <select className="form-select "
                                                disabled
                                                onChange={(e) => {

                                                    this.setState({ processo_id: e.target.value, loading_search_categoria: true, categorias: [], categoria_id: '', questoes: [], questaoSelect: null }, () => {
                                                        if (e.target.value != '') {
                                                            this.get_categorias(e.target.value)
                                                        }
                                                        else {
                                                            this.setState({ loading_search_categoria: false });
                                                        }
                                                    });
                                                }} value={this.state.processo_id}>
                                                <option value="">Selecione um processo seletivo</option>
                                                {(this.state.processos && this.state.processos.length != 0) && this.state.processos.map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value["id"]}
                                                    >{value["name_faculdade"]} - {value["processo_name"]}</option>
                                                ))}
                                            </select>
                                            {this.state.loading_search_categoria == true && <div style={{ position: 'absolute', right: 30, top: 40 }}>
                                                <div className="spinner-border spinner-border-sm text-primary" role="status"  >
                                                </div>
                                            </div>}
                                        </div>
                                    </InputGroup>

                                    {this.state.processo_id != '' && <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom" style={{ position: 'relative' }}>
                                            <Form.Label>Disciplina<Attention /></Form.Label>
                                            <select
                                                disabled

                                                className="form-select " onChange={(e) => {

                                                    this.setState({ categoria_id: e.target.value, categoria: e.target.value == '' ? {} : this.state.categorias.filter((item, id) => item.id == e.target.value)[0], questoes: [], questaoSelect: null }, () => {

                                                    });
                                                }} value={this.state.categoria_id}>
                                                <option value="">Selecione uma disciplina</option>
                                                {(this.state.categorias && this.state.categorias.length != 0) && this.state.categorias.map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value["id"]}
                                                    >{value["name"]}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </InputGroup>}


                                    {/* {this.state.categoria_id != '' && <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Busque a questão da categoria {this.state.categoria.name} pelo enunciado <Attention/></Form.Label>
                                            <Input
                                                // disabled={this.state.loading_search_questao}
                                                placeholder="Pesquise a questão pelo enunciado"
                                                value={this.state.questao_enunciado}
                                                // onChange={(e) => this.setState({ questao_enunciado: e.target.value })}
                                                onBlur={() => {
                                                    this.timeout_ = setTimeout(() => {
                                                        this.setState({ show_autocomplete_questao: false });

                                                    }, 500)
                                                }}
                                                onFocus={() => {
                                                    clearTimeout(this.timeout_);

                                                    this.setState({ show_autocomplete_questao: true })
                                                }}
                                                onChange={(e) => { this.get_questoes(e.target.value); this.setState({ questao_enunciado: e.target.value }) }}
                                            // title="Coloque o nome completo do usuário"
                                            />
                                            {this.state.show_autocomplete_questao == true && this.state.questao_enunciado != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                                                {this.state.loading_search_questao == false && this.state.questoes.map((item, id) => (
                                                    <div key={id} onClick={() => this.select_questao(item)} className="opcao_autocomplete">
                                                        <b>{item.categoria.name} - {item.type_questao == 'conjunto' ? ('Conjunto de questões: ' + item.questoes.length + (item.questoes.length == 1 ? ' questão' : ' questões')) :
                                                            (item.type_questao == 'simples' ? 'Questão única' : 'Questão dissertativa')}</b> - {item.text_questao.length < 200 ? item.text_questao : item.text_questao.substring(0, 200) + '...'}
                                                    </div>
                                                ))}
                                                {this.state.loading_search_questao == false && this.state.questoes.length == 0 &&
                                                    <div className="opcao_autocomplete">
                                                        Nenhuma questão encontrada com este termo
                                                    </div>
                                                }
                                                {this.state.loading_search_questao == true &&
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <div className="spinner-border text-primary" role="status"  >
                                                        </div></div>}
                                            </div>}
                                        </div>
                                    </InputGroup>} */}

                                    {this.state.questaoSelect != null && <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom ">
                                            <Form.Label >Questão selecionada:</Form.Label>

                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>


                                                                <th>Enunciado</th>
                                                                <th>Disciplina</th>

                                                                <th>Tipo de questão</th>

                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {this.state.questaoSelect != null &&
                                                                <tr>

                                                                    <td ><p className='text_overflow'>{this.state.questaoSelect.text_questao}</p></td>
                                                                    <td >{this.state.questaoSelect.categoria.name}</td>
                                                                    <td ><p style={{ whiteSpace: 'pre' }}>{this.state.questaoSelect.type_questao == 'conjunto' ? ('Conjunto de questões\n' + this.state.questaoSelect.questoes.length + (this.state.questaoSelect.questoes.length == 1 ? ' questão' : ' questões')) :
                                                                        (this.state.questaoSelect.type_questao == 'simples' ? 'Questão única' : 'Questão dissertativa')}</p></td>

                                                                    <td style={{ minWidth: 90 }}>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ questaoSelect: this.state.questaoSelect, showModal: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: 5 }}
                                                                            title="Gerenciar aula"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                        {/* <span
                                                                            onClick={(e) => {
                                                                                this.setState({ questaoSelect: null });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Deletar texto"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span> */}
                                                                    </td>

                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {this.state.questaoSelect == null && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhuma questão selecionada</p>

                                                </div>
                                            </div>}
                                            <div>
                                                <p style={{ marginBottom: 0 }}><b>Nome do responsável: </b> {this.state.questaoSelect.user.name}</p>
                                                <p><b>E-mail do responsável: </b> {this.state.questaoSelect.user.email}</p>

                                            </div>

                                        </div>
                                    </InputGroup>}


                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Descrição dos fatos <Attention/></Form.Label>
                                            <Input
                                                placeholder="Descrição dos fatos"
                                                value={this.state.descricao}
                                                onChange={(e) => this.setState({ descricao: e.target.value })}
                                                required
                                                as="textarea"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Fundamentação do pedido <Attention/></Form.Label>
                                            <Input
                                                placeholder="Fundamentação do pedido"
                                                value={this.state.fundamentacao}
                                                onChange={(e) => this.setState({ fundamentacao: e.target.value })}
                                                required
                                                as="textarea"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Pedido realizado <Attention/></Form.Label>
                                            <Input
                                                placeholder="Pedido realizado"
                                                value={this.state.pedido_realizado}
                                                onChange={(e) => this.setState({ pedido_realizado: e.target.value })}
                                                required
                                                as="textarea"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>
                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Número de inscrição do candidato <Attention /></Form.Label>
                                                <Input
                                                    placeholder="Número de inscrição do candidato"
                                                    value={this.state.numero_inscricao}
                                                    onChange={(e) => this.setState({ numero_inscricao: e.target.value })}
                                                    required
                                                // title="Coloque o nome completo do usuário"
                                                />
                                            </div>
                                        </InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom ">
                                            <Form.Label >Imagens/arquivos do recurso (Máximo 10 arquivos)</Form.Label>
                                            <Input
                                                type="file"
                                                accept="image/*,application/pdf"
                                                multiple
                                                onChange={(e) => {
                                                    this.setState({ loading_files: true });
                                                    let files = [...e.target.files];
                                                    let files_images = [...this.state.files_images];

                                                    console.log(files);
                                                    // SetInputValue(file);

                                                    // PreviewImage(file);
                                                    // (props.ImageChange && props.ImageChange(file));
                                                    if (files && FileReader) {

                                                        files.map((file, id) => {
                                                            files_images.push(URL.createObjectURL(file));

                                                        })
                                                        let files_actual = [...this.state.files, ...files];
                                                        this.setState({ loading_files: false, files: files_actual.filter((item,id)=>id<10), files_images: files_images.filter((item,id)=>id<10) });



                                                    }
                                                    this.inputRef.current.value = '';
                                                }}
                                                ref={this.inputRef}
                                            />
                                            <div className='row' style={{ marginRight: 0, marginLeft: 0 }}>
                                                {this.state.files.map((item, id) => (
                                                    <div key={id} style={{ width: 100, marginRight: 10, marginTop: 10,position:'relative' }}>
                                                        {!item.name.includes('.pdf') && <img
                                                            src={this.state.files_images[id]}
                                                            style={{ height: 75, width: 100, objectFit: 'contain' }}
                                                        />}
                                                        {item.name.includes('.pdf') && <img
                                                            src={PDF_IMAGE}
                                                            style={{ height: 75, width: 100, objectFit: 'contain' }}
                                                        />}
                                                        <div style={{ width: 100 }}>
                                                            <p style={{ marginBottom: 0, textAlign: 'center', marginTop: 5, fontSize: 11 }}>{item.name}</p>
                                                        </div>
                                                        <IconButton
                                                            style={{background:'rgba(0,0,0,0.5)',position:'absolute',top:0,right:0,width:20,height:20}}
                                                            id="remove-button"
                                                            size="small"
                                                            onClick={() => {
                                                                let files = this.state.files.filter((item2,id2)=>id!=id2);

                                                                let files_images = this.state.files_images.filter((item2,id2)=>id!=id2);

                                                                this.setState({files,files_images});

                                                            }}
                                                        >
                                                            <CloseIcon style={{
                                                                width: "0.7em",
                                                                color: "white"
                                                            }} />
                                                        </IconButton>
                                                    </div>
                                                ))}

                                            </div>

                                        </div>
                                    </InputGroup>




                                    <MuiPickersUtilsProvider utils={MomentUtils}>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom me-3">
                                                <Form.Label>Data limite <Attention /></Form.Label>
                                                <DatePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Data inválida"
                                                    ampm={false}
                                                    minDate={moment()}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    format="DD/MM/YYYY"
                                                    value={this.state.fim}
                                                    onChange={(date) => { this.setState({ fim: date }) }}

                                                />
                                            </div>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Horário limite <Attention /></Form.Label>
                                                <TimePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Horário inválido"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    label=""
                                                    value={this.state.hora_fim}
                                                    onChange={(date) => { this.setState({ hora_fim: date }) }}

                                                />
                                            </div>
                                        </InputGroup>
                                    </MuiPickersUtilsProvider>


                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to="/recursos"
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar recurso"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando recurso"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarRecurso);


