// Assets Imports
import "./assets/ChatBotQuestao.css";
import { URL } from "../../variables";

// Library Imports
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Form, Modal } from "react-bootstrap";
import {
  FaPaperclip,
  FaTrash,
  FaRedo,
  FaArrowLeft,
  FaEdit,
  FaSave,
} from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";

// Component Imports
import ContextForm from "./components/ContextForm";
import ValidationModal from "./components/ValidationModal";

// Service Imports
import { fetchChatBotResponse } from "../../Auxiliar/servicesChatBot";
const GENERATING_MESSAGES = [
  "Gerando resposta, por favor aguarde...",
  "Estou pensando na melhor resposta...",
  "Processando sua pergunta, aguarde um momento...",
  "Calculando a resposta perfeita para você...",
];
const ChatBotQuestao = () => {
  const [messages, setMessages] = useState(() =>
    JSON.parse(localStorage.getItem("messages") || "[]")
  );
  const [loading, setLoading] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [context, setContext] = useState({
    tema: "",
    disciplina: "",
    tipoQuestao: "",
    nivelDificuldade: "",
    nivelEscolaridade: "",
    topicosEspecificos: "",
    quantidadeAlternativas: 0,
    contexto: "",
  });
  const [isEditingContext, setIsEditingContext] = useState(true);
  const [editingMessageIndex, setEditingMessageIndex] = useState(null);
  const [editedMessage, setEditedMessage] = useState("");
  const chatBodyRef = useRef(null);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [messageIndexToValidate, setMessageIndexToValidate] = useState(null);

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  const clearLocalStorageItems = (keys) => {
    keys.forEach((key) => localStorage.removeItem(key));
  };

  useEffect(() => {
    return () => {
      clearLocalStorageItems([
        "lastAlternativas",
        "lastEnunciado",
        "lastSolucao",
        "lastSolucaoResposta",
        "lastResposta",
        "palavrasChaves",
      ]);
    };
  }, []);

  useEffect(() => {
    if (!loading) return;
    const interval = setInterval(() => {
      setLoadingMessageIndex(
        (prevIndex) => (prevIndex + 1) % GENERATING_MESSAGES.length
      );
    }, 4000);
    return () => clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    const savedContext = localStorage.getItem("context");
    if (savedContext) {
      setContext(JSON.parse(savedContext));
    }
  }, []);

  const handleContextSubmit = (contextData) => {
    if (isEditingContext) {
      setContext(contextData);
      localStorage.setItem("context", JSON.stringify(contextData));
      setIsEditingContext(false);
    }
  };

  const handleEditContext = () => {
    setIsEditingContext(true);
  };

  const handleVincularMessage = async (index) => {
    const messageToSave = messages[index]?.text;
  
    if (messageToSave) {
      const enunciadoMatch = messageToSave.match(/<enunciado>(.*?)<\/enunciado>/s);
      const enunciado = enunciadoMatch ? enunciadoMatch[1] : "";
  
      const alternativasMatches = messageToSave.match(/<alternativa>(.*?)<\/alternativa>/g);
      const alternativas = alternativasMatches
        ? alternativasMatches.map((alt) =>
            alt.replace(/<\/?alternativa>/g, "").replace(/[a-e]\)/, "")
          )
        : [];
  
      const solucaoMatch = messageToSave.match(/<solucao>(.*?)<\/solucao>/s);
      const solucaoTexto = solucaoMatch
        ? solucaoMatch[1].trim().replace(/^[a-e]\)/, "")
        : "";
  
      const respostaMatch = messageToSave.match(/<resposta>(.*?)<\/resposta>/s);
      const resposta = respostaMatch ? respostaMatch[1] : "";
  
      // Mantemos as alternativas na ordem original
      const correctAlternativeIndex = alternativas.findIndex(
        (alt) => alt.trim() === solucaoTexto
      );
  
      // Converter o índice da alternativa correta para a letra correspondente
      const correctAlternativeLetter = String.fromCharCode(97 + correctAlternativeIndex);
  
      const palavrasChavesMatch = messageToSave.match(/<key>(.*?)<\/key>/s);
      const palavrasChaves = palavrasChavesMatch ? palavrasChavesMatch[1] : "";
  
      // Salvando dados no localStorage
      const localStorageItems = {
        lastSolucao: correctAlternativeLetter,
        lastSolucaoResposta: solucaoTexto,
        lastResposta: resposta,
        lastEnunciado: enunciado,
        lastAlternativas: JSON.stringify(alternativas),
        tipoQuestao: context.tipoQuestao === "Questão objetiva" ? "simples" : "dissertativa",
        nivelEscolaridade: context.nivelEscolaridade,
        nivelDificuldade: context.nivelDificuldade,
        numeroAlternativas: alternativas.length,
        palavrasChaves: palavrasChaves,
        disciplina: context.disciplina, // Save disciplina to localStorage
      };
  
      Object.entries(localStorageItems).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    } else {
      alert("Erro ao vincular a mensagem.");
    }
  };
  
  const handleSend = useCallback(() => {
    if (!context.tema) return;
    setLoading(true);

    let promptText;
    if (context.tipoQuestao === "Questão dissertativa") {
      promptText = `Você é um professor de ${
        context.disciplina
      }. Crie uma questão dissertativa sobre ${context.tema} ${
        context.contexto ? `(utilizando como base: ${context.contexto})` : ""
      }, com nível de dificuldade ${
        context.nivelDificuldade
      }, adequada ao nível ${context.nivelEscolaridade}. 
      
    **Instruções:**
    1. Coloque a questão dentro das tags <enunciado></enunciado>.
    2. Gere uma resposta dissertativa extremamente direta e sucinta, limite-a até 100 palavras/tokens, de forma que apenas indique ao avaliador o que deve ser cobrado como resposta dentro das tags <resposta></resposta>.
    3. Com base no enunciado gerado, crie palavras chaves a respeito dele e coloque entre as tags <key></key>


    **Atenção:** Não inclua nada fora das tags.`;
    } else {
      promptText = `Aja como um professor de ${
        context.disciplina
      }, você deve CRIAR uma questão do tipo ${
        context.tipoQuestao
      } sobre o tema ${context.tema}, na disciplina ${context.disciplina}.
      A questão deve ter nível de dificuldade ${
        context.nivelDificuldade
      } e ser adequada ao nvel de escolaridade ${context.nivelEscolaridade}.
      
      **Importante:** Todo o conteúdo gerado deve ser colocado exclusivamente DENTRO das tags fornecidas, sem adicionar nada fora das tags. A questão gerada NÃO pode conter texto fora das tags, lembre-se de abrir e fechar as tags corretamente com <> e </>.
      
      1. GERE o enunciado da questão dentro desta tag:
      <enunciado></enunciado>
      
      2. Crie ${
        context.quantidadeAlternativas
      } alternativas factuais e completas, relacionadas ao tema e ao nível escolar mencionado. As alternativas devem ser plausíveis, não genéricas, e formatadas assim (sem espaços extras):
      <alternativa>a)Escreva a primeira alternativa completa aqui</alternativa>
      <alternativa>b)Escreva a segunda alternativa completa aqui</alternativa>

      ${
        context.quantidadeAlternativas > 2
          ? `<alternativa>d)Escreva a quarta alternativa completa aqui</alternativa>`
          : ""
      }
      ${
        context.quantidadeAlternativas > 3
          ? `<alternativa>d)Escreva a quarta alternativa completa aqui</alternativa>`
          : ""
      }
      ${
        context.quantidadeAlternativas > 4
          ? `<alternativa>e)Escreva a quinta alternativa completa aqui</alternativa>`
          : ""
      }
      
      3. A resposta correta deve ser fornecida da seguinte maneira:
      <solucao>Escreva aqui a alternativa correta</solucao>

      4. Com base no enunciado gerado, crie palavras chaves a respeito dele e coloque entre as tags <key></key>
      
      **Não inclua texto fora das tags em hipótese alguma.**`;
    }

    fetchChatBotResponse(promptText)
      .then((responseText) => {
        if (responseText) {
          const cleanedResponse = responseText
            .replace(/^.*?:\s*/, "")
            .replace(/<\/instruction>/g, "");
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: cleanedResponse, sender: "bot" },
          ]);
        } else {
          throw new Error("Resposta do chatbot não está definida.");
        }
      })
      .catch((error) => {
        console.error("Erro:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Erro ao obter resposta do chatbot.", sender: "bot" },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });

    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, [context]);

  const handleClearMessages = () => {
    setMessages([]);
    localStorage.removeItem("messages");
    clearLocalStorageItems([
      "lastEnunciado",
      "lastAlternativas",
      "lastSolucao",
      "lastSolucaoResposta",
      "lastResposta",
      "palavrasChaves",
    ]);
  };

  const handleEditMessage = (index) => {
    setEditingMessageIndex(index);
    setEditedMessage(messages[index].text);
  };

  const handleSaveEdit = (index) => {
    const updatedMessages = [...messages];
    updatedMessages[index].text = editedMessage;
    setMessages(updatedMessages);
    setEditingMessageIndex(null);
    setEditedMessage("");
  };

  const handleCancelEdit = () => {
    setEditingMessageIndex(null);
    setEditedMessage("");
  };


  useEffect(() => {
    if (chatBodyRef.current)
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  }, [messages]);

  const handleShowModal = (index) => {
    setMessageIndexToValidate(index);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setMessageIndexToValidate(null);
  };

  return (
    <div className="chat-container">
      <header className="chat-header d-flex justify-content-between align-items-center">
        <Link
          to={`/questoes/register/${id}`}
          onClick={() => {
            clearLocalStorageItems([
              "lastEnunciado",
              "lastAlternativas",
              "lastSolucao",
              "lastSolucaoResposta",
              "lastResposta",
              "palavrasChaves",
            ]);
          }}
        >
          <Button variant="outline-light">
            <FaArrowLeft /> Voltar
          </Button>
        </Link>
        <h1 className="h5 mb-0 text-white">Criação de Questões</h1>
        <div></div>
      </header>

      <div className="main-content">
        <div className="context-section">
          {isEditingContext ? (
            <ContextForm onSubmit={handleContextSubmit} initialData={context} />
          ) : (
            <Card className="context-summary">
              <Card.Body>
                <Card.Title>Contexto da Questão</Card.Title>
                <Card.Text>
                  Disciplina: {context.disciplina}
                  <br />
                  Tipo de Questão: {context.tipoQuestao}
                  <br />
                  Quantidade de Alternativas:{" "}
                  {context.quantidadeAlternativas || "Não especificado"}
                  <br />
                  Nível de Escolaridade: {context.nivelEscolaridade}
                  <br />
                  Nível de Dificuldade: {context.nivelDificuldade}
                  <br />
                  Tema Principal: {context.tema}
                  <br />
                  Contexto: {context.contexto || "Não especificado"}
                  <br />
                </Card.Text>
                <Button variant="outline-primary" onClick={handleEditContext}>
                  Editar Contexto
                </Button>
              </Card.Body>
            </Card>
          )}
        </div>

        <footer className="chat-footer d-flex justify-content-center align-items-center">
          <Button
            variant="primary"
            onClick={handleSend}
            disabled={isEditingContext}
          >
            Gerar Questão
          </Button>
        </footer>

        <div className="chat-section">
          <div className="chat-body" ref={chatBodyRef}>
            {messages.map((msg, index) => (
              <Card key={index} className="message mb-4">
                <Card.Body>
                  {editingMessageIndex === index ? (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={editedMessage}
                      onChange={(e) => setEditedMessage(e.target.value)}
                    />
                  ) : (
                    <>
                      {msg.sender === "bot" && (
                        <div>
                          {msg.text
                            .split(
                              /(<enunciado>|<\/enunciado>|<solucao>|<\/solucao>|<resposta>|<\/resposta>)/g
                            )
                            .map((part, i) => {
                              if (part.trim() === "") return null;
                              if (part === "<enunciado>") {
                                return (
                                  <div
                                    key={i}
                                    className="enunciado"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <strong>Enunciado:</strong>{" "}
                                    <span>
                                      {msg.text
                                        .split(/<\/enunciado>/)[0]
                                        .split("<enunciado>")[1]
                                        .trim()}
                                    </span>
                                  </div>
                                );
                              }
                              if (part === "</enunciado>") {
                                return null;
                              }
                              if (part === "<solucao>") {
                                return (
                                  <div
                                    key={i}
                                    className="solucao"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <strong>Solução:</strong>{" "}
                                    <span>
                                      {msg.text
                                        .split(/<\/solucao>/)[0]
                                        .split("<solucao>")[1]
                                        .trim()}
                                    </span>
                                  </div>
                                );
                              }
                              if (part === "</solucao>") {
                                return null;
                              }
                              if (part === "<resposta>") {
                                return (
                                  <div
                                    key={i}
                                    className="resposta"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <strong>Resposta:</strong>{" "}
                                    <span>
                                      {msg.text
                                        .split(/<\/resposta>/)[0]
                                        .split("<resposta>")[1]
                                        .trim()}
                                    </span>
                                  </div>
                                );
                              }
                              if (part === "</resposta>") {
                                return null;
                              }
                            })}

                          {(() => {
                            const alternatives = msg.text.match(
                              /<alternativa>(.*?)<\/alternativa>/g
                            );
                            if (alternatives) {
                              return (
                                <div
                                  className="alternativas"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <strong>Alternativas:</strong>
                                  <div style={{ marginTop: "5px" }}>
                                    {alternatives.map((alt, index) => (
                                      <div
                                        key={index}
                                        style={{ marginBottom: "5px" }}
                                      >
                                        {alt
                                          .replace(/<\/?alternativa>/g, "")
                                          .trim()}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })()}
                        </div>
                      )}
                    </>
                  )}
                </Card.Body>
                <Card.Footer>
                  {editingMessageIndex === index ? (
                    <>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleSaveEdit(index)}
                      >
                        <FaSave /> Salvar
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="ms-2"
                        onClick={handleCancelEdit}
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleEditMessage(index)}
                      >
                        <FaEdit /> Editar
                      </Button>
                      {msg.sender === "bot" && (
                        <Button
                          variant="info"
                          size="sm"
                          className="ms-2"
                          onClick={() => handleShowModal(index)}
                        >
                          <FaPaperclip /> Validar Questão
                        </Button>
                      )}
                    </>
                  )}
                </Card.Footer>
              </Card>
            ))}

            {loading && (
              <div className="loading-indicator">
                <CircularProgress size={24} />
                <span className="ms-3 text-muted">
                  {GENERATING_MESSAGES[loadingMessageIndex]}
                </span>
              </div>
            )}
          </div>

          <footer className="chat-footer d-flex justify-content-between align-items-center">
            <div>
              <Button
                variant="outline-danger"
                className="me-2"
                onClick={handleClearMessages}
              >
                <FaTrash /> Limpar
              </Button>
            </div>

            <Link to={`/questoes/register/${id}`}>
              <Button variant="outline-info" className="ms-2">
                <FaArrowLeft /> Voltar
              </Button>
            </Link>
          </footer>
        </div>
      </div>

      <ValidationModal
        show={showModal}
        onClose={handleCloseModal}
        onValidate={handleVincularMessage}
        messageIndex={messageIndexToValidate}
        id={id}
        isEditingContext={isEditingContext}
      />
    </div>
  );
};

export default ChatBotQuestao;
