
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdFileDownload, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';


import { TextField } from '@material-ui/core';
import './label.css';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import { BsFile, BsFillEyeFill } from 'react-icons/bs';
class CriarRedacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            enunciado: '',
            msgErroTextoApoio: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            inicio: moment(),
            processo: { name: '' },
            arquivos: [],
            texto: '',
            titulo: '',
            texto_value:''
        };

        this.config = {
            height: 300,
            // askBeforePasteHTML:false,
            // askBeforePasteFromWord:false,
            // defaultActionOnPaste:'insert_only_text',
            // defaultActionOnPasteFromWord:'insert_only_text',
            // pasteHTMLActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            // pasteFromWordActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            readonly: this.state.loading_image,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }

    get_faculdade(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_processo/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, processo: resp.processo
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    componentWillUnmount(){
        Array.from(document.getElementsByClassName('jodit-wysiwyg')).forEach((el) => {
            // Do stuff here
            el.blur()
        })
    }

    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('enunciado', this.state.enunciado);
        for(let i=0;i<this.state.arquivos.length;i++){
            form.append('titulo[]', this.state.arquivos[i].titulo);
            form.append('apoio[]', this.state.arquivos[i].apoio);
        }
        form.append('processo_id', this.state.processo.id);
        document.getElementById('text_enunciado').innerHTML=this.state.enunciado;
        form.append('enunciado_content', document.getElementById('text_enunciado').textContent);


        fetch(`${URL}api/faculdade/store_redacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_faculdade(this.props.match.params.id);
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/processos/show/${this.props.match.params.id}`} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                        <div hidden id="text_enunciado">

</div>
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar Redação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre as redações {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'do processo':'da vaga/cargo'} {this.state.processo.name}</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder="Nome da redação"
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label >Enunciado da redação <Attention /> {this.state.loading_image == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                            </div>}</Form.Label>
                                            <JoditEditor
                                                ref={this.editor}
                                                value={this.state.enunciado}
                                                config={this.config}
                                                height={400}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => this.setState({ enunciado: newContent })} // preferred to use only this option to update the content for performance reasons
                                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label >Título <Attention /> </Form.Label>
                                            <Input
                                                placeholder="Título do texto de apoio"
                                                value={this.state.titulo}
                                                onChange={(e) => this.setState({ titulo: e.target.value })}
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>


                                    </InputGroup>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label >Texto de apoio (.pdf) <Attention /> </Form.Label>
                                            <input type="file" placeholder="Texto de apoio (.pdf)"
                                                accept='application/pdf'
                                                value={this.state.texto_value}
                                                className="form form-control"
                                                onChange={(e) => {
                                                    console.log(e.target.files[0])
                                                    this.setState({ texto: e.target.files[0],texto_value:e.target.value })
                                                }} />
                                            {/* <Input
                                                type="file"
                                                placeholder="Texto de apoio (.pdf)"
                                                accept='application/pdf'
                                                value={this.state.texto}
                                                onChange={(e) => {
                                                    console.log(e.target.value)
                                                    this.setState({ texto: e.target.value })
                                                }}
                                            // title="Coloque o nome completo do usuário"
                                            /> */}
                                        </div>
                                    </InputGroup>
                                    <p hidden={this.state.msgErroTextoApoio == ''} style={{ color: 'red' }}>{this.state.msgErroTextoApoio}</p>

                                    <DefaultButton
                                        type="button"
                                        onClick={() => {
                                            
                                            if (this.state.titulo == '') {
                                                this.setState({ msgErroTextoApoio: 'O título do texto de apoio é obrigatório' });
                                            }
                                            else if (this.state.texto == '') {
                                                this.setState({ msgErroTextoApoio: 'Você deve selecionar um arquivo tipo .pdf para inserir um texto de apoio' });
                                            }
                                            else {
                                                let arquivos = [...this.state.arquivos];
                                                arquivos.push({ titulo: this.state.titulo, apoio: this.state.texto });
                                                this.setState({ msgErroTextoApoio: '', arquivos, texto: '',texto_value:'', titulo: '' }, () => {
                                                    console.log(arquivos)
                                                })
                                            }
                                        }}
                                        bg="confirm"
                                        text="Inserir"
                                    />
                                    <hr />
                                    <Form.Label >Textos de apoio <Attention /></Form.Label>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Título</th>
                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.arquivos.map((item, id) => (
                                                                <tr key={id}>
                                                                    <td>{item.titulo}</td>
                                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                                                    <td>
                                                                        <a href={window.URL.createObjectURL(item.apoio)} target="_blank">
                                                                            <span
                                                                                onClick={() => {

                                                                                }}
                                                                                className="inline-flex align-center jcc bg-success icon"
                                                                                style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                                title="Gerenciar texto"
                                                                            >
                                                                                <MdFileDownload size={17} color="white" />
                                                                            </span>
                                                                        </a>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let arquivos=this.state.arquivos.filter((i,id2)=>id2!=id);
                                                                                this.setState({arquivos});
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Deletar texto"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {this.state.arquivos.length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhum texto de apoio adicionado</p>

                                                </div>
                                            </div>}
                                        </div>

                                    </InputGroup>

                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to={`/processos/show/${this.props.match.params.id}`}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar redação"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando redação"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        
    }
);

export default connect(mapsStateToProps, { logout })(CriarRedacao);


