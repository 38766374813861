import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";



const AtribuicoesRedacoes = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_atribuicoes, setLoadingatribuicoes] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [atribuicoes, setatribuicoes] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { demanda_id, demanda_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [atribuicao_id, setatribuicaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getatribuicoes = async (page, search) => {

        let response = await Request.Get(`faculdade/get_atribuicoes_redacoes/${demanda_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setatribuicoes(response.atribuicoes);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingatribuicoes(false);


        }
    }

    const Deleteatribuicao = async (atribuicao_id) => {

        let response = await Request.Delete(`faculdade/delete_atribuicao/${atribuicao_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getatribuicoes(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getatribuicoes(1, search_oficial);
    }, []);

    const pesquisarTimeout = useRef(null);
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as atribuições da demanda "{demanda_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo nome do elaborador ou nome da disciplina" ValueChange={value => {
                            clearTimeout(pesquisarTimeout.current);
                            setSearchOficial(value);

                            pesquisarTimeout.current = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingatribuicoes(true);

                                Getatribuicoes(1, value)

                            }, 1000);
                        }} />

                        <div style={{display:'flex'}}>
                        <Link to={{
                            pathname: "/atribuicoes/register_redacoes/" + demanda_id,
                            // state: {
                            //     course_id: id
                            // }
                        }} style={{ textDecoration: "none" }}>
                            <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar/Editar atribuições</p></Button>
                        </Link>
                        </div>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Descritivo</th>
                                        <th>Nome do elaborador</th>
                                        <th>E-mail do elaborador</th>
                                        {/* <th>Quantidade de questões</th> */}
                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_atribuicoes == false && atribuicoes.map((item, id) => (

                                        <tr key={id}>
                                            <td><p>{item.descritivo}</p></td>
                                            <td>{item.professor.name}</td>
                                            <td>{item.professor.email}</td>

                                            {/* <td>{item.numero_questoes==null?'Não especificado':item.numero_questoes}</td> */}


                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>
                                                <Link to={`/atribuicoes/show/${item.id}`} onClick={(e) => e.stopPropagation()}>

                                                    <span
                                                        onClick={() => {

                                                        }}
                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar atribuição"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span>
                                                </Link>
                                                <Link to={ "/atribuicoes/register_redacoes/" + demanda_id} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar atribuição"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={(e) => {
                                                        setatribuicaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar atribuição"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_atribuicoes == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_atribuicoes == false && atribuicoes.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma atribuição encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingatribuicoes(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getatribuicoes(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_atribuicao={this.get_atribuicao.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingatribuicoes(true);
                        Deleteatribuicao(atribuicao_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar esta atribuição, o elaborador não terá mais acesso a esta disciplina/demanda
                </SweetAlert>


            </div>

        </div>
    )
}

export default AtribuicoesRedacoes;
