
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';


import { TextField } from '@material-ui/core';
import './label.css';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import PasswordInput from '../ReusableComponents/PasswordInput';
import { Close } from '@material-ui/icons';
import { RadioGroup } from '@mui/material';
import { FormControlLabel, Radio } from '@material-ui/core';
class EditarProfessor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            email: '',
            password: '',
            password_confirmation: '',


            loading_save: false,
            msgErro: '',
            loading_image: false,
            faculdade: { name: '' },
            disciplinas: [],
            disciplinas_select: {},
            can_create_redacao:false,
            can_do_audition:false
        };


    }

    get_professor(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_professor/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    let disciplinas_select={};
                    for(let i=0;i<resp.professor.disciplinas.length;i++){
                        disciplinas_select[resp.professor.disciplinas[i].id]=resp.professor.disciplinas[i];
                    }
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false,
                        name:resp.professor.name,
                        email:resp.professor.email,
                        disciplinas: resp.disciplinas,
                        disciplinas_select,
                        can_create_redacao:resp.professor.opcoes_elaborador!=undefined?resp.professor.opcoes_elaborador.can_create_redacao : false,
                        can_do_audition:resp.professor.opcoes_elaborador!=undefined?resp.professor.opcoes_elaborador.can_do_audition : false,

                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('email', this.state.email);
        form.append('password', this.state.password);
        form.append('can_create_redacao', this.state.can_create_redacao);
        form.append('can_do_audition', this.state.can_do_audition);

        form.append('password_confirmation', this.state.password_confirmation);

        form.append('professor_id', this.props.match.params.id);



        fetch(`${URL}api/faculdade/update_professor`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                name:this.state.name,
                email:this.state.email,
                password:this.state.password,
                password_confirmation:this.state.password_confirmation,
                professor_id:this.props.match.params.id,
                can_create_redacao:this.state.can_create_redacao,
                can_do_audition:this.state.can_do_audition,
                disciplinas:Object.keys(this.state.disciplinas_select)
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false });
                    this.props.history.push({
                        pathname: `/elaboradores`,
                        
                        search:'?'+new URLSearchParams({tab: '1'}).toString()
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_professor(this.props.match.params.id);
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/`} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Elaborador</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um elaborador do seu sistema </p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder="Nome do elaborador"
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>E-mail <Attention /></Form.Label>
                                            <Input
                                                placeholder="E-mail"
                                                value={this.state.email}
                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>
                                        <PasswordInput
                                            label="Senha"
                                            placeholder="Digite sua senha"
                                            className="flex1"
                                            value={this.state.password}
                                            OnChangeText={(e) => this.setState({ password: e.target.value })}
                                        // title="Coloque a senha do usuário"
                                        />
                                    </InputGroup>

                                    <InputGroup>
                                        <PasswordInput
                                            label="Confirmação de senha"
                                            placeholder="Digite a confirmação de senha"
                                            className="flex1"
                                            value={this.state.password_confirmation}
                                            OnChangeText={(e) => this.setState({ password_confirmation: e.target.value })}
                                            
                                        // title="Coloque a senha do usuário"
                                        />
                                    </InputGroup>

                                    <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom" style={{ position: 'relative' }}>
                                            <Form.Label>Disciplinas<Attention /></Form.Label>
                                            <select
                                                disabled={this.state.loading_search_questao}

                                                className="form-select " onChange={(e) => {
                                                    let disciplinas_select = { ...this.state.disciplinas_select };
                                                    disciplinas_select[e.target.value] = this.state.disciplinas.filter((item, id) => item.id == e.target.value)[0];
                                                    this.setState({categoria_id:'', disciplinas_select }, () => {

                                                    });
                                                }} value={this.state.categoria_id}>
                                                <option value="">Selecione uma disciplina</option>
                                                {(this.state.disciplinas && this.state.disciplinas.length != 0) && this.state.disciplinas.map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value["id"]}
                                                    >{value["name"]}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </InputGroup>

                                    {<div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
                                        {Object.values(this.state.disciplinas_select).map((item, id) => (
                                            <div key={id} style={{ backgroundColor: '#f2f2f2', borderRadius: 10, padding: 5, width: 'auto', marginTop: 0, display: 'flex', marginRight: 5,marginBottom:10 }}>
                                                <p style={{ marginBottom: 0, textAlign: 'center' }}>{item.name}</p>
                                                <Close onClick={() => {
                                                    let disciplinas_select = {...this.state.disciplinas_select};
                                                    delete disciplinas_select[item.id];
                                                    this.setState({ disciplinas_select });

                                                }} style={{ cursor: 'pointer', width: '0.9rem', marginLeft: 3 }} />
                                            </div>
                                        ))}
                                    </div>}

                                    {<InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Elaborador pode criar redação? <Attention /></Form.Label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={this.state.can_create_redacao}
                                                onChange={(e) => {
                                                    this.setState({ can_create_redacao: e.target.value });
                                                }}>

                                                <FormControlLabel onClick={(e) => {
                                                    this.setState({ can_create_redacao: true });
                                                }} control={<Radio checked={this.state.can_create_redacao == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ can_create_redacao: false });

                                                }} control={<Radio checked={this.state.can_create_redacao == false} value={false} />} style={{ color: 'black' }} label={'Não'} />
                                            </RadioGroup>
                                        </div>
                                    </InputGroup>}


                                    {<InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Elaborador pode ser auditor? <Attention /></Form.Label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={this.state.can_do_audition}
                                                onChange={(e) => {
                                                    this.setState({ can_do_audition: e.target.value });
                                                }}>

                                                <FormControlLabel onClick={(e) => {
                                                    this.setState({ can_do_audition: true });
                                                }} control={<Radio checked={this.state.can_do_audition == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ can_do_audition: false });

                                                }} control={<Radio checked={this.state.can_do_audition == false} value={false} />} style={{ color: 'black' }} label={'Não'} />
                                            </RadioGroup>
                                        </div>
                                    </InputGroup>}

                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            search={'?' + new URLSearchParams({ tab: 1 }).toString()}
                                            to={`/elaboradores`}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar elaborador"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando elaborador"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarProfessor);


