
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdFileDownload, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';



import { TextField } from '@material-ui/core';
import './label.css';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import { BsFile, BsFillEyeFill } from 'react-icons/bs';
class CriarCandidatoVinculado extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            enunciado: '',
            msgErroTextoApoio: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            inicio: moment(),
            processo: { name: '' },
            arquivos: [],
            texto: '',
            titulo: '',
            texto_value: '',
            candidato_enunciado: '',
            show_autocomplete_candidato: false,
            loading_search_candidato: false,
            candidatos: [],
            candidatos_selecionadas: {},

            showModal: false,
            candidatoSelect: null

        };


    }

    get_processo(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_processo/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, processo: resp.processo,
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        let candidatos = Object.values(this.state.candidatos_selecionadas);
        for (let i = 0; i < candidatos.length; i++) {
            form.append('candidatos[]', candidatos[i].id);
        }
        form.append('processo_id', this.state.processo.id);


        fetch(`${URL}api/faculdade/store_candidatos_vinculados`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_processo(this.props.match.params.id);
    }

    get_candidato_search(search) {
        if (this.state.loading_search_candidato == false) {
            this.setState({ loading_search_candidato: true });
        }
        fetch(`${URL}api/faculdade/get_candidatos_nao_vinculados_search/${this.state.processo.id}?page=1&search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_candidato: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    if(this.state.candidato_enunciado==search){
                        this.setState({ loading_search_candidato: false, candidatos: resp.candidatos });
                    }
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_candidato: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_candidato: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    select_candidato(item) {
        let candidatos = { ...this.state.candidatos_selecionadas };
        if (candidatos[item.id] == undefined) {
            candidatos[item.id] = item;
        }
        this.setState({ candidato_enunciado: '', show_autocomplete_candidato: false, candidatos_selecionadas: candidatos })
    }


    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/processos/show/${this.props.match.params.id}?tab=7`} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>

                            <div hidden id="text_enunciado">

                            </div>
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Vincular Candidato(s)</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Vincule um ou mais candidatos ao processo {this.state.processo.name}</p>
                            <br />
                            <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} candidato={this.state.candidatoSelect} />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>

                                    {/* <h5 style={{ fontWeight: 'bold' }}>Candidatos</h5>
                                    <br /> */}


                                    {<InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Busque um ou mais candidatos que deseja vincular </Form.Label>
                                            <Input
                                                placeholder="Pesquise o candidato pelo nome ou cpf"
                                                value={this.state.candidato_enunciado}
                                                // onChange={(e) => this.setState({ candidato_enunciado: e.target.value })}
                                                onBlur={() => {
                                                    this.timeout_ = setTimeout(() => {
                                                        this.setState({ show_autocomplete_candidato: false });

                                                    }, 500)
                                                }}
                                                onFocus={() => {
                                                    clearTimeout(this.timeout_);
                                                    this.setState({ show_autocomplete_candidato: true })
                                                }}
                                                onChange={(e) => { this.get_candidato_search(e.target.value); this.setState({ candidato_enunciado: e.target.value }) }}
                                            // title="Coloque o nome completo do usuário"
                                            />
                                            {this.state.show_autocomplete_candidato == true && this.state.candidato_enunciado != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                                                {this.state.loading_search_candidato == false && this.state.candidatos.map((item, id) => (
                                                    <div key={id} onClick={() => this.select_candidato(item)} className="opcao_autocomplete">
                                                        <b>{item.name}</b> - {item.cpf}
                                                    </div>
                                                ))}
                                                {this.state.loading_search_candidato == false && this.state.candidatos.length == 0 &&
                                                    <div className="opcao_autocomplete">
                                                        Nenhum candidato encontrado com este termo
                                                    </div>
                                                }
                                                {this.state.loading_search_candidato == true &&
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <div className="spinner-border text-primary" role="status"  >
                                                        </div></div>}
                                            </div>}
                                        </div>
                                    </InputGroup>}



                                    <Form.Label >Candidatos selecionados <Attention /></Form.Label>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>E-mail</th>
                                                                <th>CPF</th>
                                                                <th>Data de nascimento</th>

                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.values(this.state.candidatos_selecionadas).map((item, id) => (
                                                                <tr key={id}>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.cpf}</td>
                                                                    <td>{moment(item.nascimento).format('DD/MM/YYYY')}</td>

                                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                                                    <td>
                                                                        {/* <span
                                                                            onClick={() => {
                                                                                this.setState({ candidatoSelect: item, showModal: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Gerenciar aula"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span> */}
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let candidatos_selecionadas = { ...this.state.candidatos_selecionadas };
                                                                                delete candidatos_selecionadas[item.id];
                                                                                this.setState({ candidatos_selecionadas });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Desvincular candidato"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {Object.values(this.state.candidatos_selecionadas).length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhum candidato selecionado</p>

                                                </div>
                                            </div>}
                                        </div>

                                    </InputGroup>


                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            search={'?tab=7'}
                                            to={`/processos/show/${this.props.match.params.id}`}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar candidatos"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando candidatos"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarCandidatoVinculado);


