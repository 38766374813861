export const handleFileChange = (
  newFile,
  setPreview,
  setNewFile,
  clearFile,
  clearCurrentFile
) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    setPreview(reader.result);
  };
  reader.readAsDataURL(newFile);
  setNewFile(newFile);
  if (clearCurrentFile !== null) {
    clearCurrentFile(null);
  }
  if (clearFile !== null) {
    clearFile(null);
  }
};
