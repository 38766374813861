import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaPlus, FaVideo } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import ModalRespostas from "./ModalRespostas";



const ProvasModelos = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(false);
    const [loading_provas, setLoadingprovas] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });

    const [provaSelect, setProvaSelect] = useState(null);


    const [search, setSearch] = useState('');
    const { processo_id, processo_name, inicio,simulado } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [prova_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

  
   
    useEffect(() => {
    }, []);
    const user = useSelector(state=>state.AppReducer.user);
    console.log(showModal,provaSelect)
    let pesquisarTimeout = null;
    const history = useHistory();
    const {provas,prova_name} = props;
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam os modelos da prova {prova_name} {(user.empresa==null || user.empresa.change_text==false)?'do processo':'da vaga/cargo'} "{processo_name}"</p>
                    <ModalRespostas model showModal={showModal} setShowModal={(e) => { setShowModal(e); setProvaSelect(null) }} provaSelect={{respostas:'{}'}} prova_name={provaSelect?.name} questoes={provaSelect?.questoes ?? null} />

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                   

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>Número de questões</th>
                                        <th>Modelo</th>
                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {provas.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            <td>{item.questoes.map((item, id) => {
                                        if (item.type_questao == 'conjunto') {
                                            return item.questoes.length;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }).reduce((a, b) => a + b, 0)}</td>
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}
                                            <td>{'Modelo '+ item.code_exam}</td>
                                            <td>

                                                    <span
                                                        onClick={() => {
                                                            setProvaSelect(item);
                                                            setShowModal(true);
                                                        }}
                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar prova"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                           
                        </div>
                    </div>
                    {provas.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma prova encontrada</p>

                        </div>
                    </div>}


                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_prova={this.get_prova.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}


            </div>

        </div>
    )
}

export default ProvasModelos;
