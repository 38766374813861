import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFilePdf, BsFilePdfFill, BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import { STORAGE_URL, URL } from "../../variables";



const TextosApoio = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(false);
    const [loading_textos, setLoadingtextos] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [textos, settextos] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, redacao_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [textos_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Gettextos = async (page, search) => {

        let response = await Request.Get(`faculdade/get_textos/${processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            settextos(response.textos);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingtextos(false);


        }
    }

   


    

    const DeleteRedacao = async (textos_id) => {

        let response = await Request.Delete(`faculdade/desvincular_texto/${textos_id}/${processo_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Gettextos(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        // Gettextos(1, search_oficial);
        settextos(props.textos);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam os textos de apoio da redação "{redacao_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                   

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Título</th>
                                        <th data-priority="3">PDF</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_textos == false && textos.map((item, id) =>(

                                        <tr key={id}>
                                            <td>{item.titulo}</td>

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>
                                            <a target="_blank" href={STORAGE_URL+item.arquivo}>

                                                <span
                                                    onClick={() => {
                                                        // GetRelatórioCorretor(item);
                                                    }}
                                                    className="inline-flex align-center jcc bg-white icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px",border:'1px solid grey' }}
                                                    title="Visualizar texto"
                                                >
                                                    <BsFilePdf size={17} color="red" />
                                                </span>
                                                </a>
                                               
                                               
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_textos == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_textos == false && textos.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma texto encontrada</p>

                        </div>
                    </div>}

                  

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_textos={this.get_textos.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingtextos(true);
                        DeleteRedacao(textos_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este texto, ele não terá mais acesso as textos deste processo
                </SweetAlert>


            </div>

        </div>
    )
}

export default TextosApoio;
