import axios from "axios";

export const fetchChatBotResponse = async (question) => {
  try {
    const response = await axios.post(
      "https://questionai.ontest.com.br/ask",
      {
        question,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Erro na resposta da API");
    }

    return response.data.answer;
  } catch (error) {
    console.error("Erro ao chamar a API:", error);
    throw new Error(
      "Ocorreu um erro ao obter a resposta. Tente novamente mais tarde."
    );
  }
};
