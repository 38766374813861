import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { useSelector } from "react-redux";

const ModalRegras = props => {
    const { processo_name, showModal, setShowModal,regras } = props;
    const user = useSelector(state=>state.AppReducer.user);

    return (
        regras != null && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Regras {(user.empresa==null || user.empresa.change_text==false)?'do processo':'da vaga/cargo'} {processo_name}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <div className="custom_scrollbar">

                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: regras }}>

                    </div>
                </div>

            </Modal.Body>
        </Modal>

    );
}

export default ModalRegras;