import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';

const ModalRespostas = props => {
    const { provaSelect,model, showModal, setShowModal, questoes, prova_name } = props;
    console.log(provaSelect)
    let respostas = provaSelect != null ? JSON.parse(provaSelect.respostas) : {};

    const DecideBorder = (resposta, correta, atual) => {
        if (atual == resposta && resposta == correta) {
            return '1px solid green';
        }
        else if (atual == resposta) {
            return '1px solid red';
        }
        else if (atual == correta) {
            return '1px solid green';
        }
        else {
            return '';
        }
    }
    let number_questao = 0;
    return (
        provaSelect != null && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>{!model ? <>Respostas {provaSelect.user.name} - {prova_name}</>: <>{prova_name}</>}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <div className="custom_scrollbar">

                    {questoes!=null && questoes.map((item, id) => {
                        if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                            number_questao = number_questao + 1;
                        }
                        return (
                            <div key={id}>
                                <hr />

                                <h6 className="mt-0 header-title" style={{ textAlign: 'right', marginBottom: 10, fontSize: 15 }}>
                                    {item.categoria.name}</h6>

                                {item.type_questao == 'dissertativa' && <div className="row" style={{ fontWeight: 'normal',margin:0,margin:0 }}>
                                    <div className="col-2 col-sm-1">
                                        <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                            <b>{number_questao})</b></h5>
                                    </div>
                                    <div className="col-10 col-sm-11">


                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                        </div>
                                        {!model && <>
                                        <p><b>Resposta: </b></p>
                                        <p style={{whiteSpace:'pre-line',marginBottom:0}}>
                                            {respostas[item.id] != undefined ? respostas[item.id] : '-'}
                                        </p>
                                        </>}
                                        
                                    </div>
                                </div>}
                                {/* {!!Form::open(['action' =>
['ProcessoUserController@responder_questao'],'data-toggle'=>'validator','files' =>
false,'method'=>'post','id'=>$data['prova']->id.'_'.$questao->id]) !!} */}
                                {item.type_questao == 'simples' && <div className="row" style={{ fontWeight: 'normal',margin:0 }}>
                                    <div className="col-2 col-sm-1">
                                        <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                            <b>{number_questao})</b></h5>
                                    </div>
                                    <div className="col-10 col-sm-11">


                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                        </div>
                                        <p><b>Alternativas:</b></p>
                                        <p><b>Correta: </b>{item.correta}</p>
                                        {!model && <>
                                        <p><b>Respondida: </b>{respostas[item.id] != undefined ? respostas[item.id] : '-'}</p>
                                        </>}

                                        <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item.id] != undefined ? respostas[item.id] : '-', item.correta, 'a') }}>
                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                <p style={{ color: 'black', fontWeight: 'normal' }}>a)</p>
                                            </div>
                                            <div style={{ width: '90%' }}>

                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[0].name }}>

                                                </div>
                                            </div>

                                        </div>
                                        {item.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item.id] != undefined ? respostas[item.id] : '-', item.correta, 'b') }}>
                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                <p style={{ color: 'black', fontWeight: 'normal' }}>b)</p>
                                            </div>
                                            <div style={{ width: '90%' }}>

                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[1].name }}>

                                                </div>
                                            </div>

                                        </div>}
                                        {item.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item.id] != undefined ? respostas[item.id] : '-', item.correta, 'c') }}>
                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                <p style={{ color: 'black', fontWeight: 'normal' }}>c)</p>
                                            </div>
                                            <div style={{ width: '90%' }}>

                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[2].name }}>

                                                </div>
                                            </div>

                                        </div>}
                                        {item.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item.id] != undefined ? respostas[item.id] : '-', item.correta, 'd') }}>
                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                <p style={{ color: 'black', fontWeight: 'normal' }}>d)</p>
                                            </div>
                                            <div style={{ width: '90%' }}>

                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[3].name }}>

                                                </div>
                                            </div>

                                        </div>}

                                        {item.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item.id] != undefined ? respostas[item.id] : '-', item.correta, 'e') }}>
                                            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                <p style={{ color: 'black', fontWeight: 'normal' }}>e)</p>
                                            </div>
                                            <div style={{ width: '90%' }}>

                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[4].name }}>

                                                </div>
                                            </div>

                                        </div>}
                                    </div>
                                </div>}





                                {item.type_questao == 'conjunto' && <div className="row" style={{ fontWeight: 'normal',margin:0 }}>

                                    <div className="col-12 col-sm-12">


                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                        </div>
                                        {item.questoes.map((item2, id2) => {
                                            number_questao = number_questao + 1;

                                            return (<div key={id2} className="row" style={{ fontWeight: 'normal',margin:0 }}>
                                                <div className="col-2 col-sm-1">
                                                    <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                        <b>{number_questao})</b></h5>
                                                </div>
                                                <div className="col-10 col-sm-11">


                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.name }}>

                                                    </div>
                                                    <p><b>Alternativas:</b></p>
                                                    <p><b>Correta: </b>{item2.correta}</p>
                                                    {!model && <>
                                                    <p><b>Respondida: </b>{respostas[item2.id] != undefined ? respostas[item2.id] : '-'}</p>
                                                    </>}

                                                    <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item2.id] != undefined ? respostas[item2.id] : '-', item2.correta, 'a') }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ color: 'black', fontWeight: 'normal' }}>a)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[0].name }}>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    {item2.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item2.id] != undefined ? respostas[item2.id] : '-', item2.correta, 'b') }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ color: 'black', fontWeight: 'normal' }}>b)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[1].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                    {item2.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item2.id] != undefined ? respostas[item2.id] : '-', item2.correta, 'c') }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ color: 'black', fontWeight: 'normal' }}>c)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[2].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                    {item2.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item2.id] != undefined ? respostas[item2.id] : '-', item2.correta, 'd') }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ color: 'black', fontWeight: 'normal' }}>d)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[3].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}

                                                    {item2.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(respostas[item2.id] != undefined ? respostas[item2.id] : '-', item2.correta, 'e') }}>
                                                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ color: 'black', fontWeight: 'normal' }}>e)</p>
                                                        </div>
                                                        <div style={{ width: '90%' }}>

                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[4].name }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                </div>
                                            </div>)
                                        })}

                                    </div>
                                </div>}
                            </div>

                        )
                    })}


                </div>


            </Modal.Body>
        </Modal>

    );
}

export default ModalRespostas;