// Libraries Imports
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Pagination } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Modal, Form } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt } from "react-icons/fa";
import { BsFillEyeFill, BsGraphUp } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";
import { LuCheckCircle2 } from "react-icons/lu";
import { MdDelete } from "react-icons/md";

// Components Imports
import Search from "../ReusableComponents/Search";

// Utils Imports
import { URL } from "../../variables";
import { get, post } from "../../Services/api";

class TreinamentosInstituicao extends Component {
  state = {
    redirect: false,
    trainings: [],
    loading: true,
    showDeleteConfirmation: false,
    trainingToDelete: "",
    pagination: {
      current_page: 1,
      last_page: 1,
      total: 0,
      per_page: 30,
    },
    errorMsg: "",
    search: "",
    loading_search: false,
    showModal: false,
    selectedTrainingId: null,
    startDate: "",
    endDate: "",
    formData: {
      availableFiscais: [],
      fiscaisVinculados: [],
      selectedFiscais: [],
      searchFiscais: "",
      selectedCheckboxes: {},
    },
    loadingFiscais: false,
    showMessageModal: false,
    messageModalContent: "",
    messageModalTitle: "",
    users: [],
  };

  componentDidMount() {
    this._isMounted = true;
    this.getTrainings();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getTrainings = async (
    page = this.state.pagination.current_page,
    search = this.state.search,
    busca = true
  ) => {
    const { faculdade_id, token } = this.props;
    const { loading_search, loading } = this.state;

    if (busca && !loading_search) {
      this.setState({ loading_search: true });
    } else if (!busca && !loading) {
      this.setState({ loading: true });
    }

    try {
      const response = await get(
        `api/faculdade/exibir_vinculacoes/${faculdade_id}?page=${page}&search=${search}`,
        token
      );

      if (this._isMounted) {
        const trainings = response.data.map((training) => ({
          ...training,
          status: this.calculateTrainingStatus(training),
        }));

        this.setState({
          trainings: trainings,
          loading: false,
          loading_search: false,
          pagination: {
            current_page: response.pagination.current_page,
            last_page: response.pagination.last_page,
            total: response.pagination.total,
            per_page: response.pagination.per_page,
          },
        });
      }
    } catch (error) {
      console.error(error);
      if (this._isMounted) {
        this.setState({ redirect: true });
      }
    }
  };

  calculateTrainingStatus = (training) => {
    let status = "Aguardando";

    if (training.data_inicial && training.data_final) {
      const now = new Date();
      const startDate = new Date(training.data_inicial);
      const endDate = new Date(training.data_final);

      if (now >= startDate && now <= endDate) {
        status = "Em andamento";
      } else if (now > endDate) {
        status = "Concluído";
      }
    }

    return status;
  };

  handleConfirm = async (trainingToDelete) => {
    try {
      await this.unassignTraining(trainingToDelete);
      await this.handleDeleteTraining(trainingToDelete);
    } catch (error) {
      console.error("Erro ao executar operações:", error.message);
    }
  };

  unassignTraining = async (trainingId) => {
    const { token } = this.props;

    try {
      const response = await fetch(
        `${URL}api/unassign_training/${trainingId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Erro ao desvincular treinamento");
      }

      return await response.json();
    } catch (error) {
      console.error("Erro ao desvincular treinamento:", error.message);
      throw error;
    }
  };

  removeUserFromTraining = async (trainingId, userIds) => {
    const { token } = this.props;
    try {
      const response = await post(
        `api/remove_user_from_training/${trainingId}`,
        {
          training_id: trainingId,
          user_ids: userIds,
        },
        token
      );

      this.setState({
        showMessageModal: true,
        messageModalTitle: "Sucesso!",
        messageModalContent: "Fiscal desvinculado com sucesso!",
      });

      return response;
    } catch (error) {
      console.error("Erro ao remover usuário do treinamento:", error.message);
      this.setState({
        showMessageModal: true,
        messageModalTitle: "Erro!",
        messageModalContent: "Erro ao remover usuário do treinamento!",
      });
      throw error;
    }
  };

  handleDeleteTraining = async (trainingId) => {
    const { faculdade_id, token } = this.props;

    try {
      const response = await post(
        `api/faculdade/desvincular_treinamento`,
        {
          faculdade_id: faculdade_id,
          treinamento_id: trainingId,
        },
        token
      );

      if (response.error) {
        this.setState({
          errorMsg: response.error,
          showDeleteConfirmation: false,
        });
      } else {
        this.setState({ showDeleteConfirmation: false });
        this.getTrainings();
      }
    } catch (error) {
      console.error(error);
      if (this._isMounted) {
        this.setState({ redirect: true });
      }
    }
  };

  getFiscais = async (faculdadeId) => {
    try {
      const data = await get(
        `api/faculdade/get_fiscais/${faculdadeId}`,
        this.props.token
      );

      const fiscais = data.fiscais || [];

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          availableFiscais: fiscais.map(({ id, name }) => ({ id, name })),
        },
        loadingFiscais: false,
      }));
    } catch (error) {
      this.handleError("Erro ao buscar fiscais", error);
    }
  };

  handleError = (message, error) => {
    console.error(`${message}:`, error.message);
    this.setState({ loadingFiscais: false });
  };

  getEditFiscais = async (faculdadeId) => {
    try {
      const data = await get(
        `api/faculdade/get_fiscais/${faculdadeId}`,
        this.props.token
      );

      this.setState((prevState) => ({
        editFormData: {
          ...prevState.editFormData,
          availableFiscais:
            data.fiscais?.map((fiscal) => ({
              id: fiscal.id,
              name: fiscal.name,
            })) ?? [],
        },
      }));
    } catch (error) {
      console.error("Erro ao buscar fiscais:", error.message);
    }
  };

  updateTraining = async () => {
    const { selectedTrainingId, formData, startDate, endDate } = this.state;

    if (formData.fiscaisVinculados.length === 0) {
      this.setState({
        errorMsg: "Por favor, selecione pelo menos um fiscal.",
        showMessageModal: true,
        messageModalTitle: "Erro!",
        messageModalContent: "Por favor, selecione pelo menos um fiscal.",
      });
      return;
    }

    try {
      const response = await post(
        `api/update_training/${selectedTrainingId}`,
        {
          users: formData.fiscaisVinculados,
          data_inicial: startDate,
          data_final: endDate,
        },
        this.props.token
      );

      if (response.error) {
        throw new Error("Erro ao atualizar o treinamento");
      }

      this.setState({
        errorMsg: "",
        showMessageModal: true,
        messageModalTitle: "Sucesso!",
        messageModalContent: "Fiscal vinculado com sucesso!",
      });
      this.hideModal();
      this.getTrainings(this.state.pagination.current_page);
    } catch (error) {
      console.error(error.message || "Erro ao atualizar o treinamento");
      this.setState({
        errorMsg: error.message || "Erro ao atualizar o treinamento",
        showMessageModal: true,
        messageModalTitle: "Erro!",
        messageModalContent: error.message || "Erro ao atualizar o treinamento",
      });
    }
  };

  getUsersByTraining = async () => {
    const { selectedTrainingId } = this.state;

    this.setState({ loadingUsers: true });

    try {
      const data = await get(
        `api/treinamento/fiscal/${selectedTrainingId}`,
        this.props.token
      );

      this.setState({ users: data.users, loadingUsers: false });

      return data.users;
    } catch (error) {
      console.error("Erro ao buscar usuários por treinamento:", error.message);
      this.setState({ loadingUsers: false });
      throw error;
    }
  };

  updateEditTraining = async () => {
    const { selectedTrainingId, editFormData, token } = this.state;

    try {
      const response = await post(
        `api/update_training/${selectedTrainingId}`,
        {
          users: editFormData.selectedFiscais,
        },
        token
      );

      if (response.error) {
        throw new Error("Erro ao atualizar o treinamento");
      }

      this.setState({
        errorMsg: "",
        showMessageModal: true,
        messageModalTitle: "Sucesso!",
        messageModalContent: "Fiscal vinculado com sucesso!",
      });
      this.handleClose();
      this.getTrainings(this.state.pagination.current_page);
    } catch (error) {
      console.error(error.message || "Erro ao atualizar o treinamento");
      this.setState({
        errorMsg: error.message || "Erro ao atualizar o treinamento",
        showMessageModal: true,
        messageModalTitle: "Erro!",
        messageModalContent: error.message || "Erro ao atualizar o treinamento",
      });
    }
  };

  handleSearchChange = (value) => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState(
        {
          search: value,
          pagination: { ...this.state.pagination, current_page: 1 },
          loading_search: true,
        },
        this.getTrainings
      );
    }, 500);
  };

  handlePageChange = (event, value) => {
    this.setState(
      { pagination: { ...this.state.pagination, current_page: value } },
      this.getTrainings
    );
  };

  handleShowModal = async (trainingId) => {
    try {
      this.setState({
        showModal: true,
        loadingFiscais: true,
        selectedTrainingId: trainingId,
        startDate: "",
        endDate: "",
      });

      const { trainings } = this.state;
      const selectedTraining = trainings.find(
        (training) => training.id === trainingId
      );
      if (selectedTraining) {
        this.setState({
          startDate: selectedTraining.data_inicial || "",
          endDate: selectedTraining.data_final || "",
        });
      }

      await this.getFiscais(this.props.faculdade_id);

      const users = await this.getUsersByTraining();

      const selectedCheckboxes = {};
      users.forEach((user) => {
        selectedCheckboxes[user.id] = true;
      });

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          selectedCheckboxes,
          fiscaisVinculados: users.map((user) => user.id),
        },
        loadingFiscais: false,
      }));
    } catch (error) {
      console.error("Erro ao carregar os dados do modal:", error);

      this.setState({ loadingFiscais: false });
    }
  };

  hideModal = () => {
    this.setState({
      showModal: false,
      selectedTrainingId: null,
      startDate: "",
      endDate: "",
      formData: {
        availableFiscais: [],
        fiscaisVinculados: [],
        selectedFiscais: [],
        searchFiscais: "",
        selectedCheckboxes: {},
      },
    });
  };

  hideMessageModal = () => {
    this.setState({
      showMessageModal: false,
      messageModalTitle: "",
      messageModalContent: "",
    });
  };

  handleStartDateChange = (e) => {
    this.setState({ startDate: e.target.value });
  };

  handleEndDateChange = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleFiscalSelection = (fiscalId) => {
    this.setState((prevState) => {
      const selectedCheckboxes = { ...prevState.formData.selectedCheckboxes };
      if (selectedCheckboxes[fiscalId]) {
        delete selectedCheckboxes[fiscalId];
      } else {
        selectedCheckboxes[fiscalId] = true;
      }

      const selectedFiscais = Object.keys(selectedCheckboxes);

      return {
        formData: {
          ...prevState.formData,
          selectedCheckboxes,
          selectedFiscais,
          fiscaisVinculados: selectedFiscais,
        },
      };
    });
  };

  handleSearchFiscaisChange = (value) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        searchFiscais: value,
      },
    }));
  };

  handleSelectAllFiscais = () => {
    const { availableFiscais } = this.state.formData;
    const allFiscalIds = availableFiscais.map((fiscal) => fiscal.id);
    const selectedCheckboxes = {};

    allFiscalIds.forEach((id) => {
      selectedCheckboxes[id] = true;
    });

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        selectedCheckboxes,
        selectedFiscais: allFiscalIds,
        fiscaisVinculados: allFiscalIds,
      },
    }));
  };

  renderTrainingsTable = () => {
    const { trainings, loading_search, loading } = this.state;

    if (loading || loading_search) {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <table id="tech-companies-1" className="table table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {!loading_search &&
                trainings.map((training, index) => (
                  <tr key={index}>
                    <td>{training.nome}</td>
                    <td>{training.descricao}</td>
                    <td>{training.status}</td>
                    <td>
                      <Link
                        to={`/treinamento/dashboard/${training.id}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <span
                          className="inline-flex align-center jcc bg-success icon"
                          style={{
                            width: "2em",
                            height: "2em",
                            borderRadius: "7px",
                            marginRight: "2px",
                          }}
                          title="Gerenciar dashboard"
                        >
                          <BsGraphUp size={17} color="white" />
                        </span>
                      </Link>
                      <span
                        onClick={() => this.handleShowModal(training.id)}
                        className="inline-flex align-items-center justify-content-center bg-warning icon"
                        style={{
                          width: "2em",
                          height: "2em",
                          borderRadius: "7px",
                          marginRight: "2px",
                        }}
                        title="Gerenciar"
                      >
                        <BsFillEyeFill size={17} color="white" />
                      </span>
                      <span
                        onClick={() =>
                          this.setState({
                            showDeleteConfirmation: true,
                            trainingToDelete: training.id,
                          })
                        }
                        className="inline-flex align-items-center justify-content-center bg-danger icon"
                        style={{
                          width: "2em",
                          height: "2em",
                          borderRadius: "7px",
                        }}
                        title="Deletar treinamento"
                      >
                        <MdDelete size={17} color="white" />
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  render() {
    const {
      redirect,
      showDeleteConfirmation,
      trainingToDelete,
      errorMsg,
      pagination,
      showModal,
      startDate,
      endDate,
      formData,
      loadingFiscais,
      showMessageModal,
      messageModalTitle,
      messageModalContent,
    } = this.state;
    const { user, faculdade_name, faculdade_id } = this.props;

    if (redirect) {
      return <Redirect to="/" />;
    }

    const filteredFiscais = formData.availableFiscais.filter(
      (fiscal) =>
        !formData.searchFiscais ||
        (fiscal.name &&
          fiscal.name
            .toLowerCase()
            .includes(formData.searchFiscais.toLowerCase()))
    );

    return (
      <div className="row">
        <div className="col-12">
          <p className="text-muted">
            Aqui ficam{" "}
            {user.empresa == null || user.empresa.change_text == false
              ? "os treinamentos da instituição"
              : "as vagas/cargos do processo"}{" "}
            "{faculdade_name}"
          </p>
          <div className="flex jcsb flex-wrap mb-4">
            <Search
              placeholder="Pesquisar pelo nome do treinamento"
              ValueChange={this.handleSearchChange}
            />
            <Link
              to={`/instituicao/link/${faculdade_id}`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="success" className="flex align-center jcc">
                <FaListAlt style={{ marginRight: 10 }} />
                <p style={{ margin: 0 }}>Adicionar treinamento</p>
              </Button>
            </Link>
          </div>
          <div className="table-rep-plugin">{this.renderTrainingsTable()}</div>
          <Pagination
            className={pagination.last_page === 1 ? "hide" : ""}
            style={{ display: "flex", justifyContent: "flex-end" }}
            count={pagination.last_page}
            page={pagination.current_page}
            onChange={this.handlePageChange}
          />

          <SweetAlert
            warning
            title={"Atenção"}
            show={showDeleteConfirmation}
            onConfirm={() => this.handleConfirm(trainingToDelete)}
            onCancel={() => this.setState({ showDeleteConfirmation: false })}
            confirmBtnText="Sim, desejo desvincular"
            cancelBtnText="Cancelar"
          >
            {errorMsg
              ? errorMsg
              : "Ao desvincular este treinamento, ele não estará mais disponível na instituição"}
          </SweetAlert>

          <Modal show={showModal} onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Gerenciar Treinamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loadingFiscais ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <Form>
                  <Form.Group controlId="formStartDate" className="mb-3">
                    <Form.Label>Data de Início</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={this.handleStartDateChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formEndDate" className="mb-3">
                    <Form.Label>Data de Finalização</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={this.handleEndDateChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formFiscais" className="mb-3">
                    <Form.Label>Selecionar Fiscais</Form.Label>
                    <Search
                      placeholder="Pesquisar fiscais"
                      ValueChange={this.handleSearchFiscaisChange}
                    />
                    <Button
                      variant="secondary"
                      onClick={this.handleSelectAllFiscais}
                      className="mt-2 mb-2"
                    >
                      Selecionar Todos
                    </Button>
                    <div className="mt-2">
                      {filteredFiscais.map((fiscal) => (
                        <Form.Check
                          key={fiscal.id}
                          type="checkbox"
                          label={fiscal.name}
                          checked={!!formData.selectedCheckboxes[fiscal.id]}
                          onChange={() => this.handleFiscalSelection(fiscal.id)}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.hideModal}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={this.updateTraining}>
                Salvar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showMessageModal} onHide={this.hideMessageModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {messageModalTitle === "Erro!" && (
                  <RiErrorWarningLine
                    style={{
                      marginRight: "8px",
                      marginBottom: "5px",
                      color: "red",
                    }}
                  />
                )}
                {messageModalTitle === "Sucesso!" && (
                  <LuCheckCircle2
                    style={{
                      marginRight: "8px",
                      marginBottom: "5px",
                      color: "#198754",
                    }}
                  />
                )}
                {messageModalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{messageModalContent}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.hideMessageModal}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(TreinamentosInstituicao);
