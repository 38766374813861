import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaArrowLeft, FaArrowRight, FaListAlt, FaVideo } from "react-icons/fa";
import { MdArrowLeft, MdArrowRight, MdArrowRightAlt, MdDelete, MdModeEdit, MdPlayArrow } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";



const Screens = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_videos, setLoadingvideos] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [videos, setvideos] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });


    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(1);

    const [search, setSearch] = useState('');
    const { candidato_id, candidato_name, processo_id } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [video_id, setvideoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getvideos = async (page, search,last=false) => {

        let response = await Request.Get(`faculdade/get_screens_candidato/${processo_id}/${candidato_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            if (response.videos.length > 0) {
                if(last==true){
                    setVideoSelect(response.videos[response.videos.length-1]);

                }
                else{
                    setVideoSelect(response.videos[0]);

                }
            }
            else{
                setVideoSelect(null); 
            }
            setvideos(response.videos);

            // setPagination({...response.pagination});
            setCurrentPage(response.pagination.current_page);
            setLastPage(response.pagination.last_page);

            setLoadingScreen(false);
            setLoadingvideos(false);


        }
    }

    const [video_select, setVideoSelect] = useState(null);

    const Deletevideo = async (video_id) => {

        let response = await Request.Delete(`faculdade/delete_video/${video_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getvideos(current_page, search_oficial);



    }

    useEffect(() => {
        Getvideos(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam os videos da tela do candidato "{candidato_name}"</p>
                    {video_select != null && <div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {!video_select.url.includes('video_not_found') && <video  controls height="300" src={video_select.url}></video>}
                            {video_select.url.includes('video_not_found') && <video  controls height="300" poster={video_select.url}></video>}

                        </div>
                        <p style={{textAlign:'center',marginBottom:0,marginTop:15}}>{video_select.path.split('/')[video_select.path.split('/').length - 1]}</p>
                        <br />
                        <div className="flex jcsb flex-wrap mb-4">
                            <Button variant="success" onClick={() => {
                                if (videos[0].id == video_select.id & current_page !=1) {
                                    // let page = { ...pagination };
                                    // page.current_page += 1;
                                    let current=current_page;
                                    setCurrentPage(current_page-1);
                                    setLoadingvideos(true);

                                    Getvideos(current-1, search_oficial,true)
                                }
                                else if(videos[0].id != video_select.id){
                                    for(let i=0;i<videos.length;i++){
                                        if(video_select.id==videos[i].id){
                                            setVideoSelect(videos[i-1]);
                                            break;
                                        }
                                    }
                                }
                            }} disabled={(videos[0]==undefined || (videos[0].id == video_select.id && current_page == 1)) || loading_videos} className="flex align-center jcc"><FaArrowLeft style={{ marginRight: 10, fontSize: 16 }} /> <p style={{ margin: 0 }}>Anterior</p></Button>

                            <Button variant="success" onClick={() => {
                                if (videos[videos.length - 1].id == video_select.id & current_page < last_page) {
                                    // let page = { ...pagination };
                                    // page.current_page += 1;
                                    let current=current_page;
                                    setCurrentPage(current_page+1);
                                    setLoadingvideos(true);

                                    Getvideos(current+1, search_oficial)
                                }
                                else if(videos[videos.length - 1].id != video_select.id){
                                    for(let i=0;i<videos.length;i++){
                                        if(video_select.id==videos[i].id){
                                            setVideoSelect(videos[i+1]);
                                            break;
                                        }
                                    }
                                }
                            }} disabled={(videos[videos.length - 1]==undefined || (videos[videos.length - 1].id == video_select.id && current_page == last_page)) || loading_videos} className="flex align-center jcc"><p style={{ margin: 0 }}>Próximo</p> <FaArrowRight style={{ marginLeft: 10, fontSize: 16 }} /></Button>

                        </div>

                        <hr />
                    </div>}
                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo nome do video" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                // let page = { ...pagination };
                                // page.current_page = 1;
                                // setPagination(page);
                                setCurrentPage(1);
                                setLoadingvideos(true);

                                Getvideos(1, value)

                            }, 500);
                        }} />


                        {/* <Link to={{
                                    pathname: "/videos/register/" + candidato_id,
                                    // state: {
                                    //     course_id: id
                                    // }
                                }} style={{ textDecoration: "none" }}>
                                    <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar video</p></Button>
                                </Link> */}
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>Data</th>
                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_videos == false && videos.map((item, id) => (

                                        <tr style={{ backgroundColor: (video_select != null && video_select.id == item.id) ? '#4BB543' : '' }} key={id}>
                                            <td>{item.path.split('/')[item.path.split('/').length - 1]}</td>
                                            <td>{moment(item.date).format('DD/MM/YYYY HH:mm:ss')}</td>

                                            <td>
                                                <span
                                                    onClick={() => {
                                                        setVideoSelect(item);
                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Gerenciar video"
                                                >
                                                    <MdPlayArrow size={17} color="white" />
                                                </span>
                                                {/* <Link to={`/videos/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={(e) => {
                                                        setvideoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar video"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_videos == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_videos == false && videos.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum video de tela encontrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={last_page}
                        // value={pagination.current_page}
                        page={current_page}
                        onChange={(_, value) => {
                            setLoadingvideos(true);
                            console.log(_, value);
                            console.log(pagination);
                            setCurrentPage(value);
                            // let page = { ...pagination };
                            // page.current_page = value;
                            // setPagination(page);
                            Getvideos(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_video={this.get_video.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingvideos(true);
                        Deletevideo(video_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar este video, ele não estará mais disponível no sistema
                </SweetAlert>


            </div>

        </div>
    )
}

export default Screens;
