
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import ModalQuestao from './ModalQuestaoQuestionario';
import ModalCadastroQuestaoQuestionario from './ModalCadastroQuestaoQuestionario';
import { BsFillEyeFill } from 'react-icons/bs';
import { FaListAlt, FaQuestion, FaQuestionCircle } from 'react-icons/fa';
import './style.css';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { isUuid } from 'uuidv4';
import VerQuestionario from './VerQuestionario';



class EditarQuestionario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/questionarios/',
            loading_screen: false,
            enunciado: '',
            alternativa_a: '',
            alternativa_b: '',
            alternativa_c: '',
            alternativa_d: '',
            alternativa_e: '',
            loading_save: false,
            msgErro: '',
            loading_image_enunciado: false,
            loading_image_a: false,
            loading_image_b: false,
            loading_image_c: false,
            loading_image_d: false,
            loading_image_e: false,
           

            categoria: { name: '' },
            type_questao: 'simples',
            questoes: [],
            show_modal_cadastro: false,
            showModal: false,
            questaoSelect: null,
            showModalQuestionario:false,
            questionarioSelect:{}

        };

        this.load_configs();


    }


    get_questionario(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_questionario/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false,
                        enunciado: resp.questionario.name,
                        questoes: resp.questionario.questoes,
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    load_configs() {
        this.config_enunciado = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_enunciado,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_enunciado: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_enunciado: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_enunciado: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let data = {}
        data.titulo = this.state.enunciado;
        data.questionario_id = this.props.match.params.id;

        data.questoes=this.state.questoes.map((item,id)=>{
            let item2={...item};
            if(isUuid(item2.id)){
                delete item2.id;
            }
            return item2;
        });


        fetch(`${URL}api/faculdade/update_questionario`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false,redirect:true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_questionario(this.props.match.params.id);
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    renderTypeQuestao(e) {
        if (e == 'multipla_escolha') {
            return 'Escolha múltipla';
        }
        if (e == 'paragrafo') {
            return 'Parágrafo';
        }
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <div hidden id="text_enunciado">

                            </div>

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Questionário</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um questionário do sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} />

                                <ModalCadastroQuestaoQuestionario
                                    salvarQuestao={(e) => {
                                        let questoes = [...this.state.questoes];
                                        questoes.push(e);
                                        this.setState({ questoes, show_modal_cadastro: false });
                                    }}
                                    setShowModal={(e) => this.setState({ show_modal_cadastro: e })} showModal={this.state.show_modal_cadastro} categoria={this.state.categoria} />
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>




                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label >Título do questionário <Attention /> {this.state.loading_image_enunciado == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                            </div>}</Form.Label>
                                            <Input
                                                placeholder="Título do questionário"
                                                value={this.state.enunciado}
                                                onChange={(e) => this.setState({ enunciado: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                            {/* <JoditEditor
                                                ref={this.editor_enunciado}
                                                value={this.state.enunciado}
                                                config={this.config_enunciado}
                                                
                                                height={400}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => this.setState({ enunciado: newContent })} // preferred to use only this option to update the content for performance reasons
                                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                            /> */}
                                        </div>
                                    </InputGroup>
                                    <VerQuestionario showModal={this.state.showModalQuestionario} setShowModal={(e) => this.setState({ showModalQuestionario: e })} questionario={this.state.questionarioSelect} />

                                    {<div>
                                        <br />
                                        <InputGroup>
                                            <div className="flex justify-content-between flex-wrap mb-4" style={{ width: '100%' }}>

                                                <Button 
                                            disabled={this.state.questoes.length==0}
                                                
                                                onClick={() => {
                                                    this.setState({ showModalQuestionario: true,questionarioSelect:{name:this.state.enunciado,questoes:this.state.questoes} });
                                                }} variant="info" className="flex align-center jcc"><FaQuestionCircle style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Ver questionário</p></Button>

                                                <Button onClick={() => {
                                                    this.setState({ show_modal_cadastro: true });
                                                }} variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar pergunta</p></Button>
                                            </div>
                                        </InputGroup></div>}
                                    {<InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom col-12">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Nº</th>

                                                                <th>Enunciado</th>
                                                                {/* <th>Categoria</th> */}
                                                                <th>Tipo de pergunta</th>

                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.questoes.map((item, id) => (
                                                                <tr key={id}>
                                                                    <td >{id+1})</td>

                                                                    <td ><p className='text_overflow'>{item.text_questao}</p></td>
                                                                    {/* <td >{item.categoria.name}</td> */}

                                                                    <td >{this.renderTypeQuestao(item.type_questao)}</td>
                                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                                                    <td style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                                        <div style={{ display: 'grid',marginRight:5 }}>
                                                                            <IconButton disabled={id == 0} size="small" style={{ padding: 0 }} onClick={() => {
                                                                                let questoes = [...this.state.questoes];
                                                                                const element = questoes.splice(id, 1)[0];
                                                                                if (id - 1 < 0) {
                                                                                    return;
                                                                                }
                                                                                questoes.splice(id - 1, 0, element);
                                                                                this.setState({ questoes });
                                                                            }}>
                                                                                <ArrowDropUp style={{ borderRadius: 100 }} />
                                                                            </IconButton>
                                                                            <IconButton disabled={id == this.state.questoes.length - 1} size="small" style={{ padding: 0 }} onClick={() => {
                                                                                // let alternativas_inputs = this.state.alternativas_inputs.filter((item2, id2) => item2.id != item.id);
                                                                                // this.setState({ alternativas_inputs });
                                                                                let questoes = [...this.state.questoes];
                                                                                const element = questoes.splice(id, 1)[0];
                                                                                if (id + 1 >= this.state.questoes.length) {
                                                                                    return;
                                                                                }
                                                                                questoes.splice(id + 1, 0, element);
                                                                                this.setState({ questoes });
                                                                            }}>
                                                                                <ArrowDropDown style={{ borderRadius: 100 }} />
                                                                            </IconButton>
                                                                        </div>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ questaoSelect: { ...item }, showModal: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px",marginRight:5 }}
                                                                            title="Gerenciar pergunta"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let questoes = this.state.questoes.filter((item2, id2) => id != id2);
                                                                                this.setState({ questoes });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px",marginRight:5 }}
                                                                            title="Deletar pergunta"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {this.state.questoes.length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhuma pergunta adicionada</p>

                                                </div>
                                            </div>}
                                        </div>

                                    </InputGroup>}
                                    <br />
                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to={"/questionarios"}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar questionário"
                                            disabled={this.state.loading_image_enunciado == true || this.state.loading_image_a || this.state.loading_image_b || this.state.loading_image_c || this.state.loading_image_d || this.state.loading_image_e}
                                            loadingtext="Salvando questionário"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarQuestionario);


