// Library Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Button, Card } from "react-bootstrap";
import JoditEditor from "jodit-react";
import CircularProgress from "@material-ui/core/CircularProgress";

// Utils Imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post, put } from "../../Services/api";
import removeHtmlTags from "../../Auxiliar/removeHtmlTags";

// Loading Skeleton Component
class LoadingSkeleton extends Component {
  render() {
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress />
      </div>
    );
  }
}

// Success Modal Component
class SuccessModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Questão avaliada com sucesso!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

// Edit Modal Component
class EditModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JoditEditor
            value={this.props.value}
            onChange={this.props.onChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={this.props.onSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class ShowAvaliarQuestoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questao: {
        name: "",
        alternativas: [],
        user: {},
      },
      demanda: {},
      comentarios: [],
      novoComentario: "",
      status: "",
      isLoading: true,
      isCommentsLoading: true,
      showSuccessModal: false,
      showEditQuestaoModal: false,
      showEditAlternativaModal: false,
      currentAlternativaIndex: null,
      currentAlternativaValue: "",
    };
  }

  componentDidMount() {
    this.fetchQuestao();
    this.fetchComentarios();
  }

  fetchQuestao = async () => {
    try {
      const { id } = this.props.match.params;
      const resp = await get(
        `api/faculdade/get_questao_review/${id}`,
        this.props.token
      );
      this.setState({
        questao: resp.questao || {},
        demanda: resp.demanda || {},
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  fetchComentarios = async () => {
    try {
      const { id } = this.props.match.params;
      const res = await get(`api/get_comentario/${id}`, this.props.token);
      const comentarios = res.map((item) => ({
        comentario: item.comentario,
        user_name: item.user_name,
      }));
      this.setState({ comentarios, isCommentsLoading: false });
    } catch (error) {
      this.setState({ isCommentsLoading: false });
    }
  };

  editStatusQuestao = async (status) => {
    try {
      const { id } = this.props.match.params;
      await post(
        `api/faculdade/update_status_questao/${id}`,
        { status },
        this.props.token
      );
      this.setState({ showSuccessModal: true });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  editQuestao = async (questao) => {
    try {
      const { id } = this.props.match.params;
      await put(
        `api/faculdade/update_questao/${id}`,
        {
          name: questao.name,
          text_questao: removeHtmlTags(questao.name),
          alternativas: questao.alternativas,
        },
        this.props.token
      );
      this.setState({ showSuccessModal: true });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleAddComentario = async () => {
    try {
      const { id } = this.props.match.params;
      await post(
        "api/store_comentario",
        {
          questao_id: id,
          comentario: this.state.novoComentario,
        },
        this.props.token
      );
      const newComentarios = [
        ...this.state.comentarios,
        {
          comentario: this.state.novoComentario,
          user_name: this.props.user.name,
        },
      ];
      this.setState({ comentarios: newComentarios, novoComentario: "" });
    } catch (error) {}
  };

  handleStatusChange = (e) => this.setState({ status: e.target.value });

  handleSubmitStatus = () => this.editStatusQuestao(this.state.status);

  handleNovoComentarioChange = (e) =>
    this.setState({ novoComentario: e.target.value });

  handleQuestaoChange = (value) =>
    this.setState({ questao: { ...this.state.questao, name: value } });

  handleAlternativaChange = (index, value) => {
    const alternativas = [...this.state.questao.alternativas];
    alternativas[index].name = value;
    this.setState({ questao: { ...this.state.questao, alternativas } });
  };

  handleSaveChanges = async () => {
    try {
      await this.editQuestao(this.state.questao);
    } catch (error) {}
  };

  toggleEditQuestaoModal = () =>
    this.setState({ showEditQuestaoModal: !this.state.showEditQuestaoModal });

  toggleEditAlternativaModal = (index) => {
    this.setState({
      showEditAlternativaModal: !this.state.showEditAlternativaModal,
      currentAlternativaIndex: index,
      currentAlternativaValue:
        this.state.questao.alternativas[index]?.name || "",
    });
  };

  handleAlternativaEditorChange = (value) =>
    this.setState({ currentAlternativaValue: value });

  handleSaveAlternativa = () => {
    this.handleAlternativaChange(
      this.state.currentAlternativaIndex,
      this.state.currentAlternativaValue
    );
    this.toggleEditAlternativaModal(null);
  };

  handleBackButtonClick = () => this.props.history.goBack();

  letras = ["a", "b", "c", "d", "e"];

  render() {
    if (this.state.isLoading) {
      return <LoadingSkeleton />;
    }

    const editorConfig = {
      readonly: true,
      toolbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
    };

    return (
      <div className="container mt-4">
        <div className="d-flex justify-content-between mb-2">
          <h1 className="mb-4">Tela de Avaliação</h1>
          <Button
            className="mb-4"
            variant="secondary"
            onClick={this.handleBackButtonClick}
          >
            Voltar
          </Button>
        </div>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Dados da Questão:</Card.Title>
            <br />
            {this.state.questao.user && (
              <p>
                <strong>Nome do Criador:</strong> {this.state.questao.user.name}
              </p>
            )}
            <p>
              <strong>Data de Criação:</strong>{" "}
              {formatDate(this.state.questao.created_at)}
            </p>
            <p>
              <strong>Demanda Relacionada:</strong> {this.state.demanda.name}
            </p>
            <p>
              <strong>Nível de Dificuldade:</strong>{" "}
              {this.state.questao.dificuldade}
            </p>
            <p>
              <strong>Escolaridade:</strong> {this.state.questao.escolaridade}
            </p>
            <p>
              <strong>Palavras-Chaves: </strong>
              {this.state.questao.keywords &&
              this.state.questao.keywords.length > 0 ? (
                this.state.questao.keywords
              ) : (
                <span>Nenhuma palavra-chave vinculada</span>
              )}
            </p>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title className="text-center">
              Enunciado da Questão
            </Card.Title>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="flex-grow-1 mr-3">
                <JoditEditor
                  value={this.state.questao.name}
                  config={editorConfig}
                />
              </div>
              <Button variant="secondary" onClick={this.toggleEditQuestaoModal}>
                Editar
              </Button>
            </div>
            <ul className="list-group mb-3">
              {this.state.questao.alternativas &&
                this.state.questao.alternativas.map((alternativa, index) => (
                  <li key={index} className="list-group-item">
                    <Card.Title className="text-center">
                      Alternativas
                    </Card.Title>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="mr-3">
                        <strong>
                          {this.letras[index]})&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </strong>{" "}
                      </div>
                      <div className="flex-grow-1 mr-3">
                        <JoditEditor
                          value={alternativa.name}
                          config={editorConfig}
                        />
                      </div>
                      <Button
                        variant="secondary"
                        onClick={() => this.toggleEditAlternativaModal(index)}
                      >
                        Editar
                      </Button>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="text-center">
              <Button variant="success" onClick={this.handleSaveChanges}>
                Salvar Alterações
              </Button>
            </div>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Resposta Correta</Card.Title>
            {this.state.questao.correta ? (
              <p>{this.state.questao.correta})</p>
            ) : (
              <p>Nenhuma questão correta encontrada.</p>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title className="fw-bold fs-4">Comentários</Card.Title>
            <div className="mb-3">
              {this.state.isCommentsLoading ? (
                <p className="text-muted">Carregando comentários...</p>
              ) : (
                <ul className="list-group">
                  {this.state.comentarios.map((comentario, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex align-items-center"
                    >
                      <strong>{comentario.user_name}:</strong>
                      <span className="ms-2">{comentario.comentario}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <h3 className="mt-4">Adicionar Comentário</h3>
              <textarea
                className="form-control mb-2"
                value={this.state.novoComentario}
                onChange={this.handleNovoComentarioChange}
                placeholder="Escreva seu comentário aqui..."
              />
              <Button
                variant="secondary"
                className="mt-2"
                onClick={this.handleAddComentario}
              >
                Adicionar
              </Button>
            </div>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Avaliar</Card.Title>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  value="aprovado"
                  checked={this.state.status === "aprovado"}
                  onChange={this.handleStatusChange}
                />
                Aprovar
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  value="recusado"
                  checked={this.state.status === "recusado"}
                  onChange={this.handleStatusChange}
                />
                Desaprovar
              </label>
            </div>
            <Button
              variant="success"
              className="mt-2"
              onClick={this.handleSubmitStatus}
            >
              Enviar Avaliação
            </Button>
          </Card.Body>
        </Card>

        <SuccessModal
          show={this.state.showSuccessModal}
          handleClose={() => this.setState({ showSuccessModal: false })}
        />

        <EditModal
          show={this.state.showEditQuestaoModal}
          handleClose={this.toggleEditQuestaoModal}
          title="Editar Enunciado da Questão"
          value={this.state.questao.name}
          onChange={this.handleQuestaoChange}
          onSave={this.toggleEditQuestaoModal}
        />

        <EditModal
          show={this.state.showEditAlternativaModal}
          handleClose={this.toggleEditAlternativaModal}
          title="Editar Alternativa"
          value={this.state.currentAlternativaValue}
          onChange={this.handleAlternativaEditorChange}
          onSave={this.handleSaveAlternativa}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(
  withRouter(ShowAvaliarQuestoes)
);
