import JoditEditor from "jodit-react";
import React, { Component, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { API_URL, STORAGE_URL, URL } from "../../variables";
import { Form, FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";
import Btn from '@mui/material/Button';
import Input from '@mui/material/Input';
import { Checkbox, CircularProgress, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { uuid } from 'uuidv4';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import DragDropTable from './DragDropTable';
import { RiCloseFill } from "react-icons/ri";
import { MdSearch } from "react-icons/md";
import { logout } from '../../actions/AppActions';
import { connect } from "react-redux";
import ModalQuestao from "../CategoriasQuestoes/ModalQuestao";


class ModalPalavraChave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            keyword: '',
            type_questao: 'multipla_escolha',
            questoes: [],
            questaoSelect: null,
            loading_get_all: false,
            questoes_array: [],
            questoes_selecionadas: {},
            categorias: [],
            categoria: { id: '' },
            msgErro:'',
            showModal:false
        };




    }



    renderTypeQuestao(e) {
        if (e == 'multipla_escolha') {
            return 'Escolha múltipla';
        }
        if (e == 'paragrafo') {
            return 'Parágrafo';
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (Object.values(prevState.questoes_selecionadas).length != Object.values(this.state.questoes_selecionadas).length) {
            this.parseQuestoesDragDrop();
        }
    }

    parseQuestoesDragDrop() {
        let questoes = [...this.state.questoes_array];
        let ids = questoes.map((item, id) => item.id);
        let ids_exists = []

        for (let i = 0; i < Object.values(this.state.questoes_selecionadas).length; i++) {

            let item = Object.values(this.state.questoes_selecionadas)[i];
            ids_exists.push(item.id);

            if (!ids.includes(item.id)) {
                questoes.push({
                    numero: i + 1,
                    id: item.id,
                    enunciado: <p className='text_overflow'>{item.text_questao}</p>,
                    categoria: item.categoria.name,
                    tipo_questao: <p style={{ whiteSpace: 'pre' }}>{item.type_questao == 'conjunto' ? ('Conjunto de questões\n' + item.questoes.length + (item.questoes.length == 1 ? ' questão' : ' questões')) :
                        (item.type_questao == 'simples' ? 'Questão única' : 'Questão dissertativa')}</p>,
                    acoes: i
                });
            }
        }
        questoes = questoes.filter((item, id) => {
            if (ids_exists.includes(item.id)) {
                return true;
            }
            return false;
        })
        this.setState({ questoes_array: questoes });
    }


    get_questoes_palavra_chave() {
        if (this.state.loading_get_all == false) {
            this.setState({ loading_get_all: true });
        }
        fetch(`${URL}api/faculdade/get_questoes_palavra_chave/${this.props.categoria.id}?keyword=${this.state.keyword}&type=${this.props.type}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_get_all: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let questoes = { ...this.state.questoes_selecionadas };
                    for (let i = 0; i < resp.questoes.length; i++) {
                        if (questoes[resp.questoes[i].id] == undefined) {
                            questoes[resp.questoes[i].id] = resp.questoes[i];
                        }
                    }

                    this.setState({ loading_get_all: false, questoes_selecionadas: questoes });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_get_all: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_get_all: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    render() {
        let radio_index = 0;

        return (
            <Modal
                show={this.props.showModal == true}
                dialogClassName="modal_width"
                onHide={() => this.props.setShowModal(false)}
            >
                <Modal.Header closeButton closeLabel=''><Modal.Title>Buscar questões da disciplina {this.props.categoria?.name} por palavra chave<br /></Modal.Title></Modal.Header>
                <Modal.Body>
                    {this.props.categoria != undefined && <div >
                        {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} />

                        <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Palavra-chave <Attention /></Form.Label>
                                <Input
                                    disabled={this.state.loading_get_all}
                                    placeholder="Palavra-chave"
                                    value={this.state.keyword}
                                    onChange={(e) => this.setState({ keyword: e.target.value })}
                                    required
                                    onKeyPress={(e)=>{
                                        if(e.key=='Enter'){
                                            e.preventDefault();
                            this.get_questoes_palavra_chave();

                                        }
                                    }}
                                // title="Coloque o nome completo do usuário"
                                />
                            </div>
                            <div className="margin-bottom" style={{display:'flex',justifyContent:'end',alignItems:'end'}}>
                            <a
                           style={{paddingLeft:10,cursor:'pointer'}}
                           onClick={(e)=>{
                            e.preventDefault();
                            if(this.state.loading_get_all==true){
                                return;
                            }
                            this.get_questoes_palavra_chave();
                           }}
                        >
                           {this.state.loading_get_all==false &&  <MdSearch style={{width:25,height:25}} />}
                            {this.state.loading_get_all==true && <CircularProgress
                    style={{
                        width: "25px",
                        height: "25px",
                        color: 'green'
                    }}
                />}
                        </a>
                        </div>

                            
                        </InputGroup>

                        <Form.Label >Questões selecionadas</Form.Label>
                        <br />
                        <Form.Label >Total: <span style={{ fontWeight: 'normal' }}>{Object.values(this.state.questoes_selecionadas).map((item, id) => {
                            if (item.type_questao == 'conjunto') {
                                return item.questoes.length;
                            }
                            else {
                                return 1;
                            }
                        }).reduce((a, b) => a + b, 0)} {Object.values(this.state.questoes_selecionadas).map((item, id) => {
                            if (item.type_questao == 'conjunto') {
                                return item.questoes.length;
                            }
                            else {
                                return 1;
                            }
                        }).reduce((a, b) => a + b, 0) == 1 ? 'questão' : 'questões'}</span></Form.Label>

                        <InputGroup>
                            <DragDropTable draggable={false} data={this.state.questoes_array}
                                showData={(index) => {
                                    let questao_id = this.state.questoes_array[index].id;
                                    this.setState({ questaoSelect: { ...this.state.questoes_selecionadas[questao_id] }, showModal: true });

                                }}
                                deleteData={(index) => {
                                    let questao_id = this.state.questoes_array[index].id;

                                    let questoes_selecionadas = { ...this.state.questoes_selecionadas };
                                    delete questoes_selecionadas[questao_id];
                                    let questoes_array = this.state.questoes_array.filter((item, id) => item.id != questao_id);
                                    this.setState({ questoes_selecionadas, questoes_array });
                                }}
                                setData={(update_) => {
                                    this.setState({ questoes_array: update_ });
                                }} />



                        </InputGroup>


                    </div>}

                </Modal.Body>

                <Modal.Footer>
                <div className="w100 inline-flex jcsb margin-top-1">
                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
 <DefaultButton
                        bg="secondary"
                        text="Fechar"
                        icon={<RiCloseFill />}
                        onClick={() => {
                            this.props.setShowModal(false)
                            // setShowModal(false);
                            // setSalasSelecionadas({});
                        }}
                    />
                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                        <DefaultButton
                            type="button"
                            onClick={() => {
                               this.props.saveQuestoes({...this.state.questoes_selecionadas});
                               this.setState({questoes_selecionadas:{},questoes_array:[],keyword:''})
                            }}
                            bg="confirm"
                            text="Salvar questões"
                            loadingtext="Salvando questões"
                        />
                    </div>
                </Modal.Footer>

            </Modal>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);
export default connect(mapsStateToProps, { logout })(ModalPalavraChave);