

const LogsDash = props => {
    let processo = props.processo;
    return (
        <>
        <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <table id="tech-companies-1" className="table table-striped" style={{borderRadius:'2rem'}}>
                            <thead>
                                <tr>

                                    <th>Log</th>
                                    <th>Quantidade</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total fiscais</td>
                                    <td>{processo.total_fiscais}</td>
                                </tr>
                                <tr>
                                    <td>Total corretores</td>
                                    <td>{processo.total_corretores}</td>
                                </tr>
                                <tr>
                                    <td>Total candidatos</td>
                                    <td>{processo.total_alunos}</td>
                                </tr>
                                <tr>
                                    <td>Candidatos que fizeram login</td>
                                    <td>{processo.alunos_login}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos que acessaram a tela de termos</td>
                                    <td>{processo.tela_termos}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos que acessaram a tela de avaliação não iniciada</td>
                                    <td>{processo.tela_avaliacao_nao_iniciada}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos que acessaram a tela de aceite de regras</td>
                                    <td>{processo.entraram_alunos_aceite}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos que acessaram a tela de avaliação em andamento</td>
                                    <td>{processo.entraram_alunos_prova}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos que acessaram a tela de avaliação finalizada</td>
                                    <td>{processo.tela_avaliacao_finalizada}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos ausentes</td>
                                    <td>{processo.ausentes_alunos}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos que acessaram e não finalizaram</td>
                                    <td>{processo.nao_finalizaram_alunos}</td>
                                </tr>

                                <tr>
                                    <td>Candidatos que acessaram e finalizaram</td>
                                    <td>{processo.finalizaram_alunos}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        </>
    );


}

export default LogsDash;