
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ModalDemanda from './ModalDemanda';
import { BsFillEyeFill } from 'react-icons/bs';
import ModalDemandaRedacao from './ModalDemandaRedacao';
import { FaListAlt } from 'react-icons/fa';


class CriarAtribuicoesRedacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/demandas',
            loading_screen: false,
            roles: [],
            name: '',
            descricao: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,

            fim: moment().add(1, 'day'),
            hora_inicio: moment(),
            hora_fim: moment().add(1, 'hour'),
            inicio: moment(),
            professors: [],
            professores: [],

            professor_search: '',
            show_autocomplete_professor: false,
            loading_search_professor: false,
            professores_select: [],
            showModal: false,
            professorSelect: null,
            demanda: {},
            demandas_professores: [],
            type_atribuicao:'questao',
            id_select:null
        };


    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });

        // if (Object.values(this.state.professores_select).length == 0) {
        //     this.setState({ loading_save: false, msgErro: 'A demanda deve ter pelo menos uma disciplina selecionada' });
        //     return;
        // }

        fetch(`${URL}api/faculdade/store_atribuicoes_redacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                demanda_id: this.state.demanda.id,
                professores: this.state.professores_select
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_data(this.props.match.params.id);
    }


    get_data(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_data_demanda_redacao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let professores_select=[]
                    for(let i=0;i<resp.demandas_professores.length;i++){
                        let professor=resp.demandas_professores[i].professor;
                        professor.descritivo=resp.demandas_professores[i].descritivo;
                        professor.demanda_id=resp.demandas_professores[i].id;
                        professores_select.push(professor)
                    }
                   
                    this.setState({
                        loading_screen: false, 
                        professores: resp.professores,
                        demanda: resp.demanda,
                        professores_select:professores_select
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    select_professor(item,id) {
        let professores = [ ...this.state.professores_select ];
        if(id!=null){
            professores[id]=item;
        }
        else{
            professores.push(item);
        }
        this.setState({ professor_search: '', professores_select: professores })
    }

    render() {
        console.log(this.state.professores_select)
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={"/demandas/show/" + this.state.demanda.id+'?tab=1'} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Adicionar/Editar Atribuiçõe Redações</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Adicione/Edite atribuições de redações à demanda "{this.state.demanda.name}"</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div style={{display:'flex',justifyContent:'flex-end',marginBottom:10}}>
                                <Button onClick={(e)=>{
                                    e.preventDefault();
                                    this.setState({showModal:true,professorSelect:null,id_select:null})
                                }} variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar atribuição</p></Button>
                                </div>

                                {this.state.showModal == true && <ModalDemandaRedacao professorSelect={this.state.professorSelect} showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} id={this.state.id_select}  professores_all={this.state.professores} OnFinish={(professor,descritivo,id) => {
                                    professor.descritivo = descritivo;

                                    this.select_professor(professor,id);
                                }} />}

                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    {/* <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Demanda:  <span style={{fontWeight:'normal'}}>{this.state.demanda.name}</span></Form.Label>
                                            
                                        </div>
                                    </InputGroup> */}

                                    
                                    <Form.Label >Elaboradores selecionados</Form.Label>
                                    <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom col-12">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="table" className="table table-striped">
                                                        <thead>
                                                            <tr>

                                                                <th>Elaborador</th>
                                                                <th>Descritivo</th>

                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.professores_select.map((item, id) => (
                                                                <tr key={id}>
                                                                    <td ><p >{item.name}</p></td>
                                                                    <td >
                                                                       {item.descritivo}
                                                                    </td>


                                                                    <td style={{ minWidth: 90 }}>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ professorSelect: { ...item }, showModal: true,id_select:id });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: 5 }}
                                                                            title="Gerenciar disciplina"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let professores_select = this.state.professores_select.filter((item2,id2)=>id2!=id);
                                                                                this.setState({ professores_select });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Deletar disciplina"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {this.state.professores_select.length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhuma disciplina adicionada</p>

                                                </div>
                                            </div>}
                                        </div>

                                    </InputGroup>
                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            search='tab=1'
                                            icon={<RiArrowGoBackLine />}
                                            to={"/demandas/show/" + this.state.demanda.id}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar atribuições"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando demanda"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarAtribuicoesRedacao);


