import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import Attention from "../ReusableComponents/Attention";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiCloseFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import TextReadMore from "../ReusableComponents/TextReadMore";
import moment from "moment";
import { URL } from "../../variables";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/AppActions";
import { CircularProgress } from "@material-ui/core";
import Request from "../../Request";
import { Close } from "@material-ui/icons";

const ModalQuestoesCsv = props => {
    const token = useSelector(store => store.AppReducer.token);
    const { categoria_name, showModal, setShowModal, categoria_id, onFinish } = props;
    const [questoes_csv, setquestoesCsv] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [loading_jobs, setLoadingJobs] = useState(true);

    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });

    const [csvFile, setCsvFile] = useState(null);
    const [csvValue, setCsvValue] = useState('');
    const [keyword, setKeyWord] = useState('');
    const [palavras_chaves, setParavrasChaves] = useState([]);



    const [msgErro, setMessageErro] = useState('');
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    useEffect(() => {

        if (showModal == true) {
            setLoadingJobs(true);
            setJobs([]);
            GetJobsCsvs();
        }
    }, [showModal]);

    const GetJobsCsvs = async (page = 1, search = '') => {

        let response = await Request.Get(`faculdade/get_csv_jobs_questoes/${categoria_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }


        if (response.pagination.current_page == page) {

            setJobs(response.questoes);

            setPagination(response.pagination);
            setLoadingJobs(false);


        }
    }


    const addUsers = (questoes_csv,) => {
        setLoading(true);
        setMessageErro('');

        let form = new FormData();
        form.append('categoria_id', categoria_id);
        form.append('questoes', csvFile);
        palavras_chaves.map((item,id)=>{
            form.append('palavras_chaves[]', item);
        })

        fetch(`${URL}api/faculdade/save_questoes_csv`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');

                    dispatch(logout());
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setMessageErro(erro);
                    setLoading(false);



                }
                else {
                    setLoading(false);
                    // setShowModal(false);
                    setquestoesCsv([]);
                    setCsvFile(null);
                    GetJobsCsvs();

                    // if (resp.processo != null) {


                }




            } catch (err) {
                setLoading(false);

                setMessageErro('Erro ao pegar resposta no servidor');

                console.log(err);


            }

        })
            .catch((err) => {
                setLoading(false);
                setMessageErro('Erro ao pegar resposta no servidor. Você está conectado a internet?');

                console.log(err);
            });
    }

    return (
        <Modal
            show={showModal == true}
            dialogClassName="modal-90w modal-width"
            onHide={() => {
                setShowModal(false);
                setquestoesCsv([]);
                setCsvFile(null);
                onFinish();

            }}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Adicionar questões na disciplina {categoria_name}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                {msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMessageErro('')}></button>


                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>

                    </div>
                    <br />

                </div>}

                <div className="table-rep-plugin w-100">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <table id="tech-companies-1" className="table table-striped">
                            <thead>
                                <tr>

                                    <th>Status</th>
                                    <th>Erro</th>
                                    <th>Progresso</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading_jobs == false && jobs.map((item, id) => (

                                    <tr key={id}>
                                        <td style={{ color: (item.status == 'Erro na execução' ? 'red' : (item.status == 'Finalizado' ? 'green' : 'darkgoldenrod')) }}>{item.status}</td>
                                        <td>{item.error_friendly ?? '-'}</td>
                                        <td>{item.progresso}</td>
                                        <td>{item.total_progresso}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        {loading_jobs == true &&
                            <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                <CircularProgress />
                            </div></div>

                        }
                    </div>
                </div>
                {loading_jobs == false && jobs.length == 0 && <div className="row w-100">
                    <div className="col-12">
                        <p style={{ textAlign: 'center' }}>Nenhuma execução encontrada</p>

                    </div>
                </div>}

                <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Palavras chaves (Palavras que serão aplicadas para identificar todas questões do arquivo)</Form.Label>
                        <div>
                            <Input
                                placeholder="Palavra chave"
                                value={keyword}
                                onChange={(e) => setKeyWord(e.target.value)}
                            />

                            {<div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
                                {palavras_chaves.map((item, id) => (
                                    <div key={id} style={{ backgroundColor: '#f2f2f2', borderRadius: 10, padding: 5, width: 'auto', marginTop: 5, display: 'flex', marginRight: 5 }}>
                                        <p style={{ marginBottom: 0, textAlign: 'center' }}>{item}</p>
                                        <Close onClick={() => {
                                            let palavras_chaves_ = palavras_chaves.filter((item2, id2) => id2 != id);
                                            setParavrasChaves(palavras_chaves_);

                                        }} style={{ cursor: 'pointer', width: '0.9rem', marginLeft: 3 }} />
                                    </div>
                                ))}
                            </div>}

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                <Button
                                    variant="outline-primary"
                                    onClick={(e) => {
                                        if (keyword.trim().length == 0) {
                                            return;
                                        }
                                        let palavras_chaves_ = [...palavras_chaves];
                                        palavras_chaves_.push(keyword);
                                        setParavrasChaves(palavras_chaves_);
                                        setKeyWord('');
                                    }}
                                    className="flex jcc align-center"
                                >Incluir palavra chave</Button>
                            </div>

                        </div>


                    </div>
                </InputGroup>
                <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label >Arquivo .docx <Attention /> </Form.Label>
                        <input type="file" placeholder="Arquivo .docx contendo as questões"
                            accept=".docx"
                            value={csvValue}
                            className="form form-control"
                            onChange={(e) => {

                                console.log(e.target.files[0])
                                setCsvFile(e.target.files[0]);
                                setCsvValue('');
                                setMessageErro('');


                                // var reader = new FileReader();
                                // reader.onload = () => {
                                //     // document.getElementById('out').innerHTML = reader.result;
                                //     let lines = reader.result.split("\n");
                                //     let questoes = [];
                                //     let cpfs = [];
                                //     let emails = [];
                                //     let numeros_inscricao=[];
                                //     for (let i = 0; i < lines.length; i++) {
                                //         let line = lines[i];
                                //         line = line.split(';');
                                //         let questao = {};
                                //         console.log(line)

                                //         if (line.length == 1) {
                                //             continue;
                                //         }
                                //         if (line.length < 6) {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' não possui 6 colunas.')
                                //             return;
                                //         }
                                //         if (line[0] == '' || line[1] == '' || line[2] == '' || line[3] == '' || line[4] == '' || line[5] == '') {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui colunas em branco.');
                                //             return;
                                //         }

                                //         if (!validateEmail(line[2])) {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um e-mail inválido.');
                                //             return;
                                //         }

                                //         if (emails.includes(line[2])) {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um e-mail que já apareceu nessa planilha. O E-mail deve ser único por questao.');
                                //             return;
                                //         }

                                //         emails.push(line[2])



                                //         if (!moment(line[3], 'YYYY-MM-DD', true).isValid()) {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' está com a data de nascimento inválido.');

                                //             return;
                                //         }

                                //         if (line[4].replace(/\./g, '').replace(/\-/g, '').length > 11) {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' está com o CPF inválido.');


                                //             return;
                                //         }

                                //         let cpf_ = line[4].replace(/\./g, '').replace(/\-/g, '');
                                //         let cpf = '';
                                //         for (let i = cpf_.length; i < 11; i++) {
                                //             cpf += '0';
                                //         }
                                //         cpf += line[4].replace(/\./g, '').replace(/\-/g, '');

                                //         if (cpfs.includes(cpf)) {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um CPF que já apareceu nessa planilha. O CPF deve ser único por questao.');
                                //             return;
                                //         }
                                //         cpfs.push(cpf)

                                //         if (numeros_inscricao.includes(line[0])) {
                                //             setCsvFile(null);
                                //             setCsvValue('');
                                //             setMessageErro('O arquivo .csv está com o padrão inválido. A linha ' + (i + 1) + ' possui um número de inscrição que já apareceu nessa planilha. O Número de inscrição deve ser único por questao.');
                                //             return;
                                //         }

                                //         numeros_inscricao.push(line[0])

                                //         questao.numero_inscricao = line[0];

                                //         questao.name = line[1];

                                //         questao.email = line[2];

                                //         questao.nascimento = line[3];

                                //         questao.cpf = line[4].replace(/\./g, '').replace(/\-/g, '');
                                //         cpf = '';
                                //         for (let i = questao.cpf.length; i < 11; i++) {
                                //             cpf += '0';
                                //         }
                                //         cpf += line[4].replace(/\./g, '').replace(/\-/g, '');
                                //         questao.cpf = cpf;

                                //         questao.password = line[5];

                                //         questoes.push(questao);

                                //     }

                                //     setquestoesCsv(questoes);
                                // };
                                // reader.readAsBinaryString(e.target.files[0]);
                            }} />
                        
                        {csvFile!=null && <div style={{  borderRadius: 10, padding: 5, width: 'auto', marginTop: 5, display: 'flex', marginRight: 5 }}>
                                        <p style={{ marginBottom: 0, textAlign: 'center' }}><b>Arquivo selecionado: </b>{csvFile.name}</p>
                                        <Close onClick={() => {
                                            
                                           setCsvFile(null);

                                        }} style={{ cursor: 'pointer', width: '0.9rem', marginLeft: 3 }} />
                                    </div>}

                    </div>




                </InputGroup>
                <div>
                    <b style={{ float: "left", marginRight: "2px" }}>Padrão:  </b>
                    <div >
                        O .docx deve ter os seguintes caractéres dividindo cada questões:$$$$$
                        <br />
                        O .docx deve ter os seguintes caractéres dividindo cada alternativa:&&&&&
                        <br />
                        Se for a última questão, ao final do arquivo também deve colocar os caractéres:&&&&&

                        <br />
                        O .docx deve ter os seguintes caractéres para indicar que na linha abaixo esta a resposta da questão:=====
                        <br />
                        {/* {(readmore ? props.children : props.children.substring(0, props.limit) + (props.children.length > props.limit ? "..." : ""))} */}
                    </div>
                </div>
                <a href={URL+'ModeloQuestoesOnTest.docx'} target="_blank">Baixar Exemplo de Arquivo</a>

                <br />
                <br />



                {loading == false && <div className="w100 inline-flex jcsb margin-top-1">
                    {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                    <DefaultButton
                        bg="secondary"
                        text="Fechar"
                        icon={<RiCloseFill />}
                        onClick={() => {
                            setShowModal(false);
                            setquestoesCsv([]);
                            setCsvFile(null);
                            onFinish();

                        }}
                    />
                    {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                    <DefaultButton
                        type="button"
                        disabled={csvFile == null}

                        onClick={() => {
                            addUsers(questoes_csv);
                        }}
                        bg={csvFile != null ? "confirm" : "secondary"}
                        text="Adicionar"
                    />
                </div>}

                {loading == true && <div className="w100 inline-flex jcsb margin-top-1 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>}

            </Modal.Body>
        </Modal>

    );
}

export default ModalQuestoesCsv;