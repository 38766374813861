import { Box, Typography } from "@material-ui/core";
import { blue, deepOrange, deepPurple, indigo, purple, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

import Board from "./Board";
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles({
    mt: {
        marginTop: 10
    },
    mb: {
        marginBottom: 10
    },
    link: {
        textDecoration: "none",
        cursor: "pointer"
    }
});

const Actions = props => {

    const classes = useStyles();

    return (
        <Box>
            <Box
                component={Typography}
                color={"rgba(0, 0, 0, 0.6)"}
                className={`${classes.mt} ${classes.mb}`}
            >
                Ações rápidas
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
            >
                <Box>
                    <Board
                        color={purple}
                        title={"Adicionar cursos"}
                        link={{
                            link: "/courses/add",
                            idleIcon: <AddIcon/>
                        }}
                    />
                    <Board
                        color={deepPurple}
                        title={"Gerenciar cursos"}
                        link={{
                            link: "/courses/add",
                            idleIcon: <AddIcon/>
                        }}
                    />
                </Box>
                <Box>
                    <Board
                        color={red}
                        title={"Adicionar usuários"}
                        link={{
                            link: "/courses/add",
                            idleIcon: <AddIcon/>
                        }}
                    />
                    <Board
                        color={deepOrange}
                        title={"Gerenciar usuários"}
                        link={{
                            link: "/courses/add",
                            idleIcon: <AddIcon/>
                        }}
                    />
                </Box>
                <Box>
                    <Board
                        color={blue}
                        title={"Adicionar professores"}
                        link={{
                            link: "/courses/add",
                            idleIcon: <AddIcon/>
                        }}
                    />
                    <Board
                        color={indigo}
                        title={"Gerenciar professores"}
                        link={{
                            link: "/courses/add",
                            idleIcon: <AddIcon/>
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Actions;