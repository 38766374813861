import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaFile, FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdDownload, MdFileDownload, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { API_URL, STORAGE_FILES_URL, STORAGE_URL, URL } from "../../variables";
import ModalRespostas from "../ProvasProcesso/ModalRespostas";
import VerQuestionarioRespondido from "../QuestionariosVinculados/VerQuestionarioRespondido";



const Redacoes = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_redacoes, setLoadingredacoes] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [redacoes, setredacoes] = useState([]);
    const [resultados_prova, setResultadosProva] = useState([]);
    const [questionarios, setQuestionarios] = useState([]);


    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id,candidato_name,candidato_id } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [corretor_id, setcorretorId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);

    const [showModalQuestionario, setShowModalQuestionario] = useState(false);

    let refs = [];
    const dispatch = useDispatch();

    const Getredacoes = async (page, search) => {

        let response = await Request.Get(`faculdade/get_redacoes_candidato/${processo_id}/${candidato_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
           
            setredacoes(response.textos);
            setResultadosProva(response.resultados_prova);
            setQuestionarios(response.questionarios);
            setLoadingScreen(false);
            setLoadingredacoes(false);

           
    }

    const [provaSelect, setProvaSelect] = useState(null);
    // const [showModal, setShowModal] = useState(false);
    const [questionarioselect, setquestionarioselect] = useState(null);
   

    useEffect(() => {
        Getredacoes(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                <p className="text-muted">Aqui ficam as avaliações do candidato "{candidato_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        {/* <Search placeholder="Pesquisar pelo nome do corretor" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setSearchOficial(value);
                                setLoadingredacoes(true);

                                Getredacoes(1, value)

                            }, 500);
                        }} />

                        
                                <Link to={{
                                    pathname: "/redacoes/register/" + faculdade_id,
                                    // state: {
                                    //     course_id: id
                                    // }
                                }} style={{ textDecoration: "none" }}>
                                    <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar corretor</p></Button>
                                </Link> */}
                    </div>
                    <div className="row w-100">
                        <div className="col-12">
                            <p ><b style={{fontWeight:600}}>Redações: </b></p>
                        </div>
                    </div>

                    <ModalRespostas showModal={showModal} setShowModal={(e) => { setShowModal(e); setProvaSelect(null) }} provaSelect={provaSelect} prova_name={provaSelect?.prova?.name} questoes={provaSelect?.prova?.questoes} />

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Redação</th>
                                        <th>Título</th>
                                        {/* <th>Criado em</th> */}

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_redacoes == false && redacoes.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.redacao.name}</td>
                                            <td>{item.titulo==null?'-':item.titulo}</td>
                                            {/* <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td> */}
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>
                                                {item.correcao!=null && 
                                                <a target="_blank" href={URL+'api/faculdade/download_redacao_corrigida/'+item.id}>
                                                <span
                                                    onClick={() => {
                                                       
                                                    }}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px",marginRight:5 }}
                                                    title="Gerenciar correção"
                                                >
                                                    <FaFile size={17} color="white" />
                                                </span>
                                                </a>
                                                }
                                                <a target="_blank" href={item.file==null? (URL+'api/faculdade/download_redacao/'+item.id):(STORAGE_URL+item.file)}>
                                                <span
                                                   
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Gerenciar redação"
                                                >
                                                    <MdDownload size={17} color="white" />
                                                </span>
                                                </a>
                                                {/* <Link to={`/redacoes/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link> */}
                                                {/* <span
                                                    onClick={(e) => {
                                                        setcorretorId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar corretor"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_redacoes == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress/>
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_redacoes == false && redacoes.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma redação encontrado</p>

                        </div>
                    </div>}

                    <hr/>
                    <br/>
                    <div className="row w-100">
                        <div className="col-12">
                            <p ><b style={{fontWeight:600}}>Provas: </b></p>
                        </div>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Prova</th>
                                        <th>Número de Questões</th>
                                        {/* <th>Criado em</th> */}

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_redacoes == false && resultados_prova.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.prova.name}</td>
                                            <td>{item.prova.questoes.map((item2, id) => {
                                        if (item2.type_questao == 'conjunto') {
                                            return item2.questoes.length;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }).reduce((a, b) => a + b, 0)}</td>
                                            {/* <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td> */}
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>
                                            <span
                                                    onClick={() => {
                                                        setProvaSelect(item);
                                                        setShowModal(true);
                                                    }}
                                                    className="inline-flex align-center jcc bg-warning icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar relatório"
                                                >
                                                    <BsFillEyeFill size={17} color="white" />
                                                </span>
                                                {/* <Link to={`/redacoes/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link> */}
                                                {/* <span
                                                    onClick={(e) => {
                                                        setcorretorId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar corretor"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_redacoes == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress/>
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_redacoes == false && resultados_prova.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma prova encontrada</p>

                        </div>
                    </div>}




                    <VerQuestionarioRespondido showModal={showModalQuestionario} setShowModal={(e) => { setShowModalQuestionario(e); setquestionarioselect(null) }} questionario={questionarioselect?.questionario} resultado={questionarioselect} respostas={questionarioselect?.respostas} />

                    <hr/>
                    <br/>
                    <div className="row w-100">
                        <div className="col-12">
                            <p ><b style={{fontWeight:600}}>Questionários: </b></p>
                        </div>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Questionário</th>
                                        <th>Número de Questões</th>
                                        {/* <th>Criado em</th> */}

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_redacoes == false && questionarios.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.questionario.name}</td>
                                            <td>{item.questionario.questoes.length} Questões</td>
                                            {/* <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td> */}
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>
                                            <span
                                                    onClick={() => {
                                                        setquestionarioselect(item);
                                                        setShowModalQuestionario(true);
                                                    }}
                                                    className="inline-flex align-center jcc bg-warning icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar relatório"
                                                >
                                                    <BsFillEyeFill size={17} color="white" />
                                                </span>

                                                <a target="_blank" href={API_URL+'faculdade/get_questionario_pdf/'+item.questionario_id+'/'+processo_id+'/'+item.user.id}>
                                                
                                                <span
                                                    onClick={() => {

                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar respostas"
                                                >
                                                    <MdFileDownload size={17} color="white" />
                                                </span>
                                                </a>
                                                {/* <Link to={`/redacoes/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link> */}
                                                {/* <span
                                                    onClick={(e) => {
                                                        setcorretorId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar corretor"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_redacoes == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress/>
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_redacoes == false && questionarios.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum questionário encontrado</p>

                        </div>
                    </div>}

                 

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_corretor={this.get_corretor.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress/>
                </div></div>}

                

            </div>

        </div>
    )
}

export default Redacoes;
