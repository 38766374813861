import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import { API_URL, URL } from "../../variables";


var pesquisarTimeout = null;

const DiagramacoesNominais = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_zip, setLoadingZip] = useState(false);

    const [loading_provas, setLoadingprovas] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');
    const [message_zip, setMessageZip] = useState('');


    const [message_zip2, setMessageZip2] = useState('');
    const [loading_zip2, setLoadingZip2] = useState(false);


    const [provas, setprovas] = useState([]);

    const [questoes, setquestoes] = useState([]);

    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { prova_id, prova_name, processo_id, processo_name,prova_type } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [provas_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [provaSelect, setProvaSelect] = useState(null);


    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();
    const timerRef = useRef(null);
    const timerRefZip = useRef(null);


    const Getprovas = async (page, search) => {

        let response = await Request.Get(`faculdade/get_candidatos_vinculados/${props.processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setprovas(response.candidatos);



            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingprovas(false);


        }
    }


    const GetProvasPdfZip = async () => {

        let response = await Request.Get(`faculdade/download_diagramacoes/${props.diagramacao_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'redacoes_processo_'+processo_name+'.zip');
        // }
        else {
            if (response['status'] == true) {
                document.getElementById('download_link').href = response['path'];
                document.getElementById('download_link').click();
                setLoadingZip(false);
                setMessageZip('');

                return;
            }
            else if (response['status'] != 'Error') {
                setMessageZip(response['message']);
            }
            timerRef.current = setTimeout(() => {
                GetProvasPdfZip();
            }, 20000);

        }


    }




    const GetProvassZip = async () => {

        let response = await Request.Get(`faculdade/get_respostas_prova/${prova_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'provas_processo_'+processo_name+'.zip');
        // }
        else {
            // setLoadingZip(false);
            // setMessageZip('');


            if (response['status'] == true) {
                document.getElementById('download_link').href = response['path'];
                document.getElementById('download_link').click();
                setLoadingZip2(false);
                setMessageZip2('');

                return;
            }
            else if (response['status'] != 'Error') {
                setMessageZip2(response['message']);
            }
            timerRefZip.current = setTimeout(() => {
                GetProvassZip();
            }, 20000);


        }


    }

    const DeleteRedacao = async (provas_id) => {

        let response = await Request.Delete(`faculdade/desvincular_prova/${provas_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getprovas(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getprovas(1, search_oficial);

        return () => {
            clearTimeout(timerRef.current)
            clearTimeout(timerRefZip.current)

        }
    }, []);

    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">

                <a id="download_link" target="_blank"></a>

                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as respostas da prova "{prova_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}
                    <a id="download_link" target="_blank"></a>
                    <div className="flex jcsb flex-wrap mb-4">

                        <Search className="mt-2" placeholder="Pesquisar pelo nome/cpf/número de inscrição do candidato" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingprovas(true);

                                Getprovas(1, value)

                            }, 500);
                        }} />

                        <div className=" mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>
                            <div className="d-flex " style={{
                            justifyContent: 'flex-end'
                        }}>

                                { loading_zip == false && <span onClick={() => {
                                    if (loading_zip == false) {
                                        setLoadingZip(true);
                                        setMessageZip('Criando .zip para download');
                                        GetProvasPdfZip();
                                    }
                                    else {

                                    }
                                }} style={{ textDecoration: "none" }}>
                                    {<Button variant="success" className="flex align-center jcc"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar provas em .pdf</p></Button>}

                                </span>}
                                {loading_zip == true && <div style={{ flexBasis: "50%", paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress size={15} />
                                </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                                    </div>
                                </div>}

                            </div>


                        </div>


                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>CPF</th>
                                        <th>Número de Inscrição</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_provas == false && provas.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.cpf}</td>
                                            <td>{item.numero_inscricao}</td>



                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>

                                               
                                                <Link target={'_blank'} to={`/diagramacoes/show/${props.diagramacao_id}/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title={"Ver diagramação do candidato "+item.name}
                                                    >
                                                        <BsFillEyeFill color="white" size={17} />
                                                    </span>
                                                </Link> 
                                                {/* <span
                                                    onClick={() => {
                                                        GetVideoCandidato(item);

                                                    }}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar Vídeo"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span> */}
                                                {/* <Link to={`/provas_vinculados/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link> */}
                                                {/* <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular provas"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_provas == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_provas == false && provas.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma prova encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingprovas(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getprovas(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_provas={this.get_provas.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingprovas(true);
                        DeleteRedacao(provas_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este prova, ele não terá mais acesso a este processo
                </SweetAlert>


            </div>

        </div>
    )
}

export default DiagramacoesNominais;
