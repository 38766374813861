// Library Imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { Dropdown, Form } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RiArrowGoBackLine, RiCloseCircleLine } from "react-icons/ri";

// Components Imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import Attention from "../ReusableComponents/Attention";

// Utils Imports
import { get, put } from "../../Services/api";

const EditarTreinamento = ({ token, match }) => {
  const [formData, setFormData] = useState({
    redirect: false,
    loading: true,
    nome: "",
    descricao: "",
    modulos: [],
    modulosAll: [],
    modulo_ids: [],
    loadingSave: false,
    msgErro: "",
    dropdownOpen: false,
  });

  useEffect(() => {
    const { id } = match.params;
    const fetchData = async () => {
      try {
        const trainingResponse = await get(
          `api/treinamento/get_training/${id}`,
          token
        );
        const modulosResponse = await get("api/modulo/get_modules", token);

        const { nome, descricao, modulos } = trainingResponse.data;
        const moduloIdsArray = Array.isArray(modulos)
          ? modulos.map((modulo) => modulo.id)
          : [];

        setFormData({
          nome,
          descricao,
          modulos,
          modulosAll: modulosResponse.data,
          modulo_ids: moduloIdsArray,
          loading: false,
          redirect: false,
          loadingSave: false,
          msgErro: "",
        });
      } catch (error) {
        console.error("Erro:", error);
        setFormData((prevState) => ({
          ...prevState,
          redirect: true,
          loading: false,
        }));
      }
    };

    fetchData();
  }, [match.params, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (id) => {
    setFormData((prevState) => {
      const modulo_ids = prevState.modulo_ids.includes(id)
        ? prevState.modulo_ids.filter((moduloId) => moduloId !== id)
        : [...prevState.modulo_ids, id];
      const modulos = prevState.modulosAll.filter((modulo) =>
        modulo_ids.includes(modulo.id)
      );
      return { ...prevState, modulo_ids, modulos };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id } = match.params;
    const { nome, descricao, modulo_ids } = formData;

    setFormData((prevState) => ({
      ...prevState,
      loadingSave: true,
      msgErro: "",
    }));

    try {
      await put(
        `api/treinamento/edit_training/${id}`,
        { nome, descricao, modulo_ids },
        token
      );
      setFormData((prevState) => ({
        ...prevState,
        msgErro: "Treinamento editado com sucesso",
        redirect: true,
      }));
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao editar treinamento",
        loadingSave: false,
      }));
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const modulos = Array.from(formData.modulos);
    const [reorderedItem] = modulos.splice(result.source.index, 1);
    modulos.splice(result.destination.index, 0, reorderedItem);

    setFormData((prevState) => ({ ...prevState, modulos }));
    await saveOrder(modulos);
  };

  const saveOrder = async (modulos) => {
    const { id } = match.params;
    try {
      await put(
        `api/treinamento/edit_training/${id}`,
        {
          nome: formData.nome,
          descricao: formData.descricao,
          modulo_ids: modulos.map((modulo) => modulo.id),
        },
        token
      );
    } catch (error) {
      console.error(error.message || "Erro ao salvar a nova ordem");
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao salvar a nova ordem",
      }));
    }
  };

  const toggleDropdown = () => {
    setFormData((prevState) => ({
      ...prevState,
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  const handleRemoveModule = (id) => {
    setFormData((prevState) => {
      const modulo_ids = prevState.modulo_ids.filter(
        (moduloId) => moduloId !== id
      );
      const modulos = prevState.modulos.filter((modulo) => modulo.id !== id);
      return { ...prevState, modulo_ids, modulos };
    });
  };

  if (formData.redirect) {
    return <Redirect to="/treinamentos" />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{
                fontSize: "1.5rem",
                color: "black",
                marginBottom: "1rem",
              }}
            >
              Editar Treinamento
            </h4>
            <p
              style={{
                fontSize: "1rem",
                color: "black",
                marginBottom: "1.5rem",
              }}
            >
              Edite o treinamento do seu sistema
            </p>
            {formData.msgErro && (
              <div
                style={{
                  display: "flex",
                  marginBottom: "1.5rem",
                  width: "100%",
                }}
              >
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        msgErro: "",
                      }))
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{formData.msgErro}</p>
                </div>
              </div>
            )}
            {!formData.loading ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group" style={{ marginBottom: "1.5rem" }}>
                  <label htmlFor="nome">
                    Nome <Attention />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group" style={{ marginBottom: "1.5rem" }}>
                  <label htmlFor="descricao">Descrição</label>
                  <input
                    type="text"
                    className="form-control"
                    id="descricao"
                    name="descricao"
                    value={formData.descricao}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className="form-group flex fdcolumn flex1 margin-bottom mb-3"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor="modulo_id">Módulo Vinculado</label>
                  <Dropdown
                    show={formData.dropdownOpen}
                    onToggle={toggleDropdown}
                  >
                    <Dropdown.Toggle variant="secondary" id="dropdown-modulo">
                      Selecione os Módulos
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      {formData.modulosAll.map((modulo) => (
                        <Dropdown.Item
                          key={modulo.id}
                          as="div"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            label={modulo.name}
                            checked={formData.modulo_ids.includes(modulo.id)}
                            onChange={() => handleSelectChange(modulo.id)}
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div
                  className="form-group mb-3"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor="modulos_vinculados">Módulos vinculados</label>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="modulos">
                      {(provided) => (
                        <ul
                          className="list-group"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {formData.modulo_ids.length === 0 ? (
                            <li className="list-group-item">
                              Nenhum conteúdo selecionado
                            </li>
                          ) : (
                            formData.modulos.map((modulo, index) => (
                              <Draggable
                                key={modulo.id}
                                draggableId={modulo.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    {modulo.name}
                                    <button
                                      type="button"
                                      className="btn btn-link p-0"
                                      onClick={() =>
                                        handleRemoveModule(modulo.id)
                                      }
                                    >
                                      <RiCloseCircleLine
                                        size={20}
                                        color="red"
                                      />
                                    </button>
                                  </li>
                                )}
                              </Draggable>
                            ))
                          )}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div className="w-100 d-flex justify-content-between mt-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/treinamentos"
                  />
                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar treinamento"
                    disabled={formData.loadingSave}
                    loadingtext="Salvando treinamento"
                    loading={formData.loadingSave}
                  />
                </div>
              </form>
            ) : (
              <div>
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(EditarTreinamento);
