




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdOutlineRule, MdQuestionAnswer, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import TabPanel from "../ReusableComponents/TabPanel";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import PDF_IMAGE from '../../assets/images/pdf.png';
import './modal.css';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';
import { BsFillEyeFill } from 'react-icons/bs';
import ModalResposta from './ModalResposta';

class ShowRecurso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            faculdade: {},
            recurso: {},
            show_more: false,

            activeTab: 0,
            showModal:false,
            respostaSelect:null,
            showModalQuestao:false
        };

    }


    get_recurso(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_recurso/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_recurso();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, recurso: resp.recurso
                    });
                    // if (resp.recurso != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    componentDidMount() {
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.get_recurso(this.props.match.params.id);
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div style={{ position: "relative" }}>
                <div className="card">
                    <div className="card-body">
                        <div style={{ float: 'right' }}>
                            <DefaultButton
                                bg="secondary"
                                text="Voltar"
                                icon={<RiArrowGoBackLine />}
                                to={`/recursos`}
                            />
                        </div>
                        <div className="flex jcsb">
                            <h4 className="header">{this.state.recurso.name}</h4>
                        </div>

                        {<div id="myModalCam" className="modal_2">

                            <span onClick={() => {
                                var modal = document.getElementById("myModalCam");
                                modal.style.display = "none";
                            }} className="close">&times;</span>

                            <img className="modal-content_2" id="imgShow" />

                            <div id="caption"></div>
                        </div>}


                        <TextReadMore title="Data e hora limite: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                            {moment(this.state.recurso.data_limite).format('DD/MM/YYYY HH:mm')}
                        </TextReadMore>
                        <TextReadMore title="Descrição dos fatos: " element="div" limit={120} style={{ width: "100%", marginBottom: 0, whiteSpace: 'pre' }}>
                            {this.state.recurso.description}
                        </TextReadMore>

                        <TextReadMore title="Fundamentação do pedido: " element="div" limit={120} style={{ width: "100%", marginBottom: 0,marginTop:10, whiteSpace: 'pre' }}>
                            {this.state.recurso.fundamentacao}
                        </TextReadMore>

                        <TextReadMore title="Pedido realizado: " element="div" limit={120} style={{ width: "100%", marginBottom: 0,marginTop:10, whiteSpace: 'pre' }}>
                            {this.state.recurso.pedido_realizado}
                        </TextReadMore>

                        <a hidden id="link_pdf" target={'_blank'} href="#"></a>

                        {this.state.recurso.files.length > 0 && <Form.Label >Imagem(s)/arquivo(s) do recurso:</Form.Label>}
                        <div className='row' style={{ marginRight: 0, marginLeft: 0 }}>
                            {this.state.recurso.files.map((item, id) => (
                                <div key={id} style={{ width: 100, marginRight: 10, marginTop: 10, position: 'relative' }}>
                                    {!item.name.includes('.pdf') && <img
                                    onClick={() => {
                                        var modal = document.getElementById("myModalCam");
                                        var modalImg = document.getElementById("imgShow");
                                        modalImg.src = STORAGE_URL + item.path;
                                        modal.style.display = "block";
                                    }}
                                    className="myImg"
                                        src={STORAGE_URL + item.path}
                                        style={{ height: 75, width: 100, objectFit: 'contain' }}
                                    />}
                                    {item.name.includes('.pdf') && <img
                                    onClick={()=>{
                                        document.getElementById('link_pdf').href=STORAGE_URL + item.path;
                                        document.getElementById('link_pdf').click();
                                    }}
                                    className="myImg"
                                        src={PDF_IMAGE}
                                        style={{ height: 75, width: 100, objectFit: 'contain' }}
                                    />}
                                    <div style={{ width: 100 }}>
                                        <p style={{ marginBottom: 0, textAlign: 'center', marginTop: 5, fontSize: 11 }}>{item.name}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                        <ModalResposta showModal={this.state.showModal} setShowModal={(e)=>this.setState({showModal:e})} resposta={this.state.respostaSelect}   />
                        <ModalQuestao showModal={this.state.showModalQuestao} setShowModal={(e) => this.setState({ showModalQuestao: e })} questao={this.state.recurso.questao} />
                        
                        <div className="btn btn-outline-primary btn-lg btn-block margin-bottom" style={{marginTop:'1rem',width:'100%'}} onClick={() => {
                                this.setState({showModalQuestao:true});

                            }}>

                        <p   style={{ marginRight: "2px",marginTop:0,marginBottom:0, textAlign:'center' }}> <b style={{ float: "left", marginRight: "2px", color: 'black', marginRight: 2 }}></b> <MdQuestionAnswer /> {'Ver questão'} </p>
                        </div>

                        <div className="flex fdcolumn flex1 margin-bottom ">
                            <Form.Label >Histórico de resposta(s):</Form.Label>
                                 <div className="table-rep-plugin">
                                 <div className="table-responsive mb-0" data-pattern="priority-columns">
                                     <table id="tech-companies-1" className="table table-striped">
                                         <thead>
                                             <tr>

                                                 <th>Resposta</th>
                                                 <th>Data da resposta</th>
                                                 <th>Status</th>


                                                 <th data-priority="3">Ações</th>

                                             </tr>
                                         </thead>
                                         <tbody>
                                             {this.state.recurso.respostas.map((item, id) => (

                                                 <tr key={id}>
                                                     <td ><p style={{whiteSpace:'nowrap'}}> {item.resposta.substring(0,100)}{item.resposta.length>=100?'...':''}</p></td>
                                                     {/* <td>Questão {id + 1}</td> */}
                                                     <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                     <td>{item.status=='deferido'?'Deferido':'Indeferido'}</td>


                                                     {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                                     <td>
                                                      

                                                             <span
                                                                onClick={()=>{
                                                                    this.setState({respostaSelect:item,showModal:true})
                                                                }}
                                                                 className="inline-flex align-center jcc bg-warning icon"
                                                                 style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", }}
                                                                 title="Gerenciar recurso"
                                                             >
                                                                 <BsFillEyeFill size={17} color="white" />
                                                             </span>

                                                      
                                                     </td>

                                                 </tr>
                                             ))}

                                         </tbody>
                                     </table>

                                 </div>
                                </div>
                                {this.state.recurso.respostas.length == 0 && <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhuma resposta encontrada</p>

                                            </div>
                                        </div>} 
                           
                        </div>

                        {/* <div style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre',height:this.state.show_more==false?100:'fit-content',overflowY:this.state.show_more==false? 'hidden':'visible' }}>
                            <b style={{ float: "left", marginRight: "2px" }}>{'Regras:'} </b>
                            <br/>
                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.state.recurso.regras }}>
                            </div>
                        </div> */}
                        {/* <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div className="course-banner" style={{
                                backgroundImage: `url(${STORAGE_URL+ this.state.faculdade.logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat:'no-repeat',
                                backgroundPositionX:'center',
                                width: "100%",
                                height: "200px",
                                borderRadius: "7px"
                            }} />
                        </div> */}

                        {/* <Tabs
                            value={this.state.activeTab}
                            onChange={(_, value) => {
                                this.setState({ activeTab: value });
                                this.props.history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label="Atribuições" />
                          


                        </Tabs>
                        <TabPanel value={this.state.activeTab} index={0}>
                            <Atribuicoes recurso_id={this.state.recurso.id} recurso_name={this.state.recurso.name} />
                        </TabPanel> */}

                    </div>
                </div>

            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowRecurso);


