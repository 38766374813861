import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaArrowLeft, FaArrowRight, FaListAlt, FaImage } from "react-icons/fa";
import { MdArrowLeft, MdArrowRight, MdArrowRightAlt, MdDelete, MdFileDownload, MdModeEdit, MdPlayArrow } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import './modal.css';



const ImagesCam = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_images, setLoadingimages] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [images, setimages] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });


    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(1);

    const [search, setSearch] = useState('');
    const { candidato_id, candidato_name, processo_id } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [Image_id, setImageId] = useState(null);
    const [loading_zip, setLoadingZip] = useState(false);
    const [message_zip, setMessageZip] = useState('');

    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getimages = async (page, search, last = false) => {

        let response = await Request.Get(`faculdade/get_images_cam_candidato/${processo_id}/${candidato_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            if (response.images.length > 0) {
                if (last == true) {
                    setImageSelect(response.images[response.images.length - 1]);

                }
                else {
                    setImageSelect(response.images[0]);

                }
            }
            else{
                setImageSelect(null); 
            }
            setimages(response.images);

            // setPagination({...response.pagination});
            setCurrentPage(response.pagination.current_page);
            setLastPage(response.pagination.last_page);

            setLoadingScreen(false);
            setLoadingimages(false);


        }
    }

    const [Image_select, setImageSelect] = useState(null);

    const DeleteImage = async (Image_id) => {

        let response = await Request.Delete(`faculdade/delete_Image/${Image_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getimages(current_page, search_oficial);



    }

    const GetPdfImageFile = async () => {

        let response = await Request.Get(`faculdade/download_relatorio_imagens/${candidato_id}/${processo_id}`);
        console.log(response);
        setLoadingZip(false);
        setMessageZip('');

        if (response === undefined) return;
        if (response.message == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'provas_processo_'+processo_name+'.zip');
        // }
        else {
            document.getElementById('download_link').href = response['link'];
            document.getElementById('download_link').click();

            // saveAs(response, 'analises_candidatos_' + processo_name + '.xlsx');


        }
    }

    useEffect(() => {
        Getimages(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">
            <a id="download_link" target="_blank"></a>

                {<div id="myModalCam" className="modal_2">

                    <span onClick={() => {
                        var modal = document.getElementById("myModalCam");
                        modal.style.display = "none";
                    }} className="close">&times;</span>

                    <img className="modal-content_2" id="imgShow" />

                    <div id="caption"></div>
                </div>}

                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as imagens do candidato "{candidato_name}"</p>
                    {Image_select != null && <div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img className="myImg" onClick={() => {
                                var modal = document.getElementById("myModalCam");
                                var modalImg = document.getElementById("imgShow");
                                modalImg.src = Image_select.url;
                                modal.style.display = "block";
                            }} height="320" width="240" src={Image_select.url} />

                        </div>
                        <p style={{ textAlign: 'center', marginBottom: 0, marginTop: 15 }}>{Image_select.path.split('/')[Image_select.path.split('/').length - 1]}</p>
                        <br />
                        <div className="flex jcsb flex-wrap mb-4">
                            <Button variant="success" onClick={() => {
                                if (images[0].id == Image_select.id & current_page != 1) {
                                    // let page = { ...pagination };
                                    // page.current_page += 1;
                                    let current = current_page;
                                    setCurrentPage(current_page - 1);
                                    setLoadingimages(true);

                                    Getimages(current - 1, search_oficial, true)
                                }
                                else if (images[0].id != Image_select.id) {
                                    for (let i = 0; i < images.length; i++) {
                                        if (Image_select.id == images[i].id) {
                                            setImageSelect(images[i - 1]);
                                            break;
                                        }
                                    }
                                }
                            }} disabled={(images[0]==undefined || (images[0].id == Image_select.id && current_page == 1)) || loading_images} className="flex align-center jcc"><FaArrowLeft style={{ marginRight: 10, fontSize: 16 }} /> <p style={{ margin: 0 }}>Anterior</p></Button>

                            <Button variant="success" onClick={() => {
                                if (images[images.length - 1].id == Image_select.id & current_page < last_page) {
                                    // let page = { ...pagination };
                                    // page.current_page += 1;
                                    let current = current_page;
                                    setCurrentPage(current_page + 1);
                                    setLoadingimages(true);

                                    Getimages(current + 1, search_oficial)
                                }
                                else if (images[images.length - 1].id != Image_select.id) {
                                    for (let i = 0; i < images.length; i++) {
                                        if (Image_select.id == images[i].id) {
                                            setImageSelect(images[i + 1]);
                                            break;
                                        }
                                    }
                                }
                            }} disabled={((images[images.length - 1]==undefined) || (images[images.length - 1].id == Image_select.id && current_page == last_page)) || loading_images} className="flex align-center jcc"><p style={{ margin: 0 }}>Próximo</p> <FaArrowRight style={{ marginLeft: 10, fontSize: 16 }} /></Button>

                        </div>

                        <hr />
                    </div>}
                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo nome da imagem" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                // let page = { ...pagination };
                                // page.current_page = 1;
                                // setPagination(page);
                                setCurrentPage(1);
                                setLoadingimages(true);

                                Getimages(1, value)

                            }, 500);
                        }} />


                        {/* <Link to={{
                                    pathname: "/images/register/" + candidato_id,
                                    // state: {
                                    //     course_id: id
                                    // }
                                }} style={{ textDecoration: "none" }}>
                                    <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar Image</p></Button>
                                </Link> */}

                        <div className="d-flex mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>
                            {<div onClick={() => {
                                if (loading_zip == false) {
                                    setLoadingZip(true);
                                    setMessageZip('Criando .pdf para download');
                                    GetPdfImageFile();
                                }
                                else {

                                }
                            }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {loading_zip == false && <Button variant="success" className="flex align-center jcc me-2"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar pdf com imagens</p></Button>}
                                {loading_zip == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <CircularProgress size={15} />
                                </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                                    </div>
                                </div>}
                            </div>}


                        </div>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>Data</th>
                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_images == false && images.map((item, id) => (

                                        <tr style={{ backgroundColor: (Image_select != null && Image_select.id == item.id) ? '#4BB543' : '' }} key={id}>
                                            <td>{item.path.split('/')[item.path.split('/').length - 1]}</td>
                                            <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>

                                            <td>
                                                <span
                                                    onClick={() => {
                                                        setImageSelect(item);
                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Gerenciar imagem"
                                                >
                                                    <MdPlayArrow size={17} color="white" />
                                                </span>
                                                {/* <Link to={`/images/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={(e) => {
                                                        setImageId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar Image"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_images == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_images == false && images.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma imagem encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={last_page}
                        // value={pagination.current_page}
                        page={current_page}
                        onChange={(_, value) => {
                            setLoadingimages(true);
                            console.log(_, value);
                            console.log(pagination);
                            setCurrentPage(value);
                            // let page = { ...pagination };
                            // page.current_page = value;
                            // setPagination(page);
                            Getimages(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_Image={this.get_Image.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingimages(true);
                        DeleteImage(Image_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar este Image, ele não estará mais disponível no sistema
                </SweetAlert>


            </div>

        </div>
    )
}

export default ImagesCam;
