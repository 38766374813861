import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { logout } from '../../actions/AppActions';
import Skeleton from '@material-ui/lab/Skeleton';
import JoditEditor from 'jodit-react';
import { Form } from 'react-bootstrap';

class EditarQuestaoConteudo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/conteudo/show/',
            loading: true,
            nome: '',
            enunciado: '',
            loadingSave: false,
            msgErro: '',
            mounted: false,
            enunciadoInicial: '',
            alternativas: [],
            quantidadeAlternativas: 0,
            alternativaCorreta: '',
        };
    }

    componentDidMount() {
        this.setState({ mounted: true });

        const { id } = this.props.match.params;
        this.getQuestion(id);
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    async getQuestion(id) {
        try {
            const response = await fetch(`${URL}api/conteudo/get_question/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`
                },
            });
            if (!response.ok) {
                throw new Error('Erro ao obter dados da questão');
            }

            const data = await response.json();
            const questao = data.questao;

            if (this.state.mounted) {
                const quantidadeAlternativas = questao.alternativas.length;
                this.setState({
                    loading: false,
                    nome: questao.nome,
                    enunciado: questao.enunciado,
                    alternativas: questao.alternativas,
                    quantidadeAlternativas: quantidadeAlternativas,
                    alternativaCorreta: this.convertIndexToLetter(questao.alternativa_correta),
                });
            }

        } catch (error) {
            if (this.state.mounted) {
                this.setState({ redirect: true });
            }
        }
    }

    removeHtmlTags(str) {
        return str ? str.replace(/<[^>]*>/g, '') : '';
    }

    async salvar() {
        this.setState({ loadingSave: true, msgErro: '' });
        const { nome, enunciado, alternativas } = this.state;

        const text_enunciado_questao = this.removeHtmlTags(enunciado);
        const alternativasSemHtml = alternativas.map(alternativa => ({ nome: this.removeHtmlTags(alternativa.name.trim()) }));

        if (!nome.trim() || !text_enunciado_questao.trim() || !alternativasSemHtml) {
            this.setState({ msgErro: 'Todos os campos são obrigatórios', loadingSave: false });
            return;
        }

        const { token, user, match } = this.props;

        try {
            await this.saveQuestion({ nome, enunciado, text_enunciado_questao, alternativas, token, user, match });
            this.setState({ loadingSave: false, redirect: true });
        } catch (error) {
            this.setState({ loadingSave: false, msgErro: error.message });
        }
    }

    convertLetterToIndex(letter) {
        return letter.charCodeAt(0) - 97;
    }


    async saveQuestion({ nome, enunciado, text_enunciado_questao, alternativas, token, user, match }) {
        const { id } = match.params;

        const requestBody = {
            nome,
            enunciado,
            text_enunciado_questao,
            user_id: user.id,
            alternativas: alternativas.map((alternativa, index) => ({
                name: alternativa.name,
                alternativa_correta: index === this.convertLetterToIndex(this.state.alternativaCorreta),
                questao_id: id,
                order: String.fromCharCode(97 + index),
            })),
        };

        try {
            const response = await fetch(`${URL}api/conteudo/edit_question/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Erro ao editar a questão: ${errorData.message}`);
            }

            return response.json();
        } catch (error) {
            throw new Error('Erro ao editar a questão: ' + error.message);
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value });
    };

    handleChangeEnunciado = (enunciado) => {
        this.setState({ enunciado: enunciado });

    };

    handleChangeQuantidadeAlternativas = (event) => {
        const quantidade = parseInt(event.target.value);
        const novasAlternativas = Array.from({ length: quantidade }, (_, index) => {
            if (this.state.alternativas[index]) {
                return this.state.alternativas[index];
            } else {
                return { nome: '', alternativa_correta: false, order: String.fromCharCode(97 + index) };
            }
        });

        this.setState({
            quantidadeAlternativas: quantidade,
            alternativas: novasAlternativas,
        });
    };
    handleChangeAlternativa = (index, value) => {
        const novasAlternativas = [...this.state.alternativas];
        novasAlternativas[index] = { ...novasAlternativas[index], name: value };
        this.setState({ alternativas: novasAlternativas }, () => {
        });
    };


    handleSelectAlternativaCorreta = (event) => {
        this.setState({ alternativaCorreta: event.target.value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.salvar();
    };

    convertIndexToLetter(index) {
        return String.fromCharCode(97 + index);
    }

    editorOptions = {
        height: 400,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: 'insert_only_text',
        defaultActionOnPasteFromWord: 'insert_only_text',
        pasteHTMLActionList: [
            { value: 'insert_only_text', text: 'Insert only Text' }
        ],
        pasteFromWordActionList: [
            { value: 'insert_only_text', text: 'Insert only Text' }
        ],
        language: 'pt_br',
        toolbarButtonSize: 'large',
        "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,undo,redo,hr,link,symbol,table",
        uploader: {
            url: API_URL + 'faculdade/upload_image',
            insertImageAsBase64URI: false,
            imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
            headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
            filesVariableName: function (t) {
                return 'imagem';
            },
            withCredentials: false,
            pathVariableName: 'path',
            method: 'POST',
            prepareData: (formdata) => {
                return formdata;
            },
            isSuccess: (e) => {
                return 'Upload realizado com sucesso';
            },
            getMessage: (e) => {
                return e;
            },
            process: (resp) => {
                let files = [];
                files.unshift(STORAGE_URL + resp.imagem);

                return {
                    files: files,
                };
            },
            error: (elem, e) => {
                console.log(e);
                console.log(elem);
            },
            defaultHandlerSuccess: function (resp, elem) {
                if (resp.files && resp.files.length) {
                    resp.files.forEach((filename) => {
                        this.selection.insertImage(filename);
                    });
                }
            },
            defaultHandlerError: (elem, e) => {
                console.log(e);
                console.log(elem);
            },
        },
    }

    render() {
        const { loading, nome, enunciado, alternativas, redirect, path, msgErro, loadingSave, quantidadeAlternativas, alternativaCorreta } = this.state;
        const contentId = localStorage.getItem('idContent');

        if (redirect) {
            return <Redirect to={`${path}${contentId}`} />;
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body card-body-custom" id="card">
                            <h4 className="mt-0 header-title header-title-custom">Editar Questão</h4>
                            <p className="paragraph-custom">Edite as questões do seu sistema</p>
                            <br />

                            {msgErro && (
                                <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0 }}>{msgErro}</p>
                                    </div>
                                </div>
                            )}

                            {loading ? (
                                <div>
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                </div>
                            ) : (
                                <form className="position-relative" onSubmit={this.handleSubmit}>
                                    <div className="form-group flex fdcolumn flex1 margin-bottom">
                                        <label htmlFor="nome" className="label-custom">Nome</label>
                                        <input
                                            type="text"
                                            className="form-control input-custom"
                                            id="nome"
                                            placeholder="Nome da questão"
                                            value={nome}
                                            onChange={(e) => this.setState({ nome: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <Form.Group controlId="formEnunciado" className="mb-4">
                                        <Form.Label>Enunciado:</Form.Label>
                                        <JoditEditor
                                            value={enunciado}
                                            config={this.editorOptions}
                                            onBlur={(newContent) => this.handleChangeEnunciado(newContent)}
                                            onChange={newContent => { }}
                                        />
                                    </Form.Group>

                                    <div className="form-group flex fdcolumn flex1 margin-bottom">
                                        <label htmlFor="quantidadeAlternativas" className="label-custom">Quantidade de Alternativas</label>
                                        <select
                                            className="form-control"
                                            id="quantidadeAlternativas"
                                            value={quantidadeAlternativas}
                                            onChange={this.handleChangeQuantidadeAlternativas}
                                        >
                                            {[2, 3, 4, 5].map((number) => (
                                                <option key={number} value={number}>{number}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {[...Array(quantidadeAlternativas)].map((_, index) => (
                                        <Form.Group key={index} controlId={`formAlternativa${index}`} className="mb-4">
                                            <Form.Label>{`Alternativa ${String.fromCharCode(97 + index)})`}</Form.Label>
                                            <JoditEditor
                                                value={alternativas[index] ? alternativas[index].name : ''}
                                                config={this.editorOptions}
                                                onBlur={(newContent) => this.handleChangeAlternativa(index, newContent)}
                                                onChange={newContent => { }}
                                            />
                                        </Form.Group>
                                    ))}

                                    <Form.Group controlId="formAlternativaCorreta" className="mb-4">
                                        <Form.Label>Alternativa Correta:</Form.Label>
                                        <Form.Control as="select" value={alternativaCorreta} onChange={this.handleSelectAlternativaCorreta}>
                                            <option value="">Escolha a alternativa correta</option>
                                            {[...Array(quantidadeAlternativas)].map((_, index) => (
                                                <option key={index} value={String.fromCharCode(97 + index)}>
                                                    {`Alternativa ${String.fromCharCode(97 + index)})`}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <div className="w-100 d-flex justify-content-between mt-3">
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to={`/conteudo/show/${contentId}`}
                                        />
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar questão"
                                            loadingtext="Salvando questão"
                                            loading={loadingSave}
                                        />
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(EditarQuestaoConteudo);
