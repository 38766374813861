import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import Attention from "../ReusableComponents/Attention";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiCloseFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const ModalDemandaRedacao = props => {
    const { processo_name, showModal, setShowModal, professores_all, OnFinish,professorSelect,id } = props;
    const [professores_selecionadas, setprofessoresSelecionadas] = useState({});
    const [selectprofessor, setSelectprofessor] = useState('');
    const [professores, setProfessores] = useState('');


    const [descritivo, setDescritivo] = useState('');
    const [msgErro, setMsgErro] = useState('');


    const [professores_total, setprofessoresTotal] = useState({});

    useEffect(()=>{
        if(professorSelect!=null){
            console.log(professorSelect)
            console.log(professores_total);
            let professores_selecionadas_={}
            professores_selecionadas_[professorSelect.id] = { ...professorSelect };

            setprofessoresSelecionadas(professores_selecionadas_);
            setDescritivo(professorSelect.descritivo)
        }
    },[professorSelect])

    useEffect(() => {

        let professores_total_ = {};
        for (let i = 0; i < professores_all.length; i++) {
            professores_total_[professores_all[i].id] = professores_all[i];
        }
        setprofessoresTotal(professores_total_);

    }, []);

    const number_questoes = new Array(5).fill(0);
    console.log(number_questoes)


    return (
        <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => {
                setShowModal(false);
                setprofessoresSelecionadas({});
            }}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Adicionar atribuição<br />
            </Modal.Title></Modal.Header>
            <Modal.Body>
            {msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <p>Selecione um elaborador que devera criar uma ou mais redações nessa demanda</p>

                <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Descritivo <Attention /></Form.Label>
                        <Input
                            placeholder="Descritivo"
                            value={descritivo}
                            onChange={(e) => setDescritivo(e.target.value)}
                            required
                            as="textarea"
                        />
                    </div>
                </InputGroup>


                <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Elaboradores <Attention /></Form.Label>
                        <select className="form-select " onChange={(e) => {
                            if (e.target.value != '') {
                                let professores_selecionadas_ = { ...professores_selecionadas };
                               
                                professores_selecionadas_[e.target.value] = { ...professores_total[e.target.value] };

                                setprofessoresSelecionadas(professores_selecionadas_);
                            }
                        }} value={selectprofessor}>
                            <option value="">Selecione um ou mais elaboradores</option>
                            {Object.values(professores_total).sort((a, b) => a.name - b.name).map((value, index) => (
                                <option
                                    key={index}
                                    value={value["id"]}
                                >{value["name"]} - {value["email"]}</option>
                            ))}
                        </select>
                        {/* <a onClick={()=>{
                            let professores_selecionadas_ = { ...professores_selecionadas };
                            Object.values(professores_total).map((value, index) => {
                                professores_selecionadas_[value.id]=value;
                            })
                            setprofessoresSelecionadas(professores_selecionadas_);
                            setprofessoresTotal({});

                        }} style={{textAlign:'right',fontSize:15,marginTop:10,cursor:'pointer',textDecoration:'none'}} className="link-success">Selecionar todos as professores</a> */}

                    </div>
                </InputGroup>

                <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th data-priority="3">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(professores_selecionadas).sort((a, b) => a.created_at - b.created_at).map((item, id) => (
                                            <tr key={id}>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>
                                                    <span
                                                        onClick={(e) => {
                                                            let professores_selecionadas_ = { ...professores_selecionadas };
                                                            delete professores_selecionadas_[item.id];

                                                            setprofessoresSelecionadas(professores_selecionadas_);
                                                        }}
                                                        className="inline-flex align-center jcc bg-danger icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Deletar elaborador"
                                                    >
                                                        <MdDelete size={17} color="white" />
                                                    </span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {Object.values(professores_selecionadas).length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhum elaborador selecionado</p>

                            </div>
                        </div>}
                    </div>

                </InputGroup>


                {<div className="w100 inline-flex jcsb margin-top-1">
                    {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                    <DefaultButton
                        bg="secondary"
                        text="Fechar"
                        icon={<RiCloseFill />}
                        onClick={() => {
                            setShowModal(false);
                            setprofessoresSelecionadas({});
                        }}
                    />
                    {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                    <DefaultButton
                        type="button"
                        disabled={Object.values(professores_selecionadas).length == 0}

                        onClick={() => {
                            if(descritivo.trim().length==0){
                                setMsgErro('O campo Descritivo é obrigatório');
                                return;
                            }
                            let professor = Object.values({ ...professores_selecionadas })[0];
                            professor.demanda_id=professorSelect?.demanda_id;
                            OnFinish(professor,descritivo,id);
                            setShowModal(false);

                            setprofessoresSelecionadas({});
                            setDescritivo('');
                            setMsgErro('');


                        }}
                        bg={Object.values(professores_selecionadas).length > 0 ? "confirm" : "secondary"}
                        text="Salvar"
                    />
                </div>}

            </Modal.Body>
        </Modal>

    );
}

export default ModalDemandaRedacao;