import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/AppActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'react-bootstrap';
import { BsFillEyeFill } from "react-icons/bs";
import { FaListAlt } from 'react-icons/fa';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { Pagination } from '@material-ui/lab';
import SweetAlert from 'react-bootstrap-sweetalert';
import Search from '../ReusableComponents/Search';
import { URL } from '../../variables';

class Modulos extends Component {
    state = {
        redirect: false,
        modules: [],
        loading: false,
        loading_search: true,
        showDeleteConfirmation: false,
        pagination: {
            current_page: 1,
            last_page: 1,
        },
        moduleToDelete: '',
        errorMsg: '',
        search: '',
        isMounted: false,
    };

    componentDidMount() {
        this.setState({ isMounted: true });
        this.getModules(this.state.pagination.current_page);
    }

    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    getModules = async (page, search = '', busca = true) => {
        if (busca && !this.state.loading_search) {
            this.setState({ loading_search: true });
        } else if (!busca && !this.state.loading) {
            this.setState({ loading: true });
        }

        try {
            const response = await fetch(`${URL}api/modulo/get_modules?page=${page}&search=${search}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar módulos');
            }
            const data = await response.json();

            if (this.state.isMounted) {
                this.setState({ modules: data.data || [], pagination: data.pagination || { current_page: 1, last_page: 1 } });
            }

        } catch (error) {
            console.error(error.message || 'Erro ao buscar módulos');
            if (this.state.isMounted) {
                this.setState({ errorMsg: error.message || 'Erro ao buscar módulos', redirect: true });
            }

        } finally {
            if (this.state.isMounted) {
                this.setState({ loading_search: false, loading: false });
            }
        }
    };

    handleDeleteModule = async (moduleId) => {
        try {
            const response = await fetch(`${URL}api/modulo/delete_module/${moduleId}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Erro ao excluir módulo');
            }
            await this.getModules(this.state.pagination.current_page, this.state.search, false);

        } catch (error) {
            console.error(error.message || 'Erro ao excluir módulo');
            if (this.state.isMounted) {
                this.setState({ errorMsg: error.message || 'Erro ao excluir módulo' });
            }

        } finally {
            if (this.state.isMounted) {
                this.setState({ showDeleteConfirmation: false });
            }
        }
    };

    handleSearchChange = (value) => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.setState({
                pagination: { ...this.state.pagination, current_page: 1 },
                search: value,
                loading_search: true,
            });
            this.getModules(1, value);
        }, 500);
    };

    formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        const horas = String(data.getHours()).padStart(2, '0');
        const minutos = String(data.getMinutes()).padStart(2, '0');
        const segundos = String(data.getSeconds()).padStart(2, '0');

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    }


    render() {
        const { redirect, modules, loading, loading_search, showDeleteConfirmation, moduleToDelete, errorMsg, pagination } = this.state;

        if (redirect) {
            return <Redirect to="/modulos" />;
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Módulos</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os módulos do seu sistema</p>
                            <br />
                            <div className="row">
                                <div className="col-12">
                                    <div className="flex jcsb flex-wrap mb-4">
                                        <Search placeholder="Pesquisar pelo nome do módulo" ValueChange={this.handleSearchChange} />
                                        <Link to="/modulo/register/" style={{ textDecoration: "none" }}>
                                            <Button variant="success" className="flex align-center jcc">
                                                <FaListAlt style={{ marginRight: 10 }} />
                                                <p style={{ margin: 0 }}>Adicionar novo módulo</p>
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="table-rep-plugin">
                                        {loading ? (
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Data de criação</th>
                                                            <th>Ações</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!loading_search && modules.map((module, index) => (
                                                            <tr key={index}>
                                                                <td>{module.name}</td>
                                                                <td>{this.formatarData(module.created_at)}</td>


                                                                <td>
                                                                    <Link to={`/modulo/show/${module.id}`} onClick={(e) => e.stopPropagation()}>
                                                                        <span className="inline-flex align-center jcc bg-warning icon" style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }} title="Gerenciar">
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                    </Link>
                                                                    <Link to={`/modulo/edit/${module.id}`} style={{ cursor: 'pointer' }}>
                                                                        <span
                                                                            className="inline-flex align-center jcc bg-info icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }}
                                                                            title="Editar demanda"
                                                                        >
                                                                            <MdModeEdit color="white" size={17} />
                                                                        </span>
                                                                    </Link>
                                                                    <span
                                                                        onClick={() => this.setState({ showDeleteConfirmation: true, moduleToDelete: module.id })}
                                                                        className="inline-flex align-center jcc bg-danger icon"
                                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                        title="Deletar demanda"
                                                                    >
                                                                        <MdDelete size={17} color="white" />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                    {loading_search && (
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    )}
                                    {!loading_search && modules.length === 0 && (
                                        <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhuma disciplina cadastrada</p>
                                            </div>
                                        </div>
                                    )}
                                    <Pagination
                                        className={(pagination && pagination.last_page === 1 ? "hide" : "")}
                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                        count={pagination.last_page}
                                        page={pagination.current_page}
                                        onChange={(_, value) => {
                                            this.setState({ pagination: { ...pagination, current_page: value } });
                                            this.getModules(value, this.state.search, false);
                                        }}
                                    />
                                    <SweetAlert
                                        warning
                                        title={"Atenção"}
                                        show={showDeleteConfirmation}
                                        onConfirm={() => this.handleDeleteModule(moduleToDelete)}
                                        onCancel={() => this.setState({ showDeleteConfirmation: false })}
                                        confirmBtnText="Sim, desejo apagar"
                                        cancelBtnText="Cancelar"
                                    >
                                        {errorMsg ? errorMsg : "Ao deletar este módulo, ele não estará mais disponível no sistema"}
                                    </SweetAlert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(Modulos);
