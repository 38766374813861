import React, { useState } from 'react';
import Attention from '../ReusableComponents/Attention';
import { URL } from '../../variables';
import { connect } from 'react-redux';
import { logout } from '../../actions/AppActions';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import DefaultButton from '../ReusableComponents/DefaultButton';

const CriarConteudo = ({ token }) => {
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [file, setFile] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const [msgErro, setMsgErro] = useState('');
    const [filePreview, setFilePreview] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState(null);
    const [hasQuestions, setHasQuestions] = useState(false);
    const history = useHistory();


    const pdfUpload = React.useRef();
    const videoUpload = React.useRef();
    const imageUpload = React.useRef();

    const salvar = (e) => {
        e.preventDefault();
        setLoadingSave(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('type', type);
        formData.append('file', file);
        formData.append('has_questions', hasQuestions ? 'true' : 'false');

        if (thumbnail !== null) {
            formData.append('thumbnail', thumbnail);
        }

        return fetch(`${URL}api/conteudo/store_content`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: formData

        })

            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao cadastrar conteúdo');
                }
                return response.json();
            })
            .then(data => {
                setMsgErro('Conteúdo cadastrado com sucesso');
                setLoadingSave(false);
                history.push("/conteudos");

                return data;
            })
            .catch(error => {
                setMsgErro(error.message || 'Erro ao cadastrar conteúdo');
                setLoadingSave(false);
                throw error;
            });
    };





    const handleNomeChange = (e) => {
        setName(e.target.value);
    };
    const handleTypeChange = (e) => {
        setType(e.target.value);
        setFilePreview(null);
        setHasQuestions(false);
    };


    const handlePdfFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFilePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setFile(event.target.files[0]);
    };

    const handleVideoFileChange = (event) => {
        setFile(event.target.files[0]);
        setHasQuestions(false);
    };

    const handleCapaImageFileChange = (event) => {
        const thumbnail = event.target.files[0];
        if (thumbnail) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setThumbnailPreview(reader.result);
            };
            reader.readAsDataURL(thumbnail);
        }
        setThumbnail(event.target.files[0]);
    };

    const removeFilePDF = (e) => {
        e.preventDefault();
        setFilePreview(null);
        if (pdfUpload.current) {
            pdfUpload.current.value = "";
        }
    }
    const removeFileAllVideo = (e) => {
        e.preventDefault();
        setFilePreview(null);
        if (videoUpload.current || imageUpload.current) {
            videoUpload.current.value = "";
            imageUpload.current.value = "";
        }
    }
    const removeFileVideo = (e) => {
        e.preventDefault();
        if (videoUpload.current) {
            videoUpload.current.value = "";
        }
    }
    const removeFileImage = (e) => {
        e.preventDefault();
        setFilePreview(null);
        if (imageUpload.current) {
            imageUpload.current.value = "";
        }
    }



    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body card-body-custom" id="card">
                        <h4 className="mt-0 header-title header-title-custom">Cadastrar Conteúdo</h4>
                        <p className="paragraph-custom">Cadastre os conteúdos do seu sistema</p>
                        {msgErro && (

                            <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>
                                    <p style={{ marginBottom: 0 }}>{msgErro}</p>
                                </div>
                            </div>
                        )}

                        <form onSubmit={salvar} className="position-relative">
                            <div className="form-group mb-3">
                                <label htmlFor="nome" className="label-custom">Nome <Attention /></label>
                                <input
                                    type="text"
                                    className="form-control input-custom"
                                    id="nome"
                                    placeholder="Nome do conteúdo"
                                    value={name}
                                    onChange={handleNomeChange}
                                    required
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="tipo" className="label-custom">Tipo <Attention /></label>
                                <select
                                    className="form-control input-custom"
                                    id="tipo"
                                    value={type}
                                    onChange={handleTypeChange}
                                    required
                                >
                                    <option value="">Selecione qual será o tipo do arquivo</option>
                                    <option value="pdf">PDF</option>
                                    <option value="video">Vídeo</option>
                                </select>
                            </div>
                            {type === 'pdf' && (
                                <div className="form-group mb-3">
                                    <label htmlFor="pdfUpload" className="label-custom">Upload do PDF <Attention /></label>
                                    <input
                                        type="file"
                                        className="form-control input-custom"
                                        id="pdfUpload"
                                        accept=".pdf"
                                        onChange={handlePdfFileChange}
                                        ref={pdfUpload}
                                    />
                                    {filePreview && (
                                        <div className="mt-3">
                                            <label className="label-custom">Pré-visualização do PDF</label>
                                            <iframe
                                                src={filePreview}
                                                style={{ width: '100%', height: '500px' }}
                                                frameBorder="0"
                                            />
                                        </div>
                                    )}
                                    <button type="button" className="btn btn-danger btn-sm mt-3" onClick={e => removeFilePDF(e)}>Limpar arquivo</button>
                                </div>
                            )}
                            {type === 'video' && (
                                <div className="form-group mb-3">


                                    <label htmlFor="videoUpload" className="label-custom d-block mb-2">Upload do Vídeo (Menor do que 8 Mb) <Attention /></label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="file"
                                            className="form-control input-custom"
                                            id="videoUpload"
                                            accept=".mp4,.avi,.mov,.wmv,.mkv,.flv,.webm,.mpeg,.mpg,.3gp,.m4v"
                                            onChange={handleVideoFileChange}
                                            ref={videoUpload}
                                        />
                                        <button type="button" className="btn btn-danger" onClick={e => removeFileVideo(e)}>Limpar</button>
                                    </div>


                                    <label htmlFor="videoUpload" className="label-custom">Upload da Capa do Vídeo</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="file"
                                            className="form-control input-custom"
                                            id="capaImage"
                                            accept=".jpg,.jpeg,.png,.bmp,.tiff,.tif,.svg,.webp"
                                            onChange={handleCapaImageFileChange}
                                            ref={imageUpload}
                                        />
                                        <button type="button" className="btn btn-danger" onClick={e => removeFileImage(e)}>Limpar</button>
                                    </div>


                                    {thumbnailPreview && (
                                        <div className="form-group mb-3">
                                            <label className="label-custom">Pré-visualização do Arquivo</label>
                                            <div className="preview-container">
                                                <img src={thumbnailPreview} alt="Pré-visualização" className="img-preview" />
                                            </div>
                                        </div>
                                    )}
                                    <button type="button" className="btn btn-danger mt-3" onClick={e => removeFileAllVideo(e)}>Limpar todos os arquivos</button>
                                </div>
                            )}



                            <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="hasQuestions"
                                    checked={hasQuestions}
                                    onChange={() => setHasQuestions(!hasQuestions)}
                                />
                                <label className="form-check-label" htmlFor="hasQuestions">
                                    Há questões neste conteúdo?
                                </label>
                            </div>


                            <div className="d-flex justify-content-between mt-3">
                                <DefaultButton
                                    bg="secondary"
                                    text="Voltar"
                                    icon={<RiArrowGoBackLine />}
                                    to="/conteudos"
                                />
                                <DefaultButton
                                    type="submit"
                                    bg="confirm"
                                    text="Salvar conteúdo"
                                    disabled={loadingSave}
                                    loadingtext="Salvando conteúdo"
                                    loading={loadingSave}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

};

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(CriarConteudo);