import JoditEditor from "jodit-react";
import React, { Component, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { API_URL, STORAGE_URL, URL } from "../../variables";
import { Form, FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";
import Btn from '@mui/material/Button';
import Input from '@mui/material/Input';
import { Checkbox, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { uuid } from 'uuidv4';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";


class VerQuestionarioVinculado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            enunciado: '',

            loading_save: false,
            msgErro: '',
            loading_image_enunciado: false,
            loading_image_a: false,
            loading_image_b: false,
            loading_image_c: false,
            loading_image_d: false,
            loading_image_e: false,
            correta: '',
            respostas: {},
            visao_candidato: false,
            alternativas_inputs: [{ type: 'radio', name: '', uuid: uuid() }],
            type_questao: 'multipla_escolha',
            questoes: []
        };
        this.load_configs();



    }



    renderTypeQuestao(e) {
        if (e == 'multipla_escolha') {
            return 'Escolha múltipla';
        }
        if (e == 'paragrafo') {
            return 'Parágrafo';
        }
    }



    load_configs() {
        this.config_enunciado = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_enunciado,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_enunciado: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_enunciado: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_enunciado: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }

    componentDidMount() {
        console.log(this.props.questionario)
        let respostas = {};
        for (let i = 0; i < this.props.questionario.questoes.length; i++) {
            let questao = this.props.questionario.questoes[i];
            respostas[questao.id] = { type: questao.type_questao, 'alternativa_id': '', textos: {}, checkboxs: {} };
            for (let j = 0; j < questao.alternativas_inputs.length; j++) {
                let alternativa = questao.alternativas_inputs[j];
                if (alternativa.type == 'texto') {
                    respostas[questao.id]['textos'][alternativa.uuid] = { 'texto': '', 'required': alternativa.required };
                }
                else if (alternativa.type == 'checkbox') {
                    respostas[questao.id]['checkboxs'][alternativa.uuid] = false;
                }
            }
        }

        this.setState({ respostas, visao_candidato: false });
    }




    render() {
        console.log(this.state.visao_candidato);
        return (
            <div className="row">

                <div className="col-12">
                    <p className="text-muted">Aqui ficam as questões do questionário "{this.props.questionario.name}"</p>

                    <div style={{  }}>

                    <div style={{ marginTop: 15, border: "1px solid #dadce0", borderRadius: 8, transition: "box-shadow .25s cubic-bezier(0,0,0.2,1)", backgroundColor: '#fff', padding: 15 }}>
                        <InputGroup style={{ marginTop: 10 }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => {
                                console.log(e.target.checked)
                                this.setState({ visao_candidato: e.target.checked })
                            }} value={uuid()} checked={this.state.visao_candidato} />} style={{ flexGrow: 1 }} label={'Visão do candidato'} />

                        </InputGroup>
                    </div>
                    {this.props.questionario.questoes != undefined && this.props.questionario.questoes.map((questao, id5) => {
                        let radio_index=0;

                        if (this.state.visao_candidato == true) {
                            return (Object.values(this.state.respostas).length != 0 && <div key={id5} style={{ marginTop: 15, border: "1px solid #dadce0", borderRadius: 8, transition: "box-shadow .25s cubic-bezier(0,0,0.2,1)", backgroundColor: '#fff', padding: 15 }}>

                                <InputGroup>
                                    {/* {<InputGroup>
                                    <div className="flex fdcolumn flex1 margin-bottom">
                                        <Form.Label>Tipo de questão <Attention /></Form.Label>
                                        <p style={{ marginBottom: 0 }}>{this.renderTypeQuestao(questao.type_questao)}</p>

                                       
                                    </div>
                                </InputGroup>} */}

                                    <div className="flex fdcolumn flex1 ">
                                        <Form.Label >Pergunta {id5 + 1}) <Attention/></Form.Label>
                                        <p style={{ marginBottom: 0 }}>{questao.name}</p>

                                    </div>
                                </InputGroup>



                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={this.state.respostas[questao.id]['alternativa_id']}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        let respostas = { ...this.state.respostas };
                                        respostas[questao.id]['alternativa_id'] = e.target.value;
                                        console.log(respostas);
                                        this.setState({ respostas });
                                    }}
                                >
                                    {questao.alternativas_inputs.map((item, id) => {
                                        if (item.type == 'checkbox') {
                                            console.log(this.state.respostas[questao.id]['checkboxs'][item.uuid])
                                            return (<InputGroup key={id} style={{ marginTop: 10 }}>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                    if (questao.type_questao == 'paragrafo') {
                                                        let respostas = { ...this.state.respostas };
                                                        respostas[questao.id]['checkboxs'][item.uuid] = e.target.checked;
                                                        respostas[questao.id]['textos'][Object.keys(respostas[questao.id]['textos'])[0]]['texto'] = '';
                                                        console.log(respostas);
                                                        this.setState({ respostas });
                                                    }

                                                }} value={item.uuid} checked={this.state.respostas[questao.id]['checkboxs'][item.uuid]} />} style={{ flexGrow: 1 }} label={item.name} />

                                            </InputGroup>);
                                        }
                                        else if (item.type == 'radio') {
                                            return (
                                                <InputGroup key={id} style={{ marginTop: 10 }}>

                                                    <FormControlLabel control={<Radio value={item.uuid} />} style={{ color: 'black' }} label={item.name} />


                                                </InputGroup>
                                            );
                                        }
                                        else if (item.type == 'texto') {
                                            return (
                                                (questao.type_questao == 'paragrafo'
                                                    || this.state.respostas[questao.id]['textos'][item.uuid]['required'] == 'required'
                                                    || this.state.respostas[questao.id]['textos'][item.uuid]['required'] == 'not_required'
                                                    || (this.state.respostas[questao.id]['alternativa_id'] == this.state.respostas[questao.id]['textos'][item.uuid]['required'])) && <div key={id} style={{ marginTop: 10 }}>
                                                    {questao.type_questao == 'multipla_escolha' && <p style={{ marginBottom: 0, fontWeight: 'bold' }}>{item.name}</p>}
                                                    <InputGroup >
                                                        <Input style={{ flexGrow: 1 }} disabled={questao.type_questao == 'paragrafo' && this.state.respostas[questao.id]['checkboxs'][Object.keys(this.state.respostas[questao.id]['checkboxs'])] == true} placeholder={item.name} value={this.state.respostas[questao.id]['textos'][item.uuid]['texto']} onChange={(e) => {
                                                            let respostas = { ...this.state.respostas };
                                                            respostas[questao.id]['textos'][item.uuid]['texto'] = e.target.value;
                                                            console.log(respostas);
                                                            this.setState({ respostas });
                                                        }} />
                                                        {questao.type_questao=='multipla_escolha' && this.state.respostas[questao.id]['textos'][item.uuid]['required']!= 'not_required'  &&  <Attention />}


                                                    </InputGroup>
                                                </div>
                                            );
                                        }
                                    })}
                                </RadioGroup>
                            </div>);
                        }
                        else {
                            return (<div key={id5} style={{ marginTop: 15, border: "1px solid #dadce0", borderRadius: 8, transition: "box-shadow .25s cubic-bezier(0,0,0.2,1)", backgroundColor: '#fff', padding: 10 }}>

                                <InputGroup>
                                    {/* {<InputGroup>
                                    <div className="flex fdcolumn flex1 margin-bottom">
                                        <Form.Label>Tipo de questão <Attention /></Form.Label>
                                        <p style={{ marginBottom: 0 }}>{this.renderTypeQuestao(questao.type_questao)}</p>

                                       
                                    </div>
                                </InputGroup>} */}

                                    <div className="flex fdcolumn flex1 ">
                                        <Form.Label >Pergunta {id5 + 1})</Form.Label>
                                        <p style={{ marginBottom: 0 }}>{questao.name}</p>

                                    </div>
                                </InputGroup>



                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={''}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                    }}
                                >
                                    {questao.alternativas_inputs.map((item, id) => {
                                        if (item.type == 'checkbox') {
                                            return (<InputGroup key={id} style={{ marginTop: 10 }}>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {

                                                }} value={item.uuid} checked={false} />} style={{ flexGrow: 1 }} label={item.name} />

                                            </InputGroup>);
                                        }
                                        else if (item.type == 'radio') {
                                radio_index=radio_index+1;

                                            return (
                                                <InputGroup key={id} style={{ marginTop: 10 }}>

                                                    <FormControlLabel control={<Radio value={item.uuid} />} style={{ color: 'black' }} label={'(' + (radio_index) + ')  '+item.name} />


                                                </InputGroup>
                                            );
                                        }
                                        else if (item.type == 'texto') {
                                            return (
                                                <div key={id} style={{ marginTop: 10 }}>
                                                    {questao.type_questao == 'multipla_escolha' && <p style={{ marginBottom: 0, fontWeight: 'bold' }}>{item.name}</p>}
                                                    <InputGroup >
                                                        <Input style={{ flexGrow: 1 }} placeholder={item.name} value={''} onChange={(e) => {

                                                        }} />

                                                        {questao.type_questao == 'multipla_escolha' && <div style={{ display: 'flex' }}>

                                                            {/* <InputLabel id={"label"+id}>Obrigatório se</InputLabel> */}
                                                            <Select
                                                                // labelId={"label"+id}
                                                                // id={"select"+id}
                                                                disabled
                                                                SelectDisplayProps={{
                                                                    height: 'fit-content',
                                                                }}
                                                                variant='standard'
                                                                value={item.required}
                                                                onChange={(e) => {
                                                                    let alternativas_inputs = [...questao.alternativas_inputs];
                                                                    alternativas_inputs[id].required = e.target.value;
                                                                    this.setState({ alternativas_inputs });
                                                                }}
                                                                // label="Obrigatório se"
                                                                style={{ marginRight: 10, borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0 }}
                                                            >
                                                                <MenuItem value="not_required">
                                                                    Não é obrigatório
                                                                </MenuItem>
                                                                <MenuItem value={'required'}>Obrigatório sempre</MenuItem>
                                                                {questao.alternativas_inputs.filter((item2, id2) => item2.type == 'radio').map((item2, id2) => (
                                                                    <MenuItem key={id2} value={item2.uuid}>Obrigatório se assinalado "({id2 + 1})"</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>}
                                                    </InputGroup>
                                                </div>
                                            );
                                        }
                                    })}
                                </RadioGroup>
                            </div>);
                        }
                    })}
                    </div>

                </div>
            </div>

        );
    }
}

export default VerQuestionarioVinculado;