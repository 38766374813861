




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdCheck, MdContentCopy, MdCopyAll, MdOutlineRule, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import TabPanel from "../ReusableComponents/TabPanel";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import './label.css';
import user4 from '../../assets/images/default.png';
import ImagesAnalises from './ImagesAnalises';
import Relatorio from '../CandidatosVinculados/Relatorio';

class ShowAnaliseObjeto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            log: {},
            activeTab: 0,
            show_more: false,
            processo:{}
        };

    }


    get_analises_candidato(id,processo_id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_analises_candidato_objetos/${id}/${processo_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, user: resp.user, processo:resp.processo
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    componentDidMount() {
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.get_analises_candidato(this.props.match.params.id,this.props.match.params.processo_id);
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div style={{ position: "relative" }}>
                <div className="card">
                    <div className="card-body">
                        <div style={{ float: 'right' }}>
                            <DefaultButton
                                bg="secondary"
                                text="Voltar"
                                icon={<RiArrowGoBackLine />}
                                search={'?processo_id='+this.state.processo.id}

                                to={`/instituicao/dashboard/${this.state.processo.faculdade_id}`}
                            />
                        </div>
                        <div className="flex jcsb">
                            <h4 className="header">{this.state.user.name}</h4>
                        </div>


                        {/* <TextReadMore title="Responsável: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10 }}>
                            {responsavel}
                        </TextReadMore>
                        <TextReadMore title="Descrição: " element="div" limit={120} style={{ width: "100%" }}>
                            {course_description}
                        </TextReadMore> */}

                        <br />
                        <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div className="course-banner" style={{
                                backgroundImage:this.state.user.imagem!=null? `url(${STORAGE_URL + this.state.user.imagem})`:`url(${user4})`,
                                backgroundSize: "contain",
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: 'center',
                                width: "100%",
                                height: "200px",
                                borderRadius: "7px"
                            }} />
                        </div>

                        <br />
                        <div className="w100 margin-bottom">

                            <TextReadMore title="E-mail: " element="div" limit={120} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                {this.state.user.email}
                            </TextReadMore>

                            <TextReadMore title="Primeiro Login: " element="div" limit={120} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                {this.state.user.first_last == null ? 'Nunca acessou o sistema' : moment(this.state.user.first_last.first).format('DD/MM/YYYY HH:mm:ss')}
                            </TextReadMore>

                            <TextReadMore title="Último Login: " element="div" limit={120} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                {this.state.user.first_last == null ? 'Nunca acessou o sistema' : moment(this.state.user.first_last.last).format('DD/MM/YYYY HH:mm:ss')}
                            </TextReadMore>

                        </div>



                        <Tabs
                            value={this.state.activeTab}
                            onChange={(_, value) => {
                                this.setState({ activeTab: value });
                                this.props.history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label="Imagens" />
                            <Tab label="Relatórios" />

                        </Tabs>
                        <TabPanel value={this.state.activeTab} index={0}>
                            <ImagesAnalises processo_id={this.state.processo.id} candidato_id={this.state.user.id} candidato_name={this.state.user.name} />

                        </TabPanel>

                        <TabPanel value={this.state.activeTab} index={1}>
                        <Relatorio processo_id={this.state.processo.id} candidato_id={this.state.user.id} candidato_name={this.state.user.name} />

                        </TabPanel>


                    </div>
                </div>

            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowAnaliseObjeto);


