import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { URL } from "../../variables";

const BancaQuestoes = ({ user, token }) => {
  const [questoes, setQuestoes] = useState([]);
  const [filteredQuestoes, setFilteredQuestoes] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [filters, setFilters] = useState({
    tipo_questao: "",
    escolaridade: "",
    nivel_dificuldade: "",
    disciplina: "",
  });
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState(''); // Adicione este estado
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchQuestoes = async () => {
      if (!user || !user.id) {
        console.warn("User ou user.id não estão disponíveis.");
        return;
      }
      try {
        const response = await axios.post(
          `${URL}api/listar_questoes`,
          { user_id: user.id },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Adiciona o token ao cabeçalho
            },
          }
        );
        const questoes = response.data.questoes;
        setQuestoes(questoes);
        setFilteredQuestoes(questoes);

        // Extract unique disciplines
        const uniqueDisciplines = [
          ...new Set(questoes.map((q) => q.disciplina)),
        ];
        setDisciplines(uniqueDisciplines);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Erro ao buscar questões: Endpoint não encontrado.");
        } else {
          console.error("Erro ao buscar questões:", error);
        }
      }
    };

    fetchQuestoes();
  }, [user, token]);

  // Função para remover uma questão
  const removerQuestao = async (id) => {
    try {
      await axios.post(`${URL}api/remover_questao/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`, // Adiciona o token ao cabeçalho
        },
      });
      setQuestoes((questoesAnteriores) =>
        questoesAnteriores.filter((questao) => questao.id !== id)
      );
      setFilteredQuestoes((questoesAnteriores) =>
        questoesAnteriores.filter((questao) => questao.id !== id)
      );
    } catch (error) {
      console.error("Erro ao remover a questão:", error);
    }
  };

  const applyFilters = () => {
    const filtered = questoes.filter((questao) => {
      return (
        (filters.tipo_questao === "" || questao.tipo_questao === filters.tipo_questao) &&
        (filters.escolaridade === "" || questao.escolaridade === filters.escolaridade) &&
        (filters.nivel_dificuldade === "" || questao.nivel_dificuldade === filters.nivel_dificuldade) &&
        (filters.disciplina === "" || questao.disciplina === filters.disciplina)
      );
    });

    setFilteredQuestoes(filtered);
    setFiltersApplied(true);

    if (filtered.length === 0) {
      setErrorMessage('Nenhuma questão encontrada com os filtros aplicados.');
    } else {
      setErrorMessage('');
    }
  };

  // Calculate the items to display based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredQuestoes.slice(indexOfFirstItem, indexOfLastItem);

  // Render options for discipline filter
  const renderDisciplineOptions = () => {
    return disciplines.map((disciplina, index) => (
      <option key={index} value={disciplina}>
        {disciplina}
      </option>
    ));
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Render pagination controls
  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredQuestoes.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center mt-4">
          {pageNumbers.map((number) => (
            <li
              key={number}
             
            >
              <button
                onClick={() => handlePageChange(number)}
                className="page-link"
                style={{
                  color: currentPage === number ? '#fff' : '#6c757d',
                  backgroundColor: currentPage === number ? '#6c757d' : '#f1f1f1',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '8px',
                  transition: 'background-color 0.3s, color 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = currentPage === number ? '#5a6268' : '#e2e6ea';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = currentPage === number ? '#6c757d' : '#f1f1f1';
                }}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <div className="banca-questoes container mt-5">
      <div className="card p-4 shadow-sm">
        <h1 className="text-center mb-4">Banca de Questões</h1>
        <p className="text-center">Bem-vindo à Banca de Questões!</p>

        {/* Filtro de Questões */}
        <div className="mb-4">
          <h5 className="text-center mb-3">Filtrar Questões</h5>
          <div className="row justify-content-center">
            <div className="col-10 col-md-3 mb-3">
              <label htmlFor="tipo_questao" className="form-label text-muted">
                Tipo de Questão
              </label>
              <select
                id="tipo_questao"
                name="tipo_questao"
                className="form-select"
                value={filters.tipo_questao}
                onChange={(e) =>
                  setFilters({ ...filters, tipo_questao: e.target.value })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="">Todos</option>
                <option value="objetiva">Objetiva</option>
                <option value="dissertativa">Dissertativa</option>
              </select>
            </div>
            <div className="col-10 col-md-3 mb-3">
              <label htmlFor="escolaridade" className="form-label text-muted">
                Escolaridade
              </label>
              <select
                id="escolaridade"
                name="escolaridade"
                className="form-select"
                value={filters.escolaridade}
                onChange={(e) =>
                  setFilters({ ...filters, escolaridade: e.target.value })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="">Todos</option>
                <option value="Ensino Fundamental">Ensino Fundamental</option>
                <option value="Ensino Médio">Ensino Médio</option>
                <option value="Ensino Superior">Ensino Superior</option>
              </select>
            </div>
            <div className="col-10 col-md-3 mb-3">
              <label
                htmlFor="nivel_dificuldade"
                className="form-label text-muted"
              >
                Nível de Dificuldade
              </label>
              <select
                id="nivel_dificuldade"
                name="nivel_dificuldade"
                className="form-select"
                value={filters.nivel_dificuldade}
                onChange={(e) =>
                  setFilters({ ...filters, nivel_dificuldade: e.target.value })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="">Todos</option>
                <option value="Fácil">Fácil</option>
                <option value="Médio">Médio</option>
                <option value="Difícil">Difícil</option>
              </select>
            </div>
            <div className="col-10 col-md-3 mb-3">
              <label htmlFor="disciplina" className="form-label text-muted">
                Disciplina
              </label>
              <select
                id="disciplina"
                name="disciplina"
                className="form-select"
                value={filters.disciplina}
                onChange={(e) =>
                  setFilters({ ...filters, disciplina: e.target.value })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="">Todos</option>
                {renderDisciplineOptions()}
              </select>
            </div>
          </div>
          <div className="text-center mt-3">
            <button
              className="btn btn-primary px-4 py-2"
              style={{ borderRadius: "8px" }}
              onClick={applyFilters}
            >
              Aplicar Filtros
            </button>
          </div>
        </div>

        {/* Renderizar lista de questões apenas se os filtros foram aplicados */}
        {filtersApplied && (
          <>
            {errorMessage && <p className="alert alert-warning">{errorMessage}</p>}
            <ul className="list-group">
              {currentItems.map((questao, index) => (
                <li
                  key={index}
                  className="list-group-item rounded-3 shadow-lg mb-4 p-4 border-0"
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <div style={{ flex: 1 }}>
                      <h5 className="fw-bold">
                        {questao.enunciado || `Questão ${index + 1}`}
                      </h5>
                      <hr className="my-3" />

                      <p className="text-secondary mb-1">
                        <strong>Disciplina:</strong> {questao.disciplina}
                      </p>
                      <p className="text-secondary mb-1">
                        <strong>Tipo:</strong>{" "}
                        {questao.tipo_questao === "simples"
                          ? "Objetiva"
                          : questao.tipo_questao}
                      </p>
                      <p className="text-secondary mb-1">
                        <strong>Nível de Dificuldade:</strong>{" "}
                        {questao.nivel_dificuldade}
                      </p>
                      <p className="text-secondary mb-1">
                        <strong>Escolaridade:</strong> {questao.escolaridade}
                      </p>

                      {(questao.tipo_questao === "objetiva" ||
                        questao.tipo_questao === "simples") &&
                        questao.alternativas && (
                          <div className="mt-3">
                            <strong className="text-secondary">
                              Alternativas:
                            </strong>
                            <ul className="list-unstyled ps-3 mt-2">
                              {(() => {
                                try {
                                  const alternativas = JSON.parse(
                                    questao.alternativas
                                  );
                                  return Array.isArray(alternativas)
                                    ? alternativas.map((alt, altIndex) => (
                                        <li
                                          key={altIndex}
                                          className="text-muted py-1"
                                        >
                                          <span className="text-dark fw-bold">
                                            {String.fromCharCode(97 + altIndex)})
                                          </span>{" "}
                                          {alt}
                                        </li>
                                      ))
                                    : null;
                                } catch (error) {
                                  console.error(
                                    "Erro ao analisar alternativas:",
                                    error
                                  );
                                  return null;
                                }
                              })()}
                            </ul>
                          </div>
                        )}

                      <hr className="my-3" />
                      <p className="text-secondary">
                        <strong>Solução:</strong> {questao.resposta_solucao}
                      </p>
                    </div>

                    <button
                      className="btn btn-danger btn-sm rounded-pill"
                      onClick={() => removerQuestao(questao.id)}
                    >
                      Remover
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            {renderPagination()}
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
  }),
  null
)(BancaQuestoes);
