// Library Imports
import React, { useState, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { RiArrowGoBackLine, RiCloseCircleLine } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components Imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import Attention from "../ReusableComponents/Attention";

// Utils Imports
import { logout } from "../../actions/AppActions";
import { get, post } from "../../Services/api";

const CriarModulo = ({ token, history }) => {
  const [formData, setFormData] = useState({
    name: "",
    descricao: "",
    loadingSave: false,
    msgErro: "",
    contents: [],
    loading: true,
    conteudoIds: [],
    selectedContents: [],
  });

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const getContent = async () => {
      try {
        const data = await get("api/conteudo/get_contents", token, signal);

        if (isMounted) {
          setFormData((prevState) => ({
            ...prevState,
            contents: data.data.data || [],
            loading: false,
          }));
        }
      } catch (error) {
        if (error.name !== "AbortError" && isMounted) {
          console.log(error.message || "Erro ao buscar conteúdos");
          setFormData((prevState) => ({
            ...prevState,
            msgErro: error.message || "Erro ao buscar conteúdos",
            loading: false,
          }));
        }
      }
    };

    getContent();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (id) => {
    setFormData((prevState) => {
      const conteudoIds = prevState.conteudoIds.includes(id)
        ? prevState.conteudoIds.filter((conteudoId) => conteudoId !== id)
        : [...prevState.conteudoIds, id];

      const selectedContents = prevState.contents.filter((content) =>
        conteudoIds.includes(content.id)
      );

      return {
        ...prevState,
        conteudoIds,
        selectedContents,
      };
    });
  };

  const salvar = async (e) => {
    e.preventDefault();
    setFormData((prevState) => ({
      ...prevState,
      loadingSave: true,
    }));

    try {
      const requestBody = {
        name: formData.name,
        description: formData.descricao,
        conteudo_ids: formData.selectedContents.map((content) => content.id),
      };

      await post("api/modulo/store_module", requestBody, token);

      setFormData((prevState) => ({
        ...prevState,
        msgErro: "Módulo cadastrado com sucesso",
      }));
      history.push("/modulos");
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao cadastrar módulo",
      }));
    } finally {
      setFormData((prevState) => ({
        ...prevState,
        loadingSave: false,
      }));
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const selectedContents = [...formData.selectedContents];
    const [reorderedItem] = selectedContents.splice(result.source.index, 1);
    selectedContents.splice(result.destination.index, 0, reorderedItem);

    setFormData((prevState) => ({
      ...prevState,
      selectedContents,
    }));
  };

  const handleRemoveContent = (id) => {
    setFormData((prevState) => {
      const conteudoIds = prevState.conteudoIds.filter(
        (conteudoId) => conteudoId !== id
      );
      const selectedContents = prevState.selectedContents.filter(
        (content) => content.id !== id
      );

      return {
        ...prevState,
        conteudoIds,
        selectedContents,
      };
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div
            className="card-body card-body-custom"
            id="card"
            style={{ overflow: "visible" }}
          >
            <h4
              className="mt-0 header-title header-title-custom mb-3"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Cadastrar Módulo
            </h4>
            <p
              className="paragraph-custom mb-3"
              style={{ fontSize: "1rem", color: "black" }}
            >
              Cadastre os módulos do seu sistema
            </p>
            {formData.msgErro !== "" && (
              <div style={{ display: "flex", width: "100%", marginBottom: 15 }}>
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        msgErro: "",
                      }))
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{formData.msgErro}</p>
                </div>
              </div>
            )}
            <form onSubmit={salvar} className="position-relative">
              <div className="form-group flex fdcolumn flex1 margin-bottom">
                <label htmlFor="nome" className="label-custom mb-2">
                  Nome <Attention />
                </label>
                <input
                  type="text"
                  className="form-control input-custom"
                  id="nome"
                  name="name"
                  placeholder="Nome do módulo"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group flex fdcolumn flex1 margin-bottom">
                <label htmlFor="descricao" className="label-custom mb-2">
                  Descrição
                </label>
                <textarea
                  className="form-control textarea-custom"
                  id="descricao"
                  name="descricao"
                  placeholder="Descrição do módulo"
                  value={formData.descricao}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group flex fdcolumn flex1 margin-bottom">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Selecione Conteúdos
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {formData.contents.map((content) => (
                      <Dropdown.Item
                        key={content.id}
                        as="div"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Form.Check
                          type="checkbox"
                          label={content.name}
                          checked={formData.conteudoIds.includes(content.id)}
                          onChange={() => {
                            handleSelectChange(content.id);
                          }}
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="form-group mb-3">
                <label
                  htmlFor="conteudos_vinculados"
                  className="label-custom mb-2"
                >
                  Conteúdos vinculados <Attention />
                </label>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="selectedContents">
                    {(provided) => (
                      <ul
                        className="list-group mb-3"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {formData.selectedContents.length === 0 ? (
                          <li className="list-group-item">
                            Nenhum conteúdo selecionado
                          </li>
                        ) : (
                          formData.selectedContents.map((content, index) => (
                            <Draggable
                              key={content.id.toString()}
                              draggableId={content.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {content.name}
                                  <button
                                    type="button"
                                    className="btn btn-link p-0"
                                    onClick={() =>
                                      handleRemoveContent(content.id)
                                    }
                                  >
                                    <RiCloseCircleLine size={20} color="red" />
                                  </button>
                                </li>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <div className="w-100 d-flex justify-content-between mt-3">
                <DefaultButton
                  bg="secondary"
                  text="Voltar"
                  icon={<RiArrowGoBackLine />}
                  onClick={() => history.push("/modulos")}
                />
                <DefaultButton
                  type="submit"
                  bg="confirm"
                  text="Salvar módulo"
                  disabled={formData.loadingSave}
                  loadingtext="Salvando módulo"
                  loading={formData.loadingSave}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(CriarModulo);
