import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ResultadosRedacaoIA from "./ResultadosRedacaoIA";
import { fetchChatBotResponse } from "../../Auxiliar/servicesChatBotNoLimit";
import axios from "axios";
import { API_URL } from "../../variables";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";


const RedacaoIA = ({
  processo_name,
  processo_id,
  redacao_name,
  redacao_id,
  token, // Adicionando o token como prop
}) => {
  const [showModal, setShowModal] = useState(false);
  const [redacaoTexto, setRedacaoTexto] = useState("");
  const [chatbotResponse, setChatbotResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [temaRedacao, setTemaRedacao] = useState(""); // Nova variável para o tema da redação
  const [selectedRedacoes, setSelectedRedacoes] = useState([]);
  const [redacoes, setRedacoes] = useState([]); // Add missing redacoes state
  const [showGenerateAgain, setShowGenerateAgain] = useState(false);

  const loadingPhrases = [
    "Analisando sua redação...",
    "Identificando pontos principais...",
    "Avaliando argumentação...",
    "Verificando coerência textual...",
    "Aplicando critérios de correção...",
    "Gerando feedback personalizado...",
    "Quase lá..."
  ];

  const [currentPhrase, setCurrentPhrase] = useState(loadingPhrases[0]);

  useEffect(() => {
    if (loading) {
      let phraseIndex = 0;
      const interval = setInterval(() => {
        phraseIndex = (phraseIndex + 1) % loadingPhrases.length;
        setCurrentPhrase(loadingPhrases[phraseIndex]);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleShow = async (redacaoId) => {
    console.log("ID da Redação:", redacaoId); // Adiciona o console.log aqui
    setShowModal(true);
    setLoading(true);
    setShowGenerateAgain(false); // Resetar o estado do botão "Gerar Novamente"

    try {
      // Fazer a requisição GET para verificar se já existe uma correção
      const getResponse = await axios.get(
        `http://localhost:8000/api/correcao_redacao_ia/${redacaoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (getResponse.data) {
        // Se a requisição GET retornar uma correção, exibir essa correção no modal
        const {
          nota,
          competencia_1,
          competencia_2,
          competencia_3,
          competencia_4,
          competencia_5,
        } = getResponse.data;
        const response = `
          <nota>${nota}</nota>
          **Competência 1**${competencia_1}
          **Competência 2**${competencia_2}
          **Competência 3**${competencia_3}
          **Competência 4**${competencia_4}
          **Competência 5**${competencia_5}
        `;
        setChatbotResponse(response);
      } else {
        // Se a requisição GET não retornar nada, fazer a requisição para a IA
        await fetchAndSetChatbotResponse(redacaoId, temaRedacao, redacaoTexto);
      }
    } catch (error) {
      console.error("Erro ao obter a correção existente:", error);
      // Se a requisição GET der erro, fazer a requisição para a IA
      await fetchAndSetChatbotResponse(redacaoId, temaRedacao, redacaoTexto);
    } finally {
      setLoading(false);
    }
  };

  const fetchAndSetChatbotResponse = async (
    redacaoId,
    temaRedacao,
    redacaoTexto
  ) => {
    try {
      const response = await fetchChatBotResponse(temaRedacao, redacaoTexto);
      setChatbotResponse(response);

      // Extract data from chatbot response
      const notaMatch = response.match(/<nota>(.*?)<\/nota>/);
      const nota = notaMatch ? notaMatch[1] : "N/A";

      const competencias = response
        .split("**Competência")
        .slice(1)
        .map((competencia, index) => {
          const parts = competencia.split("**");
          return parts[1] ? parts[1].trim() : "";
        });

      // Prepare data for POST request
      const postData = {
        id_texto_redacao: redacaoId, // Use the redacaoId here
        processo_id: processo_id,
        nota: parseInt(nota, 10),
        competencia_1: competencias[0] || "",
        competencia_2: competencias[1] || "",
        competencia_3: competencias[2] || "",
        competencia_4: competencias[3] || "a",
        competencia_5: competencias[4] || "a",
      };

      // Send POST request
      await axios.post(
        "http://localhost:8000/api/correcao_redacao_ia",
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(
        "Erro ao obter a resposta do chatbot ou enviar os dados:",
        error
      );
      setShowGenerateAgain(true); // Mostrar o botão "Gerar Novamente" em caso de erro
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setChatbotResponse("");
    setRedacaoTexto("");
    setTemaRedacao(""); // Resetar o tema da redação
  };

  const corrigirRedacao = async (redacoes) => {
    const data = {
      processo_id: processo_id,
      redacoes: redacoes.map((redacao) => ({
        id: redacao.id,
        texto: redacao.texto,
      })),
    };

    try {
      const response = await axios.post(
        `${API_URL}faculdade/corrigir-todas-redacoes`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      alert("Correção iniciada com sucesso!");
    } catch (error) {
      console.error("Erro ao corrigir a redação:", error);
      alert("Erro ao corrigir a redação. Tente novamente mais tarde.");
    }
  };

  const handleSelectRedacao = (redacao) => {
    setSelectedRedacoes((prevSelected) =>
      prevSelected.includes(redacao.id)
        ? prevSelected.filter((redacaoId) => redacaoId !== redacao.id)
        : [...prevSelected, redacao.id]
    );
  };

  const handleSelectAllRedacoes = () => {
    if (selectedRedacoes.length === redacoes.length) {
      setSelectedRedacoes([]);
    } else {
      setSelectedRedacoes(redacoes.map((redacao) => redacao.id));
    }
  };

  const renderChatbotResponse = () => {
    const notaMatch = chatbotResponse.match(/<nota>(.*?)<\/nota>/);
    const nota = notaMatch ? notaMatch[1] : "N/A";

    const competencias = chatbotResponse
      .split("**Competência")
      .slice(1)
      .map((competencia, index) => {
        const parts = competencia.split("**");
        return {
          title: `Competência ${index + 1}:`,
          content: parts[1] ? parts[1].trim() : "",
        };
      });

    return (
      <div>
        <h3>Nota: {nota}</h3>
        {competencias.map((competencia, index) => (
          <div key={index}>
            <h4>{competencia.title}</h4>
            <p>{competencia.content}</p>
          </div>
        ))}
      </div>
    );
  };

  const handleGenerateAgain = () => {
    handleShow(redacao_id); // Chama a função handleShow novamente com o redacao_id atual
  };

  return (
    <div>
      <h2>Corrigir com IA</h2>
      <p>Processo: {processo_name}</p>
      <p>Redação: {redacao_name}</p>
      <ResultadosRedacaoIA
        processo_name={processo_name}
        processo_id={processo_id}
        redacao_name={redacao_name}
        redacao_id={redacao_id}
        onVisualizarCorrecao={handleShow} // Passando a função handleShow como prop
        onCorrigirRedacao={corrigirRedacao} // Passando a função corrigirRedacao como prop
        onSelectRedacao={handleSelectRedacao} // Passando a função handleSelectRedacao como prop
        onSelectAllRedacoes={handleSelectAllRedacoes} // Passando a função handleSelectAllRedacoes como prop
        selectedRedacoes={selectedRedacoes} // Passando as redações selecionadas como prop
      />

      <Modal show={showModal} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Correção da Redação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress style={{ marginBottom: '20px' }} />
              <p>{currentPhrase}</p>
            </div>
          ) : (
            renderChatbotResponse()
          )}
        </Modal.Body>
        <Modal.Footer>
          {showGenerateAgain && (
            <Button variant="primary" onClick={handleGenerateAgain}>
              Gerar Novamente
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(RedacaoIA);
