import React from 'react';
import Routes from './routes';
import {useSelector, useDispatch} from 'react-redux'

import Request from "./Request";
import { QueryClient, QueryClientProvider } from 'react-query';

import "./css/index.css";
import "./css/courses.css";
import "./css/tablecard.css";
import "./css/lessons.css";
import "./css/buttons.css";
import "./css/skeleton-styles.css";
const queryClient = new QueryClient();

function App()  {
    const dispatch=useDispatch();
    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    
    if(user==null || user== undefined){
        user={};
    }
    console.log(token);
    dispatch({type:'login',payload:{token:token,user:user}});
    return (
        <QueryClientProvider client={queryClient}>

        <Routes />
        </QueryClientProvider>

    );
}

export default App;
