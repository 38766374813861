import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Router } from "react-router-dom";

import Home from "./Home/Home";
import Login from "./Auth/Login";
import { useSelector, useDispatch } from 'react-redux'
import ProvaUuid from "./Auth/ProvaUuid";
import ShowDiagramacaoAluno from "./Home/Diagramacoes/ShowDiagramacaoAluno";


const PrivateRoute = ({ component: Component, ...rest }) => {
    // const stream = useSelector(state => state.AppReducer.stream);
    const token = useSelector(state => state.AppReducer.token)

    return (
        <Route
            {...rest}
            render={props =>
                token != null ? (
                    <Component {...props}>
                    </Component>
                ) : (
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )
            }
        />)
};

const LoginRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.AppReducer.token);
    return (
        <Route
            {...rest}
            render={props =>
                token == null ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
            }
        >

        </Route>
        );
};

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);

const Routes = () => {
    const token = useSelector(state => state.AppReducer.token)
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={`/diagramacoes/show/:id/:user_id`} render={(props2) => <ShowDiagramacaoAluno {...props2} />} />
                <Route path="/prova/:uuid" component={(props) => <ProvaUuid {...props} />} />
                <LoginRoute path="/login" component={(props) => <Login {...props} />} />
                <PrivateRoute path="/" component={(props) => <Home {...props} />} />

                <OtherRoute path="*" path_name='/'/>
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;