import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaPlus, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdPlusOne, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import { API_URL, STORAGE_FILES_URL, URL } from "../../variables";
import ModalAddZip from "./ModalAddZip";



const ResultadosRedacao = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_zip, setLoadingZip] = useState(false);
    const [showModalRedacao, setShowModalRedacao] = useState(false);


    const [loading_redacoes, setLoadingredacoes] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');
    const [message_zip, setMessageZip] = useState('');


    const [redacoes, setredacoes] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { redacao_id, redacao_name, processo_id, processo_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [redacoes_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();
    const timerRef = useRef(null);

    const Getredacoes = async (page, search) => {

        let response = await Request.Get(`faculdade/get_resultados_redacao/${redacao_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setredacoes(response.redacoes);


            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingredacoes(false);


        }
    }




    const GetRedacoesZip = async () => {

        let response = await Request.Get(`faculdade/download_redacoes/${redacao_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'redacoes_processo_'+processo_name+'.zip');
        // }
        else {
            if (response['status'] == true) {
                document.getElementById('download_link').href = response['path'];
                document.getElementById('download_link').click();
                setLoadingZip(false);
                setMessageZip('');

                return;
            }
            else if (response['status'] != 'Error') {
                setMessageZip(response['message']);
            }
            timerRef.current = setTimeout(() => {
                GetRedacoesZip();
            }, 20000);

        }


    }

    const DeleteRedacao = async (redacoes_id) => {

        let response = await Request.Delete(`faculdade/desvincular_redacao/${redacoes_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getredacoes(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getredacoes(1, search_oficial);

        return () => {
            clearTimeout(timerRef.current)
        }
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as respostas da redação "{redacao_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}
                    <a id="download_link" target="_blank"></a>
                    <div className="flex jcsb flex-wrap mb-4">
                        <Search placeholder="Pesquisar pelo nome do candidato" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingredacoes(true);

                                Getredacoes(1, value)

                            }, 500);
                        }} />
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                        {<span onClick={() => {
                           setShowModalRedacao(true);
                        }} style={{ textDecoration: "none",marginRight:10 }}>
                            {<Button variant="success" className="flex align-center jcc"><FaPlus style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar .zip/.rar de redações externas</p></Button>}

                        </span>}

                        {loading_zip == false && <span onClick={() => {
                            if (loading_zip == false) {
                                setLoadingZip(true);
                                setMessageZip('Criando .zip para download');
                                GetRedacoesZip();
                            }
                            else {

                            }
                        }} style={{ textDecoration: "none" }}>
                            {<Button variant="success" className="flex align-center jcc"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar todas redações</p></Button>}

                        </span>}
                        {loading_zip == true && <div style={{ flexBasis: "50%", paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                            {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                            <CircularProgress size={15} />
                        </div>
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                            </div>
                        </div>}
                        </div>
                    </div>
                    {showModalRedacao==true && <ModalAddZip redacao_id={redacao_id} showModal={showModalRedacao} setShowModal={(e)=>{

                        setShowModalRedacao(e)

                        setLoadingScreen(true);
                        Getredacoes(1, search_oficial);

                    }} />}
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>CPF</th>
                                        <th>Status</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_redacoes == false && redacoes.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.user.name}</td>
                                            <td>{item.user.email}</td>
                                            <td>{item.user.cpf}</td>
                                            {item.processo_resultado.status == 'F' && <td style={{ color: 'green' }}>{'Finalizado'}</td>}
                                            {item.processo_resultado.status == 'I' && <td style={{ color: 'darkgoldenrod' }}>{'Não Finalizado'}</td>}
                                            {item.processo_resultado.status == 'Não iniciado' && <td style={{ color: 'darkgoldenrod' }}>{'Não Iniciado'}</td>}
                                            {item.processo_resultado.status == 'Ausente' && <td style={{ color: 'ref' }}>{'Ausente'}</td>}





                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>
                                                {(item.processo_resultado.status == 'F' || item.processo_resultado.status == 'I') ? <a target="_blank" href={item.file==null? (URL+'api/faculdade/download_redacao/'+item.id):(item.file)}>

                                                    <span
                                                        onClick={() => {

                                                        }}
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Baixar relatório"
                                                    >
                                                        <MdFileDownload size={17} color="white" />
                                                    </span>
                                                </a> : '-'}
                                                {/* <span
                                                    onClick={() => {
                                                        GetVideoCandidato(item);

                                                    }}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar Vídeo"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span> */}
                                                {/* <Link to={`/redacoes_vinculados/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link> */}
                                                {/* <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular redacoes"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_redacoes == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_redacoes == false && redacoes.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma redação encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingredacoes(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getredacoes(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_redacoes={this.get_redacoes.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingredacoes(true);
                        DeleteRedacao(redacoes_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este redacao, ele não terá mais acesso a este processo
                </SweetAlert>


            </div>

        </div>
    )
}

export default ResultadosRedacao;
