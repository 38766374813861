import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';

import './modal.css';
import { THIS_URL } from "../../variables";


const AnalisesMovimentoFacial = props => {
    const [select_image, SetSelectImage] = useState('');

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_logs, setLoadinglogs] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [logs, setlogs] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, processo_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [logs_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const [loading_zip, setLoadingZip] = useState(false);
    const [message_zip, setMessageZip] = useState('');


    const [loading_zip2, setLoadingZip2] = useState(false);
    const [message_zip2, setMessageZip2] = useState('');


    const Getlogs = async (page, search) => {

        let response = await Request.Get(`faculdade/get_analises_movimento_facial/${processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setlogs(response.logs);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadinglogs(false);


        }
    }

    const timerRefExcel = useRef(null);

    const GetExcelFile = async () => {

        let response = await Request.Get(`faculdade/generate_excel/${processo_id}`);
        console.log(response);

        if (response === undefined) return;
        if (response.message == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'provas_processo_'+processo_name+'.zip');
        // }
        else {

            if(response['status']==true){
                document.getElementById('download_link').href=response['path'];
                document.getElementById('download_link').click();
                setLoadingZip(false);
                setMessageZip('');

                return;
            }
            else if(response['status']!='Error'){
                setMessageZip(response['message']);
            }
            timerRef.current = setTimeout(()=>{
                GetExcelFile();
            },20000);
            // saveAs(response, 'analises_candidatos_' + processo_name + '.xlsx');


        }


    }
    const timerRef = useRef(null);

    const GetPdfsZip = async () => {

        let response = await Request.Get(`faculdade/download_pdfs_analises/`+processo_id);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'redacoes_processo_'+processo_name+'.zip');
        // }
        else{
            if(response['status']==true){
                document.getElementById('download_link').href=response['path'];
                document.getElementById('download_link').click();
                setLoadingZip2(false);
            setMessageZip2('');

                return;
            }
            else if(response['status']!='Error'){
                setMessageZip2(response['message']);
            }
            timerRef.current = setTimeout(()=>{
                GetPdfsZip();
            },20000);

        }


    }


    useEffect(() => {
        Getlogs(1, search_oficial);

        return () => {
            clearTimeout(timerRef.current)
            clearTimeout(timerRefExcel.current)

        }
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            {<div id="myModal" className="modal_2">

                <span onClick={() => {
                    var modal = document.getElementById("myModal");
                    modal.style.display = "none";
                    SetSelectImage('');
                }} className="close">&times;</span>

                <img className="modal-content_2" id="img01" />

                <div id="caption"></div>
            </div>}
            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as análises dos candidatos vinculados ao processo "{processo_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}
                    <a id="download_link" target="_blank"></a>

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search className="mt-2" placeholder="Pesquisar pelo nome do candidato" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadinglogs(true);

                                Getlogs(1, value)

                            }, 500);
                        }} />





                        {/* <div className="d-flex mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>
                            {<div onClick={() => {
                                if (loading_zip2 == false) {
                                    setLoadingZip2(true);
                                    setMessageZip2('Criando .zip para download');
                                    GetPdfsZip();
                                }
                                else {

                                }
                            }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center',maxWidth:'50%' }}>
                                {loading_zip2 == false && <Button variant="success" className="flex align-center jcc me-2"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar análises em pdf</p></Button>}
                                {loading_zip2 == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <CircularProgress size={15} />
                                </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center" >
                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip2}</p>
                                    </div>
                                </div>}
                            </div>}

                            {<div onClick={() => {
                                if (loading_zip == false) {
                                    setLoadingZip(true);
                                    setMessageZip('Criando .xlsx para download');
                                    GetExcelFile();
                                }
                                else {

                                }
                            }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center',maxWidth:'50%' }}>
                                {loading_zip == false && <Button variant="success" className="flex align-center jcc me-2"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar planilha excel</p></Button>}
                                {loading_zip == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <CircularProgress size={15} />
                                </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                                    </div>
                                </div>}
                            </div>}



                        </div> */}
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        {/* <th>E-mail</th> */}
                                        <th>CPF</th>
                                        {/* <th>Foto de Inscrição</th> */}
                                        <th>Foto de Entrada</th>
                                        <th>Estatísticas</th>


                                        {/* <th>Data de nascimento</th> */}

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_logs == false && logs.map((item, id) => (

                                        <tr height="110px" key={id}>
                                            <td>{item.user.name}</td>
                                            {/* <td>{item.user.email}</td> */}
                                            <td>{item.user.cpf}</td>

                                            {/* <td>
                                                {item.user.fotos.map((item2, id2) => (
                                                    <div key={id2} style={{ marginRight: 5 }}>
                                                        <img className="myImg" onClick={() => {
                                                            var modal = document.getElementById("myModal");
                                                            var modalImg = document.getElementById("img01");
                                                            modalImg.src = item2.foto;
                                                            SetSelectImage(item2.foto);
                                                            modal.style.display = "block";
                                                        }} src={item2.foto} style={{ width: 100, height: 80, objectFit: 'contain' }} />
                                                    </div>
                                                ))}
                                                {item.user.fotos.length==0 && '-'}

                                            </td> */}

                                            <td>
                                                
                                                    {item.foto_entrada!=null && <div  style={{ marginRight: 5 }}>
                                                        <img className="myImg" onClick={() => {
                                                            var modal = document.getElementById("myModal");
                                                            var modalImg = document.getElementById("img01");
                                                            modalImg.src = item.foto_entrada;
                                                            SetSelectImage(item.foto_entrada);
                                                            modal.style.display = "block";
                                                        }} src={item.foto_entrada} style={{ width: 100, height: 80, objectFit: 'contain' }} />
                                                    </div>}
                                                    {item.foto_entrada==null && '-'}


                                            </td>
                                            <td>
                                                <p style={{marginBottom:2}}><b>Movimento Horizontal:</b> </p>
                                                <p style={{marginBottom:2}}><b>Esquerda:</b> {parseFloat((item.left_face/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p style={{marginBottom:2}}><b>Centro:</b> {parseFloat((item.center_face/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p style={{marginBottom:2}}><b>Direita:</b> {parseFloat((item.right_face/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p><b>Sem face:</b> {parseFloat((item.no_face/item.total_frames)*100).toFixed(2)+'%'}</p>

                                                <p style={{marginBottom:2}}><b>Movimento Vertical:</b> </p>


                                                <p style={{marginBottom:2}}><b>Cima:</b> {parseFloat((item.up_face/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p style={{marginBottom:2}}><b>Meio:</b> {parseFloat((item.middle_face/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p style={{marginBottom:2}}><b>Baixo:</b> {parseFloat((item.down_face/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p><b>Sem face:</b> {parseFloat((item.no_face/item.total_frames)*100).toFixed(2)+'%'}</p>


                                                <p style={{marginBottom:2}}><b>Face Detectada:</b> </p>

                                                <p style={{marginBottom:2}}><b>Detectada:</b> {parseFloat((item.face_detected_true/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p ><b>Não detectada:</b> {parseFloat((item.face_detected_false/item.total_frames)*100).toFixed(2)+'%'}</p>

                                                <p style={{marginBottom:2}}><b>Múltiplas Faces Detectadas:</b> </p>


                                                <p style={{marginBottom:2}}><b>Detectada:</b> {parseFloat((item.multiple_faces_detected_true/item.total_frames)*100).toFixed(2)+'%'}</p>
                                                <p ><b>Não detectada:</b> {parseFloat((item.multiple_faces_detected_false/item.total_frames)*100).toFixed(2)+'%'}</p>
                                            </td>

                                            {/* <td height="110px" style={{ whiteSpace: 'pre-line', textOverflow: 'ellipsis', maxHeight: 110, overflowY: 'scroll' }}>
                                               

                                            </td> */}

                                            {/* <td>{moment(item.nascimento).format('DD/MM/YYYY')}</td> */}

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>

                                            <a target="_blank" href={`${THIS_URL}candidatos_vinculados/show/${item.user.id}?tab=3`} onClick={(e) => e.stopPropagation()}>

                                                    <span

                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar candidato"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span>
                                                </a>
                                                {/* <span
                                                    onClick={() => {
                                                        GetRelatórioCandidato(item);
                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar relatório"
                                                >
                                                    <MdFileDownload size={17} color="white" />
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        GetVideoCandidato(item);

                                                    }}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar Vídeo"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span>

                                                <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular logs"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_logs == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_logs == false && logs.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma análise encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadinglogs(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getlogs(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_logs={this.get_logs.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}




            </div>

        </div>
    )
}

export default AnalisesMovimentoFacial;
