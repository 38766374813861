




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL,STORAGE_URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdOutlineRule, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import TabPanel from "../ReusableComponents/TabPanel";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";



class ShowSala extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            faculdade: {},
            processo: {},
            show_more: false,

            activeTab: 0
        };

    }


    get_sala(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_sala/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_sala();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, processo: resp.sala.processo, sala: resp.sala
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    componentDidMount() {
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.get_sala(this.props.match.params.id);
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div style={{ position: "relative" }}>
                <div className="card">
                    <div className="card-body">
                        <div style={{ float: 'right' }}>
                            <DefaultButton
                                bg="secondary"
                                text="Voltar"
                                icon={<RiArrowGoBackLine />}
                                search={'?tab=4'}
                                to={`/processos/show/${this.state.processo.id}`}
                            />
                        </div>
                        <div className="flex jcsb">
                            <h4 className="header">Sala {this.state.sala.numero}</h4>
                        </div>
                        <TextReadMore title="Processo: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                            {this.state.processo.name}
                        </TextReadMore>
                        <br />
                        <br />

                        <Tabs
                            value={this.state.activeTab}
                            onChange={(_, value) => {
                                this.setState({ activeTab: value });
                                this.props.history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label="Candidatos" />
                            <Tab label="Fiscais" />
                            <Tab label="Corretores" />


                        </Tabs>
                        <TabPanel value={this.state.activeTab} index={0}>

                            <p className="text-muted">Aqui ficam os candidatos da Sala "{this.state.sala.numero}"</p>

                            {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                            {/* <br /> */}



                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <table id="tech-companies-1" className="table table-striped">
                                        <thead>
                                            <tr>

                                                <th>Nome</th>
                                                <th>E-mail</th>
                                                <th>CPF</th>
                                                <th>Data de nascimento</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.sala.candidatos.map((item, id) => (

                                                <tr key={id}>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.cpf}</td>
                                                    <td>{moment(item.nascimento).format('DD/MM/YYYY')}</td>
                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}



                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            {this.state.sala.candidatos.length == 0 && <div className="row w-100">
                                <div className="col-12">
                                    <p style={{ textAlign: 'center' }}>Nenhum candidato encontrado</p>

                                </div>
                            </div>}


                        </TabPanel>
                        <TabPanel value={this.state.activeTab} index={1}>

                            <p className="text-muted">Aqui ficam os fiscais da Sala "{this.state.sala.numero}"</p>

                            {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                            {/* <br /> */}



                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <table id="tech-companies-1" className="table table-striped">
                                        <thead>
                                            <tr>

                                                <th>Nome</th>
                                                <th>E-mail</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.sala.fiscais.map((item, id) => (

                                                <tr key={id}>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>

                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}



                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            {this.state.sala.fiscais.length == 0 && <div className="row w-100">
                                <div className="col-12">
                                    <p style={{ textAlign: 'center' }}>Nenhum fiscal encontrado</p>

                                </div>
                            </div>}
                            {/* <Corretores faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} /> */}

                        </TabPanel>
                        <TabPanel value={this.state.activeTab} index={2}>


                            <p className="text-muted">Aqui ficam os corretores da Sala "{this.state.sala.numero}"</p>

                            {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                            {/* <br /> */}



                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <table id="tech-companies-1" className="table table-striped">
                                        <thead>
                                            <tr>

                                                <th>Nome</th>
                                                <th>E-mail</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.sala.corretores.map((item, id) => (

                                                <tr key={id}>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>

                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}



                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            {this.state.sala.corretores.length == 0 && <div className="row w-100">
                                <div className="col-12">
                                    <p style={{ textAlign: 'center' }}>Nenhum corretor encontrado</p>

                                </div>
                            </div>}

                            {/* <AddToCourse/> */}
                            {/* <Fiscais faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} /> */}

                        </TabPanel>




                    </div>
                </div>

            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowSala);


