import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import update from 'immutability-helper'

import makeData from './makeData'
import { MdDelete } from 'react-icons/md'
import { BsFillEyeFill } from 'react-icons/bs'

// const Styles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//   }
// `

const Table = ({ columns, data,setData,showData,deleteData,draggable }) => {
    // const [records, setRecords] = React.useState(data)

    const getRowId = React.useCallback(row => {
        return row.id
    }, [])
    console.log(data)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        data: data,
        columns,
        getRowId,
    })

    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = data[dragIndex]
        setData(
            update(data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        )
    }

    // useEffect(() => {
    //     setRecords(data);
    // }, [data]);
    let index_real_questions=1;
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="flex fdcolumn flex1 margin-bottom col-12">
                <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <table {...getTableProps()} className="table table-striped">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        <th>N.º</th>

                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))}
                                        <th>Ações</th>

                                    </tr>
                                ))}
                                {/* <tr>
                                    <th>N.º</th>

                                    <th>Enunciado</th>
                                    <th>Categoria</th>
                                    <th>Tipo de questão</th>

                                    <th data-priority="3">Ações</th>
                                </tr> */}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(
                                    (row, index) =>{
                                      
                                        let index_='';
                                        let number_questoes=1;
                                        if(row.original.tipo_questao.props.children.includes('Conjunto de questões')){
                                            number_questoes=parseInt(row.original.tipo_questao.props.children.split('\n')[1].split(' ')[0]);
                                        }
                                        if(number_questoes>1){
                                            index_=index_real_questions+'-'+(index_real_questions+number_questoes-1);
                                            index_real_questions+=number_questoes;
                                        }
                                        else{
                                            index_=index_real_questions;
                                        index_real_questions=index_real_questions+1;

                                        }
                                    
                                        return prepareRow(row) || (
                                            <Row
                                            draggable={draggable}
                                                index={index}
                                                index_real={index_}

                                                row={row}
                                                showData={showData}
                                                deleteData={deleteData}
                                                moveRow={moveRow}
                                                {...row.getRowProps()}
                                            />
                                        )
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {data.length == 0 && <div className="row w-100">
                    <div className="col-12">
                        <p style={{ textAlign: 'center' }}>Nenhuma questão adicionada</p>

                    </div>
                </div>}
            </div>
        </DndProvider>
    )
}

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, moveRow,showData,deleteData,draggable=true,index_real }) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)

    const noneRef = React.useRef(null)


    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!dropRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DND_ITEM_TYPE, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)
    return (
        <tr ref={dropRef} style={{ opacity }}>
            <td ref={draggable?dragRef:noneRef} style={{cursor:'all-scroll'}}>{index_real}</td>

            {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
            <td style={{ minWidth: 90 }}>
                <span
                    onClick={() => {
                        showData(index);
                        // this.setState({ questaoSelect: item, showModal: true });
                    }}
                    className="inline-flex align-center jcc bg-warning icon"
                    style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: 5 }}
                    title="Gerenciar questão"
                >
                    <BsFillEyeFill size={17} color="white" />
                </span>
                <span
                    onClick={(e) => {
                        deleteData(index);
                        // let questoes_selecionadas = { ...this.state.questoes_selecionadas };
                        // delete questoes_selecionadas[item.id];
                        // this.setState({ questoes_selecionadas });
                    }}
                    className="inline-flex align-center jcc bg-danger icon"
                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                    title="Deletar questão"
                >
                    <MdDelete size={17} color="white" />
                </span>
            </td>
        </tr>
    )
}

const App = (props) => {
    // const columns = React.useMemo(
    //     () => [
    //         {
    //             Header: 'ID',
    //             accessor: 'id',
    //         },
    //         {
    //             Header: 'Name',
    //             columns: [
    //                 {
    //                     Header: 'First Name',
    //                     accessor: 'firstName',
    //                 },
    //                 {
    //                     Header: 'Last Name',
    //                     accessor: 'lastName',
    //                 },
    //             ],
    //         },
    //         {
    //             Header: 'Info',
    //             columns: [
    //                 {
    //                     Header: 'Age',
    //                     accessor: 'age',
    //                 },
    //                 {
    //                     Header: 'Visits',
    //                     accessor: 'visits',
    //                 },
    //                 {
    //                     Header: 'Status',
    //                     accessor: 'status',
    //                 },
    //                 {
    //                     Header: 'Profile Progress',
    //                     accessor: 'progress',
    //                 },
    //             ],
    //         },
    //     ],
    //     []
    // )

    const columns = React.useMemo(
        () => [

            {
                Header: 'Enunciado',
                accessor: 'enunciado',
            },
            {
                Header: 'Categoria',
                accessor: 'categoria',

            },
            {
                Header: 'Tipo de questão',
                accessor: 'tipo_questao',
            },
            // {
            //     Header: 'Ações',
            //     accessor: 'acoes',
            // },
        ],
        []
    )

    // const data = React.useMemo(() => makeData(3), [])
    // console.log(data);

    return (
        <Table columns={columns} draggable={props.draggable} data={props.data} setData={props.setData} showData={props.showData} deleteData={props.deleteData} />
    )
}

export default App
